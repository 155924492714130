import { ProductLayout } from "./ProductLayout";

export function ProductListHeader() {
  return (
    <div className="flex my-2 mx-8 font-bold border-b-2">
      <ProductLayout>
        <div className="w-full">ProduktNr</div>
        <div className="w-full">EAN</div>
        <div className="w-full">Beskrivelse</div>
        <div className="w-full">Lvnavn</div>
        <div className="w-full">LevAlt</div>
        <div className="w-full">BID</div>
        <div className="w-full">Moms</div>
        <div className="w-full text-right mr-1">Antall</div>
        <div className="w-full text-right mr-1">Kalk</div>
        <div className="w-full text-right mr-1">Kostpris</div>
        <div className="w-full text-right mr-1">Utpris</div>
        <div className="w-full text-right mr-1">Total Pris</div>
      </ProductLayout>
    </div>
  );
}
