import { TELENOR_API_URL } from "../env";
import { LoginModel, LoginModelMapper } from "../models/login/LoginModel";

const apiUrl = TELENOR_API_URL;

export const fetchAllUsers: (uid: string) => Promise<LoginModel[]> = async (uid) => {
  try {
    const url = `${apiUrl}/users`;
    const headers = new Headers();
    headers.append("Auth0-User", uid);
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (res.ok) {
      const data = await res.json();
      const users = data.map((item: any) => LoginModelMapper.fromBackendObject(item));
      return users;
    } else {
      throw new Error("Cannot fetch users.");
    }
  } catch (err: any) {
    console.log("Error:", err.message);
    throw err;
  }
};

export const fetchUser: (userId: string, uid: string) => Promise<LoginModel> = async (userId, uid) => {
  try {
    const url = `${apiUrl}/users/${userId}`;
    const headers = new Headers();
    headers.append("Auth0-User", uid);
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (res.ok) {
      const data = await res.json();
      const user = LoginModelMapper.fromBackendObject(data);
      return user;
    } else {
      throw new Error("Cannot fetch the user.");
    }
  } catch (err: any) {
    console.log("Error:", err.message);
    throw err;
  }
};

export const createUser: (user: LoginModel, uid: string) => Promise<void> = async (user, uid) => {
  try {
    const url = `${apiUrl}/users`;
    const headers = new Headers();
    headers.append("Auth0-User", uid);
    const res = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(user),
    });

    console.log("User created: ", res.body);

    if (res.ok) {
    } else {
      throw new Error("Can't add a new user");
    }
  } catch (err: any) {
    console.log("Error:", err.message);
    throw err;
  }
};

export const updateUser: (userId: string, user: LoginModel, uid: string) => Promise<void> = async (
  userId,
  user,
  uid
) => {
  const url = `${apiUrl}/users/${userId}`;
  const headers = new Headers();
  headers.append("Auth0-User", uid);
  const res = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(user),
  });

  if (!res.ok) {
    throw new Error();
  }
};

export const deleteUser: (userId: string, uid: string) => Promise<void> = async (userId, uid) => {
  console.log("DELETING USER", userId);
  const url = `${apiUrl}/users/${userId}`;
  const headers = new Headers();
  headers.append("Auth0-User", uid);
  const res = await fetch(url, {
    method: "DELETE",
    headers: headers,
  });
  console.log(res);

  if (!res.ok) {
    throw new Error("User could not be deleted.");
  }
};
