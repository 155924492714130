export const CheckboxField = ({
  label,
  checkable,
  checked,
  onCheckedChanged,
}: {
  label: string;
  checkable?: boolean;
  checked?: boolean;
  onCheckedChanged: (checked: boolean) => void;
}) => {
  return (
    <div className="flex h-7 m-1">
      <input
        className="w-4 h-4 my-auto"
        id={label}
        type="checkbox"
        checked={checked}
        disabled={!checkable}
        onChange={(e) => {
          onCheckedChanged(e.target.checked);
        }}
      />
      <label className="ml-2 my-auto" htmlFor={label}>
        {label}
      </label>
    </div>
  );
};
