const LoadingDialog = ({ visible }: { visible: boolean }) => {
  return (
    <div
      className={`${
        visible ? "flex" : "hidden"
      } modal fixed z-50 top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto bg-gray-200 bg-opacity-50 p-16`}
    >
      <div className="flex m-auto select-none">
        <img className="h-32 w-32" src="/assets/images/loading.gif" alt="loading" />
      </div>
    </div>
  );
};

export default LoadingDialog;
