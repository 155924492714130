import ProductModel from "../models/ProductModel";

function getProductPriceAndProfit(price: number, outPrice: number, quantity: number): number[] {
  return [quantity * outPrice, quantity * (outPrice - price)];
}

export function getTotalPriceAndProfit(productLines?: ProductModel[]) {
  let totalPrice = 0;
  let totalProfit = 0;
  productLines?.forEach((item) => {
    const [itp, itop] = getProductPriceAndProfit(item.price, item.outPrice, item.quantity);
    totalPrice += itp;
    totalProfit += itop;
  });

  return { totalPrice, totalProfit };
}
