import { ExOrderProductLineModel } from "../models";

export class PriceCalculator {
  public static GetOutPrice(price: number, margin: string) {
    let marginValue;
    let outPrice;
    if (margin[margin.length - 1] === "%") {
      marginValue = parseFloat(margin.slice(0, margin.length - 1));
      outPrice = price * (1 + marginValue / 100);
    } else {
      marginValue = parseFloat(margin);
      outPrice = price + marginValue;
    }

    return Math.round(outPrice * 100 + Number.EPSILON) / 100;
  }

  public static GetTotalPrices(price: number, outPrice: number, quantity: number): number[] {
    return [quantity * outPrice, quantity * (outPrice - price)];
  }

  public static GetTotalPricesFormated(price: number, outPrice: number, quantity: number): string {
    const [totalOutPrice, totalProfit] = PriceCalculator.GetTotalPrices(price, outPrice, quantity);
    return `${totalOutPrice.toFixed(2)} (${totalProfit > 0 ? "+" : ""}${totalProfit.toFixed(2)})`;
  }
}

export function getTotalPriceAndProfit(productLines: ExOrderProductLineModel[]) {
  let totalPrice = 0;
  let totalProfit = 0;
  productLines.forEach((item) => {
    const [itp, itop] = PriceCalculator.GetTotalPrices(item.price, item.outPrice, item.quantity);
    totalPrice += itp;
    totalProfit += itop;
  });

  return { totalPrice, totalProfit };
}