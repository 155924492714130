import { useContext, useEffect, useState } from "react";
import { ModuleContext } from "../../../common/components";
import { SimpleFormDialog } from "../../../common/components/dialogs";
import { CrudDialog } from "../../../common/components/dialogs/CrudDialog";
import { ExBackend } from "../../backends";
import { ExProductBundleMapper, ExProductBundleModel } from "../../models";
import { ExProductBundleAction } from "../../models/Actions";
import { Datalist } from "../common";
import { ComboboxField } from "../common/field";
import { ToolButton } from "../common/ToolButton";
import { EditBundle } from "./EditBundle";

const BUNDLE_DATALIST = "bundleNames";

function ProductBundlerLayout({ bundles, onBundleAction }: { bundles: ExProductBundleModel[]; onBundleAction: (action: ExProductBundleAction, bundle?: ExProductBundleModel) => Promise<void> }) {
  const [selectedBundle, setSelectedBundle] = useState<ExProductBundleModel>();
  const [editingBundle, setEditingBundle] = useState<ExProductBundleModel>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [dialog, setDialog] = useState<"new" | "edit" | "delete">();

  return (
    <>
      {editingBundle && (dialog === "new" || dialog === "edit") ? (
        <CrudDialog
          title={dialog === "new" ? "Ny gruppe" : "Rediger gruppen"}
          mode="edit"
          onAction={async (action) => {
            if (action === "close") {
              setDialog(undefined);
            } else if (action === "create") {
              await onBundleAction(dialog === "new" ? "create" : "edit", editingBundle);
              setSelectedBundle(undefined);
              setSearchValue("");
            }
          }}
        >
          <EditBundle bundle={editingBundle} onSetBundle={setEditingBundle} />
        </CrudDialog>
      ) : null}
      {selectedBundle && dialog === "delete" ? (
        <SimpleFormDialog
          title="Slette gruppen"
          buttons={[
            { text: "Ja", action: "yes", color: "red" },
            { text: "Nei", action: "no", color: "green", default: true },
          ]}
          onButtonClicked={async (action) => {
            if (action === "yes") {
              await onBundleAction("delete", selectedBundle);
              setSelectedBundle(undefined);
              setSearchValue("");
            }
            setDialog(undefined);
          }}
          onCloseClicked={() => setDialog(undefined)}
        >
          <div className="text-lg my-8 mx-4">Er du sikker på at du vil slette gruppen {selectedBundle.name}?</div>
        </SimpleFormDialog>
      ) : null}
      <div className="flex px-8 py-2 border-b-2">
        <div className="w-1/3 my-auto mr-1">
          <ComboboxField
            model={{
              label: "Produkt Grupper",
              value: searchValue,
              setValue: (value) => {
                setSearchValue(value);
                setSelectedBundle(bundles.find((item) => item.name === value));
              },
            }}
            list={BUNDLE_DATALIST}
          />
          <Datalist id={BUNDLE_DATALIST} items={bundles} getTitle={(bundle) => bundle.name} getDescription={(bundle) => bundle.description} />
        </div>
        {selectedBundle ? <ToolButton image="add" onClick={() => onBundleAction("add", selectedBundle)} /> : null}
        <div className="flex w-full gap-1">
          <div className="flex ml-auto">
            <ToolButton
              image="new"
              onClick={() => {
                setEditingBundle(ExProductBundleMapper.empty());
                setDialog("new");
              }}
            />
            {selectedBundle ? (
              <ToolButton
                image="edit"
                onClick={() => {
                  setEditingBundle(ExProductBundleMapper.clone(selectedBundle));
                  setDialog("edit");
                }}
              />
            ) : null}
            {selectedBundle ? (
              <ToolButton
                image="delete"
                onClick={() => {
                  setDialog("delete");
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export function ProductBundler({ onAddBundle }: { onAddBundle: (bundle: ExProductBundleModel) => void }) {
  const processor = useContext(ModuleContext);

  const [allBundles, setAllBundles] = useState<ExProductBundleModel[]>([]);

  async function loadBundles() {
    const bundles = await processor?.runSilently(ExBackend.fetchExBundles);
    setAllBundles(bundles);
  }

  async function bundleActionHandler(action: ExProductBundleAction, bundle?: ExProductBundleModel) {
    if (action === "add" && bundle) {
      onAddBundle(bundle);
    } else if (action === "create" && bundle) {
      await processor?.run(ExBackend.createExBundle, bundle);
      await loadBundles();
    } else if (action === "edit" && bundle) {
      await processor?.run(ExBackend.updateExBundle, bundle);
      await loadBundles();
    } else if (action === "delete" && bundle) {
      await processor?.run(ExBackend.deleteExBundle, bundle);
      await loadBundles();
    }
  }

  useEffect(() => {
    (async () => {
      await loadBundles();
    })();
  }, []);

  return <ProductBundlerLayout bundles={allBundles} onBundleAction={bundleActionHandler} />;
}
