import { ModelMapper } from "./ModelMapper";

interface SklArticleModel {
  qty: {
    to: number;
    from: number;
  };
  campaign?: {
    to: string;
    from: string;
    name: string;
  };
  id?: string;
  number: "1001" | "1002" | "1003";
  description: string;
  price: string;
}

export interface SklPriceModel {
  id?: string;
  number: "1001" | "1002" | "1003";
  description: string;
  price: string;
}

export interface SklPriceGroupModel {
  from: number;
  to: number;
  prices: SklPriceModel[];
}

export interface SklCampaignModel {
  name: string;
  fromDate: string;
  toDate: string;
  priceGroups: SklPriceGroupModel[];
}

export interface SklPriceSettingsModel {
  standardPriceGroups: SklPriceGroupModel[];
  campaigns: SklCampaignModel[];
}

export const SklPriceSettingsModelMapper: ModelMapper<SklPriceSettingsModel> = {
  newModel: () => {
    return {
      standardPriceGroups: [],
      campaigns: [],
    };
  },

  mapToModel: (backendObject) => {
    const articles: SklArticleModel[] = backendObject as SklArticleModel[];
    const standardPriceGroups: SklPriceGroupModel[] = [];
    const campaigns: SklCampaignModel[] = [];

    for (const article of articles) {
      if (article.campaign) {
        const foundCampaign = campaigns.find((campaign) => campaign.name === article.campaign?.name);
        if (foundCampaign) {
          const foundPriceGroup = foundCampaign.priceGroups.find((priceGroup) => priceGroup.from === article.qty.from && priceGroup.to === article.qty.to);
          if (foundPriceGroup) {
            foundPriceGroup.prices.push({
              id: article.id,
              number: article.number,
              description: article.description,
              price: article.price,
            });
          } else {
            const newPriceGroup: SklPriceGroupModel = {
              from: article.qty.from,
              to: article.qty.to,
              prices: [
                {
                  id: article.id,
                  number: article.number,
                  description: article.description,
                  price: article.price,
                },
              ],
            };
            foundCampaign.priceGroups.push(newPriceGroup);
          }
        } else {
          const newCampaign: SklCampaignModel = {
            name: article.campaign?.name,
            fromDate: article.campaign?.from,
            toDate: article.campaign?.to,
            priceGroups: [
              {
                from: article.qty.from,
                to: article.qty.to,
                prices: [{ id: article.id, number: article.number, description: article.description, price: article.price }],
              },
            ],
          };
          campaigns.push(newCampaign);
        }
      } else {
        const foundPriceGroup = standardPriceGroups.find((priceGroup) => priceGroup.from === article.qty.from && priceGroup.to === article.qty.to);
        if (foundPriceGroup) {
          foundPriceGroup.prices.push({
            id: article.id,
            number: article.number,
            description: article.description,
            price: article.price,
          });
        } else {
          const newPriceGroup: SklPriceGroupModel = {
            from: article.qty.from,
            to: article.qty.to,
            prices: [
              {
                id: article.id,
                number: article.number,
                description: article.description,
                price: article.price,
              },
            ],
          };
          standardPriceGroups.push(newPriceGroup);
        }
      }
    }

    return {
      standardPriceGroups,
      campaigns,
    };
  },

  mapToBackendObject: (model) => {
    const articles: SklArticleModel[] = [];

    for (const priceGroup of model.standardPriceGroups) {
      for (const price of priceGroup.prices) {
        articles.push({
          id: price.id,
          number: price.number,
          description: price.description,
          qty: {
            from: priceGroup.from,
            to: priceGroup.to,
          },
          price: price.price,
        });
      }
    }

    for (const campaign of model.campaigns) {
      for (const priceGroup of campaign.priceGroups) {
        for (const price of priceGroup.prices) {
          articles.push({
            id: price.id,
            number: price.number,
            description: price.description,
            qty: {
              from: priceGroup.from,
              to: priceGroup.to,
            },
            campaign: {
              name: campaign.name,
              from: campaign.fromDate,
              to: campaign.toDate,
            },
            price: price.price,
          });
        }
      }
    }

    return articles;
  },
};
