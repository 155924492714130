import { useState } from "react";

export const StepBar = ({
  steps,
  selectedStepId,
  onStepSelected,
}: {
  steps: string[];
  selectedStepId?: string;
  onStepSelected: (stepIndex: number) => void;
}) => {
  const [selectedStepIndex, setSelecteStepIndex] = useState(0);

  const selectStep = (stepIndex: number) => {
    setSelecteStepIndex(stepIndex);
    onStepSelected(stepIndex);
  }

  return (
    <div className="flex w-full select-none">
      <div className="w-10 h-10 my-auto">
        {selectedStepIndex > 0 && (
          <img
            className="w-full h-full cursor-pointer"
            src="/assets/images/back-white.svg"
            alt="back"
            onClick={() => selectStep(selectedStepIndex - 1)}
          />
        )}
      </div>
      <div className="flex mx-auto">
        {steps.map((item, index) => (
          <div key={item} className="flex flex-col">
            <div className="flex">
              <div className="flex flex-col w-20 h-16">
                {index !== 0 && (
                  <div>
                    <div className="flex-1 border-b-2 h-8 border-black"></div>
                    <div className="flex-1"></div>
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <button
                  className={`flex h-16 w-16 rounded-full ${index <= selectedStepIndex ? "bg-black" : "border-2 border-black"}`}
                  onClick={() => selectStep(index)}
                >
                  <p className={`m-auto text-2xl ${index <= selectedStepIndex ? "text-white" : "text-black"}`}>
                    {index + 1}
                  </p>
                </button>
              </div>
              <div className="flex flex-col w-20 h-8">
                {index !== steps.length - 1 && (
                  <div>
                    <div className="flex-1 border-b-2 h-8 border-black"></div>
                    <div className="flex-1"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex mt-2">
              <p className={`mx-auto ${selectedStepIndex === index ? "text-xl font-bold" : selectedStepIndex < index ? "text-black": "text-white"}`}>{item}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="w-10 h-10 my-auto">
        {selectedStepIndex < steps.length - 1 && (
          <img
            className="w-full h-full cursor-pointer"
            src="/assets/images/forward-white.svg"
            alt="forward"
            onClick={() => selectStep(selectedStepIndex + 1)}
          />
        )}
      </div>
    </div>
  );
};
