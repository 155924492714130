import { StatsContextComponent } from "../../contexts/StatsContext";
import { Restricted } from "../common/components/Restricted";
import { KeyInfoPanel, InformationPanel, ChatDialog } from "./components";

export const DashboardModule = () => {
  return (
    <div className="flex flex-1">
      <Restricted level={1} module="dashboard">
        <div className="flex flex-1">
          <StatsContextComponent>
            <KeyInfoPanel />
          </StatsContextComponent>
        </div>
        <div className="flex flex-1">
          <InformationPanel />
        </div>
        <ChatDialog />
      </Restricted>
    </div>
  );
};
