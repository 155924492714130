import { TELENOR_API_URL } from "../env";
import OrderModel from "../models/OrderModel";

const apiUrl = TELENOR_API_URL;

export const fetchAllOrders: (userId?: string) => Promise<OrderModel[]> = async (userId?: string) => {
  const url = `${apiUrl}/orders`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const orders = await res.json();
  return orders.map((item: any) => OrderModel.fromBackendObject(item));
};

export const fetchOrder: (orderId: string, userId?: string) => Promise<OrderModel> = async (orderId: string, userId?: string) => {
  const url = `${apiUrl}/orders/${orderId}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const order = await res.json();
  return OrderModel.fromBackendObject(order);
};

export const createOrder = async (order: OrderModel, userId?: string) => {
  const url = `${apiUrl}/orders`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  console.log("CREATE ORDER", userId ? { user_id: userId, ...OrderModel.toBackendObject(order) } : OrderModel.toBackendObject(order));
  const res = await fetch(url, {
    method: "POST",
    headers: headers,
    body: userId ? JSON.stringify({ user_id: userId, ...OrderModel.toBackendObject(order) }) : JSON.stringify(OrderModel.toBackendObject(order)),
  });
  if (res.status === 200) {
    const body = await res.json();
    return body.orderId;
  }
  return false;
};

export const updateOrder = async (order: OrderModel, userId?: string) => {
  const url = `${apiUrl}/orders/${order.id}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  console.log("UPDATE ORDER", userId ? { user_id: userId, ...OrderModel.toBackendObject(order) } : OrderModel.toBackendObject(order));

  const res = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: userId ? JSON.stringify({ user_id: userId, ...OrderModel.toBackendObject(order) }) : JSON.stringify(OrderModel.toBackendObject(order)),
  });
  return res.status === 200;
};

export const deleteOrder = async (orderId: string, userId?: string) => {
  const url = `${apiUrl}/orders/${orderId}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: headers,
    });
    if (res.status === 200) {
      return true;
    }
    return false;
  } catch (ex) {
    return false;
  }
};
