import { ManagerModel } from "../models/managers/ManagerModel";
import { ProjectBundleModel, ProjectBundleModelMapper } from "../models/project/ProjectBundleModel";
import { ProjectModel } from "../models/project/ProjectModel";

export const ProjectBundlesManager: (project: ProjectModel, setProject: (project: ProjectModel) => void) => ManagerModel<ProjectBundleModel> = (
  project: ProjectModel, setProject: (project: ProjectModel) => void
) => {
  return {
    state: {
      status: "ready",
      message: "",
    },
    data: project.bundles,
    refresh: async() => {},
    new: () => {
      return ProjectBundleModelMapper.createEmptyObject();
    },
    read: async (id: string) => {
      const bundle = project.bundles.find((item) => item.id === id);
      return bundle;
    },
    create: async (bundle: ProjectBundleModel) => {
      setProject({...project, bundles: [...project.bundles, bundle]});
    },
    update: async (id: string, bundle: ProjectBundleModel) => {
      setProject({...project, bundles: [...project.bundles.map(item => item.id === id ? bundle : item)]});
    },
    delete: async (id: string) => {
      setProject({...project, bundles: project.bundles.filter(item => item.id !== id)});
    },
  };
};
