const Button = ({ children, onClick }: { children: string; onClick?: () => void }) => {
  return (
    <button
      className="text-sm font-bold px-2 py-1 rounded-md ibutton cursor-pointer"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </button>
  );
};

export default Button;
