import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

const UserProfile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [hover, setHover] = useState(false);

  if (isLoading) {
    return <div>Loading ...</div>;
  } else if (isAuthenticated && user) {
    return (
      <div
        className="text-sm font-bold px-2 py-1 rounded-md ibutton cursor-pointer"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {/* {user.picture && <img src={user.picture} alt={user.name} />} */}
        {hover ? <LogoutButton /> : <p>{user.name}</p>}
        {/* <p>{user.email}</p> */}
      </div>
    );
  } else {
    return <div
    className="text-sm font-bold px-2 py-1 rounded-md ibutton cursor-pointer"><LoginButton /></div>;
  }
};

export default UserProfile;
