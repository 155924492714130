import { useContext, useState } from "react";
import ProductModel from "../../../models/ProductModel";
import { FieldModel } from "../../../models/ui/FieldModel";
import { OrderFormFields } from "../../../ui/OrderFields";
import { PriceCalculator } from "../../../utils/PriceCalculator";
import ProductLayout from "../../common/ProductLayout";
import AddProductComponent from "./AddProductComponent";
import OrderContext from "../../../contexts/OrderContext";
import { CrudDialog } from "../../dialogs/CrudDialog";
import { EditProductComponent } from "./EditProductComponent";

const getFieldStyle = (field: FieldModel) => {
  const style = ["Antall", "Kalk", "Kostpris", "Utpris", "Total Pris"].includes(field.text) ? "text-right mr-1" : "";
  return style;
};

const ProductField = ({ product, field }: { product: ProductModel; field: FieldModel }) => {
  const orderManagement = useContext(OrderContext);
  const style = getFieldStyle(field);

  if (field.text === "Moms" && (product as any)[field.dataField]) {
    return (
      <div className="flex w-full">
        <img className="w-5 h-5 ml-auto" src="/assets/images/check.svg" alt="check" />
      </div>
    );
  } else if (field.text === "LevAlt") {
    return <div className={`w-full ${style}`}>{orderManagement.deliveryAlternatives.find((item) => item.lev_alt === (product as any)[field.dataField])?.description}</div>;
  } else if (field.text === "Total Pris") {
    return <div className={`w-full ${style}`}>{PriceCalculator.GetTotalPricesFormated(product.price, product.outPrice, product.quantity)}</div>;
  }
  return <div className={`w-full ${style}`}>{(product as any)[field.dataField]}</div>;
};

const ProductListHeader = () => {
  return (
    <div className="flex mx-8 my-1 font-bold border-b-2">
      <ProductLayout>
        {OrderFormFields.products.fields.map((field) => {
          const style = getFieldStyle(field);
          return (
            <div key={field.dataField} className={`w-full ${style}`}>
              {field.text}
            </div>
          );
        })}
      </ProductLayout>
    </div>
  );
};

const ProductListItems = ({
  products,
  edit,
  removeProduct,
  onEditProduct,
  highlighted,
}: {
  products: ProductModel[];
  edit: boolean;
  removeProduct: (product: ProductModel) => void;
  onEditProduct?: (product: ProductModel) => void;
  highlighted?: ProductModel[];
}) => {
  return (
    <div className="flex flex-col w-full gap-1">
      {products?.map((product, i) => (
        <ProductListItem index={i + 1} product={product} edit={edit} removeProduct={removeProduct} highlighted={highlighted?.includes(product)} onMoreClicked={() => onEditProduct?.(product)} />
      ))}
    </div>
  );
};

const ProductListItem = ({ index, product, edit, removeProduct, highlighted, onMoreClicked }: { index: number; product: ProductModel; edit: boolean; removeProduct: (product: ProductModel) => void; highlighted?: boolean; onMoreClicked?: () => void }) => {
  const [showTools, setShowTools] = useState(false);
  const [pointerOver, setPointerOver] = useState(false);

  return (
    <div
      className={`flex ${product.custom && "text-rose-400"}`}
      onMouseEnter={() => {
        setPointerOver(true);
      }}
      onMouseLeave={() => {
        setPointerOver(false);
      }}
    >
      <div className={`flex w-full items-center cursor-pointer ${pointerOver && "text-black"} ${highlighted && "bg-black"}`}>
        <div className="w-8 text-center">{index})</div>
        <ProductLayout>
          {OrderFormFields.products.fields.map((field) => (
            <ProductField product={product} field={field} />
          ))}
        </ProductLayout>
      </div>
      {showTools && (
        <>
          {pointerOver && (
            <button
              disabled={!edit}
              className="rounded-md w-8 h-8 flex"
              onClick={() => {
                // removeProduct(product);
              }}
            >
              {edit && <img className="h-5 m-auto" src="/assets/images/edit.svg" alt="remove product" onClick={() => removeProduct(product)} />}
            </button>
          )}
          <button
            disabled={!edit}
            className="rounded-md w-8 h-8 flex"
            onClick={() => {
              // removeProduct(product);
            }}
          >
            {edit && <img className="h-5 m-auto" src="/assets/images/delete.svg" alt="remove product" onClick={() => removeProduct(product)} />}
          </button>
        </>
      )}
      <button disabled={!edit} className="rounded-md w-8 h-8 flex my-auto" onClick={onMoreClicked}>
        {/* {showTools && (
          <div className="absolute bg-white">
            <img
                className="h-5 m-auto"
                src="/assets/images/delete-black.svg"
                alt="remove product"
                onClick={() => removeProduct(product)}
              />
              <img
                className="h-5 m-auto"
                src="/assets/images/delete-black.svg"
                alt="remove product"
                onClick={() => removeProduct(product)}
              />
              <img
                className="h-5 m-auto"
                src="/assets/images/delete-black.svg"
                alt="remove product"
                onClick={() => removeProduct(product)}
              />
          </div>
        )} */}
        {edit && <img className="h-5 m-auto" src="/assets/images/more.svg" alt="remove product" />}
      </button>
    </div>
  );
};

export const ProductsPanel = ({
  products,
  addProduct,
  removeProduct,
  updateProduct,
  edit,
  highlighted,
}: {
  products: ProductModel[];
  addProduct: (product: ProductModel) => void;
  removeProduct: (product: ProductModel) => void;
  updateProduct?: (product: ProductModel, updated: ProductModel) => void;
  edit?: boolean;
  highlighted?: ProductModel[];
}) => {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [originalProduct, setOriginalProduct] = useState<ProductModel | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(null);
  const [readonly, setReadonly] = useState<boolean>(true);

  return (
    <div className="flex flex-1 flex-col gap-1">
      {showEditDialog && (
        <CrudDialog
          mode="view"
          onAction={(action) => {
            if (action === "close") {
              setSelectedProduct(null);
              setShowEditDialog(false);
            } else if (action === "update") {
              if (originalProduct && selectedProduct) {
                updateProduct?.(originalProduct, selectedProduct);
              }
            } else if (action === "delete" && originalProduct) {
              removeProduct(originalProduct);
            } else if (action === "editOn") {
              setReadonly(false);
            } else if (action === "editOff") {
              setReadonly(true);
            }
          }}
        >
          <EditProductComponent readonly={readonly} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
        </CrudDialog>
      )}
      <ProductListHeader />
      <ProductListItems
        products={products}
        edit={edit || false}
        removeProduct={removeProduct}
        highlighted={highlighted}
        onEditProduct={(product) => {
          setOriginalProduct(product);
          setSelectedProduct(product);
          setShowEditDialog(true);
        }}
      />
      {edit && (
        <AddProductComponent
          addClickHandler={(product: ProductModel) => {
            addProduct(product);
          }}
        />
      )}
    </div>
  );
};
