import { ReactNode } from "react";
import { usePermissionChecker } from "../hooks/usePermissionChecker";

export function Restricted({ level, module, children }: { level: number; module?: string; children: ReactNode }) {
  const permissionChecker = usePermissionChecker();

  const accessAllowed = permissionChecker.isAccessAllowed(level, module);

  return <>{accessAllowed ? children : null}</>;
}
