import { useContext } from "react";
import { lookupCity } from "../../../backend";
import { OrderFormFields } from "../../../ui/OrderFields";
import { DeliveryModel } from "../../../models/DeliveryModel";
import { EditBox } from "../common/EditBox";
import { SelectBox } from "../common/SelectBox";
import OrderContext from "../../../contexts/OrderContext";

// const deliveryFields = {
//   header: { text: "Varemottaker", dataField: "delivery" },
//   fields: [
//     { text: "Navn", description: "", dataField: "name" },
//     { text: "CO adresse", description: "", dataField: "address" },
//     { text: "Gateadresse", description: "", dataField: "address2" },
//     { text: "Postnr", description: "", dataField: "zipcode" },
//     { text: "Poststed", description: "", dataField: "city" },
//     { text: "Land", description: "", dataField: "country" },
//     { text: "Kontakt", description: "", dataField: "contact" },
//     { text: "Mobil", description: "", dataField: "mobile" },
//     { text: "E-post", description: "", dataField: "email" },
//   ],
// };

export const DeliveryPanel = ({ delivery, setDelivery, onCopyCustomer, readonly, columns }: { delivery: DeliveryModel; setDelivery: (delivery: DeliveryModel) => void; onCopyCustomer: () => void; readonly?: boolean; columns?: number }) => {
  const orderManagement = useContext(OrderContext);
  // const zipcodeField = OrderFormFields.customer.delivery.fields[3];
  // const cityField = OrderFormFields.customer.delivery.fields[4];
  // const countryField = OrderFormFields.customer.delivery.fields[5];

  return (
    <div className="flex flex-col flex-1">
      <div className="flex border-b-2 mb-2 font-bold">
        {OrderFormFields.customer.delivery.header.text}
        <button hidden={readonly} title="Kopiere fra oppdragsgiver" className="ml-2" onClick={onCopyCustomer}>
          <img className="h-6" src="/assets/images/copy-paste.svg" alt="copy" />
        </button>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-1">
          <div className="flex flex-col gap-1">
            <EditBox label="Navn 1*" value={delivery.name} onTextChanged={(text) => setDelivery({ ...delivery, name: text })} readonly={readonly} maxChars={40} />
            <EditBox label="Navn 2" value={delivery.name2} onTextChanged={(text) => setDelivery({ ...delivery, name2: text })} readonly={readonly} maxChars={40} />
            <EditBox label="CO adresse" value={delivery.address} onTextChanged={(text) => setDelivery({ ...delivery, address: text })} readonly={readonly} maxChars={40} />
            <EditBox label="Gateadresse*" value={delivery.address2} onTextChanged={(text) => setDelivery({ ...delivery, address2: text })} readonly={readonly} maxChars={40} />

            <SelectBox
              label="Land*"
              items={orderManagement.countries.map((item) => ({ id: item.code, name: item.name }))}
              value={delivery.country}
              onValueChanged={(text) => {
                setDelivery({ ...delivery, country: text });
              }}
              readonly={readonly}
            />

            <EditBox
              label="Postnr*"
              value={delivery.zipcode}
              onTextChanged={(text) => setDelivery({ ...delivery, zipcode: text })}
              onEditingCompleted={async () => {
                const city = await lookupCity(delivery.country, delivery.zipcode);
                setDelivery({ ...delivery, city: city });
              }}
              readonly={readonly}
              maxChars={40}
            />
            <EditBox label="Poststed*" value={delivery.city} onTextChanged={(text) => setDelivery({ ...delivery, city: text })} readonly={readonly} maxChars={40} />
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex flex-col gap-1">
            <EditBox label="Kontakt*" value={delivery.contact} onTextChanged={(text) => setDelivery({ ...delivery, contact: text })} readonly={readonly} maxChars={40} />
            <EditBox label="Mobil*" value={delivery.mobile} onTextChanged={(text) => setDelivery({ ...delivery, mobile: text })} readonly={readonly} maxChars={40} />
            <EditBox label="E-post*" value={delivery.email} onTextChanged={(text) => setDelivery({ ...delivery, email: text })} readonly={readonly} maxChars={40} />
          </div>
        </div>
      </div>
    </div>
  );
};
