import { useContext, useEffect, useState } from "react";
import { ModuleContext } from "../common/components";
import { UsersBackend } from "./backends";
import { UserModel } from "./models";
import { UserAction } from "./models/Actions";
import { UsersLayout } from "./UsersLayout";

export function UsersModule() {
  const moduleContext = useContext(ModuleContext);

  const [allUsers, setAllUsers] = useState<UserModel[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserModel>();

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadUsers() {
    const fetchedUsers = await moduleContext?.run(UsersBackend.fetchAllUsers);
    setAllUsers(fetchedUsers);
  }

  async function selectUser(user?: UserModel) {
    if (user) {
      const fetchedUser = await moduleContext?.run(UsersBackend.fetchUser, user.id);
      setSelectedUser(fetchedUser);
    } else {
      setSelectedUser(undefined);
    }
  }

  async function updateUser(user: UserModel) {
    await moduleContext?.run(UsersBackend.updateUser, user.id, user);
    await loadUsers();
  }

  async function userActionHandler(action: UserAction, user?: UserModel) {
    if (action === "select") {
      await selectUser(user);
    } else if (action === "add" && user) {
      console.log("ADD USER", user);
    } else if (action === "update" && user) {
      await updateUser(user);
    } else if (action === "delete") {
      console.log("DELETE USER", selectedUser);
    }
  }

  return (
    <div className="flex flex-1">
      <UsersLayout users={allUsers} selectedUser={selectedUser} onAction={userActionHandler} />
    </div>
  );
}
