import { useContext, useState } from "react";
import { fetchProducts, fetchSuppliers } from "../../../backend";
import ProductModel from "../../../models/ProductModel";
import ProductLayout from "../../common/ProductLayout";
import { ProductSupplierModel } from "../../../models/product/ProductSupplierModel";
import { useAuth0 } from "@auth0/auth0-react";
import { PriceCalculator } from "../../../utils/PriceCalculator";
import { EditboxField } from "../../fields/product/EditboxField";
import { NumberField } from "../../fields/product/NumberField";
import { SelectboxField } from "../../fields/product/SelectboxField";
import { CheckboxField } from "../../fields/product/CheckboxField";
import { ReadonlyField } from "../../fields/product/ReadonlyField";
import { OutPriceField } from "../../fields/product/OutPriceField";
import OrderContext from "../../../contexts/OrderContext";

const AddProductComponent = ({ addClickHandler }: { addClickHandler: (product: ProductModel) => void }) => {
  const { user } = useAuth0();
  const orderManagement = useContext(OrderContext);

  const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(null);
  const [searchableFields, setSearchableFields] = useState({ number: "", ean: "", description: "" });
  const [allSuppliers, setAllSuppliers] = useState<ProductSupplierModel[]>([]);

  const loadFilteredProducts = async (searchField: string, searchValue: string) => {
    const products = await fetchProducts(searchField === "number" ? "" : searchField, searchValue, user?.sub);
    setFilteredProducts(products);
  };

  // const inputFieldClass = "h-8 w-full px-2 rounded-md text-black";
  // const readonlyFieldClass = "h-8 w-full px-2 rounded-md border-2 bg-transparent text-white";

  const setProductFieldValue = (fieldName: string, value: any) => {
    if (selectedProduct) {
      setSelectedProduct({ ...selectedProduct, [fieldName]: value });
    }
  };

  const setProductSupplier = (levnr: string, customize: boolean) => {
    if (selectedProduct) {
      const supplier = customize ? allSuppliers.find((s) => s.levnr === levnr) : selectedProduct.suppliers.find((s) => s.levnr === levnr);
      if (supplier) {
        setSelectedProduct({ ...selectedProduct, levnr: supplier.levnr, levname: supplier.levname });
      }
    }
  };

  const setProductPrices = (price: number, margin: string) => {
    let marginValue;
    let outPrice;
    if (selectedProduct) {
      if (margin[margin.length - 1] === "%") {
        marginValue = parseFloat(margin.slice(0, margin.length - 1));
        outPrice = price * (1 + marginValue / 100);
      } else {
        marginValue = parseFloat(margin);
        outPrice = price + marginValue;
      }

      setSelectedProduct({ ...selectedProduct, price, margin, outPrice });
    }
  };

  const searchableField = (fieldName: string, datalistName: string, customize: boolean, editable?: boolean) => {
    return (
      <input
        className={`h-8 w-full px-2 rounded-md ${editable ? "text-red-600 font-extrabold" : "text-black"}`}
        type="text"
        list={customize ? undefined : datalistName}
        value={customize ? (selectedProduct ? (selectedProduct as any)[fieldName] : null) : (searchableFields as any)[fieldName]}
        onChange={(e) => {
          if (customize) {
            setProductFieldValue(fieldName, e.currentTarget.value);
          } else {
            const value = e.target.value;
            let found = false;
            if (value.length >= 3) {
              for (const p of filteredProducts) {
                if ((p as any)[fieldName] === value) {
                  found = true;
                  setSelectedProduct(p);
                  setSearchableFields({ number: p.number || "", ean: p.ean || "", description: p.description || "" });
                  break;
                }
              }
              if (!found) {
                loadFilteredProducts(fieldName, value);
                setSelectedProduct(null);
                setSearchableFields({ number: "", ean: "", description: "", [fieldName]: value });
              }
            } else {
              setFilteredProducts([]);
              setSelectedProduct(null);
              setSearchableFields({ number: "", ean: "", description: "", [fieldName]: value });
            }
          }
        }}
      />
    );
  };

  const productLine = (customize: boolean) => {
    return (
      <ProductLayout>
        {/* ProduktNr */}
        {searchableField("number", "productNumbers", customize)}

        {/* EAN */}
        {searchableField("ean", "productEANs", customize)}

        {/* Beskrivelse */}
        {searchableField("description", "productDescriptions", customize || ProductModel.isEditable(selectedProduct), ProductModel.isEditable(selectedProduct))}
        {/* Lvrnr */}
        {/* <SelectboxField
          disabled={!selectedProduct}
          value={selectedProduct?.levnr || ""}
          onValueChanged={(value: string) => {
            setProductSupplier(value);
          }}
          items={
            customize
              ? allSuppliers.map((item) => ({ label: item.levnr, value: item.levnr })) || []
              : selectedProduct?.suppliers?.map((item) => ({ label: item.levnr, value: item.levnr })) || []
          }
        /> */}

        {/* Lvrnavn */}
        <SelectboxField
          disabled={!selectedProduct}
          value={selectedProduct?.levnr || ""}
          onValueChanged={(value: string) => {
            setProductSupplier(value, customize);
          }}
          items={customize ? allSuppliers.map((item) => ({ label: item.levname, value: item.levnr })) || [] : selectedProduct?.suppliers?.map((item) => ({ label: item.levname, value: item.levnr })) || []}
        />

        {/* LevAlt */}
        <SelectboxField
          disabled={!selectedProduct}
          value={selectedProduct?.levalt}
          onValueChanged={(value: string) => {
            setProductFieldValue("levalt", value);
          }}
          items={orderManagement.deliveryAlternatives.map((item) => ({ label: item.description, value: item.lev_alt }))}
        />

        {/* BID */}
        <EditboxField
          disabled={!selectedProduct}
          value={selectedProduct?.bid || ""}
          onValueChanged={(value: string) => {
            setProductFieldValue("bid", value);
          }}
        />

        {/* Moms */}
        <CheckboxField
          disabled={!selectedProduct}
          value={selectedProduct?.vat || false}
          onValueChanged={(value: boolean) => {
            setProductFieldValue("vat", value);
          }}
        />

        {/* Antall */}
        <NumberField
          disabled={!selectedProduct}
          value={selectedProduct?.quantity || ""}
          onValueChanged={(value: number) => {
            setProductFieldValue("quantity", value);
          }}
        />

        {/* Margin */}
        <input
          type="text"
          className="addProductField text-right"
          disabled={!selectedProduct || ProductModel.isEditable(selectedProduct)}
          value={selectedProduct?.margin || ""}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            setProductPrices(selectedProduct?.price || 0, newValue);
            // setMargin(newValue);
            // setProductFieldValue("margin", newValue);
          }}
        />
        {/* <MarginField
          disabled={!selectedProduct}
          value={selectedProduct?.bid || ""}
          onValueChanged={(value: string) => {
            setProductFieldValue("bid", value);
          }}
        /> */}

        {/* Kostpris */}
        {customize || ProductModel.isEditable(selectedProduct) ? (
          <NumberField
            disabled={!selectedProduct}
            value={selectedProduct?.price || ""}
            onValueChanged={(value: number) => {
              setProductPrices(value, selectedProduct?.margin || "0%");
            }}
          />
        ) : (
          <ReadonlyField value={selectedProduct?.price.toFixed(2) || ""} />
        )}

        {/* Utpris */}
        <OutPriceField product={selectedProduct} setProduct={setSelectedProduct} />

        {/* Totalpris */}
        <ReadonlyField value={selectedProduct ? PriceCalculator.GetTotalPricesFormated(selectedProduct?.price, selectedProduct?.outPrice, selectedProduct?.quantity) : ""} />
      </ProductLayout>
    );
  };

  return (
    <div className="flex select-none">
      {/* <input
        className="w-4 mr-2"
        type="checkbox"
        checked={selectedProduct?.custom}
        onChange={(e) => {
          if (e.target.checked) {
            if (allSuppliers.length === 0) {
              fetchSuppliers(user?.sub).then((suppliers) => setAllSuppliers(suppliers));
            }
            setSelectedProduct({ ...new ProductModel(), custom: true });
          } else {
            setSelectedProduct(null);
          }
        }}
      /> */}
      <button
        className="w-8"
        title="Tilpasning på/av"
        onClick={() => {
          if (!selectedProduct?.custom) {
            if (allSuppliers.length === 0) {
              fetchSuppliers(user?.sub).then((suppliers) => setAllSuppliers(suppliers));
            }
            setSelectedProduct({ ...new ProductModel(), custom: true });
          } else {
            setSelectedProduct(null);
          }
        }}
      >
        <img className="mx-auto" src={selectedProduct?.custom ? "/assets/images/hw/unlock.svg" : "/assets/images/hw/lock.svg"} alt="" />
      </button>

      {productLine(selectedProduct ? selectedProduct.custom : false)}

      <datalist id="productNumbers">
        {filteredProducts.map((product) => (
          <option value={product.number} label={`${product.description} (levnr: ${product.levnr}, levartnr: ${product.levartnr}, gartnr: ${product.gartnr})`} key={product.id} />
        ))}
      </datalist>
      <datalist id="productEANs">
        {filteredProducts.map((product) => (
          <option value={product.ean} label={`${product.description}`} key={product.id} />
        ))}
      </datalist>
      <datalist id="productDescriptions">
        {filteredProducts.map((product) => (
          <option value={product.description} label={`${product.number}`} key={product.id} />
        ))}
      </datalist>
      {/* </div> */}
      <button
        className="rounded-md w-8"
        disabled={selectedProduct ? selectedProduct.outPrice === null : true}
        onClick={() => {
          if (selectedProduct) {
            addClickHandler({ ...selectedProduct });
            setSearchableFields({ number: "", ean: "", description: "" });
            setSelectedProduct(null);
            setFilteredProducts([]);
          }
        }}
      >
        <img className="h-5 mx-auto" src="/assets/images/add.svg" alt="add product" />
      </button>
    </div>
  );
};

export default AddProductComponent;
