import { useEffect, useState } from "react";
import ProductModel from "../../../models/ProductModel";

export const OutPriceField = ({ product, setProduct }: { product: ProductModel | null; setProduct: (product: ProductModel) => void }) => {
  const [text, setText] = useState(product?.outPrice?.toString() || "");

  useEffect(() => {
    setText(product?.outPrice.toString() || "");
  }, [product?.margin, product?.price]);

  return (
    <input
      type="number"
      className={`addProductField text-right`}
      disabled={!product}
      value={text}
      onChange={(e) => {
        const newValue = e.currentTarget.value;
        setText(newValue);

        const outPrice = parseFloat(newValue);
        if (product && !isNaN(outPrice)) {
          const margin = ((outPrice - product.price) / product.price) * 100;
          let marginString = "";
          if (isFinite(margin)) {
            marginString = margin.toFixed(2) + "%";
          }
          setProduct({ ...product, margin: marginString, outPrice });
        }
      }}
    />
  );
};
