import { ModelMapper } from "./ModelMapper";

export interface Step2Model {
  consentOk: boolean;
  mbnAgreementNumber: string;
  oneNumberSetup: "allUsers" | "someUsers" | "none";
  oneNumberMapping: "customer" | "agent" | "telenor" | "none";
  samkomMbn: "customer" | "agent" | "telenor" | "none";
  fixedTeamsTrafficIncluded: boolean;
  msLicencesAdded: boolean;
  porting: {
    enabled: false;
    date: string;
    time: string;
  };
  conferenceCallNumber: {
    enabled: false;
    number: string;
  };
  teamsQueueNumber: {
    enabled: false;
    number: string;
  };
  invoice: {
    option: "new" | "existing";
    account: string;
    email: string;
    address: string;
  };
  piloting: boolean;
  contact: {
    name: string;
    email: string;
    mobile: string;
  };
  mbnAdmin: {
    name: string;
    email: string;
    mobile: string;
  };
  teamsAdmin: {
    name: string;
    email: string;
    mobile: string;
  };
  customerHasItPartner: boolean;
  partnerSameAsDealer: boolean;
  partner: {
    name: string;
    email: string;
    mobile: string;
  };
  deliveryInfo: string;
}

const newStep2Model: () => Step2Model = () => ({
  consentOk: false,
  mbnAgreementNumber: "",
  oneNumberSetup: "none",
  oneNumberMapping: "none",
  samkomMbn: "none",
  fixedTeamsTrafficIncluded: false,
  msLicencesAdded: false,
  porting: {
    enabled: false,
    date: "",
    time: "",
  },
  conferenceCallNumber: {
    enabled: false,
    number: "",
  },
  teamsQueueNumber: {
    enabled: false,
    number: "",
  },
  invoice: {
    option: "new",
    account: "",
    email: "",
    address: "",
  },
  piloting: false,
  contact: {
    name: "",
    email: "",
    mobile: "",
  },
  mbnAdmin: {
    name: "",
    email: "",
    mobile: "",
  },
  teamsAdmin: {
    name: "",
    email: "",
    mobile: "",
  },
  customerHasItPartner: false,
  partnerSameAsDealer: false,
  partner: {
    name: "",
    email: "",
    mobile: "",
  },
  deliveryInfo: "",
});

export const Step2ModelMapper: ModelMapper<Step2Model> = {
  newModel() {
    return newStep2Model();
  },
  mapToModel(backendObject) {
    return { ...newStep2Model(), ...backendObject };
  },
};
