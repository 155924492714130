import OrderModel from "../../models/OrderModel";
import { Schema } from "../SchemaMapper";

export const OrderSchema: Schema<OrderModel> = {
  fields: [
    {
      label: "Orgnr",
      set: 0,
      visible: true,
      export: true,
      getValue: (order) => order.customer.orgnr,
    },
    {
      label: "Navn 1",
      set: 0,
      visible: true,
      export: true,
      getValue: (order) => order.customer.name1,
    },
    {
      label: "Navn 2",
      set: 0,
      visible: true,
      export: true,
      getValue: (order) => order.customer.name2,
    },
    {
      label: "Adresse 1",
      set: 0,
      visible: true,
      export: true,
      getValue: (order) => order.customer.address1,
    },
    {
      label: "Postnr",
      set: 0,
      visible: true,
      export: true,
      getValue: (order) => order.customer.zipcode,
    },
    {
      label: "Poststed",
      set: 0,
      visible: true,
      export: true,
      getValue: (order) => order.customer.city,
    },
    //Varemottaker
    {
        label: "Navn",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.name,
      },
      {
        label: "CO addresse",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.address2,
      },
      {
        label: "Gateadresse",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.address,
      },
      {
        label: "Land",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.country,
      },
      {
        label: "Postnr",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.zipcode,
      },
      {
        label: "Poststed",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.city,
      },
      {
        label: "Kontakt",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.contact,
      },
      {
        label: "Mobil",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.mobile,
      },
      {
        label: "E-post",
        set: 1,
        visible: true,
        export: true,
        getValue: (order) => order.delivery.email,
      },
    //Ordrer
    {
        label: "Id",
        set: 2,
        visible: true,
        export: true,
        getValue: (order) => order.id,
      },
    {
      label: "Leveringsdato",
      set: 2,
      visible: true,
      export: true,
      getValue: (order) => order.deliveryDate,
    },
    {
      label: "Kunde referanse",
      set: 2,
      visible: true,
      export: true,
      getValue: (order) => order.reference,
    },
    {
      label: "Kunde rekvisisjon",
      set: 2,
      visible: true,
      export: true,
      getValue: (order) => order.requisition,
    },
    {
      label: "CaseNr/Bestillingsnr",
      set: 2,
      visible: true,
      export: true,
      getValue: (order) => order.caseNumber,
    },
    {
      label: "SapNr",
      set: 2,
      visible: true,
      export: true,
      getValue: (order) => order.sapNumber,
    },
    {
      label: "Sporingsnummer",
      set: 2,
      visible: true,
      export: true,
      getValue: (order) => order.tracking,
    },
    {
      label: "Samlefaktura",
      set: 2,
      visible: true,
      export: true,
      getValue: (order) => order.invoice,
    },
    {
      label: "Ordrebekreftelse",
      set: 2,
      visible: true,
      export: true,
      getValue: (order) => order.orderConfirm,
    },
  ],
  sets: ["Oppdragsgiver", "Varemottaker", "Ordrer"],
  layout: [{ sets: [0, 1, 2] }],
};
