import { UIField } from "../../../models/UIField";

export const DateField = ({ model, readonly, disabled }: { model: UIField; readonly?: boolean; disabled?: boolean }) => {
  return (
    <div className="flex h-7 items-center">
      <label title={model.description}>
        {model.label}
      </label>
      <input
        type="date"
        className={`w-3/5 ml-auto px-2 rounded border-2 ${!disabled ? "text-black" : "bg-transparent text-white"}`}
        readOnly={readonly}
        value={model.value}
        onInput={(e) => {
          model.setValue(e.currentTarget.value);
        }}
      />
    </div>
  );
};
