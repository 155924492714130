import { useState } from "react";
import { CrudDialog } from "../../../common/components/dialogs/CrudDialog";
import { ExOrderProductLineModel } from "../../models";
import { EditProductLineLayout } from "./EditProductLine";
import { useProductLineEditor } from "./useProductLineEditor";
import { useProductLineViewer } from "./useProductLineViewer";

export function EditProductLineDialog({ productLine, onUpdate, onDelete, onClose }: { productLine: ExOrderProductLineModel; onUpdate: (updatedLine: ExOrderProductLineModel) => void; onDelete: () => void; onClose: () => void }) {
  const [readonly, setReadonly] = useState<boolean>(true);

  const productLineViewer = useProductLineViewer(productLine, EditProductLineLayout);
  const productLineEditor = useProductLineEditor(productLine, EditProductLineLayout);

  return (
    <CrudDialog
      mode="view"
      onAction={(action) => {
        if (action === "close") {
          onClose();
        } else if (action === "update" && productLineEditor.value) {
          onUpdate(productLineEditor.value);
        } else if (action === "delete") {
          onDelete();
        } else if (action === "editOn") {
          setReadonly(false);
        } else if (action === "editOff") {
          setReadonly(true);
        }
      }}
    >
      <div className="m-4">{readonly ? productLineViewer : productLineEditor.layout}</div>
      {/* <EditProductLine productLine={selectedProduct} onSetProductLine={setSelectedProduct} readonly={readonly} /> */}
    </CrudDialog>
  );
}
