import { useContext, useRef, useState } from "react";
import { SelectBox } from "../../../../components/elements/common/SelectBox";
import { TextField } from "../../../../components/common/TextField";
import { ENV, TELENOR_PORTAL_SERVICES_URL } from "../../../../env";
import AppContext from "../../../../contexts/AppContext";
import { Language, Gender, Text2SpeechModel, Text2SpeechModelManager, TextSectionModelManager, supportedDelay, supportedLanguage, supportedGender, supportedSpeed, Format, supportedFormat } from "./models/Text2SpeechModel";

const servicesUrl = TELENOR_PORTAL_SERVICES_URL;
// const servicesUrl = "http://localhost:8000";

export function Text2Speech() {
  const appContext = useContext(AppContext);

  const [status, setStatus] = useState<"init" | "processing" | "completed" | "error">("init");
  const [fileName, setFilename] = useState<string>("");
  const [fileUrl, setFileUrl] = useState<string>("");

  const [model, setModel] = useState<Text2SpeechModel>(Text2SpeechModelManager().create());
  const importFileRef = useRef<HTMLInputElement | null>(null);

  function exportSsml() {
    const json = Text2SpeechModelManager().toJson(model);
    const blob = new Blob([json], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "export.json";
    link.href = url;
    link.click();
  }

  function importSsml(fileName: string) {
    fetch(fileName, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then(function (response) {
      console.log(response);
      return response.json();
    });
  }

  async function createVoice() {
    const body = JSON.stringify({ ssml: Text2SpeechModelManager().toSsml(model), language: model.defaultLanguage, gender: model.defaultGender, encoding: model.format, speed: model.speed });
    
    setStatus("processing");
    try {
      const url = `${servicesUrl}/text2speech?env=${ENV}`;
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Auth0-User", appContext.login?.uid || "");
      const res = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
        redirect: "follow",
      });

      if (res.status === 200) {
        const voiceFile = await res.blob();
        const url = URL.createObjectURL(voiceFile);
        setFileUrl(url);
        setFilename(`output.${model.format.toLowerCase()}`);
        setStatus("completed");
      } else {
        setStatus("error");
      }
    } catch (err) {
      setStatus("error");
      console.log("Error:", err);
    }
  }

  return status === "init" ? (
    <div className="flex flex-col m-auto gap-2 p-2 rounded-md">
      {/* <div className="flex ml-auto">
        <input className="hidden" ref={importFileRef} type="file" id="file" onChange={(e) => importSsml(e.target.value)} />
        <button className="tp-button-cancel" onClick={exportSsml}>
          Eksport
        </button>
        <button className="ml-4 tp-button-cancel" onClick={() => importFileRef.current?.click()}>
          Import
        </button>
      </div> */}
      {model.textSections.map((section) => (
        <div className="flex flex-col gap-1 bg-gray-600 p-2 rounded-md">
          <div className="flex w-full">
            <button className="-mt-1 ml-auto -mr-1 w-4 h-4" onClick={() => setModel({ ...model, textSections: model.textSections.filter((item) => item !== section) })}>
              <img src="/assets/images/close.svg" />
            </button>
          </div>
          <textarea
            className="flex text-black p-2 rounded-md font-bold"
            rows={2}
            cols={96}
            value={section.text}
            onChange={(e) => setModel({ ...model, textSections: model.textSections.map((item) => (item === section ? { ...section, text: e.currentTarget.value } : item)) })}
          />
          <div className="flex gap-8">
            <div className="flex flex-1 gap-4">
              <div className="flex-1">
                <SelectBox
                  label="Språk"
                  items={supportedLanguage}
                  value={section.language}
                  onValueChanged={(value) => setModel({ ...model, textSections: model.textSections.map((item) => (item === section ? { ...item, language: value as Language } : item)) })}
                />
              </div>
              <div className="flex-1">
                <SelectBox label="Kjønn" items={supportedGender} value={section.gender} onValueChanged={(value) => setModel({ ...model, textSections: model.textSections.map((item) => (item === section ? { ...item, gender: value as Gender } : item)) })} />
              </div>
            </div>
            <div title="Delay" className="flex flex-1 items-center gap-2">
              <label className="w-1/4">Forsinkelse</label>
              <input
                className="flex-1 bg-red-200"
                type="range"
                id="delay"
                min={0}
                max={supportedDelay.length - 1}
                list="delay-values"
                value={supportedDelay.indexOf(section.delay)}
                onChange={(e) => setModel({ ...model, textSections: model.textSections.map((item) => (item === section ? { ...item, delay: supportedDelay[parseInt(e.currentTarget.value)] } : item)) })}
              />
              <label className="w-12 text-right" htmlFor="speed">
                {section.delay}s
              </label>
            </div>
          </div>
        </div>
      ))}
      <div className="flex">
        <button className="mx-auto px-8 border-2 text-xl border-dashed" onClick={() => setModel({ ...model, textSections: [...model.textSections, TextSectionModelManager().create()] })}>
          +
        </button>
      </div>
      <div className="flex w-full gap-8 items-center border-t-2 pt-4">
        <div className="flex-1">
          <SelectBox
            label="Format"
            items={supportedFormat}
            value={model.format}
            onValueChanged={(value) => setModel({ ...model, format: value as Format })}
          />
        </div>
        <div title="Speed" className="flex flex-1 items-center gap-2">
          <label className="w-1/4">Hastighet</label>
          <input
            className="flex-1 bg-red-200"
            type="range"
            id="speed"
            min={0}
            max={supportedSpeed.length - 1}
            list="speed-values"
            value={supportedSpeed.indexOf(model.speed)}
            onChange={(e) => setModel({ ...model, speed: supportedSpeed[parseInt(e.currentTarget.value)] })}
          />
          <label className="w-12 text-right" htmlFor="speed">
            {model.speed}x
          </label>
          <datalist id="delay-values">
            {supportedDelay.map((item, i) => (
              <option key={`${item}x`} value={i}></option>
            ))}
          </datalist>
          <datalist id="speed-values">
            {supportedSpeed.map((item, i) => (
              <option key={`${item}x`} value={i}></option>
            ))}
          </datalist>
        </div>
      </div>
      <button className="ml-auto tp-button-confirm" onClick={createVoice}>
        Convert
      </button>
    </div>
  ) : (
    <div className="flex flex-col h-40 w-96 mx-16 my-8">
      {status === "processing" ? (
        <img className="m-auto h-16 w-16" src="/assets/images/loading.gif" alt="loading" />
      ) : status === "completed" ? (
        <div className="flex flex-col m-auto w-full gap-2">
          <audio controls className="w-full">
            <source src={fileUrl} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
          <TextField label="Filnavn:" text={fileName} onTextChanged={(text) => setFilename(text)} />
          <div className="flex ml-auto gap-2">
            <button
              className="tp-button-confirm"
              onClick={() => {
                const link = document.createElement("a");
                link.download = fileName;
                link.href = fileUrl;
                link.click();
              }}
            >
              Nedlast
            </button>
            <button className="tp-button-cancel" onClick={() => setStatus("init")}>
              Avbryt
            </button>
          </div>
        </div>
      ) : status === "error" ? (
        <div className="flex flex-col m-auto items-center gap-4">
          <p className="m-auto text-2xl font-bold">Beklager, noe gikk galt!</p>
          <button className="tp-button-cancel" onClick={() => setStatus("init")}>
            Tilbake
          </button>
        </div>
      ) : null}
    </div>
  );
}
