function getRGBCode(text: string) {
  var hash = 0;
  for (var i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }
  var c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}

export function ClaimCell({ initials, name, action, onClick }: { initials?: string; name?: string; action?: "claim" | "unclaim"; onClick?: () => void }) {
  return (
    <div className="flex gap-2">
      <div title={name} className={`flex rounded-full h-9 w-9`} style={name ? { backgroundColor: `#${getRGBCode(name)}` } : undefined}>
        <span className="m-auto">{initials || "?"}</span>
      </div>
      {action ? (
        <button className={action === "claim" ? "tp-button-confirm" : "tp-button-cancel"} onClick={onClick}>
          {action === "claim" ? "Claim" : "Unclaim"}
        </button>
      ) : null}
    </div>
  );
}
