import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";
import { EditboxField } from "../../common/elements";
import { SelectboxField } from "../../common/elements/SelectboxField";
import { ModuleAccessLevel, UserAccessModel, UserModel } from "../models";
import { ModulePermissionLevels } from "../utils/ModulePermissionLevels";

function EditModulePermission({ module, levels, level, onSetLevel, readonly }: { module: string; levels: ModuleAccessLevel[]; level: number; onSetLevel: (level: number) => void; readonly?: boolean }) {
  return (
    <li className="flex items-center">
      <h3 className="w-48">{module}</h3>
      <div title={levels[level].description} className="flex items-center gap-2">
        <input className="w-48" type="range" id={`${module}-range`} name="volume" min="0" max={levels.length - 1} list={`${module}-options`} value={level} onChange={(e) => onSetLevel(parseInt(e.currentTarget.value))} disabled={readonly} />
        <label className="w-48" htmlFor={`${module}-range`}>
          {levels[level].label}
        </label>
        <datalist id={`${module}-options`}>
          {levels.map((item) => (
            <option value={item.value}></option>
          ))}
        </datalist>
      </div>
    </li>
  );
}

function EditUserAccess({ access, loginAccess, onSetAccess, readonly }: { access: UserAccessModel; loginAccess?: UserAccessModel; onSetAccess: (access: UserAccessModel) => void; readonly?: boolean }) {
  return (
    <>
      <h2 className="font-bold my-1">Module Permissions</h2>
      <ul className="flex flex-col gap-4 rounded border-2 p-4">
        {ModulePermissionLevels.map((item) => {
          return (
            <EditModulePermission
              key={item.moduleName}
              module={item.moduleName}
              levels={item.levels}
              level={access.modules[item.moduleName].value}
              onSetLevel={(level) => {
                const loginLevel = loginAccess?.modules[item.moduleName].value;
                if (loginAccess?.role === "admin" || (loginLevel && level <= loginLevel)) {
                  onSetAccess({ ...access, modules: { ...access.modules, [item.moduleName]: { ...access.modules[item.moduleName], value: level } } });
                }
              }}
              readonly={readonly}
            />
          );
        })}
      </ul>
    </>
  );
}

export const EditUser = ({ user, onSetUser, readonly }: { user: UserModel; onSetUser: (user: UserModel) => void; readonly: boolean }) => {
  const appContext = useContext(AppContext);
  const loginAccess = appContext.login?.access;

  const roles = loginAccess?.role === "admin" ? ["user", "admin"] : ["user"];

  return (
    <div className="flex flex-col">
      <div className="flex w-full px-1">
        <button className={`${user.active ? "tp-button-confirm" : "tp-button-cancel"} ml-auto`} disabled={readonly} onClick={() => onSetUser({ ...user, active: !user.active })}>
          {user.active ? "Active" : "Inactive"}
        </button>
      </div>
      <div className="flex flex-col mt-4 mb-8 gap-1">
        <EditboxField
          label="Navn"
          value={user.name}
          readonly={readonly}
          onSetValue={(text) => {
            onSetUser({ ...user, name: text });
          }}
        />
        <EditboxField
          label="Email"
          value={user.email}
          readonly
          onSetValue={(text) => {
            onSetUser({ ...user, email: text });
          }}
        />
        <SelectboxField label="Role" items={roles.map((item) => ({ name: item, value: item }))} value={user.access.role} onSetValue={(value) => onSetUser({ ...user, access: { ...user.access, role: value } })} disabled={readonly} />
      </div>
      {user.access.role !== "admin" ? <EditUserAccess access={user.access} loginAccess={loginAccess} onSetAccess={(access) => onSetUser({ ...user, access: access })} readonly={readonly} /> : null}
    </div>
  );
};
