import { ModelMapper } from "../ModelMapper";

export interface MessageModel {
  id: string;
  message: string;
  user_id: string;
  stamp: Date;
  name: string;
}

export const MessageModelMapper: ModelMapper<MessageModel> = {
  createEmptyObject: () => {
    return { id: "", message: "", user_id: "", stamp: new Date(), name: "" };
  },
  fromBackendObject: (backendObject: any) => {
      return {...backendObject, stamp: new Date(backendObject.stamp)};
  },
  toBackendObject: (message: MessageModel) => {
    return message;
  }
};
