import { TextField } from "@mui/material";
import { SklPriceGroupModel } from "../models/SklPriceSettingsModel";
import { useState } from "react";

const getNewPriceGroup = (lastPriceGroup?: SklPriceGroupModel) => {
  let from = lastPriceGroup?.to || 0;
  return {
    from: from,
    to: from + 1,
    prices: [
      { number: "1001", description: "Etablering", price: "100" },
      { number: "1002", description: "Månedlig kundepris", price: "100" },
      { number: "1003", description: "Månedlig brukerpris", price: "100" },
    ],
  } as SklPriceGroupModel;
};

export function SklPriceGroupPanel({
  priceGroups,
  onAdd,
  onChange,
  onDelete,
}: {
  priceGroups: SklPriceGroupModel[];
  onAdd: (priceGroup: SklPriceGroupModel) => void;
  onChange: (original: SklPriceGroupModel, changed: SklPriceGroupModel) => void;
  onDelete: (priceGroup: SklPriceGroupModel) => void;
}) {
  const [newPriceGroup, setNewPriceGroup] = useState<SklPriceGroupModel>(getNewPriceGroup(priceGroups[priceGroups.length - 1]));

  return (
    <table className="w-full my-4 text-white">
      <tr className="">
        <th className="text-left w-48">Brukere</th>
        <th className="text-left">Etablering</th>
        <th className="text-left">Månedlig kundepris</th>
        <th className="text-left">Månedlig brukerpris</th>
        <th></th>
      </tr>
      {priceGroups.map((priceGroup) => (
        <tr>
          <td className="w-32">
            {priceGroup.from} - {priceGroup.to}
          </td>
          {priceGroup.prices.map((price) => (
            <td>
              <TextField
                sx={{ input: { color: "white" } }}
                type="number"
                value={price?.price}
                onChange={(e) =>
                  onChange(priceGroup, {
                    ...priceGroup,
                    prices: priceGroup.prices.map((item) => (item === price ? { ...item, price: e.target.value } : item)),
                  })
                }
              />
            </td>
          ))}
          <td>
            <button className="my-auto h-8 w-8" onClick={() => onDelete(priceGroup)}>
              <img src="/assets/images/delete.svg" alt="delete" />
            </button>
          </td>
        </tr>
      ))}
      <tr className="">
        <td className="flex">
          <TextField sx={{ input: { color: "white", width: "48px" } }} type="number" value={newPriceGroup.from} onChange={(e) => setNewPriceGroup({ ...newPriceGroup, from: parseInt(e.target.value) })} />
          <span className="mx-2 my-auto">-</span>
          <TextField sx={{ input: { color: "white", width: "48px" } }} type="number" value={newPriceGroup.to} onChange={(e) => setNewPriceGroup({ ...newPriceGroup, to: parseInt(e.target.value) })} />
        </td>
        {newPriceGroup.prices.map((price) => (
          <td>
            <TextField
              sx={{ input: { color: "white" } }}
              type="number"
              value={price?.price}
              onChange={(e) =>
                setNewPriceGroup({
                  ...newPriceGroup,
                  prices: newPriceGroup.prices.map((item) => (item === price ? { ...item, price: e.target.value } : item)),
                })
              }
            />
          </td>
        ))}
        <td>
          <button
            className="my-auto h-8 w-8"
            onClick={() => {
              onAdd(newPriceGroup);
              setNewPriceGroup(getNewPriceGroup(newPriceGroup));
            }}
          >
            <img src="/assets/images/add.svg" alt="add" />
          </button>
        </td>
      </tr>
    </table>
  );
}
