import { useState } from "react";
import { ModalDialog } from "../common/components/dialogs";
import { FormButton } from "./components";

const baseUrl = "https://telenor-web-forms-whch5fynyq-lz.a.run.app";

const forms: { title: string; image: string; path: string }[] = [{ title: "Eierskifte for mobilabonnement", image: "/assets/images/forms/switchOwner.svg", path: "eierskifte" }];

export function FormsModule() {
  const [path, setPath] = useState<string>("");
  const [dialogTitle, setDialogTitle] = useState<string>("");

  return (
    <div className="flex flex-wrap gap-8 p-16">
      {path && (
        <ModalDialog visible onClose={() => setPath("")} title={dialogTitle}>
          <div className="flex flex-1 gap-2">
            <div className="flex flex-1 bg-white rounded-md">
              <iframe className="mx-auto my-8 w-full" src={`${baseUrl}/${path}`}></iframe>
            </div>
            <div className="flex flex-1 bg-white rounded-md">
            </div>
          </div>
        </ModalDialog>
      )}
      {forms.map((form) => (
        <FormButton
          key={form.path}
          title={form.title}
          image={form.image}
          onClick={() => {
            setPath(form.path);
            setDialogTitle(form.title);
          }}
        />
      ))}
    </div>
  );
}
