export const EditboxField = ({
  label,
  labelHint,
  labelClass,
  text,
  focus,
  onTextChanged,
  mode,
}: {
  label: string,
  labelHint?: string,
  labelClass?: string;
  text: string;
  focus?: boolean,
  onTextChanged: (text: string) => void;
  onEditingCompleted?: () => void;
  mode?: "edit" | "readonly";
}) => {

  return (
    <div className="flex m-1 h-7">
      <label title={labelHint} className={labelClass || "w-1/4 my-auto"}>
        {label}
      </label>
      <input      
        type="text"
        className={`w-full px-2 rounded-md border-2 ${mode === "edit" ? "bg-white text-black" : "bg-transparent text-white"}`}
        readOnly={mode === "readonly"}
        autoFocus={focus}
        value={text}
        onInput={(e) => {
          onTextChanged(e.currentTarget.value);
        }}
      />
    </div>
  );
};
