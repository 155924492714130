import { TELENOR_API_URL } from "../../../env";
import { FlowCreator, FlowModel, FlowNodeTypeModel, FlowOrderCreator, FlowOrderInfoCreator, FlowOrderInfoModel, FlowOrderModel, FlowStateModel } from "../models";

const baseUrl = `${TELENOR_API_URL}/flows`;

export async function getAllFlows(userId?: string): Promise<FlowModel[]> {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}`, {
    method: "GET",
    headers: headers,
  });
  const data = await res.json();
  return data as FlowModel[];
}

export async function getFlow(flowId: string, userId?: string) {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/${flowId}`, {
    method: "GET",
    headers: headers,
  });
  const data = await res.json();
  return data;
}

export async function updateFlow(flow: FlowModel, userId?: string): Promise<FlowModel> {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/${flow.group_id}`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ steps: flow.steps?.map((item) => ({ ...item, id: "", first_step: item.first_step ? "1" : "0" })) }),
  });
  const data = await res.json();

  return { ...flow, ...FlowCreator().fromBackendObject(data) };
}

export async function getAllNodeTypes(userId?: string): Promise<FlowNodeTypeModel[]> {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/nodes`, {
    method: "GET",
    headers: headers,
  });
  const data = await res.json();
  return data as FlowNodeTypeModel[];
}

export async function getAllFlowStates(userId?: string): Promise<FlowStateModel[]> {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/states`, {
    method: "GET",
    headers: headers,
  });
  const data = await res.json();
  return data as FlowStateModel[];
}

export async function getAllOrderInfos(flowId: string, userId?: string): Promise<FlowOrderInfoModel[]> {
  const creator = FlowOrderInfoCreator();

  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/${flowId}/orders`, {
    method: "GET",
    headers: headers,
  });
  const data = await res.json();
  return data.map((item: any) => creator.fromBackendObject(item));
}

export async function getActiveOrderInfos(userId?: string): Promise<FlowOrderInfoModel[]> {
  const creator = FlowOrderInfoCreator();

  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/orders/overview?active=1`, {
    method: "GET",
    headers: headers,
  });
  const data = await res.json();
  return data.map((item: any) => creator.fromBackendObject(item));
}

export async function getOrder(orderId: string, userId?: string): Promise<FlowOrderModel> {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/orders/${orderId}`, {
    method: "GET",
    headers: headers,
  });
  const data = await res.json();
  return FlowOrderCreator().fromBackendObject(data);
}

export async function createOrder(order: FlowOrderModel, userId?: string): Promise<FlowOrderModel> {
  console.log("CREATE ORDER", order);
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/orders`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(FlowOrderCreator().toBackendObject(order)),
  });
  const data = await res.json();
  console.log("ORDER CREATED", data);
  return FlowOrderCreator().fromBackendObject(data.order.raw_data);
}

export async function updateOrder(order: FlowOrderModel, userId?: string) {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/orders/${order.id}`, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(FlowOrderCreator().toBackendObject(order)),
  });
  const data = await res.json();
  return data;
}

export async function claimOrder(orderId: string, userId?: string) {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/orders/${orderId}/claim`, {
    method: "PUT",
    headers: headers,
  });
  if(res.ok) {
    return true;
  } else {
    throw new Error("Order already claimed")
  }
}

export async function unclaimOrder(orderId: string, userId?: string) {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/orders/${orderId}/claim`, {
    method: "DELETE",
    headers: headers,
  });
  if(res.ok) {
    return true;
  } else {
    throw new Error("Can't unclaim the order");
  }
}

export async function tagOrder(orderId: string, tag: any, userId?: string) {
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/orders/${orderId}/tag`, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(tag)
  });
  if(res.ok) {
    return true;
  } else {
    throw new Error("Can't tag the order");
  }
}

export async function runOrderSteps(order: FlowOrderModel, stepId: string, stop: boolean, userId?: string) {
  await updateOrder(order, userId);
  const headers = new Headers();
  headers.append("Auth0-User", userId || "");
  const res = await fetch(`${baseUrl}/orders/${order.id}/run?status=${stepId}&stop=${stop ? "1" : "0"}`, {
    method: "GET",
    headers: headers,
  });
  const data = await res.json();
  return data;
}
