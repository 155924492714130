import { useContext, useState } from "react";
import AppContext from "../../contexts/AppContext";
import { ModuleContext } from "../common/components";
import { ExBackend } from "./backends";
import { ExLayout, ExOrderCreated } from "./ExLayout";
import { ExOrderMapper, ExOrderModel } from "./models";
import { checkRequiredFields } from "./utils/ExOrderHelper";

export function ExModule() {
  const [order, setOrder] = useState<ExOrderModel>(ExOrderMapper.empty());

  const processor = useContext(ModuleContext);
  const appContext = useContext(AppContext);

  const [result, setResult] = useState<string>("");

  async function createOrderHandler() {
    appContext.setNotification(null);
    const requiredFields = checkRequiredFields(order);
    if (requiredFields) {
      appContext.setNotification({ type: "error", text: `Obligatoriske felter: ${requiredFields}` });
    } else if (order.bundles.length + order.products.length === 0) {
      appContext.setNotification({ type: "error", text: `Du må legge til minst ett produkt i listen` });
    } else {
      const createdOrder = await processor?.run(ExBackend.createExOrder, order);
      setResult(`Bestillingen #${createdOrder.orderId} opprettet`);
    }
  }

  return (
    <div className="flex flex-1">
      {result ? (
        <ExOrderCreated
          message={result}
          onBack={() => {
            setOrder(ExOrderMapper.empty());
            setResult("");
          }}
        />
      ) : (
        <ExLayout order={order} onSetOrder={setOrder} onCreateOrder={createOrderHandler} />
      )}
    </div>
  );
}
