import { useContext, useEffect, useState } from "react";
import { CustomerModel } from "../../../models/CustomerModel";
import backends from "../../../backends";
import AppContext from "../../../contexts/AppContext";

const SearchableField = ({ label, description, value, datalistName, onSetValue, readonly }: { label: string; description: string; value: string; datalistName: string; onSetValue: (value: string) => void; readonly?: boolean }) => {
  return (
    <div className="flex m-1 h-7">
      <label title={description} className="w-48 my-auto">
        {label}
      </label>
      <input
        className={readonly ? "editBoxReadonlyField" : "editBoxField"}
        value={value}
        list={datalistName}
        onInput={(e) => {
          onSetValue(e.currentTarget.value);
        }}
        readOnly={readonly}
      />
    </div>
  );
};

const ReadOnlyField = ({ label, description, value }: { label: string; description: string; value: string }) => {
  return (
    <div className="flex m-1 h-7">
      <label title={description} className="w-48 my-auto">
        {label}
      </label>
      <input className="editBoxReadonlyField" value={value} />
    </div>
  );
};

export const CustomerPanel = ({ customer, onSelectCustomer, readonly }: { customer: CustomerModel | null; onSelectCustomer: (customer: CustomerModel | null) => void; readonly?: boolean }) => {
  const appContext = useContext(AppContext);
  const [customers, setCustomers] = useState<CustomerModel[]>([]);
  const [idNamePair, setIdNamePair] = useState<{ orgnr: string; name1: string }>({ orgnr: "", name1: "" });

  const searchCustomers = async (fieldName: string, fieldValue: string) => {
    let found = false;
    if (fieldValue.length >= 3) {
      for (const c of customers) {
        if ((c as any)[fieldName] === fieldValue) {
          found = true;
          setIdNamePair({ orgnr: c.orgnr || "", name1: c.name1 || "" });
          onSelectCustomer(c);
          break;
        }
      }
      if (!found) {
        setIdNamePair({ orgnr: "", name1: "", [fieldName]: fieldValue });
        onSelectCustomer(null);
        const customerList = await backends.customers.fetchAll(fieldName, fieldValue, appContext.login?.uid);
        setCustomers(customerList);
      }
    } else {
      setIdNamePair({ orgnr: "", name1: "", [fieldName]: fieldValue });
      onSelectCustomer(null);
      setCustomers([]);
    }
  };

  useEffect(() => {
    if (customer) {
      setCustomers([customer]);
      setIdNamePair({ orgnr: customer.orgnr, name1: customer.name1 });
    }
  }, []);

  return (
    <div className="col-span-2">
      <div className="border-b-2 mb-3 font-bold">Oppdragsgiver</div>
      <SearchableField
        label="Orgnr"
        description=""
        value={idNamePair.orgnr}
        datalistName="orgnr"
        onSetValue={(value) => {
          searchCustomers("orgnr", value);
        }}
        readonly={readonly}
      />
      <SearchableField
        label="Navn 1"
        description=""
        datalistName="name1"
        value={idNamePair.name1}
        onSetValue={(value) => {
          searchCustomers("name1", value);
        }}
        readonly={readonly}
      />
      <ReadOnlyField label="Navn 2" description="" value={customer?.name2 || ""} />
      <ReadOnlyField label="Adresse" description="" value={customer?.address1 || ""} />
      <ReadOnlyField label="Postnr" description="" value={customer?.zipcode || ""} />
      <ReadOnlyField label="Poststed" description="" value={customer?.city || ""} />

      <datalist id="orgnr">
        {customers.map((customer) => (
          <option value={customer.orgnr} label={`${customer.name1}, ${customer.zipcode ? customer.zipcode + " " : ""}${customer.city || ""}`} key={customer.id} />
        ))}
      </datalist>

      <datalist id="name1">
        {customers.map((customer) => (
          <option value={customer.name1} label={`${customer.zipcode ? customer.zipcode + " " : ""}${customer.city ? customer.city + " " : ""} (${customer.orgnr})`} key={customer.id} />
        ))}
      </datalist>
    </div>
  );
};
