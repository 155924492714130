import { ExOrderModel, ExOrderProductLineModel } from "../models";
import { getTotalPriceAndProfit } from "./PriceCalculator";

export function checkRequiredFields(exOrder: ExOrderModel) {
  const customerFields: string[] = [];
  if (!exOrder.customer?.orgnr) customerFields.push("Orgnr");
  if (!exOrder.customer?.name1) customerFields.push("Navn 1");

  const deliveryFields: string[] = [];
  if (!exOrder.delivery.name) deliveryFields.push("Navn 1");
  if (!exOrder.delivery.address2) deliveryFields.push("Gateadresse");
  if (!exOrder.delivery.zipcode) deliveryFields.push("Postnr");
  if (!exOrder.delivery.city) deliveryFields.push("Poststed");
  if (!exOrder.delivery.country) deliveryFields.push("Land");
  if (!exOrder.delivery.contact) deliveryFields.push("Kontakt");
  if (!exOrder.delivery.mobile) deliveryFields.push("Mobil");
  if (!exOrder.delivery.email) deliveryFields.push("E-post");

  return `${customerFields.length > 0 ? "Oppdragsgiver(" + customerFields.join(", ") + "), " : ""}${deliveryFields.length > 0 ? "Varemottaker(" + deliveryFields.join(", ") + ")" : ""}`;
}
export function getAllProductLines(order: ExOrderModel) {
  const allProductLines: ExOrderProductLineModel[] = [];
  for (const bundle of order.bundles) {
    allProductLines.push(...bundle.productLines);
  }
  allProductLines.push(...order.products);
  return allProductLines;
}

export function getTotals(order: ExOrderModel) {
  const productLines = getAllProductLines(order);
  return getTotalPriceAndProfit(productLines);
}

export function getOrderedSuppliers(order: ExOrderModel) {
  const suppliers: { id: string; name: string }[] = [];
  for (const bundle of order.bundles) {
    for (const productLine of bundle.productLines) {
      const found = suppliers.find((item) => item.id === productLine.levnr);
      if (!found) {
        suppliers.push({ id: productLine.levnr, name: productLine.levname });
      }
    }
  }
  for (const productLine of order.products) {
    const found = suppliers.find((item) => item.id === productLine.levnr);
    if (!found) {
      suppliers.push({ id: productLine.levnr, name: productLine.levname });
    }
  }
  return suppliers;
}
