import { FlowOrderDataModel } from "../../models";

export function OrderDataJsonViewer({ orderData, setOrderData }: { orderData: FlowOrderDataModel; setOrderData?: (data: FlowOrderDataModel) => void }) {
  const mappedData = orderData.map((item) => ({ status: item.status, state: item.state, in: item.in, out: item.out, next_status: item.next_status }));

  return (
    <div className="flex flex-1">
      <textarea className="flex flex-1 rounded-md" readOnly value={JSON.stringify(mappedData, null, 4)} />
    </div>
  );
}
