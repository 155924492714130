import { useState } from "react";
import { SklOrderModel } from "../models/SklOrderModel";
import { FormDateField, FormSwitchField, FormTextField } from "./elements/FormField";
import { TELENOR_SKL_EXPORT_PAGE_URL } from "../../../env";

export function SklOrderControlPanel(originalOrder: SklOrderModel, onSave?: (updatedOrder: SklOrderModel) => void): JSX.Element {
  const [order, setOrder] = useState(originalOrder);
  const [exportSteps, setExportSteps] = useState<{ step1: boolean; step2: boolean }>({ step1: true, step2: true });

  return (
    <div className="flex m-2 text-md px-16 py-4 border-double border-b-4 border-[var(--inselo-light-gray)] gap-8">
      <div className="flex flex-1 flex-col gap-2 ml-8">
        <FormSwitchField
          field={{
            label: "Nummere er lastet til kunde",
            value: order.status.numbersLoaded,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, numbersLoaded: value } }),
          }}
        />
        <FormSwitchField
          field={{
            label: "Er løsning klar for test/pilotering?",
            value: order.status.readyForTest,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, readyForTest: value } }),
          }}
        />
        <FormSwitchField
          field={{
            label: "Er løsning klar for bruk?",
            value: order.status.readyForUse,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, readyForUse: value } }),
          }}
        />
        <FormSwitchField
          field={{
            label: "Er løsning satt i drift?",
            value: order.status.inUse,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, inUse: value } }),
          }}
        />
        <FormSwitchField
          field={{
            label: "Er løsning LEVERT?",
            value: order.status.delivered,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, delivered: value } }),
          }}
        />
      </div>
      <div className="flex flex-1 flex-col gap-2 ml-4">
        <FormDateField
          field={{
            label: "Dato for klar for test/pilotering",
            value: order.status.testStartDate,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, testStartDate: value } }),
          }}
          frontLabel
        />
        <FormDateField
          field={{
            label: "Dato for driftsettelse",
            value: order.status.prodStartDate,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, prodStartDate: value } }),
          }}
          frontLabel
        />
        <FormDateField
          field={{
            label: "Dato for oppstart faktura",
            value: order.status.invoiceStartDate,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, invoiceStartDate: value } }),
          }}
          frontLabel
        />
        <FormTextField
          field={{
            label: "Kommentar",
            value: order.status.comment,
            setValue: (value) => setOrder({ ...order, status: { ...order.status, comment: value } }),
          }}
          rows={3}
          frontLabel
        />
      </div>
      <div className="flex flex-col gap-2 ml-4">
        <button className="tp-button-confirm ml-auto mt-auto" onClick={() => onSave?.(order)}>
          Save
        </button>
      </div>
      <div className="flex flex-1 flex-col ml-4">
        
          <legend>Eksport</legend>
          <FormSwitchField
            field={{
              label: "Step 1",
              value: exportSteps.step1,
              setValue: (value) => setExportSteps({ ...exportSteps, step1: value }),
            }}
          />
          <FormSwitchField
            field={{
              label: "Step 2",
              value: exportSteps.step2,
              setValue: (value) => setExportSteps({ ...exportSteps, step2: value }),
            }}
          />
          <button className="tp-button-confirm ml-auto mt-auto" onClick={() => {
            const stepsString = `${exportSteps.step1 ? "1" : ""}${exportSteps.step2 ? "2" : ""}`
            const url = `${TELENOR_SKL_EXPORT_PAGE_URL}/${order.hash}?type=txt&steps=${stepsString}`
            window.open(url);
          }}>
            Eksport
          </button>
        
      </div>
    </div>
  );
}
