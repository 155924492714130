import { ExProductBundleModel } from "../../models";
import { EditboxField } from "../common/field";
import { TotalPrice } from "../../../common/components/panels/TotalPrice";
import { ProductListHeader } from "./ProductListHeader";
import { ProductLines } from "./ProductLines";
import { getTotalPriceAndProfit } from "../../utils";

export function EditBundle({ bundle, onSetBundle, readonly }: { bundle: ExProductBundleModel; onSetBundle: (bundle: ExProductBundleModel) => void; readonly?: boolean }) {
  const { totalPrice, totalProfit } = getTotalPriceAndProfit(bundle.products);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col gap-2 w-1/2 my-4">
        <EditboxField model={{ label: "Navn:", value: bundle.name, setValue: (value) => onSetBundle({ ...bundle, name: value }) }} readonly={readonly} />
        <EditboxField model={{ label: "Beskrivelse:", value: bundle.description, setValue: (value) => onSetBundle({ ...bundle, description: value }) }} readonly={readonly} />
      </div>
      <div className="w-full text-sm my-4">
        <p>Produkter:</p>
        <ProductListHeader />
        <ProductLines productLines={bundle.products} onSetProductLines={(productLines) => onSetBundle({ ...bundle, products: productLines })} />
      </div>
      <div className="flex my-4 -mr-4">
        <TotalPrice price={totalPrice} profit={totalProfit} />
      </div>
    </div>
  );
}
