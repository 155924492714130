import { ExProductModel, ExProductMapper, ExProductSupplierModel } from ".";
import { PriceCalculator } from "../utils";

export interface ExOrderProductLineModel extends ExProductModel {
  article_id?: string;
  line_id: string | null;
  bundle_id?: string;
  lev_alt: string;
  levartnr: string;
  calculation: string;
  outPrice: number;
  quantity: number;
  suppliers: ExProductSupplierModel[];
  bid: string;
  custom: boolean;
  moms: boolean;
  gartnr: string;
  information: string;
}

export const ExOrderProductLineMapper = {
  empty: () => {
    return {
      ...ExProductMapper.empty(),
      line_id: null,
      bundle_id: "",
      lev_alt: "2",
      calculation: "10%",
      outPrice: 0,
      quantity: 1,
      suppliers: [],
      bid: "",
      custom: false,
      moms: true,
      information: "",
    };
  },

  clone: (productLine: ExOrderProductLineModel) => {
    return { ...productLine };
  },

  fromProduct(po: ExProductModel) {
    let productLine = { ...ExOrderProductLineMapper.empty(), ...po } as ExOrderProductLineModel;
    productLine.outPrice = PriceCalculator.GetOutPrice(productLine.price, productLine.calculation);
    return productLine;
  },

  fromBackendObject: (bo: any) => {
    return {
      ...ExProductMapper.fromBackendObject(bo),
      article_id: bo.article_id,
      line_id: bo.line_id || null,
      bundle_id: bo.bundle_id || "",
      lev_alt: bo.lev_alt || "1",
      calculation: bo.calculation || "10%",
      outPrice: bo.outprice ? parseFloat(bo.outprice) : "",
      quantity: bo.quantity ? parseFloat(bo.quantity) : 1,
      suppliers: [],
      bid: bo.bid || "",
      custom: false,
      moms: bo.moms === "1",
      information: bo.information || "",
    };
  },

  toBackendObject: (product: ExOrderProductLineModel) => {
    return {
      article_id: product.id,
      id: null,
      calculation: product.calculation,
      outprice: product.outPrice,
      quantity: product.quantity,
      levnr: product.levnr,
      bid: product.bid,
      moms: product.moms ? "1" : "0",
      lev_alt: product.lev_alt,
    };
  },
};
