import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrderModel from "../../models/OrderModel";
import { fetchOrder } from "../../backend";
import AppContext from "../../contexts/AppContext";

export const HwOrderExport = () => {
  const { id } = useParams();

  const appContext = useContext(AppContext);

  const [order, setOrder] = useState<OrderModel>();

  useEffect(() => {
    (async () => {
      setOrder((window as any).order);
    })();
  }, []);

  return order ? (
    <div className="text-black">
      <h1>
        <img src="https://europe-west1-slog-prod.cloudfunctions.net/telenor-lightweight-api/images/telenor_logo.png" />
      </h1>
      <h1>Ordrebekreftelse</h1>
      {JSON.stringify(order)}
      <h2>
        <strong>Oppdragsgiver</strong>
      </h2>
      <p>
        {order.customer.name1}
        <br /> {order.customer.address1}
        <br /> {order.customer.address2}
        <br /> {order.customer.zipcode} {order.customer.city}
      </p>
      <h2>
        <strong>Varemottaker</strong>
      </h2>
      <p>
        {order.delivery.name}
        <br /> {order.delivery.address}
        <br /> {order.delivery.address2}
        <br /> {order.delivery.zipcode} {order.delivery.city}
      </p>
      <h2>
        <strong>Informasjon</strong>
      </h2>
      {/* <p>
        <strong>Dokumentnummer</strong> {{ dokument_nummer }}
        <br />
        <strong>Dokumentdato</strong> {{ dokument_dato }}
        <br />
        <strong>Valuta</strong> {{ order_valuta }}
        <br />
        <strong>Leveringsdato</strong> {{ leverings_dato }}
        <br />
        <strong>Ansvarlig selger</strong> {{ order_seller_name }}
        <br />
        <strong>Deres referanse</strong> {{ your_reference }}
        <br />
        <strong>Filial</strong> {{ order_filial }}
        <br />
        <strong>Bestillingsnummer</strong> {{ order_casenr }} <br />
        <strong>Portal ordre id</strong> {{ oid }} &nbsp;
      </p> */}
      <h2>
        <strong>Merknad</strong>
      </h2>
      {/* <p>
        <strong>Kundereferanse</strong> {{ order_reference }}
        <br />
        <strong>Kunderekvisisjon</strong> {{ order_requisition }}
      </p> */}
      <h2>
        <strong>Artikler&nbsp;</strong>
      </h2>
      {/* {{ articles2 }} */}
    </div>
  ) : (
    <div className="text-black">Loading...</div>
  );
};
