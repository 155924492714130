import { useState } from "react";
import ProductModel from "../../../models/ProductModel";
import { PriceCalculator } from "../../../utils/PriceCalculator";

export const TotalPricePanel = ({ products }: { products: ProductModel[] }) => {
    const [expanded, setExpanded] = useState(true);
    let totalPrice = 0;
    let totalOutPrice = 0;
    products.forEach((item) => {
      const [itp, itop] = PriceCalculator.GetTotalPrices(item.price, item.outPrice, item.quantity);
      totalPrice += itp;
      totalOutPrice += itop;
    });
    return (
      <div className="flex w-full">
        <div className="flex iwgray text-black font-bold rounded-l-md ml-auto mr-0 p-2">
          {expanded && (
            <div className="mr-2 my-auto cursor-pointer" onClick={() => setExpanded(false)}>
              &#8594;
            </div>
          )}
          {!expanded && (
            <div className="mr-2 my-auto cursor-pointer" onClick={() => setExpanded(true)}>
              &#8592;
            </div>
          )}
          {expanded && (
            <div className="w-64">
              <div className="m-auto font-bold border-b-2 border-black text-center w-full">TOTAL PRIS</div>
              <div className="w-full mt-2 text-center">
                {totalPrice.toFixed(2)} ({totalOutPrice > 0 && "+"}
                {totalOutPrice.toFixed(2)})
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };