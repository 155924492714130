import { ExProductDeliveryOption, ExProductModel, ExProductSupplierModel } from "../../models/ExProductModel";
import { PriceCalculator } from "../../utils/PriceCalculator";
import { CheckboxField, EditboxField, NumberField, OutPriceField, ReadonlyField, SelectboxField } from "./product-field";
import { ExOrderProductLineMapper, ExOrderProductLineModel } from "../../models";
import { isProductEditable } from "../../utils/ProductHelper";
import { Datalist } from "../common";
import { ComboboxField } from "./product-field";

export function EditProductLineLayout({ children }: { children: JSX.Element[] }) {
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-6">
        <div className="grid grid-cols-3 col-span-3 gap-2 w-96 items-center">
          <label className="col-span-1">ProduktNr</label>
          <div className="col-span-2">{children[0]}</div>
          <label className="col-span-1">EAN</label>
          <div className="col-span-2">{children[1]}</div>
          <label className="col-span-1">Beskrivelse</label>
          <div className="col-span-2">{children[2]}</div>
          <label className="col-span-1">Lvnavn</label>
          <div className="col-span-2">{children[3]}</div>
          <label className="col-span-1">LevAlt</label>
          <div className="col-span-2">{children[4]}</div>
          <label className="col-span-1">BID</label>
          <div className="col-span-2">{children[5]}</div>
        </div>
        <div className="grid grid-cols-3 col-span-3 gap-2 ml-4 w-96 items-center">
          <label className="col-span-1">Moms</label>
          <div className="flex col-span-2">
            <div className="ml-auto">{children[6]}</div>
          </div>
          <label className="col-span-1">Antall</label>
          <div className="col-span-2">{children[7]}</div>
          <label className="col-span-1">Kalk</label>
          <div className="col-span-2">{children[8]}</div>
          <label className="col-span-1">Kostpris</label>
          <div className="col-span-2">{children[9]}</div>
          <label className="col-span-1">Utpris</label>
          <div className="col-span-2">{children[10]}</div>
          <label className="col-span-1">Total Pris</label>
          <div className="col-span-2">{children[11]}</div>
        </div>
      </div>
      <div className="grid grid-cols-6 items-center">
        <label className="col-span-1">Informasjon</label>
        <div className="col-span-5">{children[12]}</div>
      </div>
    </div>
  );
}

const PRODUCT_NUMBER_DATALIST_NAME = "productNumberList";
const PRODUCT_EAN_DATALIST_NAME = "productEanList";
const PRODUCT_DESCRIPTION_DATALIST_NAME = "productDescriptionList";

function ProductLineViewer({ productLine, Layout }: { productLine: ExOrderProductLineModel; Layout: React.ComponentType<{ children: JSX.Element[] }> }) {
  return (
    <Layout>
      <ReadonlyField value={productLine.number} align="left" />
      <ReadonlyField value={productLine.ean} align="left" />
      <ReadonlyField value={productLine.description} align="left" />
      <ReadonlyField value={productLine.levname} align="left" />
      <ReadonlyField value={productLine.lev_alt} align="left" />
      <ReadonlyField value={productLine.bid} align="left" />
      <div>{productLine.moms ? "True" : "False"}</div>
      <ReadonlyField value={productLine.quantity} />
      <ReadonlyField value={productLine.calculation} />
      <ReadonlyField value={productLine.price} />
      <ReadonlyField value={productLine.outPrice} />
      <ReadonlyField value={productLine ? PriceCalculator.GetTotalPricesFormated(productLine?.price, productLine?.outPrice, productLine?.quantity) : ""} />
      <ReadonlyField value={productLine.information} align="left" />
    </Layout>
  );
}

function productLineEditor(
  Layout: React.ComponentType<{ children: JSX.Element[] }>,
  productLine: ExOrderProductLineModel | undefined,
  searchFields: { number: string; ean: string; description: string },
  filteredProducts: ExProductModel[],
  deliveryOptions: ExProductDeliveryOption[],
  suppliers: ExProductSupplierModel[],
  onSetProductLine: (productLine?: ExOrderProductLineModel) => void,
  setSearchFields: ({ number, ean, description }: { number: string; ean: string; description: string }) => void,
  onFilterProducts: (serachField?: string, searchValue?: any) => void
) {
  const productFields = {
    number: { value: searchFields.number, setValue: (value: string) => filterProducts("number", value) },
    ean: { value: searchFields.ean, setValue: (value: string) => filterProducts("ean", value) },
    description: { value: searchFields.description, setValue: (value: string) => filterProducts("description", value) },
    levnr: { disabled: !productLine, value: productLine?.levnr || "", setValue: (value: string) => setProductSupplier(value, productLine?.custom) },
    lev_alt: { disabled: !productLine, value: productLine?.lev_alt || "", setValue: (value: string) => productLine && onSetProductLine({ ...productLine, lev_alt: value }) },
    bid: { disabled: !productLine, value: productLine?.bid || "", setValue: (value: string) => productLine && onSetProductLine({ ...productLine, bid: value }) },
    moms: { disabled: !productLine, value: productLine?.moms || false, setValue: (value: boolean) => productLine && onSetProductLine({ ...productLine, moms: value }) },
    quantity: { disabled: !productLine, value: productLine?.quantity || "", setValue: (value: number) => productLine && onSetProductLine({ ...productLine, quantity: value }) },
    calculation: { disabled: !productLine || isProductEditable(productLine), value: productLine?.calculation || "", setValue: (value: string) => setProductPrices(productLine?.price || 0, value) },
    price: { disabled: !productLine, value: productLine?.price.toFixed(2) || "", setValue: (value: number) => setProductPrices(value, productLine?.calculation || "0%") },
    information: { disabled: !productLine, value: productLine?.information || "", setValue: (value: string) => productLine && onSetProductLine({ ...productLine, information: value }) },
  };

  async function filterProducts(fieldName: string, searchValue: string) {
    setSearchFields({ ...searchFields, [fieldName]: searchValue });
    if (searchValue.length >= 3) {
      for (const prod of filteredProducts) {
        if ((prod as any)[fieldName] === searchValue) {
          onFilterProducts();
          setSearchFields({ number: prod.number, ean: prod.ean, description: prod.description });
          onSetProductLine(ExOrderProductLineMapper.fromProduct(prod));
          return;
        }
      }

      onFilterProducts(fieldName, searchValue);
      onSetProductLine(undefined);
    } else {
      onFilterProducts();
      setSearchFields({ number: "", ean: "", description: "", [fieldName]: searchValue });
      onSetProductLine(undefined);
    }
  }

  function getProductSuppliers() {
    if (productLine?.custom) {
      return suppliers.map((item) => ({ label: item.levname, value: item.levnr })) || [];
    } else {
      return productLine?.suppliers?.map((item) => ({ label: item.levname, value: item.levnr })) || [];
    }
  }

  const setProductSupplier = (levnr: string, customize?: boolean) => {
    if (productLine) {
      const supplier = customize ? suppliers.find((s) => s.levnr === levnr) : productLine.suppliers.find((s) => s.levnr === levnr);
      if (supplier) {
        onSetProductLine({ ...productLine, levnr: supplier.levnr, levname: supplier.levname });
      }
    }
  };

  const setProductPrices = (price: number, calculation: string) => {
    let marginValue;
    let outPrice;
    if (productLine) {
      if (calculation[calculation.length - 1] === "%") {
        marginValue = parseFloat(calculation.slice(0, calculation.length - 1));
        outPrice = price * (1 + marginValue / 100);
      } else {
        marginValue = parseFloat(calculation);
        outPrice = price + marginValue;
      }

      onSetProductLine({ ...productLine, price, calculation, outPrice });
    }
  };

  return (
    <>
      <Layout>
        <ComboboxField model={productFields.number} list={PRODUCT_NUMBER_DATALIST_NAME} />
        <ComboboxField model={productFields.ean} list={PRODUCT_EAN_DATALIST_NAME} />
        <ComboboxField model={productFields.description} list={PRODUCT_DESCRIPTION_DATALIST_NAME} />
        <SelectboxField model={productFields.levnr} items={getProductSuppliers()} />
        <SelectboxField model={productFields.lev_alt} items={deliveryOptions.map((item) => ({ label: item.description, value: item.lev_alt }))} />
        <EditboxField model={productFields.bid} />
        <CheckboxField model={productFields.moms} />
        <NumberField model={productFields.quantity} />
        <EditboxField model={productFields.calculation} style="text-right" />
        {productLine?.custom || isProductEditable(productLine) ? <NumberField model={productFields.price} /> : <ReadonlyField value={productLine?.price.toFixed(2) || ""} />}
        <OutPriceField product={productLine} setProduct={onSetProductLine} />
        <ReadonlyField value={productLine ? PriceCalculator.GetTotalPricesFormated(productLine?.price, productLine?.outPrice, productLine?.quantity) : ""} />
        <EditboxField model={productFields.information} />
      </Layout>

      <Datalist id={PRODUCT_NUMBER_DATALIST_NAME} items={filteredProducts} getTitle={(prod) => prod.number} getDescription={(prod) => `${prod.description} (levnr: ${prod.levnr}, levartnr: ${prod.levartnr}, gartnr: ${prod.gartnr})`} />
      <Datalist id={PRODUCT_EAN_DATALIST_NAME} items={filteredProducts} getTitle={(prod) => prod.ean} getDescription={(prod) => `${prod.description}`} />
      <Datalist id={PRODUCT_DESCRIPTION_DATALIST_NAME} items={filteredProducts} getTitle={(prod) => prod.description} getDescription={(prod) => `${prod.number}`} />
    </>
  );
}

// export function EditProductLine({ productLine, onSetProductLine, readonly }: { productLine?: ExOrderProductLineModel; onSetProductLine: (productLine?: ExOrderProductLineModel) => void; readonly?: boolean }) {
//   const processor = useContext(BackendProcessorContext);
//   const appConstants = useContext(AppConstantsContext);

//   const [searchFields, setSearchFields] = useState({ number: "", ean: "", description: "" });
//   const [filteredProducts, setFilteredProducts] = useState<ExProductModel[]>([]);
//   const [allSuppliers, setAllSuppliers] = useState<ExProductSupplierModel[]>([]);
//   const [allDeliveryOptions, setAllDeliveryOptions] = useState<ExProductDeliveryOption[]>([]);

//   async function filterProducts(searchField?: string, searchValue?: string) {
//     if (searchField && searchValue) {
//       try {
//         const products = await processor?.runSilently(ExBackend.fetchProducts, searchField === "number" ? "" : searchField, searchValue);
//         setFilteredProducts(products);
//       } catch (err) {
//         setFilteredProducts([]);
//         console.log("ERROR", err);
//       }
//     } else {
//       setFilteredProducts([]);
//     }
//   }

//   const layout = readonly && productLine ? <ProductLineViewer productLine={productLine} Layout={ProductLayout} /> : productLineEditor(ProductLayout, productLine, searchFields, filteredProducts, allDeliveryOptions, allSuppliers, onSetProductLine, setSearchFields, filterProducts);

//   useEffect(() => {
//     (async () => {
//       const suppliers = await processor?.runSilently(ExBackend.fetchSuppliers);
//       setAllSuppliers(suppliers);
//       const deliveryOptions = await processor?.runSilently(ExBackend.fetchDeliveryOptions);
//       setAllDeliveryOptions(deliveryOptions);
//     })();
//   }, []);

//   return <div className="m-8">{layout}</div>;
// }

export function EditProductLine({ productLine, onSetProductLine, readonly }: { productLine?: ExOrderProductLineModel; onSetProductLine: (productLine?: ExOrderProductLineModel) => void; readonly?: boolean }) {}
