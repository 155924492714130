import { useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import backends from "../../../backends";
import AppContext from "../../../contexts/AppContext";
import { ProjectModel } from "../../../models/project/ProjectModel";
import { StepBar } from "../../common/StepBar";
import { DeliveryPanel } from "../../elements/order/DeliveryPanel";
import { ProcessingContext } from "../../../contexts/ProcessingContext";
import { ProjectInfoFieldModel } from "../../../models/project/ProjectInfoFieldModel";
import { EditBox } from "../../elements/common/EditBox";
import { BundlesPanel } from "./orderWizard/BundlesPanel";
import { CustomerPanel } from "../../elements/order/CustomerPanel";
import OrderModel from "../../../models/OrderModel";
import { checkOrder } from "../../../utils/order";
import { SimpleFormDialog } from "../../dialogs/SimpleFormDialog";
import { changeOrderStatus } from "../../../backend";

const newOrderSteps: string[] = ["Varemottaker", "Informasjon", "Produkter", "Opprett"];
const editOrderSteps: string[] = ["Varemottaker", "Informasjon", "Produkter"];

const EditDeliveryInformation = ({ order, setOrder }: { order: OrderModel; setOrder: (order: OrderModel) => void }) => {
  return (
    <div className="flex m-auto gap-8">
      <CustomerPanel customer={order.customer} onSelectCustomer={() => {}} readonly />
      <DeliveryPanel
        delivery={order.delivery}
        setDelivery={(delivery) => {
          setOrder({ ...order, delivery: delivery });
        }}
        onCopyCustomer={() => {
          setOrder({
            ...order,
            delivery: {
              ...order.delivery,
              name: order.customer.name1,
              address: order.customer.address2,
              address2: order.customer.address1,
              country: order.customer.country,
              zipcode: order.customer.zipcode,
              city: order.customer.city,
            },
          });
        }}
      />
    </div>
  );
};

const EditInfoFields = ({ infoFields, infoFieldValues, setInfoFieldValues }: { infoFields: ProjectInfoFieldModel[]; infoFieldValues: { [infoField: string]: string }; setInfoFieldValues: (infoFieldValues: { [infoField: string]: string }) => void }) => {
  return (
    <div className="flex flex-col my-auto w-full gap-2">
      {infoFields.map((item) => (
        <div className="flex w-[512px] mx-auto">
          <EditBox
            key={item.name}
            label={item.name}
            placeholder={item.description}
            value={infoFieldValues[item.name]}
            onTextChanged={(text) => {
              setInfoFieldValues({ ...infoFieldValues, [item.name]: text });
            }}
          />
        </div>
      ))}
    </div>
  );
};

const CheckAndCreateOrder = ({ order, onOrderCreated }: { order: OrderModel; onOrderCreated: () => void }) => {
  const appContext = useContext(AppContext);
  const processing = useContext(ProcessingContext);

  const [resultMessage, setResultMessage] = useState<string>("");

  const processOrder = (setToReady?: boolean) => {
    processing.run(async () => {
      const checkOrderMessage = checkOrder(order, "deliveryInfo", "products", "deliveryEmail");
      if (checkOrderMessage) {
        appContext.setNotification({ type: "error", text: checkOrderMessage });
      } else {
        appContext.setNotification(null);
        const orderId = await backends.orders.create(order, appContext.login?.uid);
        if (orderId) {
          if (setToReady) {
            await changeOrderStatus(orderId, "ready", appContext.login?.uid);
          }
          setResultMessage(`Bestillingen #${orderId} opprettet`);
          onOrderCreated();
        } else {
          setResultMessage("Noe gikk galt.");
        }
      }
    });
  };

  return (
    <div className="flex flex-1 mx-4 mt-8">
      {resultMessage ? (
        <div className="m-auto">{resultMessage}</div>
      ) : (
        <div className="flex flex-col gap-2 m-auto">
          <button className="flex gap-2 p-6 bg-black rounded-lg" onClick={() => processOrder(true)}>
            <img className="h-8 w-8" src="/assets/images/projects/create-order.svg" alt="create" />
            <p className="my-auto">Sjekk og opprett ordren</p>
          </button>
          <button className="flex gap-2 p-6 bg-black rounded-lg" onClick={() => processOrder()}>
            <img className="h-8 w-8" src="/assets/images/projects/save.svg" alt="save" />
            <p className="my-auto">Sjekk og lagre ordre som draft</p>
          </button>
        </div>
      )}
    </div>
  );
};

export const OrderWizard = () => {
  const appContext = useContext(AppContext);
  const processing = useContext(ProcessingContext);
  const [step, setStep] = useState<number>(0);
  const [project, setProject] = useState<ProjectModel>();
  const [order, setOrder] = useState<OrderModel>();
  const [orderCreated, setOrderCreated] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const navigate = useNavigate();
  const match = useMatch("/prosjekter/:projectId/ordrer/:orderId");

  const projectId = match?.params.projectId || "";
  const orderId = match?.params.orderId || "";

  useEffect(() => {
    appContext.setWizardMode(true);
    if (projectId && orderId) {
      const o = new OrderModel();
      processing.run(async () => {
        const fetchedProject = await backends.projects.fetch(projectId, appContext.login?.uid);
        if (fetchedProject) {
          setProject(fetchedProject);
          if (orderId === "ny") {
            setOrder({ ...o, project_id: projectId, customer: fetchedProject.customer });
          } else {
            const fetchedOrder = await backends.orders.fetch(orderId, appContext.login?.uid);
            setOrder(fetchedOrder);
          }
        }
      });
      return () => {
        appContext.setWizardMode(false);
      };
    }
  }, [match?.params.projectId, match?.params.orderId]);

  return order ? (
    <div className="flex w-full h-full">
      {confirmDelete && (
        <SimpleFormDialog
          title="Slette bestillingen"
          buttons={[
            { text: "Ja", action: "delete", color: "tp-button-cancel" },
            { text: "Nei", action: "cancel", color: "tp-button-confirm" },
          ]}
          onButtonClicked={async (action) => {
            setConfirmDelete(false);
            if (action === "delete") {
              await backends.orders.delete(order.id, appContext.login?.uid);
              navigate(`/prosjekter/${projectId}/ordrer`);
            } else if (action === "cancel") {
            }
          }}
          onCloseClicked={() => setConfirmDelete(false)}
        >
          <p>Er du sikker på at du vil slette denne bestillingen?</p>
        </SimpleFormDialog>
      )}
      <div className="flex flex-1 p-2">
        <div className="flex w-12">
          {orderId !== "ny" && (
            <div className="flex flex-col my-auto w-full gap-2">
              <button
                onClick={() => {
                  processing.run(async () => {
                    await backends.orders.update(order, appContext.login?.uid);
                    navigate(`/prosjekter/${projectId}/ordrer`);
                  });
                }}
              >
                <img className="w-full" src="/assets/images/projects/save.svg" alt="save" />
              </button>
              <button
                onClick={() => {
                  processing.run(async () => {
                    setConfirmDelete(true);
                  });
                }}
              >
                <img className="w-full" src="/assets/images/projects/delete-order.svg" alt="delete" />
              </button>
            </div>
          )}
        </div>
        {project && (
          <div className="flex flex-1 flex-col">
            {!orderCreated && (
              <>
                <p className="m-auto text-2xl font-bold ">{orderId === "ny" ? "Opprette en ny ordre" : `Rediger ordren #${orderId}`}</p>
                <div className="flex">
                  <div className="mx-auto my-4">
                    <StepBar
                      steps={orderId === "ny" ? newOrderSteps : editOrderSteps}
                      onStepSelected={(stepIndex) => {
                        setStep(stepIndex);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-1">
              {step === 0 && <EditDeliveryInformation order={order} setOrder={setOrder} />}
              {step === 1 && <EditInfoFields infoFields={project?.information} infoFieldValues={order.information} setInfoFieldValues={(infoFieldValues) => setOrder({ ...order, information: infoFieldValues })} />}
              {step === 2 && (
                <div className="flex flex-1 mx-4 mt-8">
                  <BundlesPanel bundles={project.bundles} order={order} setOrder={setOrder} />
                </div>
              )}
              {step === 3 && <CheckAndCreateOrder order={order} onOrderCreated={() => setOrderCreated(true)} />}
            </div>
          </div>
        )}
        <div className="">
          <button
            onClick={() => {
              navigate(`/prosjekter/${projectId}/ordrer`);
            }}
          >
            <img className="h-10" src="/assets/images/projects/cancel.svg" alt="cancel" />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
