import { ModelMapper } from "../ModelMapper";

export interface OrderStatusModel {
  value: string;
  operations: string[];
}

export const OrderStatusModelMapper: ModelMapper<OrderStatusModel> = {
  createEmptyObject: () => {
    return {
      value: "",
      operations: [],
    };
  },

  fromBackendObject: (backendObject: any) => {
    return { ...backendObject };
  },

  toBackendObject: (model: OrderStatusModel) => {
    return model;
  },
};
