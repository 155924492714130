import { ModelMapper } from "./ModelMapper";

export interface CustomerModel {
  id: string;
  orgnr: string;
  name1: string;
  name2: string;
  address1: string;
  address2: string;
  zipcode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
  pricedeal: string | null;
  pricedealname: string | null;
  sellernr: string | null;
  sellername: string | null;
  debitor: string;
}

export const CustomerModelMapper: ModelMapper<CustomerModel> = {
  createEmptyObject: () => {
    return {
      id: "",
      orgnr: "",
      name1: "",
      name2: "",
      address1: "",
      address2: "",
      zipcode: "",
      city: "",
      country: "",
      phone: "",
      email: "",
      pricedeal: null,
      pricedealname: null,
      sellernr: null,
      sellername: null,
      debitor: "",
    };
  },

  fromBackendObject: (backendObject: any) => {
    return {...backendObject };
  },

  toBackendObject: (model: CustomerModel) => {
    return model;
  },
};

// export default class CustomerModelOld {
//   id: string = "";
//   orgnr: string = "";
//   name1: string = "";
//   name2: string = "";
//   address1: string = "";
//   address2: string = "";
//   zipcode: string = "";
//   city: string = "";
//   email: string = "";
//   phone: string = "";
//   // pricedeal: string | null = null;
//   // pricedealname: string | null = null;
//   // sellernr: string | null = null;
//   // sellername: string | null = null;

//   public static fromBackendObject(backendObject: BackendCustomerModel): CustomerModelOld {
//     const customer = new CustomerModelOld();

//     customer.id = backendObject.id || "";
//     customer.orgnr = backendObject.orgnr || "";
//     customer.name1 = backendObject.name1 || "";
//     customer.name2 = backendObject.name2 || "";
//     customer.zipcode = backendObject.zipcode || "";
//     customer.city = backendObject.city || "";
//     customer.address1 = backendObject.address1 || "";
//     customer.email = backendObject.email || "";
//     customer.phone = backendObject.phone || "";

//     return customer;
//   }

//   public static fromOrderBackendObject(backendObject: BackendOrderCustomerModel): CustomerModelOld {
//     const customer = new CustomerModelOld();

//     customer.id = backendObject.id || "";
//     customer.orgnr = backendObject.orgnr || "";
//     customer.name1 = backendObject.name1 || "";
//     customer.name2 = backendObject.name2 || "";
//     customer.zipcode = backendObject.zipcode || "";
//     customer.city = backendObject.city || "";
//     customer.address1 = backendObject.address1 || "";
//     customer.email = backendObject.email || "";
//     customer.phone = backendObject.phone || "";

//     return customer;
//   }

//   public static toOrderBackendObject(customer: CustomerModelOld): object {
//     return {
//       id: customer.id,
//       orgnr: customer.orgnr,
//       name1: customer.name1,
//       name2: customer.name2,
//       address1: customer.address1,
//       address2: customer.address2,
//       zipcode: customer.zipcode,
//       city: customer.city,
//       phone: customer.phone,
//       email: customer.email,
//       pricedeal: null,
//       pricedealname: null,
//       sellernr: null,
//       sellername: null,
//     };
//   }
// }
