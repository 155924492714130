import { UIField } from "../../../models/UIField";

export const TextboxField = ({ model, disabled }: { model: UIField; disabled?: boolean }) => {
  return (
    <div className="flex flex-col">
      <label>{model.label}</label>
      <textarea
        className={`rounded h-10 w-full px-2 border-2 ${!disabled ? "text-black" : "bg-transparent text-white"}`}
        value={model.value}
        onChange={(e) => {
          model.setValue(e.target.value);
        }}
      />
    </div>
  );
};
