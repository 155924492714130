import { ModelMapper } from "./ModelMapper";

export interface Step1Model {
  customer: {
    name: string;
    number: string;
    numberOfUsers: string;
    hasDealer: boolean;
  };
  tenant: {
    domain: string;
    id: string;
    alreadyLinked: boolean;
  };
  numbers: {
    existingOrNew: "existing" | "new";
    newNumber: string;
    existingNumber: string;
    useTestNumbers: boolean;
    useForeignNumbers: boolean;
    foreignCountriesNumbers: string;
  };
  mss: boolean;

  dealer: {
    name: string;
    contactName: string;
    contactEmail: string;
  };
  telenorSeller: {
    enabled: boolean;
    name: string;
    contactName: string;
    contactEmail: string;
  };
  price: {
    oneTimeFee: string;
    monthlyCustomerFee: string;
    monthlyUserFee: string;
    optionalFee: string;
    optionalFeeDescription: string;
    other: string;
  };
  contact: {
    name: string;
    email: string;
    mobile: string;
    consentPerson: boolean;
    consentName: string;
    consentEmail: string;
  };
  startDate: string;
  additionalInfo: string;
}

const newStep1Model: () => Step1Model = () => ({
  customer: {
    name: "",
    number: "",
    numberOfUsers: "",
    hasDealer: false,
  },
  tenant: {
    domain: "",
    id: "",
    alreadyLinked: false,
  },
  numbers: {
    existingOrNew: "new",
    existingNumber: "",
    newNumber: "",
    useTestNumbers: false,
    useForeignNumbers: false,
    foreignCountriesNumbers: "",
  },
  mss: false,
  dealer: {
    name: "",
    contactName: "",
    contactEmail: "",
  },
  telenorSeller: {
    enabled: false,
    name: "",
    contactName: "",
    contactEmail: "",
  },
  price: {
    oneTimeFee: "",
    monthlyCustomerFee: "",
    monthlyUserFee: "",
    optionalFee: "",
    optionalFeeDescription: "",
    other: "",
  },
  contact: {
    name: "",
    email: "",
    mobile: "",
    consentPerson: false,
    consentName: "",
    consentEmail: "",
  },
  startDate: "", //`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
  additionalInfo: "",
});

export const Step1ModelMapper: ModelMapper<Step1Model> = {
  newModel() {
    return newStep1Model();
  },
  mapToModel(backendObject) {
    return { ...newStep1Model(), ...backendObject };
  },
};
