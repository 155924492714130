export const SelectboxField = ({
  disabled,
  value,
  items,
  onValueChanged,
}: {
  disabled?: boolean;
  value?: string;
  items: { label: string; value: string }[];
  onValueChanged: (value: string) => void;
}) => {
  const s = "addProductField";

  return (
    <select
      disabled={disabled}
      className={s}
      value={value}
      onChange={(e) => {
        onValueChanged(e.target.value);
      }}
    >
      {items.map((item) => {
        return <option key={item.label} value={item.value}>{item.label}</option>;
      })}
    </select>
  );
};
