import { useParams } from "react-router-dom";
import { Text2Speech } from "./tools";

export function ToolPage() {
  const params = useParams();
  const tool = params.id;

  if (tool === "text2speech") return <Text2Speech />;
  else return null;
}
