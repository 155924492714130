import { Menu, MenuItem, TextField } from "@mui/material";

type OrderTagModel = {
  color: string;
  text: string;
  comment: string;
};

export function OrderTagDialog({ position, tags, onSetTags, onSave, onClose }: { position: { x: number; y: number } | null; tags: OrderTagModel; onSetTags: (tags: OrderTagModel) => void; onSave: () => void; onClose: () => void }) {
  return (
    <Menu
      open={position != null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={position ? { left: position.x, top: position.y } : undefined}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            onClose();
          },
        },
      }}
    >
      <div className="flex flex-col gap-4 m-2">
        <div className="flex gap-2 mr-auto h-10 items-center">
          {["yellow", "orange", "red", "violet", "blue", "green", ""].map((item) => {
            return item && tags?.color === item ? (
              <button className={`flex w-9 h-9 mx-auto rounded-full bg-${item}-400 border-4 border-black`} onClick={() => onSetTags({ ...tags, color: item })}></button>
            ) : (
              <button className={`flex w-8 h-8 mx-auto rounded-full bg-${item}-400`} onClick={() => onSetTags({ ...tags, color: item })}>
                {!item ? <p className="m-auto text-2xl">&#9747;</p> : null}
              </button>
            );
          })}
        </div>
        <div className="flex">
          <TextField className="flex-1 bg-white w-96" label="Beskrivelse" size="small" value={tags?.text} onChange={(e) => onSetTags({ ...tags, text: e.target.value })} />
        </div>
        <div className="flex">
          <TextField className="flex-1 bg-white w-96" label="Kommentar" size="small" value={tags?.comment} onChange={(e) => onSetTags({ ...tags, comment: e.target.value })} />
        </div>
      </div>
      <MenuItem
        onClick={() => {
          onClose();
          onSave();
        }}
      >
        <p className="mx-auto">Lagre</p>
      </MenuItem>
    </Menu>
  );
}
