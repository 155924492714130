import { useState } from "react";
import ModalDialog from "../common/ModalDialog";

type Action = "editOn" | "editOff" | "create" | "update" | "delete" | "close";
type DialogState = "view" | "createConfirm" | "updateConfirm" | "deleteConfirm";

interface ButtonModel {
  text: string;
  actions?: Action[];
  nextState?: DialogState;
  color?: "red" | "green";
  default?: boolean;
}

const viewModeButtons: ButtonModel[] = [
  { text: "Endre", actions: ["editOn"], nextState: "updateConfirm", color: "green", default: true },
  { text: "Fjern", nextState: "deleteConfirm", color: "red" },
];

const createConfirmButtons: ButtonModel[] = [
  { text: "Legg til", actions: ["editOff", "create", "close"], color: "green", default: true },
  { text: "Avbryt", actions: ["close"], color: "red", default: true },
];

const updateConfirmButtons: ButtonModel[] = [
  { text: "Ja", actions: ["editOff", "update", "close"], color: "green", default: true },
  { text: "Nei", actions: ["editOff", "close"], nextState: "view", color: "red" },
];

const deleteConfirmButtons: ButtonModel[] = [
  { text: "Ja", actions: ["editOff", "delete", "close"], color: "green", default: true },
  { text: "Nei", actions: ["editOff", "close"], nextState: "view", color: "red" },
];

export const CrudDialog = ({
  mode,
  title,
  children,
  onAction,
}: {
  mode: "view" | "edit";
  title?: string;
  children?: JSX.Element;
  onAction: (action: Action) => void;  
}) => {
  const [state, setState] = useState<DialogState>(mode === "edit" ? "createConfirm" : "view");

  const mapButtons: (buttons: ButtonModel[]) => JSX.Element[] = (buttons: ButtonModel[]) => {
    return buttons.map((button) => (
      <button
        key={button.text}
        className={button.color === "red" ? "tp-button-cancel" : "tp-button-confirm"}
        onClick={() => {
          if (button.actions) {
            button.actions.forEach(action => onAction(action));
          }
          if (button.nextState) {
            setState(button.nextState);
          }
        }}
      >
        {button.text}
      </button>
    ));
  };

  return (
    <ModalDialog flex visible title={title} onClose={() => onAction("close")}>
      <div
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            // onAction(buttons.find((button) => button?.default || false)?.action || "");
          }
        }}
      >
        <div>{children}</div>
        <div className="flex mt-4">
          {state === "updateConfirm" && <p className="w-full ml-auto mr-4 my-auto text-white text-right">Lagre endringer?</p>}
          {state === "deleteConfirm" && <p className="w-full ml-auto mr-4 my-auto text-white text-right">Er du sikker?</p>}
          <div className={`flex justify-between ${state === "updateConfirm" || state === "deleteConfirm" ? "gap-2" : "w-full"}`}>
            {state === "view" && mapButtons(viewModeButtons)}
            {state === "createConfirm" && mapButtons(createConfirmButtons)}
            {state === "updateConfirm" && mapButtons(updateConfirmButtons)}
            {state === "deleteConfirm" && mapButtons(deleteConfirmButtons)}
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};
