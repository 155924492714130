import { FieldModel } from "../../../models/ui/FieldModel";

export const EditboxField = ({
  field,
  labelWidth,
  maxChars,
  value,
  onTextChanged,
  onEditingCompleted,
  editable,
}: {
  field: FieldModel;
  labelWidth?: string;
  maxChars?: number;
  value: string;
  onTextChanged: (text: string) => void;
  onEditingCompleted?: () => void;
  editable?: boolean;
}) => {
  const lw = labelWidth ? "w-" + labelWidth : "w-1/3";

  return (
    <div className="flex m-1 h-7">
      <label title={field.description} className={`my-auto ${lw}`}>
        {field.text}
      </label>
      <input
        type="text"
        className={`w-full px-2 rounded-md border-2 ${editable ? "text-black" : "bg-transparent text-white"}`}
        readOnly={!editable}
        value={value}
        onInput={(e) => {
          const text = e.currentTarget.value;
          onTextChanged(maxChars ? text.substring(0, maxChars) : text);
        }}
        onBlur={(e) => {
          if (onEditingCompleted) {
            onEditingCompleted();
          }
        }}
      />
    </div>
  );
};
