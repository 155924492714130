import { ProductSupplierModel, ProductSupplierModelMapper } from "./product/ProductSupplierModel";
import { PriceCalculator } from "../utils/PriceCalculator";

const LEVALT_DEFAULT = "2";

export default class ProductModel {
  id: string = "";
  line_id: string | null = null;
  bundle_id?: string;
  number: string = "";
  ean: string = "";
  description: string = "";
  levnr: string = "";
  levname: string = "";
  levalt: string = LEVALT_DEFAULT;
  levartnr: string = "";
  margin: string = "10%";
  price: number = 0;
  outPrice: number = 0;
  quantity: number = 1;
  suppliers: ProductSupplierModel[] = [];
  bid: string = "";
  custom: boolean = false;
  vat: boolean = true;
  article_type: string = "";
  gartnr: string = "";
  information: string = "";

  public static fromBackendObject(backendObject: any): ProductModel {
    const product = new ProductModel();

    product.id = backendObject.id || "";
    product.number = backendObject.number || "";
    product.ean = backendObject.ean || "";
    product.description = backendObject.description || "";
    product.levnr = backendObject.levnr || "";
    product.levname = backendObject.levname || "";
    product.levalt = backendObject.lev_alt || LEVALT_DEFAULT;
    product.levartnr = backendObject.levartnr || "";
    product.price = backendObject.price ? parseFloat(backendObject.price) : 0;
    product.outPrice = PriceCalculator.GetOutPrice(product.price, product.margin);
    product.suppliers = [{ levnr: product.levnr, levname: product.levname }, ...(backendObject.suppliers?.map((item: any) => ProductSupplierModelMapper.fromBackendObject(item)) || [])];
    product.article_type = backendObject.article_type || "";
    product.gartnr = backendObject.gartnr || "";

    return product;
  }

  public static fromOrderBackendObject(backendObject: any): ProductModel {
    const product = new ProductModel();

    product.id = backendObject.article_id || "";
    product.line_id = backendObject.id;
    product.bundle_id = backendObject.bundle_id;
    product.number = backendObject.number || "";
    product.ean = backendObject.ean || "";
    product.description = backendObject.description || "";
    product.quantity = parseFloat(backendObject.quantity);
    product.price = parseFloat(backendObject.price);
    product.margin = backendObject.calculation;
    product.outPrice = parseFloat(backendObject.outprice);
    product.levnr = backendObject.levnr || "";
    product.levname = backendObject.levname || "";
    product.levalt = backendObject.lev_alt || LEVALT_DEFAULT;
    product.levartnr = backendObject.levartnr || "";
    product.bid = backendObject.bid || "";
    product.vat = backendObject.moms === "1";
    product.article_type = backendObject.article_type || "";
    product.gartnr = backendObject.gartnr || "";
    product.information = backendObject.information || "";

    return product;
  }

  public static toOrderBackendObject(product: ProductModel): object {
    return {
      article_id: product.id,
      id: product.line_id,
      description: ProductModel.isEditable(product) ? product.description : undefined,
      bundle_id: product.bundle_id,
      calculation: product.margin,
      price: ProductModel.isEditable(product) ? product.price : undefined,
      outprice: product.outPrice,
      quantity: product.quantity,
      levnr: product.levnr,
      bid: product.bid,
      moms: product.vat ? "1" : "0",
      lev_alt: product.levalt.toString(),
      article_type: product.article_type,
      information: product.information
    };
  }

  public static isEditable(product: ProductModel | null) {
    return product?.article_type === "T";
  }
}
