import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";

export type FlowStepSettingModel = {
  id: string;
  name?: string;
  type?: string;
  description?: string;
  values?: { id: string; name: string }[];
  value: string;
};

export function FlowStepSettings({ settings, onSetSettings }: { settings?: FlowStepSettingModel[]; onSetSettings: (settings: FlowStepSettingModel[]) => void }) {
  return (
    <div className="flex flex-col w-2/3 mx-auto gap-2">
      {settings?.map((setting) =>
        setting.type === "select" ? (
          <Tooltip title={setting.description}>
            <FormControl>
              <InputLabel>{setting.name || setting.id}</InputLabel>
              <Select label={setting.name || setting.id} size="small" value={setting.value} onChange={(e) => onSetSettings(settings.map((i) => (i === setting ? { ...i, value: e.target.value } : i)))}>
                {setting.values?.map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        ) : (
          <Tooltip title={setting.description}>
            <TextField label={setting.name || setting.id} size="small" value={setting.value} onChange={(e) => onSetSettings(settings.map((i) => (i === setting ? { ...i, value: e.target.value } : i)))} />
          </Tooltip>
        )
      )}
    </div>
  );
}
