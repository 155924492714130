import { useContext, useEffect, useState } from "react";
import { ExProductDeliveryOption, ExProductModel, ExProductSupplierModel } from "../../models/ExProductModel";
import { ProductLayout } from ".";
import { PriceCalculator } from "../../utils/PriceCalculator";
import { CheckboxField, EditboxField, NumberField, OutPriceField, ReadonlyField, SelectboxField } from "./product-field";
import { ExOrderProductLineMapper, ExOrderProductLineModel } from "../../models";
import { isProductEditable } from "../../utils/ProductHelper";
import { ModuleContext } from "../../../common/components";
import { ExBackend } from "../../backends";
import { Datalist } from "../common";
import { ComboboxField } from "./product-field";
import { ToolButton } from "../common/ToolButton";

const PRODUCT_NUMBER_DATALIST_NAME = "productNumberList";
const PRODUCT_EAN_DATALIST_NAME = "productEanList";
const PRODUCT_DESCRIPTION_DATALIST_NAME = "productDescriptionList";

export function AddProductLineLayout({
  products,
  deliveryOptions,
  suppliers,
  onFilterProducts,
  onAddClick,
}: {
  products: ExProductModel[];
  deliveryOptions: ExProductDeliveryOption[];
  suppliers: ExProductSupplierModel[];
  onFilterProducts: (serachField?: string, searchValue?: any) => void;
  onAddClick: (productLine: ExOrderProductLineModel) => void;
}) {
  const [searchFields, setSearchFields] = useState({ number: "", ean: "", description: "" });
  const [selectedProduct, setSelectedProduct] = useState<ExOrderProductLineModel>();

  const productFields = {
    number: { value: searchFields.number, setValue: (value: string) => filterProducts("number", value) },
    ean: { value: searchFields.ean, setValue: (value: string) => filterProducts("ean", value) },
    description: { value: searchFields.description, setValue: (value: string) => filterProducts("description", value) },
    levnr: { disabled: !selectedProduct, value: selectedProduct?.levnr || "", setValue: (value: string) => setProductSupplier(value, selectedProduct?.custom) },
    lev_alt: { disabled: !selectedProduct, value: selectedProduct?.lev_alt || "", setValue: (value: string) => selectedProduct && setSelectedProduct({ ...selectedProduct, lev_alt: value }) },
    bid: { disabled: !selectedProduct, value: selectedProduct?.bid || "", setValue: (value: string) => selectedProduct && setSelectedProduct({ ...selectedProduct, bid: value }) },
    moms: { disabled: !selectedProduct, value: selectedProduct?.moms || false, setValue: (value: boolean) => selectedProduct && setSelectedProduct({ ...selectedProduct, moms: value }) },
    quantity: { disabled: !selectedProduct, value: selectedProduct?.quantity || "", setValue: (value: number) => selectedProduct && setSelectedProduct({ ...selectedProduct, quantity: value }) },
    calculation: { disabled: !selectedProduct || isProductEditable(selectedProduct), value: selectedProduct?.calculation || "", setValue: (value: string) => setProductPrices(selectedProduct?.price || 0, value) },
    price: { disabled: !selectedProduct, value: selectedProduct?.price.toFixed(2) || "", setValue: (value: number) => setProductPrices(value, selectedProduct?.calculation || "0%") },
  };

  async function filterProducts(fieldName: string, searchValue: string) {
    setSearchFields({ ...searchFields, [fieldName]: searchValue });
    if (searchValue.length >= 3) {
      for (const prod of products) {
        if ((prod as any)[fieldName] === searchValue) {
          onFilterProducts();
          setSearchFields({ number: prod.number, ean: prod.ean, description: prod.description });
          setSelectedProduct(ExOrderProductLineMapper.fromProduct(prod));
          return;
        }
      }

      onFilterProducts(fieldName, searchValue);
      setSelectedProduct(undefined);
    } else {
      onFilterProducts();
      setSearchFields({ number: "", ean: "", description: "", [fieldName]: searchValue });
      setSelectedProduct(undefined);
    }
  }

  function getProductSuppliers() {
    if (selectedProduct?.custom) {
      return suppliers.map((item) => ({ label: item.levname, value: item.levnr })) || [];
    } else {
      return selectedProduct?.suppliers?.map((item) => ({ label: item.levname, value: item.levnr })) || [];
    }
  }

  const setProductSupplier = (levnr: string, customize?: boolean) => {
    if (selectedProduct) {
      const supplier = customize ? suppliers.find((s) => s.levnr === levnr) : selectedProduct.suppliers.find((s) => s.levnr === levnr);
      if (supplier) {
        setSelectedProduct({ ...selectedProduct, levnr: supplier.levnr, levname: supplier.levname });
      }
    }
  };

  const setProductPrices = (price: number, calculation: string) => {
    let marginValue;
    let outPrice;
    if (selectedProduct) {
      if (calculation[calculation.length - 1] === "%") {
        marginValue = parseFloat(calculation.slice(0, calculation.length - 1));
        outPrice = price * (1 + marginValue / 100);
      } else {
        marginValue = parseFloat(calculation);
        outPrice = price + marginValue;
      }

      setSelectedProduct({ ...selectedProduct, price, calculation, outPrice });
    }
  };

  function addProductHandler() {
    if (selectedProduct) {
      onAddClick({ ...selectedProduct });
      setSearchFields({ number: "", ean: "", description: "" });
      setSelectedProduct(undefined);
      onFilterProducts();
    }
  }

  return (
    <div className="flex select-none">
      <ToolButton image={selectedProduct?.custom ? "unlock" : "lock"} onClick={() => (selectedProduct?.custom ? setSelectedProduct(undefined) : setSelectedProduct({ ...ExOrderProductLineMapper.empty(), custom: true }))} />
      <ProductLayout>
        <ComboboxField model={productFields.number} list={PRODUCT_NUMBER_DATALIST_NAME} />
        <ComboboxField model={productFields.ean} list={PRODUCT_EAN_DATALIST_NAME} />
        <ComboboxField model={productFields.description} list={PRODUCT_DESCRIPTION_DATALIST_NAME} />
        <SelectboxField model={productFields.levnr} items={getProductSuppliers()} />
        <SelectboxField model={productFields.lev_alt} items={deliveryOptions.map((item) => ({ label: item.description, value: item.lev_alt }))} />
        <EditboxField model={productFields.bid} />
        <CheckboxField model={productFields.moms} />
        <NumberField model={productFields.quantity} />
        <EditboxField model={productFields.calculation} style="text-right" />
        {selectedProduct?.custom || isProductEditable(selectedProduct) ? <NumberField model={productFields.price} /> : <ReadonlyField value={selectedProduct?.price.toFixed(2) || ""} />}
        <OutPriceField product={selectedProduct} setProduct={setSelectedProduct} />
        <ReadonlyField value={selectedProduct ? PriceCalculator.GetTotalPricesFormated(selectedProduct?.price, selectedProduct?.outPrice, selectedProduct?.quantity) : ""} />
      </ProductLayout>
      <ToolButton image="add" disabled={!selectedProduct} onClick={addProductHandler} />

      <Datalist id={PRODUCT_NUMBER_DATALIST_NAME} items={products} getTitle={(prod) => prod.number} getDescription={(prod) => `${prod.description} (levnr: ${prod.levnr}, levartnr: ${prod.levartnr}, gartnr: ${prod.gartnr})`} />
      <Datalist id={PRODUCT_EAN_DATALIST_NAME} items={products} getTitle={(prod) => prod.ean} getDescription={(prod) => `${prod.description}`} />
      <Datalist id={PRODUCT_DESCRIPTION_DATALIST_NAME} items={products} getTitle={(prod) => prod.description} getDescription={(prod) => `${prod.number}`} />
    </div>
  );
}

export function AddProductLine({ onAdd }: { onAdd: (productLine: ExOrderProductLineModel) => void }) {
  const processor = useContext(ModuleContext);

  const [filteredProducts, setFilteredProducts] = useState<ExProductModel[]>([]);
  const [allSuppliers, setAllSuppliers] = useState<ExProductSupplierModel[]>([]);
  const [allDeliveryOptions, setAllDeliveryOptions] = useState<ExProductDeliveryOption[]>([]);

  async function filterProducts(searchField?: string, searchValue?: string) {
    if (searchField && searchValue) {
      try {
        const products = await processor?.runSilently(ExBackend.fetchProducts, searchField === "number" ? "" : searchField, searchValue);
        setFilteredProducts(products);
      } catch (err) {
        setFilteredProducts([]);
        console.log("ERROR", err);
      }
    } else {
      setFilteredProducts([]);
    }
  }

  useEffect(() => {
    (async () => {
      const suppliers = await processor?.runSilently(ExBackend.fetchSuppliers);
      setAllSuppliers(suppliers);
      const deliveryOptions = await processor?.runSilently(ExBackend.fetchDeliveryOptions);
      setAllDeliveryOptions(deliveryOptions);
    })();
  }, []);

  return <AddProductLineLayout products={filteredProducts} deliveryOptions={allDeliveryOptions} suppliers={allSuppliers} onFilterProducts={filterProducts} onAddClick={onAdd} />;
}
