import { useContext, useEffect, useState } from "react";
import { deleteOrder, fetchOrder, updateOrder } from "../../backend";
import AppContext from "../../contexts/AppContext";
import OrderModel from "../../models/OrderModel";
import ProductModel from "../../models/ProductModel";
import Card from "../common/Card";
import ModalDialog from "../common/ModalDialog";
import { ProductsPanel } from "../panels/order/ProductsPanel";
import LoadingDialog from "./LoadingDialog";
import { useAuth0 } from "@auth0/auth0-react";
import { CustomerPanel } from "../panels/order/CustomerPanel";
import { OrderPanel } from "../panels/order/OrderPanel";
import { OrderFormFields } from "../../ui/OrderFields";
import { TotalPrice } from "../../modules/common/components/panels/TotalPrice";
import { getTotalPriceAndProfit } from "../../utils/OrderHelper";

let origOrder: OrderModel | null = null;

const EditOrderDialog = ({ order, closeHandler }: { order: OrderModel; closeHandler: () => void }) => {
  const { user } = useAuth0();

  const [processing, setProcessing] = useState(false);
  const [editOrder, setEditOrder] = useState<OrderModel | null>(null);
  const [mode, setMode] = useState<"view" | "edit" | "delete">("view");

  const showEdit = true;
  const showDelete = order?.deletable;

  const appContext = useContext(AppContext);

  const updateHandler = async (order: OrderModel) => {
    setProcessing(true);
    if (await updateOrder(order, user?.sub)) {
      await appContext.refreshOrders();
    } else {
      alert("Error!");
    }
    closeHandler();
    setProcessing(false);
  };

  const deleteHandler = async () => {
    setProcessing(true);
    if (await deleteOrder(order.id, user?.sub)) {
      await appContext.refreshOrders();
    } else {
      alert("Error!");
    }
    closeHandler();
    setProcessing(false);
  };

  const setOrderInfoField = (fieldValue: any, fieldName: string, subfieldName?: string) => {
    setEditOrder((prev: any) => {
      const next = { ...prev };
      if (subfieldName) {
        next[fieldName] = { ...next[fieldName], [subfieldName]: fieldValue };
      } else {
        next[fieldName] = fieldValue;
      }
      return next;
    });
  };

  const copyCustomerInfo = () => {
    setEditOrder((prev: OrderModel | null) => {
      const next = prev
        ? {
            ...prev,
            delivery: {
              name: prev.customer.name1,
              name2: prev.customer.name2,
              address: prev.customer.address1,
              address2: "",
              zipcode: prev.customer.zipcode,
              city: prev.customer.city,
              country: "NO",
              contact: "",
              email: prev.customer.email,
              mobile: prev.customer.phone,
            },
          }
        : null;
      return next;
    });
  };

  const addProduct = (product: ProductModel) => {
    setEditOrder((prev: any) => ({
      ...prev,
      products: [...prev.products, product],
    }));
  };

  const updateProduct = (original: ProductModel, updated: ProductModel) => {
    setEditOrder((prev: any) => ({
      ...prev,
      products: prev.products.map((item: ProductModel) => (item === original ? updated : item)),
    }));
  };

  const removeProduct = (product: ProductModel) => {
    setEditOrder((prev: any) => ({
      ...prev,
      products: prev.products.filter((item: ProductModel) => item !== product),
    }));
  };

  useEffect(() => {
    setProcessing(true);
    fetchOrder(order.id, user?.sub).then((order) => {
      origOrder = order;
      setEditOrder(order);
      setProcessing(false);
    });
  }, []);

  const { totalPrice, totalProfit } = getTotalPriceAndProfit(editOrder?.products);

  return editOrder && !processing ? (
    <ModalDialog
      title={`Order #${editOrder.id}`}
      onClose={() => {
        closeHandler();
      }}
      visible={true}
    >
      <div className="flex flex-col">
        <div className="flex flex-col w-full">
          <Card title="Kunde">
            <CustomerPanel orderInfo={editOrder} changeFieldValueHandler={setOrderInfoField} onCopyCustomerInfo={copyCustomerInfo} edit={mode === "edit"} />
          </Card>
          <Card title={OrderFormFields.order.header.text}>
            <OrderPanel order={editOrder} changeFieldValueHandler={setOrderInfoField} editable={mode === "edit"} />
          </Card>
          <Card title={OrderFormFields.products.header.text}>
            <div className="flex flex-col flex-1">
              <ProductsPanel products={editOrder?.products} addProduct={addProduct} updateProduct={updateProduct} removeProduct={removeProduct} edit={mode === "edit"} />
              <div className="flex mt-2 -mr-2">
                <TotalPrice price={totalPrice} profit={totalProfit} />
              </div>
            </div>
          </Card>
        </div>
        {/* <EditOrderComponent order={editOrder} edit={mode === "edit"}/> */}
        <div className="flex m-4">
          {mode !== "view" && <p className="ml-auto mr-4 my-auto text-white">{mode === "edit" ? "Lagre endringer?" : mode === "delete" ? "Er du sikker?" : ""}</p>}
          {mode === "view" ? (
            showEdit ? (
              <button className="ml-0 tp-button-confirm" onClick={() => setMode("edit")}>
                Endre
              </button>
            ) : null
          ) : (
            <button
              className="ml-4 tp-button-confirm"
              onClick={() => {
                if (mode === "edit") {
                  if (editOrder) {
                    updateHandler(editOrder);
                  }
                } else {
                  deleteHandler();
                }
              }}
            >
              Ja
            </button>
          )}
          {mode === "view" ? (
            showDelete ? (
              <button
                className="ml-auto tp-button-cancel"
                onClick={() => {
                  setMode("delete");
                }}
              >
                Slett
              </button>
            ) : null
          ) : (
            <button
              className="ml-4 tp-button-cancel"
              onClick={() => {
                if (mode === "edit") {
                  if (origOrder) {
                    setEditOrder({
                      ...origOrder,
                      customer: { ...origOrder.customer },
                      delivery: { ...origOrder.delivery },
                      products: [...origOrder.products],
                    });
                  }
                  setMode("view");
                } else {
                  setMode("view");
                }
              }}
            >
              Nei
            </button>
          )}
        </div>
      </div>
    </ModalDialog>
  ) : (
    <LoadingDialog visible={true} />
  );
};

export default EditOrderDialog;
