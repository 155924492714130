import { Dialog } from "@mui/material";
import { ReactNode } from "react";

export interface Action {}

export function ActionDialog({
  title,
  children,
  actions,
  onAction,
}: {
  title?: string;
  children?: ReactNode;
  actions: string[];
  onAction: (action: string) => void;
}) {
  return (
    <Dialog maxWidth="lg" open={true} onClose={() => {}}>
      <div className="flex flex-col igray text-white p-2">
        <div className="flex border-b-2 pb-1">
          {title && <h2 className="text-lg font-bold my-auto">{title}</h2>}
          <button
            className="ml-auto"
            onClick={() => {
              onAction("close");
            }}
          >
            <img className="h-8 w-8" src="/assets/images/close.svg" alt="close" />
          </button>
        </div>
        <div className="flex flex-col m-4">
          {children}
          <div className="flex gap-2 mt-4 ml-auto">
            {actions.map((action) => (
              <button key={action} className="w-24 py-1 border-2 rounded-md" onClick={() => onAction(action)}>
                {action}
              </button>
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
