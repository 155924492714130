import { FlowModel, FlowStateModel, FlowStatusModel } from "../models";
import { useNavigate } from "react-router-dom";

const STATUS_ALL = "alle";

function FlowStepButton({ title, count, selected, disabled, size, onClick }: { title: FlowStatusModel; count?: number; selected: boolean; disabled?: boolean; size?: "large"; onClick: () => void }) {
  return (
    <button className={`flex w-full justify-between px-6 py-3 rounded-full border-2 ${disabled ? "text-black border-black" : ""} ${size === "large" ? "text-md" : "text-xs"} ${selected ? "bg-gray-400" : ""}`} disabled={disabled} onClick={onClick}>
      <span>{title.toUpperCase()}</span> <span>{count}</span>
    </button>
  );
}

export function FlowStatusBar({ flow, states, selectedState, selectedStep }: { flow: FlowModel; states: FlowStateModel[]; selectedState?: string; selectedStep?: string }) {
  const navigate = useNavigate();

  const orders = selectedState ? flow.orders.filter((item) => item.meta?.state?.value === selectedState) : flow.orders;

  return (
    <div className="flex gap-4">
      <div className="flex flex-1 flex-col gap-4">
        <FlowStepButton key={STATUS_ALL} title={STATUS_ALL} count={flow.orders.length} selected={!selectedState} size={"large"} onClick={() => navigate(`/flows/${flow.group_id}`)} />
        {states.map((state) => {
          const count = flow.orders.filter((order) => order.meta?.state?.value === state.id).length;
          return <FlowStepButton key={state.id} title={state.name} count={count} selected={selectedState === state.id} disabled={count === 0} size={"large"} onClick={() => navigate(`/flows/${flow.group_id}?state=${state.id}`)} />;
        })}
      </div>
      <div className="flex flex-1 flex-col gap-4">
        {flow.steps.map((step) => {
          const count = orders.filter((order) => order.meta?.status?.value === step.status).length;
          return (
            <FlowStepButton
              key={step.id}
              title={step.name}
              count={count}
              selected={selectedStep === step.status}
              disabled={count === 0}
              onClick={() => {
                if (!selectedState) {
                  navigate(`/flows/${flow.group_id}?step=${step.status}`);
                } else {
                  navigate(`/flows/${flow.group_id}?state=${selectedState}&step=${step.status}`);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
