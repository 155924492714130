import { FlowOrderInfoModel, FlowStepCreator, FlowStepModel } from ".";

export type FlowStatusModel = string;

export interface FlowGroupModel {
  group_id: string;
  flow_id: string;
  name: string;
  statuses: FlowStatusModel[];
}

export interface FlowModel extends FlowGroupModel {
  loaded: boolean;
  steps: FlowStepModel[];
  orders: FlowOrderInfoModel[];
  states: { [key: string]: { quantity: number, flag: string} };
}

export function FlowCreator(): { fromBackendObject: (bo: any) => FlowModel } {
  function fromBackendObject(bo: any) {
    return { ...bo, loaded: false, steps: bo.steps.map((item: any) => FlowStepCreator().fromBackendObject(item)), orders: [] };
  }
  return { fromBackendObject };
}
