import { UIField } from "../../../models/UIField";

export const SelectboxField = ({ model, items }: { model: UIField; items: { label: string; value: string }[] }) => {
  const s = "addProductField";

  return (
    <select
      disabled={model.disabled}
      className={s}
      value={model.value}
      onChange={(e) => {
        model.setValue(e.target.value);
      }}
    >
      {items.map((item) => {
        return (
          <option key={item.label} value={item.value}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
};
