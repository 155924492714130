import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";
import { ModuleContext } from "../components";

export function usePermissionChecker() {
  const appContext = useContext(AppContext);
  const moduleContext = useContext(ModuleContext);

  function isAccessAllowed(requiredLevel: number, module?: string) {
    const user = appContext.login;
    const moduleName = module || moduleContext?.name;
    let accessAllowed = true;
    if (user && user.access.role !== "admin" && moduleName) {
      const userPermission = user.access.modules[moduleName];
      if (userPermission) {
        accessAllowed = userPermission.value >= requiredLevel;
      }
    }
    return accessAllowed;
  }

  return {
    isAccessAllowed,
  };
}
