import { createContext, ReactNode, useState } from "react";
import { Loading } from "../components/common/Loading";

export const ProcessingContext = createContext<{
  run: (process: () => Promise<void>, error?: string) => void;
  // setState: (state: { status: "processing" | "error" | "ready"; message?: string }) => void;
}>({
  run: async (process: () => Promise<void>, error?: string) => {},
  // setState: (state: { status: "processing" | "error" | "ready"; message?: string }) => {},
});

export const ProcessingContextComponent = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<{ status: "processing" | "error" | "ready"; message?: string }>({ status: "ready" });

  const run = (process: () => Promise<void>, errorMessage?: string) => {
    setState({ status: "processing" });
    process()
      .then(() => {
        setState({ status: "ready" });
      })
      .catch((error) => {
        setState({ status: "error", message: errorMessage || error.message });
      });
  };

  //   if (state.status === "processing") {
  //     return <Loading />;
  //   } else if (state.status === "error") {
  //     return <div className="m-auto text-xl font-bold">Fail: {state.message}</div>;
  //   } else if (state.status === "ready") {
  //     return <ProcessingContext.Provider value={{ run }}>{children}</ProcessingContext.Provider>;
  //   }

  return (
    <ProcessingContext.Provider value={{ run }}>
      <div className="flex-1" hidden={state.status !== "ready"}>
        {children}
      </div>
      {state.status === "processing" && <Loading />}
      {state.status === "error" && <div className="m-auto text-xl font-bold">Fail: {state.message}</div>}
    </ProcessingContext.Provider>
  );
};
