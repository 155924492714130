import { useContext, useEffect, useState } from "react";
import { ProjectBundleModel } from "../../../models/project/ProjectBundleModel";
import { ProjectModel, ProjectModelMapper } from "../../../models/project/ProjectModel";
import { ButtonGrid } from "../../common/ButtonGrid";
import { StepBar } from "../../common/StepBar";
import { BundleEditor } from "../../panels/project/BundleEditor";
import { CustomerModel, CustomerModelMapper } from "../../../models/CustomerModel";
import { CustomerPanel } from "../../elements/order/CustomerPanel";
import { InfoFieldsPanel } from "./projectWizard/InfoFieldsPanel";
import { InfoFieldsManager } from "../../../managers/InfoFieldsManager";
import { ManagerModel } from "../../../models/managers/ManagerModel";
import { ProjectInfoFieldModel } from "../../../models/project/ProjectInfoFieldModel";
import { ProjectBundlesManager } from "../../../managers/ProjectBundlesManager";
import { BundlesPanel } from "./projectWizard/BundlesPanel";
import { useMatch, useNavigate } from "react-router-dom";
import backends from "../../../backends";
import AppContext from "../../../contexts/AppContext";
import { ProcessingContext } from "../../../contexts/ProcessingContext";
import { EmailAlertsPanel } from "./projectWizard/EmailAlertsPanel";
import { EmailAlertModel } from "../../../models/email/EmailAlertModel";

const projectSteps: string[] = ["Prosjekt", "Kunde", "Informasjon", "Produktgrupper", "E-postvarsler"];

const EditProject = ({ projectName, setProjectName }: { projectName: string; setProjectName: (newName: string) => void }) => {
  return (
    <div className="flex flex-col m-auto">
      <label className="mx-auto mb-2 ">Prosjekt navn:</label>
      <input
        className="text-black p-2 rounded-md text-center text-2xl"
        type="text"
        value={projectName}
        onInput={(e) => {
          setProjectName(e.currentTarget.value);
        }}
      />
    </div>
  );
};

const EditCustomer = ({ customer, setCustomer }: { customer: CustomerModel; setCustomer: (newCustomer: CustomerModel) => void }) => {
  return (
    <div className="flex flex-col m-auto">
      <div className="flex">
        <div className="flex m-auto">
          <CustomerPanel
            customer={customer}
            onSelectCustomer={(newCustomer) => {
              if (newCustomer) {
                setCustomer(newCustomer);
              } else {
                setCustomer(CustomerModelMapper.createEmptyObject());
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

const EditInformationFields = ({ dataManager }: { dataManager: ManagerModel<ProjectInfoFieldModel> }) => {
  return <InfoFieldsPanel dataManager={dataManager} />;
};

const EditBundles = ({ dataManager }: { dataManager: ManagerModel<ProjectBundleModel> }) => {
  return <BundlesPanel dataManager={dataManager} />;
};

const EditProductBundles = ({ bundles, setBundles }: { bundles: ProjectBundleModel[]; setBundles: (newBundles: ProjectBundleModel[]) => void }) => {
  const [showBundleEditor, setShowBundleEditor] = useState(false);
  const [activeBundle, setActiveBundle] = useState<ProjectBundleModel | undefined>();

  return (
    <div className="mx-auto">
      {showBundleEditor && (
        <BundleEditor
          bundle={activeBundle}
          onClose={() => {
            setShowBundleEditor(false);
          }}
          onSaveBundle={(oldBundle, newBundle) => {
            if (oldBundle) {
              setBundles(
                bundles.map((item) => {
                  if (item === oldBundle) {
                    return newBundle;
                  } else {
                    return item;
                  }
                })
              );
            } else {
              setBundles([...bundles, newBundle]);
            }
          }}
        />
      )}
      <ButtonGrid
        buttons={bundles.map((bundle) => ({ id: bundle.id, text: bundle.name }))}
        onButtonClicked={(bundleId) => {
          setActiveBundle(bundles.find((item) => item.id === bundleId));
          setShowBundleEditor(true);
        }}
        onDelete={(bundleId) => {
          setBundles(bundles.filter((item) => item.id !== bundleId));
        }}
      />
    </div>
  );
};

export const ProjectWizard = ({}: {}) => {
  const appContext = useContext(AppContext);
  const processing = useContext(ProcessingContext);
  const match = useMatch("/prosjekter/:id");
  const navigate = useNavigate();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [project, setProject] = useState<ProjectModel>();

  const projectId = match?.params.id || "";

  useEffect(() => {
    appContext.setWizardMode(true);
    if (projectId) {
      if (projectId === "ny") {
        setProject(ProjectModelMapper.createEmptyObject());
      } else {
        processing.run(async () => {
          const fetchedProject = await backends.projects.fetch(projectId, appContext.login?.uid);
          setProject(fetchedProject);
        });
      }
    }
    return () => {
      appContext.setWizardMode(false);
    };
  }, [match?.params.id]);

  return project ? (
    <div className="flex flex-1 flex-col">
      <div className="flex text-2xl font-bold p-2">
        <button
          onClick={() => {
            processing.run(async () => {
              if (projectId === "ny") {
                await backends.projects.create(project, appContext.login?.uid);
              } else {
                await backends.projects.update(project, appContext.login?.uid);
              }
              navigate("/prosjekter");
            });
          }}
        >
          <img className="h-10" src="/assets/images/projects/save.svg" alt="save" />
        </button>
        <p className="m-auto">{project.name}</p>
        <button onClick={() => navigate("/prosjekter")}>
          <img className="h-10" src="/assets/images/projects/cancel.svg" alt="cancel" />
        </button>
      </div>
      <div className="flex">
        <div className="mx-auto my-4">
          <StepBar
            steps={projectSteps}
            onStepSelected={(stepIndex) => {
              setCurrentStepIndex(stepIndex);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-full h-full ">
        {currentStepIndex === 0 && (
          <EditProject
            projectName={project.name}
            setProjectName={(newName) => {
              setProject({ ...project, name: newName });
            }}
          />
        )}
        {currentStepIndex === 1 && (
          <EditCustomer customer={project.customer} setCustomer={(newCustomer) => setProject({ ...project, customer: newCustomer })} />
        )}
        {currentStepIndex === 2 && <EditInformationFields dataManager={InfoFieldsManager(project, setProject)} />}
        {currentStepIndex === 3 && <EditBundles dataManager={ProjectBundlesManager(project, setProject)} />}
        {currentStepIndex === 4 && (
          <div className="flex flex-1 mx-12">
            <EmailAlertsPanel
              emailAlerts={project.emails}
              setEmailAlerts={(emailAlerts: EmailAlertModel[]) => setProject({ ...project, emails: emailAlerts })}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
