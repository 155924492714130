import { useContext, useState } from "react";
import { ProductsPanel } from "../panels/order/ProductsPanel";
import OrderContext from "../../contexts/OrderContext";
import Card from "../common/Card";
import { createOrder, changeOrderStatus } from "../../backend";
import AppContext from "../../contexts/AppContext";
import LoadingDialog from "../dialogs/LoadingDialog";
import { useAuth0 } from "@auth0/auth0-react";
import { OrderPanel } from "../panels/order/OrderPanel";
import { TotalPricePanel } from "../panels/order/TotalPricePanel";
import { CustomerPanel } from "../panels/order/CustomerPanel";
import { OrderFormFields } from "../../ui/OrderFields";
import { checkOrder } from "../../utils/order";

export const CreateOrderForm = () => {
  const { user } = useAuth0();
  const appContext = useContext(AppContext);
  const orderContext = useContext(OrderContext);
  const [status, setStatus] = useState("init");
  const [orderId, setOrderId] = useState(0);

  const createOrderHandler = async (setToReady?: boolean) => {
    const warningMessage = checkOrder(orderContext.order, "customerInfo", "deliveryInfo", "orderInfo", "deliveryEmail");
    if (warningMessage) {
      appContext.setNotification({ type: "error", text: warningMessage });
    } else {
      appContext.setNotification(null);

      setStatus("processing");
      const orderId = await createOrder(orderContext.order, user?.sub);
      if (orderId) {
        if (setToReady) {
          await changeOrderStatus(orderId, "ready", user?.sub);
        }
        setOrderId(orderId);
        await appContext.refreshOrders();
        orderContext.resetOrder();
        setStatus("done");
      } else {
        setStatus("error");
      }
    }
  };

  return (
    <>
      {status === "init" && (
        <div className="flex flex-col w-full h-full">
          <Card title={OrderFormFields.customer.header.text}>
            <CustomerPanel orderInfo={orderContext.order} changeFieldValueHandler={orderContext.setOrderProperty} onCopyCustomerInfo={orderContext.copyCustomerInfoToDeliveryInfo} edit />
          </Card>

          <Card title={OrderFormFields.order.header.text}>
            <OrderPanel order={orderContext.order} changeFieldValueHandler={orderContext.setOrderProperty} editable />
          </Card>

          <Card title={OrderFormFields.products.header.text}>
            <ProductsPanel products={orderContext.order.products} addProduct={orderContext.addProduct} removeProduct={orderContext.removeProduct} updateProduct={orderContext.updateProduct} edit />
          </Card>

          <TotalPricePanel products={orderContext.order.products} />

          {orderContext?.order.customer?.id && orderContext?.order.products.length > 0 && (
            <div className="flex gap-2 h-16 m-2 ">
              <button className="flex flex-1 h-16 rounded-md ibutton" onClick={() => createOrderHandler(true)}>
                <div className="flex gap-2 m-auto">
                  <img className="h-8 w-8" alt="create order" src="/assets/images/projects/create-order.svg" />
                  <p className="my-auto">Sjekk og opprett ordren</p>
                </div>
              </button>
              <button className="flex flex-1 h-16 rounded-md ibutton" onClick={() => createOrderHandler()}>
                <div className="flex gap-2 m-auto">
                  <img className="h-8 w-8" alt="save order" src="/assets/images/projects/save.svg" />
                  <p className="my-auto">Sjekk og lagre ordre som draft</p>
                </div>
              </button>
            </div>
          )}
        </div>
      )}

      {status === "processing" && <LoadingDialog visible />}

      {(status === "done" || status === "error") && (
        <div className="flex h-full">
          <div className="flex flex-col m-auto">
            <div>{status === "done" ? `Bestillingen #${orderId} opprettet` : "Feil"}</div>
            <div className="flex my-2">
              <button className="flex m-auto text-sm font-bold px-4 py-2 rounded-md ibutton" onClick={() => setStatus("init")}>
                Tilbake
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
