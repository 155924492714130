import { ModelMapper } from "../ModelMapper";
import ProductModel from "../ProductModel";

export interface ProjectBundleModel {
  id: string;
  name: string;
  description: string;
  products: ProductModel[];
}

export const ProjectBundleModelMapper: ModelMapper<ProjectBundleModel> = {
  createEmptyObject: () => {
    return { id: "", name: "", description: "", products: [] };
  },

  fromBackendObject: (backendObject: any) => {
    return {
      id: backendObject.id,
      name: backendObject.name,
      description: backendObject.description || "",
      products: backendObject.products.map((item: any) => ProductModel.fromOrderBackendObject(item))
    };
  },

  toBackendObject: (bundle: ProjectBundleModel) => {
    return { ...bundle, products: bundle.products.map((item) => ProductModel.toOrderBackendObject(item)) };
  },
};
