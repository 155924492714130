import { useAuth0 } from "@auth0/auth0-react";
import { createContext, ReactNode, useEffect, useState } from "react";
import { fetchOrders, lookupUser } from "../backend";
import OrderModel from "../models/OrderModel";
import LoadingDialog from "../components/dialogs/LoadingDialog";
import { LoginModel } from "../models/login/LoginModel";

const AppContext = createContext<{
  state: { status: string; message: string };

  login: LoginModel | null;

  orders: OrderModel[] | null;
  refreshOrders: () => Promise<void>;
  setProcessing: (processing: boolean) => void;

  wizardMode: boolean;
  setWizardMode: (wizard: boolean) => void;

  notification: { type: "information" | "warning" | "error"; text: string } | null;
  setNotification: (notification: { type: "information" | "warning" | "error"; text: string } | null) => void;
}>({
  state: { status: "", message: "" },

  login: null,

  orders: null,
  refreshOrders: () => {
    return Promise.resolve();
  },
  setProcessing: (processing: boolean) => {},

  wizardMode: false,
  setWizardMode: (wizard: boolean) => {},

  notification: null,
  setNotification: (notification: { type: "information" | "warning" | "error"; text: string } | null) => {},
});

export const AppContextComponent = ({ children }: { children: ReactNode }) => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  const [state, setState] = useState<{ status: string; message: string }>({ status: "init", message: "" });
  const [login, setLogin] = useState<LoginModel | null>(null);

  const [orders, setOrders] = useState<OrderModel[] | null>(null);
  const [processing, setProcessing] = useState(false);

  const [wizardMode, setWizardMode] = useState<boolean>(false);

  const [notification, setNotification] = useState<{ type: "information" | "warning" | "error"; text: string } | null>(null);

  const loadOrders = async () => {
    const orders = await fetchOrders(user?.sub);
    setOrders(orders.reverse());
  };

  useEffect(() => {
    if (isLoading) {
      setState({ status: "loading", message: "" });
    } else {
      if (isAuthenticated) {
        if (user?.sub && user?.email) {
          lookupUser(user?.sub, user?.email)
            .then((userLogin) => {
              if (userLogin) {
                if (userLogin.active && userLogin.access) {
                  setLogin(userLogin);
                  setState({ status: "ready", message: "" });
                } else {
                  setState({ status: "message", message: "Beklager, tilgang forbudt!" });
                }
              }
            })
            .catch();
        }
      } else {
        setState({ status: "message", message: "Du må logge inn for å fortsette." });
      }
    }
    // (async () => {
    //   if (isAuthenticated) {
    //     if (user?.sub && user?.email) {
    //       const userLogin = await lookupUser(user?.sub, user?.email);
    //       if (userLogin) {
    //         if (userLogin.active && userLogin.access) {
    //           setLogin(userLogin);
    //           setState({ status: "ready", message: "" });
    //         } else {
    //           setState({ status: "message", message: "Beklager, tilgang forbudt!" });
    //         }
    //       }
    //     }
    //   } else {
    //     if (isLoading) {
    //       setState({ status: "loading", message: "" });
    //     } else {
    //       setState({ status: "message", message: "Du må logge inn for å fortsette." });
    //     }
    //   }
    // })();
  }, [isLoading, isAuthenticated]);

  

  const appData = {
    state,

    login,

    orders,
    refreshOrders: async () => {
      await loadOrders();
    },
    setProcessing,

    wizardMode,
    setWizardMode,

    notification,
    setNotification,
  };

  // useEffect(() => {
  //   loadOrders();
  // }, []);

  return (
    <AppContext.Provider value={appData}>
      <LoadingDialog visible={processing} />
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
