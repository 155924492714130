import { useEffect, useState } from "react";
import { getApi } from "../backends/Api";
import { useApiRunner } from "../hooks/useApiRunner";
import { ActionDialog } from "../../common/components/dialogs/ActionDialog";
import { Loading } from "../../common/components/dialogs/Loading";
import { SklCampaignModel, SklPriceSettingsModel, SklPriceSettingsModelMapper } from "../models/SklPriceSettingsModel";
import { SklPriceGroupPanel } from "./SklPriceGroupPanel";
import { TextField } from "@mui/material";

export function SklSettingsDialog({ onClose }: { onClose: () => void }) {
  const today = new Date();
  const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

  const apiRunner = useApiRunner(getApi("skl/articles"));
  const [priceSettings, setPriceSettings] = useState<SklPriceSettingsModel>(SklPriceSettingsModelMapper.newModel());
  const [selectedCampaignName, setSelectedCampaignName] = useState<string | null>(null);

  const [addCampaign, setAddCampaign] = useState<boolean>(false);
  const [newCampaign, setNewCampaign] = useState<SklCampaignModel>({ name: "", fromDate: todayString, toDate: todayString, priceGroups: [] });

  useEffect(() => {
    (async () => {
      const fetchedArticles = await apiRunner.methods.fetchAll();
      setPriceSettings(SklPriceSettingsModelMapper.mapToModel(fetchedArticles));
    })();
  }, []);

  return (
    <>
      {apiRunner.status.state === "running" && <Loading />}
      {apiRunner.status.state === "completed" && (
        <ActionDialog
          actions={["Save", "Cancel"]}
          onAction={async (action) => {
            if (action === "close") {
              onClose();
            } else if (action === "Save") {
              await apiRunner.methods.update("", SklPriceSettingsModelMapper.mapToBackendObject?.(priceSettings));
              onClose();
            } else if (action === "Cancel") {
              onClose();
            }
          }}
        >
          <div className="">
            <h2 className="text-xl">Standard priser</h2>
            <div className="px-6">
              <SklPriceGroupPanel
                priceGroups={priceSettings.standardPriceGroups}
                onAdd={(priceGroup) => {
                  setPriceSettings({
                    ...priceSettings,
                    standardPriceGroups: [...priceSettings.standardPriceGroups, priceGroup],
                  });
                }}
                onChange={(original, changed) => {
                  setPriceSettings({
                    ...priceSettings,
                    standardPriceGroups: priceSettings.standardPriceGroups.map((item) => (item === original ? changed : item)),
                  });
                }}
                onDelete={async (priceGroup) => {
                  for await (const price of priceGroup.prices) {
                    if (price.id) {
                      await apiRunner.methods.delete(price.id);
                    }
                  }
                  const fetchedArticles = await apiRunner.methods.fetchAll();
                  setPriceSettings(SklPriceSettingsModelMapper.mapToModel(fetchedArticles));
                }}
              />
            </div>
          </div>
          <div className="border-t-2 py-4">
            <div className="flex">
              <h2 className="text-xl">Kampanjer</h2>
              {!addCampaign && (
                <button className="ml-2 my-auto h-6 w-6" onClick={() => setAddCampaign(true)}>
                  <img src="/assets/images/add.svg" alt="add" />
                </button>
              )}
            </div>
            {addCampaign && (
              <div className="flex gap-2">
                <TextField sx={{ backgroundColor: "white" }} value={newCampaign.name} onChange={(e) => setNewCampaign({ ...newCampaign, name: e.target.value })} />
                <TextField type="date" sx={{ backgroundColor: "white" }} value={newCampaign.fromDate} onChange={(e) => setNewCampaign({ ...newCampaign, fromDate: e.target.value })} />
                <TextField type="date" sx={{ backgroundColor: "white" }} value={newCampaign.toDate} onChange={(e) => setNewCampaign({ ...newCampaign, toDate: e.target.value })} />
                <button
                  className="my-auto h-8 w-8"
                  onClick={() => {
                    setPriceSettings({ ...priceSettings, campaigns: [...priceSettings.campaigns, newCampaign] });
                    setAddCampaign(false);
                  }}
                >
                  <img src="/assets/images/add.svg" alt="add" />
                </button>
              </div>
            )}
            <div className="flex flex-col py-2 gap-4">
              {priceSettings.campaigns.map((campaign) => (
                <div className="">
                  <button className="flex w-full" onClick={() => setSelectedCampaignName(campaign.name !== selectedCampaignName ? campaign.name : null)}>
                    <img src={selectedCampaignName === campaign.name ? "/assets/images/skl/less.svg" : "/assets/images/skl/more.svg"} alt="" />
                    <h3>{campaign.name}</h3>
                    <span className="ml-auto">
                      [{campaign.fromDate} - {campaign.toDate}]
                    </span>
                  </button>
                  {selectedCampaignName === campaign.name && (
                    <div className="px-6">
                      <SklPriceGroupPanel
                        priceGroups={campaign.priceGroups}
                        onAdd={(priceGroup) => {
                          setPriceSettings({
                            ...priceSettings,
                            campaigns: priceSettings.campaigns.map((item) => (item === campaign ? { ...item, priceGroups: [...item.priceGroups, priceGroup] } : item)),
                          });
                        }}
                        onChange={(original, changed) => {
                          // setPriceSettings({
                          //   ...priceSettings,
                          //   standardPriceGroups: priceSettings.standardPriceGroups.map((item) => (item === original ? changed : item)),
                          // });
                        }}
                        onDelete={async (priceGroup) => {
                          for await (const price of priceGroup.prices) {
                            if (price.id) {
                              await apiRunner.methods.delete(price.id);
                            }
                          }
                          const fetchedArticles = await apiRunner.methods.fetchAll();
                          setPriceSettings(SklPriceSettingsModelMapper.mapToModel(fetchedArticles));
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </ActionDialog>
      )}
    </>
  );
}
