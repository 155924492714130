import { ModelMapper } from "../ModelMapper";

export interface ProductSupplierModel {
  levnr: string;
  levname: string;
}

export const ProductSupplierModelMapper: ModelMapper<ProductSupplierModel> = {
  createEmptyObject: () => {
    return {
      levnr: "",
      levname: ""
    };
  },

  fromBackendObject: (backendObject: any) => {
    return {...backendObject };
  },

  toBackendObject: (model: ProductSupplierModel) => {
    return model;
  },
};

// export default class SupplierModel {
//   levnr: string = "";
//   levname: string = "";

//   public static fromBackendObject(backendObject: BackendSupplierModel): SupplierModel {
//     const supplier = new SupplierModel();

//     supplier.levnr = backendObject.levnr || "";
//     supplier.levname = backendObject.levname || "";

//     return supplier;
//   }
// }
