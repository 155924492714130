import { UIField } from "../../../models/UIField";

export const EditboxField = ({ model, readonly, maxChars }: { model: UIField; readonly?: boolean; maxChars?: number }) => {
  return (
    <div className="flex h-7 items-center">
      <label title={model.description}>{model.label}</label>
      <input
        type="text"
        className={`ml-auto w-3/5 px-2 rounded border-2 ${!readonly ? "text-black" : "bg-transparent text-white"}`}
        readOnly={readonly}
        value={model.value}
        onInput={(e) => {
          const text = e.currentTarget.value;
          model.setValue(maxChars ? text.substring(0, maxChars) : text);
        }}
        // onBlur={(e) => {
        //   if (onEditingCompleted) {
        //     onEditingCompleted();
        //   }
        // }}
      />
    </div>
  );
};
