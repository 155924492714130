import { useState } from "react";

export function TotalPrice({ price, profit }: { price: number; profit: number }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="flex w-full">
      <div className="flex iwgray text-black font-bold rounded-l-md ml-auto mr-0 px-2">
        <button className="mr-2 my-auto text-xl" onClick={() => setExpanded(!expanded)} children={expanded ? "⇾" : "⇽"} />
        {expanded && (
          <div className="w-64">
            <div className="m-auto font-bold border-b-2 border-black text-center w-full py-1.5">TOTAL PRIS</div>
            <div className="w-full text-center py-1.5">
              {price.toFixed(2)} ({profit > 0 && "+"}
              {profit.toFixed(2)})
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
