import UserProfile from "./auth/UserProfile";

export const Header = () => {
  return (
    <div className="flex w-full p-3">
      <div className="flex items-center gap-2">
        <img className="h-10" src="/assets/images/telenor/logo.svg" alt="logo" />
        <img className="h-5" src="/assets/images/telenor/name.svg" alt="telenor" />
      </div>
      <div className={`my-auto mr-2 ml-auto`}>
        <UserProfile />
      </div>
    </div>
  );
};
