import { useEffect } from "react";

export const SelectBox = ({
  label,
  items,
  value,
  onValueChanged,
  readonly,
}: {
  label: string;
  items: { id: string; name: string }[];
  value?: string;
  onValueChanged: (value: string) => void;
  readonly?: boolean;
}) => {
  useEffect(() => {
    if (!value && items.length > 0) {
      onValueChanged(items[0].id);
    }
  }, []);

  return (
    <div className="flex h-7">
      <label className="my-auto w-1/3">{label}</label>
      <select
        disabled={readonly}
        className={`${readonly ? "selectBoxReadonlyField" : "selectBoxField"}`}
        value={value}
        onChange={(e) => {
          onValueChanged(e.target.value);
        }}
      >
        {items.map((item) => {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
