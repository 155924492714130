import React, { useState } from "react";

const Card = ({ title, children }: { title: string, children: JSX.Element }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="flex flex-col p-2">
      <div className="flex iheader p-1 rounded-t-md">
        <button>
          <img
            className="w-8"
            src={expanded ? "/assets/images/expand-less.svg" : "/assets/images/expand-more.svg"}
            alt="expand"
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          />
        </button>
        <span className="ml-2 text-lg font-bold text-black">{title}</span>
      </div>
      <div className={`${expanded ? "flex" : "hidden"} p-2 border-2 rounded-b-md`}>{children}</div>
    </div>
  );
};

export default Card;
