import { ManagerModel } from "../models/managers/ManagerModel";
import { ProjectInfoFieldModel, ProjectInfoFieldsModelMapper } from "../models/project/ProjectInfoFieldModel";
import { ProjectModel } from "../models/project/ProjectModel";


export const InfoFieldsManager: (project: ProjectModel, setProject: (project: ProjectModel) => void) => ManagerModel<ProjectInfoFieldModel> = (
  project: ProjectModel, setProject: (project: ProjectModel) => void
) => {
  return {
    state: {
      status: "ready",
      message: "",
    },
    data: project.information,
    refresh: async() => {},
    new: () => ProjectInfoFieldsModelMapper.createEmptyObject(),
    create: async (field: ProjectInfoFieldModel) => {
      setProject({...project, information: project.information?.length > 0 ? [...project.information, {...field, id: project.information.length}] : [field]});
    },
    read: async (id: any) => {
      const field = project.information.find((item) => item.id === id);
      return field;
    },
    update: async (id: any, field: ProjectInfoFieldModel) => {
      setProject({...project, information: [...project.information.map(item => item.id === id ? field : item)] });
    },
    delete: async (id: any) => {
      setProject({...project, information: project.information.filter((item) => item.id !== id)});
    },
  };
};
