import { useContext, useEffect, useState } from "react";
import { FlowCreator, FlowModel, FlowOrderCreator, FlowOrderInfoModel } from "./models";
import { ModuleContext } from "../common/components";
import { FlowBackend } from "./backends";
import { useNavigate } from "react-router-dom";
import { AppConstantsContext } from "../../contexts/AppConstantsContext";
import { ActiveOrderList } from "./components/ActiveOrderList";

function FlowCard({ flowGroupId, flowName }: { flowGroupId: string; flowName: string }) {
  const navigate = useNavigate();
  const processor = useContext(ModuleContext);
  const { flowStates } = useContext(AppConstantsContext);

  const [flow, setFlow] = useState<FlowModel>();

  useEffect(() => {
    (async () => {
      const fetchedFlow = await processor?.run(FlowBackend.getFlow, flowGroupId);
      // const fetchedOrders = await processor?.run(FlowBackend.getAllOrders, flowGroupId);
      setFlow({ ...FlowCreator().fromBackendObject(fetchedFlow), orders: [] });
    })();
  }, []);

  return (
    <div className="w-96 h-72 border-2 rounded-xl">
      <button className="text-3xl font-bold border-b-2 bg-black rounded-t-xl w-full text-left p-4" onClick={() => navigate(`/flows/${flowGroupId}`)}>
        {flowName}
      </button>
      {flow ? (
        <div className="grid grid-cols-2 grid-rows-5 grid-flow-col gap-1 m-2">
          {/* {flow.statuses.map((status) => (
            <button key={`${flowGroupId}-${status}`} className="flex py-1 px-4 rounded-full border-2 bg-inselo-light-blue hover:opacity-80 text-black" onClick={() => navigate(`/flows/${flowGroupId}?status=${status}`)}>
              {status} <span className="ml-auto">{flow.orders.filter((order) => order.status_group === status).length}</span>
            </button>
          ))} */}
          {flowStates.map((state) => {
            let quantity = "-";
            let color = "bg-inselo-light-blue";
            const st = flow.states && flow.states[state.id];
            if (st) {
              quantity = st.quantity?.toString() || "-";
              if (st.flag === "notice") {
                color = "bg-inselo-green";
              } else if (st.flag === "warning") {
                color = "bg-inselo-yellow";
              } else if (st.flag === "alert") {
                color = "bg-inselo-red";
              }
            }
            return (
              <button key={`${flowGroupId}-${state.id}`} className={`flex py-1 px-4 rounded-full border-2 hover:opacity-80 text-black ${color}`} onClick={() => navigate(`/flows/${flowGroupId}?state=${state.id}`)}>
                {state.name} <span className="ml-auto">{quantity}</span>
              </button>
            );
          })}
        </div>
      ) : (
        <p className="p-2">
          <img className="h-8 w-8" src="/assets/images/loading.gif" alt="loading" />
        </p>
      )}
    </div>
  );
}

export function FlowsDashboard({ flows }: { flows: FlowModel[] }) {
  const [view, setView] = useState<"list" | "dashboard">("list");

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex w-full p-4 ">
        <button onClick={() => setView(view === "list" ? "dashboard" : "list")}>
          <img className="h-8 w-8" src={view === "list" ? "/assets/images/flows/toggle-off.svg" : "/assets/images/flows/toggle-on.svg"} alt="refresh" />
        </button>
      </div>
      {view === "list" ? <ActiveOrderList flows={flows} /> : null}
      {view === "dashboard" ? (
        <div className="flex flex-1 flex-wrap gap-4 mx-8">
          {flows.map((flow) => (
            <FlowCard key={flow.flow_id} flowGroupId={flow.group_id} flowName={flow.name} />
          ))}
        </div>
      ) : null}
    </div>
  );
}
