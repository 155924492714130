import { useContext, useEffect, useState } from "react";
import { fetchProducts } from "../../../backend";
import ProductModel from "../../../models/ProductModel";
import { ProductSupplierModel } from "../../../models/product/ProductSupplierModel";
import { PriceCalculator } from "../../../utils/PriceCalculator";
import { EditboxField } from "../../fields/product/EditboxField";
import { NumberField } from "../../fields/product/NumberField";
import { SelectboxField } from "../../fields/product/SelectboxField";
import { CheckboxField } from "../../fields/product/CheckboxField";
import { ReadonlyField } from "../../fields/product/ReadonlyField";
import { OutPriceField } from "../../fields/product/OutPriceField";
import OrderContext from "../../../contexts/OrderContext";
import backends from "../../../backends";
import AppContext from "../../../contexts/AppContext";

const EditProductLayout = ({ children }: { children: JSX.Element[] }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-6">
        <div className="grid grid-cols-3 col-span-3 gap-2 w-96 items-center">
          <label className="col-span-1">ProduktNr</label>
          <div className="col-span-2">{children[0]}</div>
          <label className="col-span-1">EAN</label>
          <div className="col-span-2">{children[1]}</div>
          <label className="col-span-1">Beskrivelse</label>
          <div className="col-span-2">{children[2]}</div>
          <label className="col-span-1">Lvnavn</label>
          <div className="col-span-2">{children[3]}</div>
          <label className="col-span-1">LevAlt</label>
          <div className="col-span-2">{children[4]}</div>
          <label className="col-span-1">BID</label>
          <div className="col-span-2">{children[5]}</div>
        </div>
        <div className="grid grid-cols-3 col-span-3 gap-2 ml-4 w-96 items-center">
          <label className="col-span-1">Moms</label>
          <div className="col-span-2">{children[6]}</div>
          <label className="col-span-1">Antall</label>
          <div className="col-span-2">{children[7]}</div>
          <label className="col-span-1">Kalk</label>
          <div className="col-span-2">{children[8]}</div>
          <label className="col-span-1">Kostpris</label>
          <div className="col-span-2">{children[9]}</div>
          <label className="col-span-1">Utpris</label>
          <div className="col-span-2">{children[10]}</div>
          <label className="col-span-1">Total Pris</label>
          <div className="col-span-2">{children[11]}</div>
        </div>
      </div>
      <div className="grid grid-cols-6 items-center">
        <label className="col-span-1">Informasjon</label>
        <div className="col-span-5">{children[12]}</div>
      </div>
    </div>
  );
};

export const EditProductComponent = ({ selectedProduct, setSelectedProduct, readonly }: { selectedProduct: ProductModel | null; setSelectedProduct: (product: ProductModel | null) => void; readonly: boolean }) => {
  const appContext = useContext(AppContext);
  const orderManagement = useContext(OrderContext);

  const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);
  // const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(product);
  const [searchableFields, setSearchableFields] = useState({ number: "", ean: "", description: "" });
  const [allSuppliers, setAllSuppliers] = useState<ProductSupplierModel[]>([]);

  const loadFilteredProducts = async (searchField: string, searchValue: string) => {
    const products = await fetchProducts(searchField === "number" ? "" : searchField, searchValue, appContext.login?.uid);
    setFilteredProducts(products);
  };

  const setProductFieldValue = (fieldName: string, value: any) => {
    if (selectedProduct) {
      setSelectedProduct({ ...selectedProduct, [fieldName]: value });
    }
  };

  const setProductSupplier = (levnr: string, customize: boolean) => {
    if (selectedProduct) {
      const supplier = customize ? allSuppliers.find((s) => s.levnr === levnr) : selectedProduct.suppliers.find((s) => s.levnr === levnr);
      if (supplier) {
        setSelectedProduct({ ...selectedProduct, levnr: supplier.levnr, levname: supplier.levname });
      }
    }
  };

  const setProductPrices = (price: number, margin: string) => {
    let marginValue;
    let outPrice;
    if (selectedProduct) {
      if (margin[margin.length - 1] === "%") {
        marginValue = parseFloat(margin.slice(0, margin.length - 1));
        outPrice = price * (1 + marginValue / 100);
      } else {
        marginValue = parseFloat(margin);
        outPrice = price + marginValue;
      }

      setSelectedProduct({ ...selectedProduct, price, margin, outPrice });
    }
  };

  const searchableField = (fieldName: string, datalistName: string, customize: boolean, editable?: boolean) => {
    return (
      <input
        className={`h-8 w-full px-2 rounded-md ${editable ? "text-red-600 font-extrabold" : "text-black"}`}
        type="text"
        list={customize ? undefined : datalistName}
        value={customize ? (selectedProduct ? (selectedProduct as any)[fieldName] : null) : (searchableFields as any)[fieldName]}
        onChange={(e) => {
          if (customize) {
            setProductFieldValue(fieldName, e.currentTarget.value);
          } else {
            const value = e.target.value;
            let found = false;
            if (value.length >= 3) {
              for (const p of filteredProducts) {
                if ((p as any)[fieldName] === value) {
                  found = true;
                  setSelectedProduct(p);
                  setSearchableFields({ number: p.number || "", ean: p.ean || "", description: p.description || "" });
                  break;
                }
              }
              if (!found) {
                loadFilteredProducts(fieldName, value);
                setSelectedProduct(null);
                setSearchableFields({ number: "", ean: "", description: "", [fieldName]: value });
              }
            } else {
              setFilteredProducts([]);
              setSelectedProduct(null);
              setSearchableFields({ number: "", ean: "", description: "", [fieldName]: value });
            }
          }
        }}
      />
    );
  };

  const productLine = (customize: boolean) => {
    return (
      <EditProductLayout>
        {/* ProduktNr */}
        {searchableField("number", "productNumbers", customize)}

        {/* EAN */}
        {searchableField("ean", "productEANs", customize)}

        {/* Beskrivelse */}
        {searchableField("description", "productDescriptions", customize || ProductModel.isEditable(selectedProduct), ProductModel.isEditable(selectedProduct))}

        {/* Lvrnavn */}
        <SelectboxField
          disabled={!selectedProduct}
          value={selectedProduct?.levnr || ""}
          onValueChanged={(value: string) => {
            setProductSupplier(value, customize);
          }}
          items={customize ? allSuppliers.map((item) => ({ label: item.levname, value: item.levnr })) || [] : selectedProduct?.suppliers?.map((item) => ({ label: item.levname, value: item.levnr })) || []}
        />

        {/* LevAlt */}
        <SelectboxField
          disabled={!selectedProduct}
          value={selectedProduct?.levalt}
          onValueChanged={(value: string) => {
            setProductFieldValue("levalt", value);
          }}
          items={orderManagement.deliveryAlternatives.map((item) => ({ label: item.description, value: item.lev_alt }))}
        />

        {/* BID */}
        <EditboxField
          disabled={!selectedProduct}
          value={selectedProduct?.bid || ""}
          onValueChanged={(value: string) => {
            setProductFieldValue("bid", value);
          }}
        />

        {/* Moms */}
        <CheckboxField
          disabled={!selectedProduct}
          value={selectedProduct?.vat || false}
          onValueChanged={(value: boolean) => {
            setProductFieldValue("vat", value);
          }}
        />

        {/* Antall */}
        <NumberField
          disabled={!selectedProduct}
          value={selectedProduct?.quantity || ""}
          onValueChanged={(value: number) => {
            setProductFieldValue("quantity", value);
          }}
        />

        {/* Margin */}
        <input
          type="text"
          className="addProductField text-right"
          disabled={!selectedProduct || ProductModel.isEditable(selectedProduct)}
          value={selectedProduct?.margin || ""}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            setProductPrices(selectedProduct?.price || 0, newValue);
          }}
        />

        {/* Kostpris */}
        {customize || ProductModel.isEditable(selectedProduct) ? (
          <NumberField
            disabled={!selectedProduct}
            value={selectedProduct?.price.toFixed(2) || ""}
            onValueChanged={(value: number) => {
              setProductPrices(value, selectedProduct?.margin || "0%");
            }}
          />
        ) : (
          <ReadonlyField value={selectedProduct?.price.toFixed(2) || ""} />
        )}

        {/* Utpris */}
        <OutPriceField product={selectedProduct} setProduct={setSelectedProduct} />

        {/* Totalpris */}
        <ReadonlyField value={selectedProduct ? PriceCalculator.GetTotalPricesFormated(selectedProduct?.price, selectedProduct?.outPrice, selectedProduct?.quantity) : ""} />
        <EditboxField
          disabled={!selectedProduct}
          value={selectedProduct?.information || ""}
          onValueChanged={(value: string) => {
            setProductFieldValue("information", value);
          }}
        />
      </EditProductLayout>
    );
  };

  useEffect(() => {
    if (selectedProduct) {
      backends.products.fetch(selectedProduct.number, appContext.login?.uid).then((fetchedProduct) => {
        setSelectedProduct({ ...selectedProduct, suppliers: fetchedProduct.suppliers });
      });
      selectedProduct.suppliers = [];
      setFilteredProducts([selectedProduct]);
      setSearchableFields({ number: selectedProduct.number, ean: selectedProduct.ean, description: selectedProduct.description });
    }
  }, []);

  return (
    <div className="flex flex-col select-none">
      {/* <input
        className="w-4 mb-8"
        type="checkbox"
        checked={selectedProduct?.custom}
        onChange={(e) => {
          if (e.target.checked) {
            if (allSuppliers.length === 0) {
              fetchSuppliers(user?.sub).then((suppliers) => setAllSuppliers(suppliers));
            }
            setSelectedProduct({ ...new ProductModel(), custom: true });
          } else {
            setSelectedProduct(null);
          }
        }}
      /> */}

      {selectedProduct && readonly ? (
        <EditProductLayout>
          <ReadonlyField value={selectedProduct.number} align="left" />
          <ReadonlyField value={selectedProduct.ean} align="left" />
          <ReadonlyField value={selectedProduct.description} align="left" />
          <ReadonlyField value={selectedProduct.levname} align="left" />
          <ReadonlyField value={orderManagement.deliveryAlternatives.find((item) => item.lev_alt === selectedProduct.levalt)?.description || ""} align="left" />
          <ReadonlyField value={selectedProduct.bid} align="left" />
          <CheckboxField disabled value={selectedProduct?.vat || false} />
          <ReadonlyField value={selectedProduct.quantity} />
          <ReadonlyField value={selectedProduct.margin} />
          <ReadonlyField value={selectedProduct.price} />
          <ReadonlyField value={selectedProduct.outPrice} />
          <ReadonlyField value={selectedProduct ? PriceCalculator.GetTotalPricesFormated(selectedProduct?.price, selectedProduct?.outPrice, selectedProduct?.quantity) : ""} />
          <ReadonlyField value={selectedProduct.information} align="left" />
        </EditProductLayout>
      ) : (
        productLine(selectedProduct ? selectedProduct.custom : false)
      )}

      <datalist id="productNumbers">
        {filteredProducts.map((product) => (
          <option value={product.number} label={`${product.description}`} key={product.id} />
        ))}
      </datalist>
      <datalist id="productEANs">
        {filteredProducts.map((product) => (
          <option value={product.ean} label={`${product.description}`} key={product.id} />
        ))}
      </datalist>
      <datalist id="productDescriptions">
        {filteredProducts.map((product) => (
          <option value={product.description} label={`${product.number}`} key={product.id} />
        ))}
      </datalist>
    </div>
  );
};
