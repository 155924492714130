import { useEffect, useState } from "react";
import { getApi } from "./backends/Api";
import { useApiRunner } from "./hooks/useApiRunner";
import { SklOrderModel, SklOrderModelMapper } from "./models/SklOrderModel";
import { Loading } from "../common/components/dialogs/Loading";
import { TELENOR_SKL_PAGE_URL } from "../../env";
import { ActionDialog } from "../common/components/dialogs/ActionDialog";
import { CustomDataTable } from "./components/elements/DataTable";
import { SklOrderControlPanel, SklSettingsDialog } from "./components";

function dateToString(date: number) {
  return date ? new Date(date).toISOString().split("T")[0] : "-";
}

const columns = [
  { name: "ID", selector: (row: SklOrderModel) => row.id, width: "4rem", sortable: true },
  {
    name: "Organisasjonsnavn",
    width: "16rem",
    cell: (row: SklOrderModel) => (
      <span className="truncate" title={row.step1?.customer?.name}>
        {row.step1?.customer?.name}
      </span>
    ),
    sortable: true,
  },
  { name: "Dato (utført bestilling)", selector: (row: SklOrderModel) => dateToString(row.status?.orderDate), width: "12rem", sortable: true },
  { name: "Antall brukere​", selector: (row: SklOrderModel) => row.step1?.customer?.numberOfUsers, width: "10rem" },
  { name: "Consent OK​", cell: (row: SklOrderModel) => (row.step2?.consentOk ? <img src="/assets/images/skl/ok.svg" alt="ok" /> : ""), width: "8rem" },
  { name: "Step-2 OK​", cell: (row: SklOrderModel) => (row.status?.state === "step2" ? <img src="/assets/images/skl/ok.svg" alt="ok" /> : ""), width: "8rem" },
  { name: "Dato LEVERT​", selector: (row: SklOrderModel) => dateToString(row.status?.deliveryDate), width: "12rem", sortable: true },
  { name: "Bestiller Navn", selector: (row: SklOrderModel) => row.step1?.contact?.name, width: "12rem", sortable: true },
  { name: "Bestiller Mobil", selector: (row: SklOrderModel) => row.step1?.contact?.mobile, width: "10rem" },
];

export function SklModule() {
  const sklOrdersApiRunner = useApiRunner(getApi("skl"));

  const [origOrders, setOrigOrders] = useState<SklOrderModel[]>([]);
  const [orders, setOrders] = useState<SklOrderModel[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<SklOrderModel>(SklOrderModelMapper.newModel());
  const [dialog, setDialog] = useState<"" | "confirm-delete" | "settings">("");

  async function getSklOrders() {
    const fetchedOrders = await sklOrdersApiRunner.methods.fetchAll();
    const mappedOrders = fetchedOrders.map((item: any) => SklOrderModelMapper.mapToModel(item));
    setOrigOrders(mappedOrders);
    setOrders(mappedOrders);
  }
  
  useEffect(() => {
    getSklOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-full h-full">
      {sklOrdersApiRunner.status.state === "running" && <Loading />}
      {dialog === "confirm-delete" && (
        <ActionDialog
          actions={["Ja", "Nei"]}
          onAction={async (action) => {
            setDialog("");
            if (action === "Ja") {
              await sklOrdersApiRunner.methods.delete(selectedOrder.hash);
              getSklOrders();
            }
          }}
        >
          <p>Er du sikker?</p>
        </ActionDialog>
      )}
      {dialog === "settings" && <SklSettingsDialog onClose={() => setDialog("")} />}
      <div className="flex flex-1 flex-col gap-2 m-4">
        <div className="flex">
          <button
            onClick={async () => {
              setDialog("settings");
            }}
          >
            <img className="h-10 w-10" src="/assets/images/settings.svg" alt="settings" />
          </button>
          <button className="ml-auto" onClick={() => getSklOrders()}>
            <img className="h-10 w-10" src="/assets/images/refresh.svg" alt="refresh" />
          </button>
        </div>
        <CustomDataTable<SklOrderModel>
          columns={columns}
          data={orders}
          rowActions={["Edit", "Delete"]}
          onRowActionClicked={(order, action) => {
            setSelectedOrder(order);
            if (action === "View") {
              window.open(`${TELENOR_SKL_PAGE_URL}/${order?.hash}`);
            } else if (action === "Edit") {
              window.open(`${TELENOR_SKL_PAGE_URL}/${order?.hash}`);
            } else if (action === "Delete") {
              setDialog("confirm-delete");
            }
          }}
          expandedRow={({ data }) => {
            return SklOrderControlPanel(data as SklOrderModel, async (order: SklOrderModel) => {
              if (order.status.delivered) {
                const foundOrigOrder = origOrders.find((item) => (item.id = order.id));
                if (foundOrigOrder?.status?.delivered !== order.status?.delivered) {
                  order.status.deliveryDate = Date.now();
                }
              }
              await sklOrdersApiRunner.methods.update(order.hash, order);
              getSklOrders();
            });
          }}
        />
      </div>
    </div>
  );
}
