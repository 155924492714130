export const EditboxField = ({
  disabled,
  readonly,
  style,
  value,
  onValueChanged,
}: {
  disabled?: boolean,
  readonly?: boolean;
  style?: string;
  value: string;
  onValueChanged?: (value: string) => void;
}) => {
  const s = readonly ? "addProductReadonlyField" : "addProductField";

  return (
    <input
      className={`${s} ${style}`}
      disabled={disabled}
      readOnly={readonly}
      value={value}
      type="text"
      onChange={(e) => {
        if (onValueChanged) {
          onValueChanged(e.currentTarget.value);
        }
      }}
      onDoubleClick={() => {
        disabled = !disabled;
      }}
    />
  );
};
