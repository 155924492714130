import { useState } from "react";
import { ExOrderBundleLineModel, ExOrderProductLineMapper, ExOrderProductLineModel } from "../../models";
import { EditProductLineDialog } from "./EditProductLineDialog";
import { ProductLine } from "./ProductLines";

function BundleLine({ bundle, onSetBundle, onRemoveBundle }: { bundle: ExOrderBundleLineModel; onSetBundle: (bundle: ExOrderBundleLineModel) => void; onRemoveBundle: () => void }) {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [selectedProductLine, setSelectedProductLine] = useState<ExOrderProductLineModel>();

  function changeBundleQuantity(quantity: number) {
    if (quantity === 0) {
      onRemoveBundle();
    } else {
      const newProductLines = bundle.products.map((item) => ({ ...ExOrderProductLineMapper.fromProduct(item), quantity: item.quantity * quantity }));
      onSetBundle({ ...bundle, quantity: quantity, productLines: newProductLines });
    }
  }

  function moreClickHandler(productLine: ExOrderProductLineModel) {
    setSelectedProductLine(productLine);
    setShowEditDialog(true);
  }

  function updateSelectedProductLine(updated: ExOrderProductLineModel) {
    onSetBundle({ ...bundle, productLines: bundle.productLines.map((item) => (item === selectedProductLine ? updated : item)) });
  }

  function deleteSelectedProductLine() {
    const updatedProductLines = bundle.productLines.filter((item) => item !== selectedProductLine);
    if (updatedProductLines.length === 0) {
      onRemoveBundle();
    } else {
      onSetBundle({ ...bundle, productLines: updatedProductLines });
    }
  }

  return (
    <>
      {selectedProductLine && showEditDialog ? (
        <EditProductLineDialog productLine={selectedProductLine} onUpdate={(productLine) => updateSelectedProductLine(productLine)} onDelete={() => deleteSelectedProductLine()} onClose={() => setShowEditDialog(false)} />
      ) : null}
      <div className="flex flex-col w-full">
        <div className="flex font-bold my-1">
          <button className="w-8 text-center" onClick={() => onSetBundle({ ...bundle, expanded: !bundle.expanded })}>
            {bundle.expanded ? "-" : "+"}
          </button>
          <p className="flex mr-8">{bundle.name}</p>
          <input className="w-12 rounded text-black text-center px-1" min={0} type="number" value={bundle.quantity} onChange={(e) => changeBundleQuantity(parseInt(e.currentTarget.value))} />
        </div>
        {bundle.expanded ? bundle.productLines.map((productLine, i) => <ProductLine key={i} product={productLine} edit onMoreClicked={() => moreClickHandler(productLine)} />) : null}
        <div className="mx-8 border-b-2 mb-1"></div>
      </div>
    </>
  );
}

export function BundleLines({ bundles, onSetBundles }: { bundles: ExOrderBundleLineModel[]; onSetBundles: (bundles: ExOrderBundleLineModel[]) => void }) {
  return (
    <div className="flex flex-col w-full">
      {bundles.map((bundle, i) => (
        <BundleLine key={i} bundle={bundle} onSetBundle={(updated) => onSetBundles(bundles.map((item) => (item === bundle ? updated : item)))} onRemoveBundle={() => onSetBundles(bundles.filter((item) => item !== bundle))} />
      ))}
    </div>
  );
}
