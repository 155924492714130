import { ButtonBar, ButtonBarModel } from "../buttons/ButtonBar";
import { ModalDialog } from "./ModalDialog";

export const SimpleFormDialog = ({ title, children, buttons, onButtonClicked, onCloseClicked }: { title?: string; children: JSX.Element; buttons: ButtonBarModel; onButtonClicked: (text: string) => void; onCloseClicked: () => void }) => {
  return (
    <ModalDialog flex visible title={title} onClose={onCloseClicked}>
      <div>
        <div>{children}</div>
        <ButtonBar buttons={buttons} onButtonClicked={onButtonClicked} />
      </div>
    </ModalDialog>
  );
};
