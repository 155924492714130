import { FieldModel } from "../models/ui/FieldModel";

export const OrderFormFields: {
  customer: {
    header: FieldModel;
    purchaser: {
      header: FieldModel;
      fields: FieldModel[];
    };
    delivery: {
      header: FieldModel;
      fields: FieldModel[];
    };
  };
  order: {
    header: FieldModel;
    fields: FieldModel[];
  };
  products: {
    header: FieldModel;
    fields: FieldModel[];
  };
} = {
  customer: {
    header: { text: "Kunde", dataField: "" },
    purchaser: {
      header: { text: "Oppdragsgiver", dataField: "" },
      fields: [
        { text: "Orgnr*", description: "", dataField: "orgnr" },
        { text: "Navn 1*", description: "", dataField: "name1" },
        { text: "Navn 2", description: "", dataField: "name2" },
        { text: "Adresse*", description: "", dataField: "address1" },
        { text: "Postnr*", description: "", dataField: "zipcode" },
        { text: "Poststed*", description: "", dataField: "city" },
      ],
    },
    delivery: {
      header: { text: "Varemottaker", dataField: "delivery" },
      fields: [
        { text: "Navn 1*", description: "", dataField: "name" },
        { text: "Navn 2", description: "", dataField: "name2" },
        { text: "CO adresse", description: "", dataField: "address" },
        { text: "Gateadresse*", description: "", dataField: "address2" },
        { text: "Postnr*", description: "", dataField: "zipcode" },
        { text: "Poststed*", description: "", dataField: "city" },
        { text: "Land*", description: "", dataField: "country" },
        { text: "Kontakt*", description: "", dataField: "contact" },
        { text: "Mobil*", description: "", dataField: "mobile" },
        { text: "E-post*", description: "", dataField: "email" },
      ],
    },
  },
  order: {
    header: { text: "Ordrer", dataField: "" },
    fields: [
      {
        text: "Leveringsdato",
        description: "something",
        type: "datebox",
        dataField: "deliveryDate",
      },
      {
        text: "Kunde referanse",
        description: "",
        type: "editbox",
        dataField: "reference",
      },
      {
        text: "Kunde rekvisisjon",
        description: "",
        type: "editbox",
        dataField: "requisition",
      },
      {
        text: "Casenr/Bestillingsnr",
        description: "",
        type: "editbox",
        dataField: "caseNumber",
      },
      {
        text: "SapNr",
        description: "",
        type: "editbox",
        dataField: "sapNumber",
      },
      {
        text: "Sporingsnummer",
        description: "",
        type: "editbox",
        dataField: "tracking",
      },
      {
        text: "PoNr",
        description: "",
        type: "editbox",
        dataField: "poNumbers",
      },
      {
        text: "Samlefaktura",
        description: "",
        type: "checkbox",
        dataField: "invoice",
      },
      {
        text: "Ordrebekreftelse",
        description: "",
        type: "checkbox",
        dataField: "orderConfirm",
      },
      {
        text: "Leverings sperre",
        description: "",
        type: "checkbox",
        dataField: "orderOnHold",
      },
      {
        text: "Faktura sperre",
        description: "",
        type: "checkbox",
        dataField: "invoiceHold",
      },
      {
        text: "Oppdatering til kunde",
        description: "",
        type: "checkbox",
        dataField: "customerUpdate",
      },
      {
        text: "Komplett levering",
        description: "",
        type: "checkbox",
        dataField: "complete",
      },
      {
        text: "Intern kommentar",
        description: "",
        type: "textbox",
        dataField: "comment",
        dataSubfield: "internal"
      },
      {
        text: "Leverandør kommentar",
        description: "",
        type: "textbox",
        dataField: "comment",
        dataSubfield: "lev"
      },
      {
        text: "Selger",
        description: "",
        type: "selectbox",
        dataField: "user_id",
      },
    ],
  },
  products: {
    header: { text: "Produkter", dataField: "" },
    fields: [
      {
        text: "ProduktNr",
        description: "",
        dataField: "number",
      },
      {
        text: "EAN",
        description: "",
        dataField: "ean",
      },
      {
        text: "Beskrivelse",
        description: "",
        dataField: "description",
      },
      {
        text: "Lvnavn",
        description: "",
        dataField: "levname",
      },
      {
        text: "LevAlt",
        description: "",
        dataField: "levalt",
      },
      {
        text: "BID",
        description: "",
        dataField: "bid",
      },
      {
        text: "Moms",
        description: "",
        dataField: "vat",
      },
      {
        text: "Antall",
        description: "",
        dataField: "quantity",
      },
      {
        text: "Kalk",
        description: "",
        dataField: "margin",
      },
      {
        text: "Kostpris",
        description: "",
        dataField: "price",
      },
      {
        text: "Utpris",
        description: "",
        dataField: "outPrice",
      },
      {
        text: "Total Pris",
        description: "",
        dataField: "",
      },
    ],
  },
};
