import { ExOrderProductLineMapper, ExOrderProductLineModel, ExProductBundleModel } from ".";

export interface ExOrderBundleLineModel extends ExProductBundleModel {
  expanded: boolean;
  quantity: number;
  productLines: ExOrderProductLineModel[];
}

export const ExOrderBundleLineMapper = {
  fromProductBundle: (pb: ExProductBundleModel) => {
    return { ...pb, expanded: false, quantity: 1, productLines: pb.products.map((item) => ExOrderProductLineMapper.fromProduct(item)) } as ExOrderBundleLineModel;
  },
};
