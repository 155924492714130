import { ReactNode, createContext, useState, useEffect, useContext } from "react";
import { LoginModel, LoginModelMapper } from "../models/login/LoginModel";
import { updateUser, fetchAllUsers } from "../backends/Users";
import AppContext from "./AppContext";

export const UserManagementContext = createContext<{
  state: {
    status: string;
    message: string;
  };
  allUsers: LoginModel[];
  getUser: (id: string) => LoginModel;
  refreshUsers: () => void;
  editUser: (user: LoginModel) => void;
  deleteUser: (uid: string) => void;
}>({
  state: {
    status: "",
    message: "",
  },
  allUsers: [],
  getUser: (id: string) => {
    return LoginModelMapper.createEmptyObject();
  },
  refreshUsers: () => {},
  editUser: (user: LoginModel) => {},
  deleteUser: (uid: string) => {},
});

export const UserManagementContextComponent = ({ children }: { children: ReactNode }) => {
  const appContext = useContext(AppContext);

  const [state, setState] = useState<{
    status: string;
    message: string;
  }>({
    status: "",
    message: "",
  });
  const [allUsers, setAllUsers] = useState<LoginModel[]>([]);

  const uid = appContext.login?.uid || "";

  useEffect(() => {
    setState({ status: "processing", message: "" });
    fetchAllUsers(uid)
      .then((fetchedUsers) => {
        setAllUsers(fetchedUsers);
        setState({ status: "ready", message: "" });
      })
      .catch((err) => {
        setState({ status: "error", message: err?.message || "Kunne ikke hente brukere" });
      });
  }, []);

  const initValue = {
    state,
    allUsers,
    getUser: (id: string) => {
      return allUsers.find((item) => item.id === id) || LoginModelMapper.createEmptyObject();
    },
    refreshUsers: async () => {
      try {
        setState({ status: "processing", message: "" });
        const users = await fetchAllUsers(uid);
        setAllUsers(users);
        setState({ status: "ready", message: "" });
      } catch (err: any) {
        setState({ status: "error", message: err?.message || "Kunne ikke hente brukere" });
      }
    },
    editUser: async (user: LoginModel) => {
      try {
        setState({ status: "processing", message: "" });
        await updateUser(user.id, user, uid);
        const users = await fetchAllUsers(uid);
        setAllUsers(users);
        setState({ status: "ready", message: "" });
      } catch (err: any) {
        setState({ status: "error", message: err?.message || "Kunne ikke endre brukeren" });
      }
    },
    deleteUser: async (uid: string) => {
      try {
        setState({ status: "processing", message: "" });
        // await editUser(user, auth0.user?.sub);
        const users = await fetchAllUsers(uid);
        setAllUsers(users);
        setState({ status: "ready", message: "" });
      } catch (err: any) {
        setState({ status: "error", message: err?.message || "Kunne ikke endre brukeren" });
      }
    },
  };

  return <UserManagementContext.Provider value={initValue}>{children}</UserManagementContext.Provider>;
};
