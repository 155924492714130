const ModalDialog = ({
  flex,
  title,
  children,
  visible,
  onClose,
}: {
  flex?: boolean;
  title?: string;
  children?: JSX.Element;
  visible: boolean;
  onClose: Function;
}) => {
  return (
    <div
      className={`${
        visible ? "flex" : "hidden"
      } modal fixed z-50 top-0 left-0 w-full h-full overflow-x-hidden bg-gray-200 bg-opacity-50 p-8`}
    >
      <div className={`flex m-auto ${flex ? "": "h-full w-full" }`}>
        <div className="flex flex-col m-auto igray px-4 py-2 rounded-lg w-full h-full overflow-y-auto ">
          <div className="flex border-b-2 mr-0 py-2">
            {title && <h2 className="text-white text-lg font-bold my-auto">{title}</h2>}
            <button
              className="ml-auto"
              onClick={() => {
                onClose();
              }}
            >
              <img className="h-8 w-8" src="/assets/images/close.svg" alt="close" />
            </button>
          </div>
          <div className="m-4 z">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalDialog;
