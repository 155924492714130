import { ModelMapper } from "../ModelMapper";

export interface OrderCommentModel {
    general: string;
    internal: string;
    lev: string;
}

export const OrderCommentModelMapper: ModelMapper<OrderCommentModel> = {
    createEmptyObject: () => {
      return {
        general: "",
        internal: "",
        lev: ""
      };
    },
  
    fromBackendObject: (backendObject: any) => {
      return {...backendObject };
    },
  
    toBackendObject: (model: OrderCommentModel) => {
      return model;
    },
  };