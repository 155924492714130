import { FieldModel } from "../../../models/ui/FieldModel";

export const TextboxField = ({
    field,
    value,
    onTextChanged,
    editable,
  }: {
    field: FieldModel;
    value: string;
    onTextChanged: (text: string) => void;
    editable?: boolean;
  }) => {
    return (
      <div className="">
        <label>{field.text}</label>
        <textarea
          className={`rounded-md h-10 w-full px-2 border-2 ${editable ? "text-black" : "bg-transparent text-white"}`}
          value={value}
          onChange={(e) => {
            onTextChanged(e.target.value);
          }}
        />
      </div>
    );
  };