import { CollapsibleCard } from "./components/common/CollapsibleCard";
import { CustomerPanel, OrderPanel, ProductListPanel } from "./components";
import { ExOrderModel } from "./models";
import { getOrderedSuppliers } from "./utils/ExOrderHelper";
import { useState } from "react";

export function ExOrderCreated({ message, onBack }: { message: string; onBack: () => void }) {
  return (
    <div className="flex flex-col m-auto gap-2">
      <p>{message}</p>
      <button
        className="ibutton rounded-md px-2 py-1"
        onClick={() => {
          console.log("CLICK");
          onBack();
        }}
      >
        Tilbake
      </button>
    </div>
  );
}

export function ExLayout({ order, onSetOrder, onCreateOrder }: { order: ExOrderModel; onSetOrder: (newOrder: ExOrderModel) => void; onCreateOrder: () => void }) {
  const [isEmailVisible, setIsEmailVisible] = useState<boolean>(true);

  function fixPoNrsAndSetOrder(order: ExOrderModel) {
    const ponrs = order.order.ponr;
    const orderedSuppliers = getOrderedSuppliers(order);
    const updatedPoNrs = orderedSuppliers.map((item) => ({ supplier: item, ponr: ponrs.find((ponr) => ponr.supplier.id === item.id)?.ponr || "" }));
    onSetOrder({ ...order, order: { ...order.order, ponr: updatedPoNrs } });
  }

  return (
    <>
      <div className="flex">
        <div className="flex flex-1 flex-col gap-2 p-2">
          <CollapsibleCard title="Kunde">
            <CustomerPanel order={order} onSetOrder={onSetOrder} />
          </CollapsibleCard>
          <CollapsibleCard title="Ordre">
            <OrderPanel order={order} onSetOrder={onSetOrder} />
          </CollapsibleCard>
          <CollapsibleCard title="Produkter">
            <ProductListPanel order={order} onSetOrder={fixPoNrsAndSetOrder} />
          </CollapsibleCard>
          <button className="ml-auto mr-4" onClick={onCreateOrder}>
            CREATE ORDER
          </button>
        </div>
        <div className={`flex ${isEmailVisible ? "w-1/5" : ""}`}>
          <button className="" onClick={() => setIsEmailVisible(!isEmailVisible)}>
            <img className="h-6 w-6" src={isEmailVisible ? "/assets/images/ex/arrow-right.svg" : "/assets/images/ex/arrow-left.svg"} alt="show/hide" />
          </button>
          {isEmailVisible ? <textarea className="flex flex-1 w-full m-2 ml-0 rounded text-black" /> : null}
        </div>
      </div>
    </>
  );
}
