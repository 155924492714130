import { ManagerModel } from "../models/managers/ManagerModel";
import { EmailAlertModel, EmailAlertModelMapper } from "../models/email/EmailAlertModel";

export const EmailAlertsManager: (
  emailAlerts: EmailAlertModel[],
  setEmailAlerts: (items: EmailAlertModel[]) => void
) => ManagerModel<EmailAlertModel> = (emailAlerts, setEmailAlerts) => {
  return {
    state: {
      status: "ready",
      message: "",
    },
    data: emailAlerts,
    refresh: async () => {},
    new: () => {
      return EmailAlertModelMapper.createEmptyObject();
    },
    create: async (emailAlert: EmailAlertModel) => {
      setEmailAlerts([...emailAlerts, {...emailAlert, id: `${emailAlerts.length + 1}`}]);
    },
    read: async (id: any) => {
      const email = emailAlerts.find((item) => item.id === id);
      return email;
    },
    update: async (id: any, email: EmailAlertModel) => {
      setEmailAlerts([...emailAlerts.map((item) => (item.id === id ? email : item))]);
    },
    delete: async (id: any) => {
      setEmailAlerts(emailAlerts.filter((item) => item.id !== id));
    },
  };
};
