export const SelectboxField = ({ label, description, value, disabled, items, onSetValue }: { label: string; description?: string; value: string; disabled?: boolean; items: { name: string; value: string }[]; onSetValue: (value: string) => void }) => {
  return (
    <div className="flex h-7 items-center">
      <label title={description}>{label}</label>
      <select
        className={`ml-auto h-full w-3/5 rounded border-2 ${!disabled ? "text-black" : "bg-transparent text-white"}`}
        disabled={disabled}
        value={value}
        onChange={(e) => {
          onSetValue(e.target.value);
        }}
      >
        {items.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
