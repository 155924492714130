import { ModelMapper } from "./ModelMapper";
import { SklOrderStatusModel, SklOrderStatusModelMapper } from "./SklOrderStatusModel";
import { Step1Model, Step1ModelMapper } from "./Step1Model";
import { Step2Model, Step2ModelMapper } from "./Step2Model";

export interface SklOrderModel {
  id: string;
  hash: string;
  step1: Step1Model;
  step2: Step2Model;
  status: SklOrderStatusModel;
}

export const SklOrderModelMapper: ModelMapper<SklOrderModel> = {
  newModel: () => {
    return {
      id: "",
      hash: "",
      step1: Step1ModelMapper.newModel(),
      step2: Step2ModelMapper.newModel(),
      status: SklOrderStatusModelMapper.newModel(),
    };
  },

  mapToModel: (backendObject) => {
    return {
      ...SklOrderModelMapper.newModel(),
      id: backendObject.id,
      hash: backendObject.hash,
      step1: Step1ModelMapper.mapToModel(backendObject?.step1),
      step2: Step2ModelMapper.mapToModel(backendObject?.step2),
      status: SklOrderStatusModelMapper.mapToModel(backendObject?.status),
    };
  },
};
