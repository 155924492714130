import { useContext, useState } from "react";
import { EmailManagementContext } from "../../contexts/EmailManagementContext";
import { EditboxField } from "../fields/user/EditboxField";
import { SimpleFormDialog } from "./SimpleFormDialog";

export const CreateEmailTemplateDialog = ({ onClose }: { onClose: () => void }) => {
  const emailManagement = useContext(EmailManagementContext);
  const [template, setTemplate] = useState<{ name: string; description: string }>({ name: "", description: "" });

  return (
    <SimpleFormDialog
      title="Legg til mal"
      buttons={[
        { text: "Lagre", color: "tp-button-confirm", action: "save", default: true },
        { text: "Avbryt", color: "tp-button-cancel", action: "cancel" },
      ]}
      onButtonClicked={(action: string) => {
        if (action === "save") {
          onClose();
          emailManagement.createTemplate(template);
        } else if (action === "cancel") {
          onClose();
        }
      }}
      onCloseClicked={onClose}
    >
      <div className="flex flex-col w-96">
        <EditboxField
          focus
          mode="edit"
          label="Navn"
          labelClass="my-auto w-40"
          text={template.name}
          onTextChanged={(text) => {
            setTemplate({ ...template, name: text });
          }}
        />
        <EditboxField
          mode="edit"
          label="Beskrivelse"
          labelClass="my-auto w-40"
          text={template.description}
          onTextChanged={(text) => {
            setTemplate({ ...template, description: text });
          }}
        />
      </div>
    </SimpleFormDialog>
  );
};
