import { ReactNode, createContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { EmailTemplateModel } from "../models/email/EmailTemplateModel";
import { fetchAllEmailTemplates, fetchEmailTemplate, createEmailTemplate, updateEmailTemplate, deleteEmailTemplate } from "../backends/Emails";

export const EmailManagementContext = createContext<{
  state: {
    status: string;
    message: string;
  };
  allTemplates: EmailTemplateModel[];
  refreshTemplates: () => void;
  createTemplate: (template: { name: string, description: string}) => void;
  getTemplate: (templateName: string) => Promise<EmailTemplateModel | null>;
  editTemplate: (template: EmailTemplateModel) => void;
  deleteTemplate: (templateName: string) => void;
}>({
  state: {
    status: "",
    message: "",
  },
  allTemplates: [],
  refreshTemplates: () => {},
  createTemplate: (template: { name: string, description: string}) => { },
  getTemplate: (templateName: string) => { return Promise.resolve(null); },
  editTemplate: (template: EmailTemplateModel) => {},
  deleteTemplate: (templateName: string) => {}
});

export const EmailManagementContextComponent = ({ children }: { children: ReactNode }) => {
  const auth0 = useAuth0();

  const [state, setState] = useState<{
    status: string;
    message: string;
  }>({
    status: "",
    message: "",
  });
  const [allTemplates, setAllTemplates] = useState<EmailTemplateModel[]>([]);

  const initValue = {
    state,
    allTemplates,
    refreshTemplates: async () => {
      try {
        setState({ status: "processing", message: "" });
        const templates = await fetchAllEmailTemplates(auth0.user?.sub);
        setAllTemplates(templates);
        setState({ status: "ready", message: "" });
      } catch (err: any) {
        setState({ status: "error", message: err?.message || "Kunne ikke hente maler" });
      }
    },
    createTemplate: async (template: { name: string, description: string}) => {
        try {
          setState({ status: "processing", message: "" });
          await createEmailTemplate(template, auth0.user?.sub);
          const templates = await fetchAllEmailTemplates(auth0.user?.sub);
          setAllTemplates(templates);
          setState({ status: "ready", message: "" });
          return template;
        } catch (err: any) {
          setState({ status: "error", message: err?.message || "Kunne ikke endre malen" });
          return null;
        }
      },
    getTemplate: async (templateName: string) => {
        try {
          setState({ status: "processing", message: "" });
          const template = await fetchEmailTemplate(templateName, auth0.user?.sub);
          setState({ status: "ready", message: "" });
          return template;
        } catch (err: any) {
          setState({ status: "error", message: err?.message || "Kunne ikke endre malen" });
          return null;
        }
      },
    editTemplate: async (template: EmailTemplateModel) => {
      try {
        setState({ status: "processing", message: "" });
        await updateEmailTemplate(template, auth0.user?.sub);
        const templates = await fetchAllEmailTemplates(auth0.user?.sub);
        setAllTemplates(templates);
        setState({ status: "ready", message: "" });
      } catch (err: any) {
        setState({ status: "error", message: err?.message || "Kunne ikke endre malen" });
      }
    },
    deleteTemplate: async (templateName: string) => {
      try {
        setState({ status: "processing", message: "" });
        await deleteEmailTemplate(templateName, auth0.user?.sub);
        const templates = await fetchAllEmailTemplates(auth0.user?.sub);
        setAllTemplates(templates);
        setState({ status: "ready", message: "" });
      } catch (err: any) {
        setState({ status: "error", message: err?.message || "Kunne ikke endre brukeren" });
      }
    },
  };

  return <EmailManagementContext.Provider value={initValue}>{children}</EmailManagementContext.Provider>;
};
