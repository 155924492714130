import backends from ".";
import { TELENOR_API_URL } from "../env";
import { CustomerModelMapper } from "../models/CustomerModel";
import { ProjectModel, ProjectModelMapper } from "../models/project/ProjectModel";

const apiUrl = TELENOR_API_URL;

export const fetchAllProjects: (userId?: string) => Promise<ProjectModel[]> = async (userId?: string) => {
  const url = `${apiUrl}/projects`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const projects = await res.json();
  return projects.map((item: any) => ProjectModelMapper.fromBackendObject(item));
};

export const fetchProject: (projectId: string, userId?: string) => Promise<ProjectModel> = async (projectId: string, userId?: string) => {
    const url = `${apiUrl}/projects/${projectId}?mode=3`;
    const headers = new Headers();
    if (userId) {
      headers.append("Auth0-User", userId);
    }
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    const fetchedProject = await res.json();
    const project = ProjectModelMapper.fromBackendObject(fetchedProject);
    const fetchedCustomer = await backends.customers.fetch(project.customer.id, userId);
    project.customer = CustomerModelMapper.fromBackendObject(fetchedCustomer);
    return project;
  };

  export const createProject = async (project: ProjectModel, userId?: string) => {
    const url = `${apiUrl}/projects`;
    const headers = new Headers();
    if (userId) {
      headers.append("Auth0-User", userId);
    }
    const res = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(ProjectModelMapper.toBackendObject(project)),
    });
    if (res.status === 200) {
      const body = await res.json();
      return body.orderId;
    }
    return false;
  };

  export const updateProject = async (project: ProjectModel, userId?: string) => {
    const url = `${apiUrl}/projects/${project.id}`;
    const headers = new Headers();
    if (userId) {
      headers.append("Auth0-User", userId);
    }
  
    const res = await fetch(url, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(ProjectModelMapper.toBackendObject(project))
    });
    return res.status === 200;
  };

  export const deleteProject = async (projectId: string, userId?: string) => {
    const url = `${apiUrl}/projects/${projectId}`;
    const headers = new Headers();
    if (userId) {
      headers.append("Auth0-User", userId);
    }
    try {
      const res = await fetch(url, {
        method: "DELETE",
        headers: headers,
      });
      if (res.status === 200) {
        return true;
      }
      return false;
    } catch (ex) {
      return false;
    }
  };