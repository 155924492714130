import { TELENOR_API_URL } from "../../../env";

const apiUrl = TELENOR_API_URL;

export type Api = {
  fetchAll: (userId: string) => Promise<any>;
  fetch: (userId: string, itemId: string) => Promise<any>;
  create: (userId: string, item: any) => Promise<any>;
  update: (userId: string, itemId: string, item: any) => Promise<any>;
  delete: (userId: string, itemId: string) => Promise<any>;
};

export function getApi(apiName: string): Api {
  const url = `${apiUrl}/${apiName}`;
  
  return {
    fetchAll: async (userId) => {
      const headers = new Headers();
      headers.append("Auth0-User", userId);
      const res = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const data = await res.json();
      return data;
    },

    fetch: async (userId, itemId) => {
      const headers = new Headers();
      headers.append("Auth0-User", userId);
      const res = await fetch(`${url}/${itemId}`, {
        method: "GET",
        headers: headers,
      });
      const data = await res.json();
      return data;
    },

    create: async (userId, item) => {
      const headers = new Headers();
      headers.append("Auth0-User", userId);
      const res = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(item),
      });
      const data = await res.json();
      return data;
    },

    update: async (userId, itemId, item) => {
      const headers = new Headers();
      headers.append("Auth0-User", userId);
      const res = await fetch(`${url}/${itemId}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(item),
      });
      const data = await res.json();
      return data;
    },

    delete: async (userId, itemId) => {
      const headers = new Headers();
      headers.append("Auth0-User", userId);
      const res = await fetch(`${url}/${itemId}`, {
        method: "DELETE",
        headers: headers,
      });
      const data = await res.json();
      return data;
    },
  };
}
