import ModalDialog from "../../components/common/ModalDialog";
import { Schema, SchemaField } from "../SchemaMapper";

export function ExportDialog<T>({ schema, setSchema, onSave, onClose }: { schema: Schema<T>; setSchema: (schema: Schema<T>) => void; onSave: () => void; onClose: () => void }) {
  const sets: { title: string; fields: SchemaField<T>[] }[] | undefined = schema.sets?.map((setTitle, setIndex) => ({ title: setTitle, fields: schema.fields.filter((field) => field.set === setIndex) }));
  const rows: { sets: { title: string; fields: SchemaField<T>[] }[] }[] | undefined = sets && schema.layout?.map((layoutRow) => ({ sets: sets?.filter((set, index) => layoutRow.sets.includes(index)) }));

  function renderSet(title: string, fields: SchemaField<T>[]) {
    return (
      <div className="flex flex-col">
        {title && (
          <label>
            <input type="checkbox" checked={fields.every((field) => field.export)} onChange={(e) => setSchema({ ...schema, fields: schema.fields.map((item) => (fields.includes(item) ? { ...item, export: e.currentTarget.checked } : item)) })} />
            <span className="ml-1">{title}</span>
          </label>
        )}
        <div className="border-2 rounded-md p-2">
          <div className="flex flex-col">
            <ul className="">
              {fields.map((field) => (
                <li>
                  <label>
                    <input type="checkbox" checked={field.export} onChange={(e) => setSchema({ ...schema, fields: schema.fields.map((item) => (item === field ? { ...item, export: e.currentTarget.checked } : item)) })} />
                    <span className="ml-1">{field.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ModalDialog title="Eksport to CSV..." onClose={onClose} visible flex>
      <div className="flex flex-col gap-4 mt-4">
        {rows && rows.map((row) => <div className="flex gap-4">{row.sets.map((set) => renderSet(set.title, set.fields))}</div>)}
        {/* {!sets && <div className="flex">{renderSet("", schema.fields)}</div>}
        {sets &&
          sets.map((set) => (
            <div className="flex flex-col">
              <p>{set.title}</p>
              <div className="border-2">renderSet(set.fields)</div>
            </div>
          ))} */}
        <button className="mt-4 ml-auto py-1 px-2 bg-black rounded-md" onClick={onSave}>
          Eksport
        </button>
      </div>
    </ModalDialog>
  );
}
