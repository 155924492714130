import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

var modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    ["link", "image"],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};

export const HtmlEditor = ({
  html,
  setHtml,
  readonly
}: {
  html: string;
  setHtml: (newHtml: string) => void;
  readonly?: boolean
}) => {
  return (
    <div className="bg-white text-black " >
      <ReactQuill
        readOnly={readonly}
        className={readonly ? `bg-gray-300 select-none min-h-[512px]` : "min-h-[512px]"}
        theme="snow"
        value={html}
        onChange={(e) => {
          setHtml(e);
        }}
        modules={modules}
      />
    </div>
  );
};
