import { useContext } from "react";
import { AppConstantsContext } from "../../../../contexts/AppConstantsContext";
import { ManagerModel } from "../../../../models/managers/ManagerModel";
import { ProjectInfoFieldModel } from "../../../../models/project/ProjectInfoFieldModel";
import { DataList, DataListColumn } from "../../../common/DataList";
import { TextField } from "../../../common/TextField";
import { SelectboxField } from "../../../fields/product/SelectboxField";

const infoFieldsTableColumns: (informationReferences: {[key: string]: string}) => DataListColumn<ProjectInfoFieldModel>[] = (informationReferences) => {
  return [
    {
      name: "Navn",
      selector: (row: ProjectInfoFieldModel) => row.name,
      sortable: true,
    },
    {
      name: "Beskrivelse",
      selector: (row: ProjectInfoFieldModel) => row.description,
      sortable: true,
    },
    {
      name: "Standardverdi",
      selector: (row: ProjectInfoFieldModel) => row.defaultValue,
      sortable: true,
    },
    {
      name: "Referanse",
      selector: (row: ProjectInfoFieldModel) => Object.keys(informationReferences).find(key => informationReferences[key] === row.reference) || "",
      sortable: true,
    },
  ];
};

const EditInfoField = ({ item, setItem, readonly }: { item: ProjectInfoFieldModel; setItem: (infoField: ProjectInfoFieldModel) => void; readonly: boolean }) => {
  const constants = useContext(AppConstantsContext);
  const informationReferences = constants.informationReferences;

  return (
    <div className="w-96">
      <TextField readonly={readonly} label="Navn" text={item.name} onTextChanged={(text) => setItem({ ...item, name: text })} />
      <TextField readonly={readonly} label="Beskrivelse" multiline text={item.description} onTextChanged={(text) => setItem({ ...item, description: text })} />
      <TextField readonly={readonly} label="Standardverdi" text={item.defaultValue} onTextChanged={(text) => setItem({ ...item, defaultValue: text })} />
      <div className="flex m-1">
        <p className="w-1/3 my-auto">Referanse</p>
        <div className="w-2/3">
          <SelectboxField
            disabled={readonly}
            items={Object.keys(informationReferences).map((key) => ({ label: key, value: informationReferences[key] }))}
            onValueChanged={(value) => {
              setItem({ ...item, reference: value });
            }}
            value={item.reference}
          />
        </div>
      </div>
    </div>
  );
};

export const InfoFieldsPanel = ({ dataManager }: { dataManager: ManagerModel<ProjectInfoFieldModel> }) => {
  const constants = useContext(AppConstantsContext);
  const informationReferences = constants.informationReferences;

  return (
    <div>
      <div className="flex mx-12">
        <DataList<ProjectInfoFieldModel> dataManager={dataManager} columns={infoFieldsTableColumns(informationReferences)} EditItemComponent={EditInfoField} />
      </div>
    </div>
  );
};
