import { useState } from "react";
import { FlowModel, FlowNodeTypeModel, FlowStepModel } from "../../models";
import { FlowSteps } from "./FlowSteps";
import { FlowStepDetails } from "./FlowStepDetails";

export function FlowDetails({ flow, nodeTypes, onUpdateFlow }: { flow: FlowModel; nodeTypes: FlowNodeTypeModel[]; onUpdateFlow: (flow: FlowModel) => void }) {
  const [activeFlow, setActiveFlow] = useState<FlowModel>(flow);
  const [activeStep, setActiveStep] = useState<FlowStepModel | null>(null);
  
  return (
    <div className="flex flex-1 py-4 gap-4">
      {activeFlow.steps ? (
        <div className="flex flex-col w-1/6 gap-8">
          <FlowSteps steps={activeFlow.steps} setSteps={(steps) => setActiveFlow({ ...activeFlow, steps: steps })} activeStep={activeStep} setActiveStep={setActiveStep} />
          <button className="mt-auto w-full rounded-md border-2 p-2" onClick={() => onUpdateFlow(activeFlow)}>
            Save
          </button>
        </div>
      ) : null}
      {activeStep ? (
        <FlowStepDetails
          flow={activeFlow}
          nodeTypes={nodeTypes}
          step={activeStep}
          onSetStep={(updatedStep) => {
            setActiveFlow({
              ...activeFlow,
              steps: activeFlow.steps?.map((item) => (item === activeStep ? updatedStep : item)),
            });
            setActiveStep(updatedStep);
          }}
          onAction={(action, data) => {
            if (action === "back") {
              setActiveStep(null);
            } else if (action === "select-step") {
              setActiveStep(data as FlowStepModel);
            }
          }}
        />
      ) : null}
    </div>
  );
}
