export interface ExOrderInfoPoNrModel {
  supplier: { id: string; name: string };
  ponr: string;
}

export interface ExOrderInfoModel {
  deliveryDate: string;
  reference: string;
  requisition: string;
  casenr: string;
  sapnr: string;
  tracking: string;
  ponr: ExOrderInfoPoNrModel[];
  invoice: boolean;
  confirm: boolean;
  hold: boolean;
  invoiceHold: boolean;
  customerupd: boolean;
  complete: boolean;
  comment_internal: string;
  comment_lev: string;
  seller: string;
}

export const ExOrderInfoMapper = {
  empty: () => {
    return {
      deliveryDate: "",
      invoice: false,
      reference: "",
      requisition: "",
      casenr: "",
      sapnr: "",
      tracking: "",
      ponr: [],
      confirm: false,
      hold: false,
      invoiceHold: false,
      customerupd: false,
      complete: false,
      comment_internal: "",
      comment_lev: "",
      seller: "",
    };
  },

  fromBackendObject: (orderBackendObject: any, commentBackendObject: any): ExOrderInfoModel => {
    return { ...ExOrderInfoMapper.empty(), ...orderBackendObject, comment_internal: commentBackendObject?.internal, comment_lev: commentBackendObject?.lev };
  },

  toBackendObjects: (order: ExOrderInfoModel) => {
    return {
      order: {
        ...order,
        ponr: undefined,
        invoice: order.invoice ? "1" : "0",
        confirm: order.confirm ? "1" : "0",
        hold: order.hold ? "1" : "0",
        invoiceHold: order.invoiceHold ? "1" : "0",
        customerupd: order.customerupd ? "1" : "0",
        complete: order.complete ? "1" : "0",
      },
      comment: { internal: order.comment_internal, lev: order.comment_lev },
    };
  },
};
