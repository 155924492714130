import { Autocomplete, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { FlowModel, FlowNodeTypeModel, FlowStepModel } from "../../../models";
import { FlowStepOutputMapping } from "./FlowStepOutputMapping";
import { FlowStepSettings } from "./FlowStepSettings";
import { FlowStepInterface } from "./FlowStepInterface";

type Panel = "in" | "out" | "mapping" | "settings";
const panels: { name: string; value: Panel; align?: "right" }[] = [
  { name: "Input", value: "in" },
  { name: "Output", value: "out" },
  { name: "Mapping", value: "mapping" },
  { name: "Settings", value: "settings", align: "right" },
];

export function FlowStepEditor({ flow, nodeTypes, step, onSetStep }: { flow: FlowModel; nodeTypes: FlowNodeTypeModel[]; step: FlowStepModel; onSetStep: (step: FlowStepModel) => void }) {
  const [visiblePanel, setVisiblePanel] = useState<Panel>("in");

  function setNodeType(nodeTypeName: string) {
    const nodeType = nodeTypes.find((nodeTypeItem) => nodeTypeItem.name === nodeTypeName);
    onSetStep({
      ...step,
      node_type: nodeTypeName,
      automated: nodeType?.automated || false,
      step_data: { in: nodeType?.in || [], out: nodeType?.out || [] },
      next_statuses: nodeType?.outcomes || [],
      settings: nodeType?.settings.map((item) => ({ id: item.id, value: "" })) || [],
    });
  }

  const stepSettings = nodeTypes.find((item) => item.name === step.node_type)?.settings?.map((ns) => ({ ...ns, value: step.settings.find((ss) => ss.id === ns.id)?.value || "" }));

  return (
    <div className="flex flex-1 gap-2">
      <div className="flex flex-col w-1/4 gap-4">
        <TextField label="Name" value={step.name} size="small" onChange={(e) => onSetStep({ ...step, name: e.target.value })} />
        <TextField label="Status ID" value={step.status} size="small" onChange={(e) => onSetStep({ ...step, status: e.target.value })} />
        <Autocomplete freeSolo options={flow.statuses.filter((status) => status)} value={step.status_group} renderInput={(params) => <TextField {...params} label="Status group" onChange={(e) => onSetStep({ ...step, status_group: e.target.value })} />} />
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Select label="Type" size="small" value={step.node_type} onChange={(e) => setNodeType(e.target.value)}>
            {nodeTypes.map((nodeType) => (
              <MenuItem key={nodeType.name} value={nodeType.name}>
                <div>
                  <h3 className="">{nodeType.name}</h3>
                  <p className="text-xs">{nodeType.description}</p>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel control={<Switch size="small" checked={step.first_step} onChange={(e) => onSetStep({ ...step, first_step: e.target.checked })} />} label="First step" />
        <TextField multiline rows={3} label="Routine" value={step.routine} size="small" onChange={(e) => onSetStep({ ...step, routine: e.target.value })} />
        {/* <div className="mt-auto">
          <NextSteps flow={flow} step={step} onStatusClick={(status) => {}} onStatusDelete={() => {}} />
        </div> */}
      </div>
      <div className="flex flex-col flex-1 border-l-2 pl-2 gap-4">
        <div className="flex gap-4 border-b-2">
          {panels.map((item) => (
            <button key={item.value} className={`px-4 py-1 rounded-t-md ${visiblePanel === item.value ? "font-bold text-white bg-black" : ""} ${item.align === "right" ? "ml-auto" : ""}`} onClick={() => setVisiblePanel(item.value)}>
              {item.name}
            </button>
          ))}
        </div>
        {visiblePanel === "in" ? (
          <FlowStepInterface locked={step.automated} fields={step.step_data.in} setFields={(fields) => onSetStep({ ...step, step_data: { ...step.step_data, in: fields } })} dataSources={flow.steps?.slice(0, flow.steps.indexOf(step))} />
        ) : visiblePanel === "out" ? (
          <FlowStepInterface locked={step.automated} fields={step.step_data.out} setFields={(fields) => onSetStep({ ...step, step_data: { ...step.step_data, out: fields } })} />
        ) : visiblePanel === "mapping" ? (
          <FlowStepOutputMapping key={step.status} steps={flow.steps} step={step} onSetNextStatuses={(statuses) => onSetStep({ ...step, next_statuses: statuses })} />
        ) : visiblePanel === "settings" ? (
          <FlowStepSettings settings={stepSettings} onSetSettings={(settings) => onSetStep({ ...step, settings: settings })} />
        ) : null}
      </div>
    </div>
  );
}
