import { useState } from "react";

export const DialogButtonBar = ({
  buttons,
  onClick,
}: {
  buttons: string /* editdelete, edit, delete */;
  onClick: (action: string /* edit, delete */, state: string /* started, confirmed, canceled */) => void;
}) => {
  const [mode, setMode] = useState("view");

  return (
    <div className="flex">
      {mode !== "view" && (
        <p className="ml-auto mr-4 my-auto text-white">
          {mode === "edit" ? "Lagre endringer?" : mode === "delete" ? "Er du sikker?" : ""}
        </p>
      )}
      {buttons !== "delete" && (
        <button
          className={`${mode !== "view" ? "ml-4" : "ml-0"} tp-button-confirm`}
          onClick={() => {
            if (mode === "view") {
              setMode("edit");
              onClick("edit", "started");
            } else if (mode === "edit") {
              setMode("view");
              onClick("edit", "confirmed");
            } else if (mode === "delete") {
              setMode("view");
              onClick("delete", "confirmed");
            }
          }}
        >
          {mode === "view" ? "Endre" : "Ja"}
        </button>
      )}
      {buttons !== "edit" && (
        <button
          className={`${mode !== "view" ? "ml-4" : "ml-auto"} tp-button-cancel`}
          onClick={() => {
            if (mode === "view") {
              setMode("delete");
              onClick("delete", "started");
            } else if (mode === "edit") {
              setMode("view");
              onClick("edit", "canceled");
            } else if (mode === "delete") {
              setMode("view");
              onClick("delete", "canceled");
            }
          }}
        >
          {mode === "view" ? "Slett" : "Nei"}
        </button>
      )}
    </div>
  );
};
