import dayjs from "dayjs";
import { FlowStepModel } from ".";

export type ExecutionState = "" | "completed" | "awaiting" | "processing" | "operator";

export interface FlowOrderExecutionStepModel {
  status: string;
  in: { [field: string]: any };
  out: { [field: string]: any };
  state: ExecutionState;
  error?: string;
  next_status: string;
  model: FlowStepModel;
}

export function FlowOrderExecutionStepCreator(): { empty: (model: FlowStepModel) => FlowOrderExecutionStepModel; fromBackendObject: (bo: any, model?: FlowStepModel) => FlowOrderExecutionStepModel } {
  function empty(model: FlowStepModel): FlowOrderExecutionStepModel {
    return {
      status: model?.status || "",
      in: {},
      out: {},
      state: "",
      next_status: "",
      model: model,
    };
  }

  function fromBackendObject(bo: any, model?: FlowStepModel): FlowOrderExecutionStepModel {
    if (!model) {
      return bo;
    }

    const orderExecutionStep = { ...bo, model: model } as FlowOrderExecutionStepModel;
    if (!orderExecutionStep.in) {
      orderExecutionStep.in = {};
    }
    for (const field of model.step_data.in) {
      if (!Object.keys(orderExecutionStep.in).includes(field.id)) {
        if (field.type === "string" || field.type === "raw data" || field.type === "select") {
          orderExecutionStep.in[field.id] = "";
        } else if (field.type === "number") {
          orderExecutionStep.in[field.id] = 0;
        } else if (field.type === "boolean") {
          orderExecutionStep.in[field.id] = false;
        } else if (field.type === "date") {
          orderExecutionStep.in[field.id] = null;
        }
      } else {
        if (field.type === "date") {
          orderExecutionStep.in[field.id] = orderExecutionStep.in[field.id] === null ? null : dayjs(orderExecutionStep.in[field.id]);
        }
      }
    }
    if (!orderExecutionStep.out) {
      orderExecutionStep.out = {};
    }
    for (const field of model.step_data.out) {
      if (!Object.keys(orderExecutionStep.out).includes(field.id)) {
        if (field.type === "string" || field.type === "raw data" || field.type === "select") {
          orderExecutionStep.out[field.id] = "";
        } else if (field.type === "number") {
          orderExecutionStep.out[field.id] = 0;
        } else if (field.type === "boolean") {
          orderExecutionStep.out[field.id] = false;
        } else if (field.type === "date") {
          orderExecutionStep.out[field.id] = null;
        }
      } else {
        if (field.type === "date") {
          orderExecutionStep.out[field.id] = orderExecutionStep.out[field.id] === null ? null : dayjs(orderExecutionStep.out[field.id]);
        }
      }
    }
    return orderExecutionStep;
  }

  return { empty, fromBackendObject };
}

export type FlowOrderDataModel = FlowOrderExecutionStepModel[];

export function FlowOrderDataCreator(): { fromBackendObject: (bo: any, orderSteps: FlowStepModel[]) => FlowOrderDataModel } {
  function fromBackendObject(bo: any, orderSteps: FlowStepModel[]): FlowOrderDataModel {
    // const orderData: FlowOrderDataModel = [];
    // for (const orderStep of orderSteps) {
    //   for (const dataItemKey of Object.keys(bo)) {
    //     if (dataItemKey === orderStep.status) {
    //       orderData.push({ ...bo[dataItemKey], state: bo[dataItemKey].status as ExecutionState, status: dataItemKey, model: orderStep });
    //     }
    //   }
    // }
    return bo
      ? bo.map((item: any) =>
          FlowOrderExecutionStepCreator().fromBackendObject(
            item,
            orderSteps.find((step) => item.status === step.status)
          )
        )
      : [];
  }

  return { fromBackendObject };
}
