import { useContext, useEffect, useState } from "react";
import { FlowCreator, FlowModel, FlowNodeTypeModel, FlowOrderInfoCreator, FlowOrderInfoModel, FlowStateModel } from "./models";
import { FlowDetails, FlowOrderAction, FlowStatusBar, FlowOrderList } from "./components";
import { ModalDialog } from "../common/components/dialogs/ModalDialog";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FlowBackend } from "./backends";
import { ModuleContext } from "../common/components";
import { OrderDetailsDialog } from "./components/OrderDetails";
import { MenuItem, Select } from "@mui/material";
import { Restricted } from "../common/components/Restricted";
import { MultipleOrdersDetailsDialog } from "./components/OrderDetails/MultipleOrdersDetailsDialog";
import { SearchField } from "../common/elements";

export function FlowPage() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const processor = useContext(ModuleContext);

  const [allStates, setAllStates] = useState<FlowStateModel[]>([]);
  const [allNodeTypes, setAllNodeTypes] = useState<FlowNodeTypeModel[]>([]);
  const [allFlows, setAllFlows] = useState<FlowModel[]>([]);
  const [activeFlow, setActiveFlow] = useState<FlowModel>();
  const [activeFlowOrders, setActiveFlowOrders] = useState<FlowOrderInfoModel[]>([]);
  const [activeOrder, setActiveOrder] = useState<FlowOrderInfoModel>();
  const [filteredOrders, setFilteredOrders] = useState<FlowOrderInfoModel[]>([]);

  const [dialog, setDialog] = useState<"" | "flow-details" | "order-details" | "all-order-details">("");

  const flowGroupId = params.id;

  useEffect(() => {
    (async () => {
      await loadAllStates();
      await loadAllNodeTypes();
      await loadAllFlows();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadActiveFlow();
      await loadActiveFlowOrders();
    })();
  }, [flowGroupId]);

  function filterOrders(orders: FlowOrderInfoModel[], searchText?: string) {
    const state = searchParams.get("state");
    if (state) {
      orders = orders.filter((order) => order.meta?.state?.value === state);
    }
    const step = searchParams.get("step");
    if (step) {
      orders = orders.filter((order) => order.meta?.status?.value === step);
    }
    if (searchText) {
      orders = orders.filter((order) => order.id.includes(searchText) || order.meta?.info?.find((item) => `${item.title}: ${item.value}`.toLowerCase().includes(searchText.toLowerCase())));
    }
    setFilteredOrders(orders);
  }

  useEffect(() => {
    filterOrders(activeFlowOrders);
  }, [searchParams]);

  async function loadAllStates() {
    const fetchedStates = await processor?.run(FlowBackend.getAllFlowStates);
    setAllStates(fetchedStates);
  }

  async function loadAllNodeTypes() {
    const fetchedNodeTypes = await processor?.run(FlowBackend.getAllNodeTypes);
    setAllNodeTypes(fetchedNodeTypes);
  }

  async function loadAllFlows() {
    const fetchedFlows = await processor?.run(FlowBackend.getAllFlows);
    setAllFlows(fetchedFlows);
  }

  async function loadActiveFlow() {
    const fetchedFlow = await processor?.run(FlowBackend.getFlow, flowGroupId);
    const flow = FlowCreator().fromBackendObject(fetchedFlow);
    setActiveFlow(flow);
  }

  async function updateActiveFlow(flow: FlowModel) {
    const updatedFlow = await processor?.run(FlowBackend.updateFlow, flow);
    setActiveFlow(FlowCreator().fromBackendObject(updatedFlow));
  }

  async function loadActiveFlowOrders() {
    const fetchedOrders = await processor?.run(FlowBackend.getAllOrderInfos, flowGroupId);
    setActiveFlowOrders(fetchedOrders);
    filterOrders(fetchedOrders);
  }

  async function claimOrder(orderId: string, unclaim?: boolean) {
    const claimed = unclaim ? await processor?.run(FlowBackend.unclaimOrder, orderId) : await processor?.run(FlowBackend.claimOrder, orderId);
    await loadActiveFlowOrders();
  }

  async function tagOrder(orderId: string, tags: any) {
    await processor?.run(FlowBackend.tagOrder, orderId, tags)
    await loadActiveFlowOrders();
  }

  async function orderActionHandler(action: FlowOrderAction, order?: FlowOrderInfoModel) {
    if (action === "add") {
      setActiveOrder(FlowOrderInfoCreator().empty(activeFlow));
      setDialog("order-details");
    } else if (action === "select") {
      // setActiveOrder(order);
    } else if (action === "details") {
      setActiveOrder(order);
      setDialog("order-details");
    } else if (action === "update") {
      // setActiveOrder(order);
    } else if (action === "claim" || action === "unclaim") {
      if (order) {
        await claimOrder(order.id, action === "unclaim");
      }
    } else if (action === "tag") {
      if(order) {
        await tagOrder(order.id, order.meta.tags)
      }
    }
  }

  return (
    <div className="flex flex-1 flex-col m-4 gap-4">
      {dialog === "flow-details" && activeFlow?.steps ? (
        <ModalDialog
          title={`Flow: ${activeFlow.name}`}
          visible
          onClose={() => {
            setDialog("");
          }}
        >
          <FlowDetails
            flow={activeFlow}
            nodeTypes={allNodeTypes}
            onUpdateFlow={async (flow) => {
              setDialog("");
              await updateActiveFlow(flow);
            }}
          />
        </ModalDialog>
      ) : null}
      {dialog === "order-details" && activeFlow && activeOrder ? (
        <OrderDetailsDialog
          orderId={activeOrder.id}
          onClose={() => {
            setDialog("");
            loadActiveFlowOrders();
          }}
        />
      ) : // <ModalDialog
      //   title={`Order #${activeOrder.id}`}
      //   visible
      //   onClose={() => {
      //     setDialog("");
      //     setActiveOrder(undefined);
      //   }}
      // >
      //   <ModuleContainer name="edit-order">
      //     <OrderDetails
      //       order={activeOrder}
      //       onUpdateOrder={async (updatedOrder, close) => {
      //         if (close) {
      //           setDialog("");
      //         }

      //         if (updatedOrder.id) {
      //           console.log("ORDER", updatedOrder)
      //           const result = await processor?.run(FlowBackend.updateOrder, updatedOrder);
      //           console.log("RESULT", result);
      //         } else {
      //           processor?.run(FlowBackend.createOrder, updatedOrder);
      //         }
      //       }}
      //     />
      //   </ModuleContainer>
      // </ModalDialog>
      null}
      {dialog === "all-order-details" ? <MultipleOrdersDetailsDialog orders={filteredOrders} onClose={() => setDialog("")} /> : null}
      <div className="flex w-full px-2 gap-4 justify-between">
        <div className="flex items-center gap-2 text-xl w-1/4">
          {activeFlow ? (
            <Select size="small" value={activeFlow?.group_id} onChange={(e) => navigate(`/flows/${e.target.value}`)} className="bg-white w-64">
              {allFlows.map((item) => (
                <MenuItem key={item.group_id} value={item.group_id} className="text-white">
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          ) : null}
          <Restricted level={2}>
            <button onClick={() => setDialog("flow-details")}>
              <img className="h-8 w-8" src="/assets/images/settings.svg" alt="settings" />
            </button>
          </Restricted>
        </div>
        <div className="flex flex-1 justify-between">
          <SearchField placeholder="Filtrer saker..." onSearch={(searchText) => filterOrders(activeFlowOrders, searchText)} />
          {/* {filteredOrders.length > 0 && (
            <button onClick={() => setDialog("all-order-details")}>
              <img className="h-8 w-8" src="/assets/images/flows/edit-all.svg" alt="edit-all" />
            </button>
          )} */}
          <button onClick={() => loadActiveFlowOrders()}>
            <img className="h-8 w-8" src="/assets/images/refresh.svg" alt="refresh" />
          </button>
        </div>
      </div>
      {activeFlow ? (
        <div className="flex flex-1 gap-4 ">
          <div className="w-1/4">
            <FlowStatusBar flow={{ ...activeFlow, orders: activeFlowOrders }} states={allStates} selectedState={searchParams.get("state") || ""} selectedStep={searchParams.get("step") || ""} />
          </div>
          <div className="flex-1">{activeFlowOrders ? <FlowOrderList steps={activeFlow.steps} orders={filteredOrders} onOrderAction={orderActionHandler} /> : null}</div>
        </div>
      ) : null}
    </div>
  );
}
