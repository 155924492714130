import { useContext, useEffect, useState } from "react";
import { FlowBackend } from "./backends";
import { ModuleContext } from "../common/components/ModuleContainer";
import { FlowAction, FlowCreator, FlowModel, FlowNodeTypeModel, FlowOrderCreator, FlowOrderModel } from "./models";
import { FlowOrderAction } from "./components";
import { FlowsDashboard } from "./FlowsDashboard";

export function FlowsModule() {
  const processor = useContext(ModuleContext);

  const [flows, setFlows] = useState<FlowModel[]>([]);
  const [nodeTypes, setNodeTypes] = useState<FlowNodeTypeModel[]>([]);
  const [activeFlow, setActiveFlow] = useState<FlowModel>();
  const [activeOrder, setActiveOrder] = useState<FlowOrderModel>();

  useEffect(() => {
    (async () => {
      const fetchedFlows = await processor?.run(FlowBackend.getAllFlows);
      setFlows(fetchedFlows);
      const fetchedNodeTypes = await processor?.run(FlowBackend.getAllNodeTypes);
      setNodeTypes(fetchedNodeTypes);
    })();
  }, []);

  async function loadFlow(flow: FlowModel) {
    if (!flow.loaded) {
      const fetchedFlow = await processor?.run(FlowBackend.getFlow, flow.group_id);
      const fetchedOrders = await processor?.run(FlowBackend.getAllOrderInfos, flow.group_id);
      const loadedFlow = { ...FlowCreator().fromBackendObject(fetchedFlow), loaded: true, orders: fetchedOrders };
      setFlows(flows.map((item) => (item === flow ? loadedFlow : item)));
      setActiveFlow(loadedFlow);
    } else {
      setActiveFlow(flow);
    }
  }

  async function refreshFlowOrders(flow: FlowModel) {
    const fetchedOrders = await processor?.run(FlowBackend.getAllOrderInfos, flow.group_id);
    const refreshedFlow = { ...flow, orders: fetchedOrders };
    setFlows(flows.map((item) => (item === flow ? refreshedFlow : item)));
    setActiveFlow(refreshedFlow);
  }

  async function flowActionHandler(action: FlowAction, flow: FlowModel) {
    if (action === "select" || action === "details") {
      await loadFlow(flow);
    } else if (action === "refresh") {
      await refreshFlowOrders(flow);
    } else if (action === "update") {
      const updatedFlow = await processor?.run(FlowBackend.updateFlow, flow);
      setFlows(flows.map((item) => (item === flow ? updatedFlow : item)));
      setActiveFlow(updatedFlow);
    }
  }

  async function orderActionHandler(action: FlowOrderAction, order?: FlowOrderModel) {
    if (action === "add") {
      setActiveOrder(FlowOrderCreator().empty(activeFlow));
    } else if (action === "select") {
      setActiveOrder(order);
    } else if (action === "details") {
      const result = await processor?.run(FlowBackend.getOrder, order?.id);
      setActiveOrder(result);
    } else if (action === "update") {
      setActiveOrder(order);
    }
  }

  return flows?.length > 0 ? <FlowsDashboard flows={flows} /> : null;

  // return flows?.length > 0 ? <FlowsLayout flows={flows} nodeTypes={nodeTypes} activeFlow={activeFlow} activeOrder={activeOrder} onFlowAction={flowActionHandler} onOrderAction={orderActionHandler} /> : null;
}
