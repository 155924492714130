import { ManagerModel } from "../../../../models/managers/ManagerModel";
import { ProjectBundleModel } from "../../../../models/project/ProjectBundleModel";
import { DataList, DataListColumn } from "../../../common/DataList";
import { EditBox } from "../../../elements/common/EditBox";
import { ProductsPanel } from "../../../panels/order/ProductsPanel";
import { TotalPricePanel } from "../../../panels/order/TotalPricePanel";

const bundlesTableColumns: DataListColumn<ProjectBundleModel>[] = [
  {
    name: "Navn",
    selector: (row: ProjectBundleModel) => row.name,
    sortable: true,
    width: "25%",
  },
  {
    name: "Beskrivelse",
    selector: (row: ProjectBundleModel) => row.description,
    sortable: true,
    width: "45%",
  },
  {
    name: "Produkter",
    selector: (row: ProjectBundleModel) => `${row.products.length}`,
    sortable: true,
    width: "15%",
  },
];

const EditBundle = ({ item, setItem, readonly }: { item: ProjectBundleModel; setItem: (bundle: ProjectBundleModel) => void; readonly: boolean }) => {
  return (
    <div className="">
      <div className="flex flex-col gap-2 w-1/2 my-4">
        <EditBox
          label="Navn:"
          value={item.name}
          onTextChanged={(text) => {
            setItem({ ...item, name: text });
          }}
          readonly={readonly}
        />
        <EditBox
          label="Beskrivelse:"
          value={item.description}
          onTextChanged={(text) => {
            setItem({ ...item, description: text });
          }}
          readonly={readonly}
        />
        <p>Produkter:</p>
      </div>
      <ProductsPanel
        products={item.products || []}
        addProduct={(product) => {
          setItem({ ...item, products: [...item.products, product] });
        }}
        removeProduct={(product) => {
          setItem({ ...item, products: item.products.filter((item) => item !== product) });
        }}
        updateProduct={(originalProduct, updatedProduct) => {
          setItem({
            ...item,
            products: item.products.map((item) => {
              return item === originalProduct ? updatedProduct : item;
            }),
          });
        }}
        edit={!readonly}
      />
      <div className="flex mt-2 -mr-8">
        <TotalPricePanel products={item.products} />
      </div>
    </div>
  );
};

export const BundlesPanel = ({ dataManager }: { dataManager: ManagerModel<ProjectBundleModel> }) => {
  return (
    <div>
      <div className="flex w-1/2 mx-auto">
        <DataList<ProjectBundleModel> dataManager={dataManager} columns={bundlesTableColumns} EditItemComponent={EditBundle} />
      </div>
    </div>
  );
};
