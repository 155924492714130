export interface ExCustomerModel {
  id: string;
  orgnr: string;
  debitor: string;
  name1: string;
  name2: string;
  zipcode: string;
  city: string;
  address1: string;
  pricedeal: string;
  pricedealdame: string;
  sellernr: string;
  sellername: string;
  email: string;
  phone: string;
}

export const ExCustomerMapper = {
  empty: () => {
    return {
      id: "",
      orgnr: "",
      debitor: "",
      name1: "",
      name2: "",
      zipcode: "",
      city: "",
      address1: "",
      pricedeal: "",
      pricedealdame: "",
      sellernr: "",
      sellername: "",
      email: "",
      phone: "",
    };
  },

  fromBackendObject: (bo: any): ExCustomerModel => {
    return { ...ExCustomerMapper.empty(), ...bo };
  },

  toBackendObject: (customer?: ExCustomerModel) => {
    return { ...customer };
  },
};
