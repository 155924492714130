import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FlowStepFieldModel, FlowStepDataItemType, FlowStepModel, SupportedFieldTypes } from "../../../models";

export type FlowStepInterfaceFieldAction = "add" | "remove" | "switch" | "set";

export function FlowStepInterface({ locked, fields, setFields, dataSources }: { locked: boolean; fields: FlowStepFieldModel[]; setFields: (data: FlowStepFieldModel[]) => void; dataSources?: FlowStepModel[] }) {
  function dataItemActionHandler(dataItem: FlowStepFieldModel | null, action: FlowStepInterfaceFieldAction) {
    if (action === "add") {
      const newDataItem: FlowStepFieldModel = {
        id: `field${(fields?.length || 0) + 1}`,
        name: `Field ${(fields?.length || 0) + 1}`,
        description: "",
        type: "string",
      };
      setFields(fields?.length ? [...fields, newDataItem] : [newDataItem]);
    } else if (action === "remove") {
      setFields(fields?.filter((item) => item !== dataItem));
    } else if (action === "switch") {
      if (dataItem) {
        const i = fields.indexOf(dataItem);
        const updatedData = [...fields];
        updatedData[i] = updatedData[i + 1];
        updatedData[i + 1] = dataItem;
        setFields(updatedData);
      }
    } else if (action === "set") {
      if (dataItem) {
        const i = fields.indexOf(dataItem);
        const updatedData = [...fields];
        updatedData[i] = updatedData[i + 1];
        updatedData[i + 1] = dataItem;
        setFields(updatedData);
      }
    }
  }

  return (
    <div className={`flex flex-1 gap-2 ${locked ? "select-none" : ""}`}>
      <div className="flex flex-1 pl-2">
        <div className="flex flex-col gap-2 mx-auto">
          {fields?.map((field, i) => (
            <FlowStepInterfaceField
              key={i}
              locked={locked}
              field={field}
              setField={(updated) => setFields(fields.map((item) => (item === field ? updated : item)))}
              isLast={i === fields.length - 1}
              onAction={(action) => dataItemActionHandler(field, action)}
              dataSources={dataSources}
            />
          ))}
          {!locked ? (
            <button className="mx-auto h-6 w-6" onClick={() => dataItemActionHandler(null, "add")}>
              <img src="/assets/images/flows/add-black.svg" alt="add" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function FlowStepInterfaceField({
  locked,
  field,
  setField,
  isLast,
  onAction,
  dataSources,
}: {
  locked: boolean;
  field: FlowStepFieldModel;
  setField: (dataItem: FlowStepFieldModel) => void;
  isLast: boolean;
  onAction: (action: FlowStepInterfaceFieldAction) => void;
  dataSources?: FlowStepModel[];
}) {
  return (
    <>
      <div className="flex">
        <div className="flex flex-col gap-2">
          <div className={`grid grid-cols-4 w-full gap-1`}>
            <TextField disabled={locked} label="Id" size="small" value={field.id} onChange={(e) => setField({ ...field, id: e.target.value })} />
            <TextField disabled={locked} label="Name" size="small" value={field.name} onChange={(e) => setField({ ...field, name: e.target.value })} />
            <FormControl disabled={locked}>
              <InputLabel>Type</InputLabel>
              <Select label="Type" size="small" value={field.type} onChange={(e) => setField({ ...field, type: e.target.value as FlowStepDataItemType })}>
                {SupportedFieldTypes?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField disabled={locked} label="Description" size="small" value={field.description} onChange={(e) => setField({ ...field, description: e.target.value })} />
          </div>
        </div>
        {!locked ? (
          <button
            className="my-auto h-6 w-6"
            onClick={(e) => {
              onAction("remove");
              e.stopPropagation();
            }}
          >
            <img src="/assets/images/flows/remove-black.svg" alt="remove" />
          </button>
        ) : null}
      </div>
      {!locked && !isLast ? (
        <button className="mx-auto h-6 w-6">
          <img src="/assets/images/flows/switch-black.svg" onClick={() => onAction("switch")} alt="switch" />
        </button>
      ) : null}
    </>
  );
}
