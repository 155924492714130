import { useContext, useEffect, useState } from "react";
import { MessageModel } from "../../../models/message/MessageModel";
import backends from "../../../backends";
import AppContext from "../../../contexts/AppContext";
import { Restricted } from "../../common/components/Restricted";

const InputBox = ({ onMessageCreated }: { onMessageCreated: (messageText: string) => void }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [messageText, setMessageText] = useState("");

  return (
    <Restricted module="dashboard" level={2}>
      <div className="flex mx-4">
        {!edit ? (
          <button className="ml-auto" onClick={() => setEdit(true)}>
            <img className="h-8 w-8" src="/assets/images/add-item.svg" alt="add" />
          </button>
        ) : (
          <div className="flex w-full gap-1">
            <input
              className="w-full px-2 rounded text-black"
              type="text"
              value={messageText}
              onInput={(e) => {
                setMessageText(e.currentTarget.value);
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  onMessageCreated(messageText);
                  setMessageText("");
                }
              }}
            />
            <button
              onClick={() => {
                onMessageCreated(messageText);
                setMessageText("");
              }}
            >
              <img className="h-8 w-8" src="/assets/images/check.svg" alt="" />
            </button>
            <button
              onClick={() => {
                setEdit(false);
                setMessageText("");
              }}
            >
              <img className="h-8 w-8" src="/assets/images/close.svg" alt="close" />
            </button>
          </div>
        )}
      </div>
    </Restricted>
  );
};

const MessageBox = ({ message, onAction }: { message: MessageModel; onAction: (id: string, action: "delete" | "") => void }) => {
  return (
    <div>
      <p className="text-sm">{`${message.name} [${message.stamp.toISOString().split("T")[0]}]`}</p>
      <div className="flex rounded-md border-2 px-4 py-2">
        <p className="flex flex-1 my-auto">{message.message}</p>
        <Restricted module="dashboard" level={3}>
          <button className="flex my-auto ml-auto" onClick={() => onAction(message.id, "delete")}>
            <img className="h-8 w-8" src="/assets/images/delete.svg" alt="delete" />
          </button>
        </Restricted>
      </div>
    </div>
  );
};

export const InformationPanel = () => {
  const appContext = useContext(AppContext);
  const [messages, setMessages] = useState<MessageModel[]>([]);

  const refreshMessages = () => {
    backends.messages.fetchAll(appContext.login?.uid).then((fetchedMessages) => {
      setMessages(fetchedMessages.sort((item1, item2) => item2.stamp.getTime() - item1.stamp.getTime()));
    });
  };

  useEffect(() => {
    refreshMessages();
  }, []);

  return (
    <div className="flex flex-1 flex-col ">
      <h1 className="mx-auto my-4 text-3xl font-bold">INFORMASJON</h1>
      <InputBox
        onMessageCreated={async (messageText) => {
          await backends.messages.create(messageText, appContext.login?.uid);
          refreshMessages();
        }}
      />
      <div className="flex flex-col gap-2 p-4">
        {messages.map((item) => (
          <MessageBox
            key={item.id}
            message={item}
            onAction={async (id: string, action: string) => {
              if (action === "delete") {
                await backends.messages.delete(id, appContext.login?.uid);
              }
              refreshMessages();
            }}
          />
        ))}
      </div>
    </div>
  );
};
