import { useState } from "react";
import { Schema, SchemaMapper } from "../SchemaMapper";
import { ExportDialog } from "./ExportDialog";

function exportItems<T>(items: T[], schema: Schema<T>) {
  const mapper = SchemaMapper<T>(schema);
  const csv = [mapper.toCsv(), ...items.map((item) => mapper.toCsv(item))].join("\n");
  const blob = new Blob([csv], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "orders.csv";
  link.href = url;
  link.click();
}

export function ExportButton<T>({
  defaultSchema,
  onGetExportedItems,
}: {
  defaultSchema: Schema<T>;
  onGetExportedItems: () => T[];
}) {
  const [schema, setSchema] = useState(defaultSchema);
  const [showExportDialog, setShowExportDialog] = useState<boolean>(false);
  return (
    <>
      {showExportDialog && (
        <ExportDialog<T>
          schema={schema}
          setSchema={setSchema}
          onSave={() => {
            const exportedItems = onGetExportedItems();
            exportItems(exportedItems, schema);
            setShowExportDialog(false);
          }}
          onClose={() => setShowExportDialog(false)}
        />
      )}
      <button className="tp-button-confirm" onClick={() => setShowExportDialog(true)}>
        Eksport
      </button>
    </>
  );
}
