import { useContext, useEffect, useState } from "react";
import { ExOrderBundleLineMapper, ExOrderBundleLineModel, ExOrderMapper, ExOrderModel, ExOrderProductLineModel, ExProductDeliveryOption } from "../models";
import { BundleLines, ProductBundler, ProductLines, ProductListHeader } from "./product-list";
import { ModuleContext } from "../../common/components";
import { ExBackend } from "../backends";
import { TotalPrice } from "../../common/components/panels/TotalPrice";
import { getTotals } from "../utils/ExOrderHelper";

function ProductListPanelLayout({
  bundles,
  products,
  deliveryOptions,
  onSetBundles,
  onSetProducts,
}: {
  bundles: ExOrderBundleLineModel[];
  products: ExOrderProductLineModel[];
  deliveryOptions: ExProductDeliveryOption[];
  onSetBundles: (bundles: ExOrderBundleLineModel[]) => void;
  onSetProducts: (products: ExOrderProductLineModel[]) => void;
}) {
  const { totalPrice, totalProfit } = getTotals({ ...ExOrderMapper.empty(), bundles: bundles, products: products });

  return (
    <div className="w-full text-sm">
      <ProductBundler onAddBundle={(bundle) => onSetBundles([...bundles, ExOrderBundleLineMapper.fromProductBundle(bundle)])} />
      <ProductListHeader />
      <BundleLines bundles={bundles} onSetBundles={onSetBundles} />
      <ProductLines productLines={products} onSetProductLines={onSetProducts} />
      <div className="flex -mr-2 mt-2">
        <TotalPrice price={totalPrice} profit={totalProfit} />
      </div>
    </div>
  );
}

export function ProductListPanel({ order, onSetOrder }: { order: ExOrderModel; onSetOrder: (updatedOrder: ExOrderModel) => void }) {
  const processor = useContext(ModuleContext);

  const [allDeliveryOptions, setAllDeliveryOptions] = useState<ExProductDeliveryOption[]>([]);

  useEffect(() => {
    (async () => {
      const deliveryOptions = await processor?.runSilently(ExBackend.fetchDeliveryOptions);
      setAllDeliveryOptions(deliveryOptions);
    })();
  }, []);

  return (
    <ProductListPanelLayout
      bundles={order.bundles}
      products={order.products}
      deliveryOptions={allDeliveryOptions}
      onSetBundles={(bundles) => onSetOrder({ ...order, bundles: bundles })}
      onSetProducts={(products) => onSetOrder({ ...order, products: products })}
    />
  );
}
