import { TELENOR_API_URL } from "../env";
import { MessageModel, MessageModelMapper } from "../models/message/MessageModel";

const servicesUrl = TELENOR_API_URL;

export const fetchAllMessages: (userId?: string) => Promise<MessageModel[]> = async (userId?) => {
  const url = `${servicesUrl}/messages`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  if (res.status === 200) {
    const body = await res.json();
    return body.map((item: any) => MessageModelMapper.fromBackendObject(item));
  }
  return [];
};

export const createMessage = async (messageText: string, userId?: string) => {
  const url = `${servicesUrl}/messages`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ 
      message: messageText
    }),
  });

  return res.status === 200;
};

export const deleteMessage = async (
  messageId: string,
  userId?: string
) => {
  const url = `${servicesUrl}/messages/${messageId}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "DELETE",
    headers: headers,
  });

  return res.status === 200;
};
