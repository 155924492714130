import { TELENOR_API_URL, TELENOR_PORTAL_SERVICES_URL } from "../env";
import { EmailAlertModel, EmailAlertModelMapper } from "../models/email/EmailAlertModel";
import { EmailTemplateModelMapper } from "../models/email/EmailTemplateModel";
import { OrderEventModel, OrderEventModelMapper } from "../models/email/OrderEventModel";

const apiUrl = TELENOR_API_URL;
const servicesUrl = TELENOR_PORTAL_SERVICES_URL;
// const servicesUrl = "http://localhost:8080";

export const fetchAllEvents: (userId?: string) => Promise<OrderEventModel[]> = async (userId) => {
  const url = `${apiUrl}/events`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const fetched = await res.json();
  const events = fetched.map((item: any) => OrderEventModelMapper.fromBackendObject(item));
  return events;
};

export const fetchAllEmails: (userId?: string) => Promise<EmailAlertModel[]> = async (userId) => {
  const url = `${apiUrl}/emails`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const fetched = await res.json();
  const emails = fetched.map((item: any) => EmailAlertModelMapper.fromBackendObject(item));
  return emails;
};

export const fetchAllEmailAddresses: (userId?: string) => Promise<string[]> = async (userId) => {
  const url = `${apiUrl}/emails/history`;

  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const fetched = await res.json();
  const emailAddresses = fetched;

  return emailAddresses;
};

export const fetchAllEmailTemplates = async (userId?: string) => {
  const url = `${servicesUrl}/email/templates`;
  const res = await fetch(url, {
    method: "GET",
  });
  if (res.status === 200) {
    const body = await res.json();
    return body.map((item: any) => EmailTemplateModelMapper.fromBackendObject(item));
  }
  return [];
};

export const fetchEmailTemplate = async (templateName: string, userId?: string) => {
  const url = `${servicesUrl}/email/templates/${templateName}`;
  const res = await fetch(url, {
    method: "GET",
  });
  if (res.status === 200) {
    const body = await res.json();
    return EmailTemplateModelMapper.fromBackendObject(body);
  }
  return null;
};

export const createEmailTemplate = async (template: { name: string; description: string }, userId?: string) => {
  const url = `${servicesUrl}/email/templates`;
  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(template),
  });
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  return null;
};

export const updateEmailTemplate = async (template: { name: string; version: string; html: string }, userId?: string) => {
  const url = `${servicesUrl}/email/templates/${template.name}`;
  const res = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(template),
  });
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  return null;
};

export const deleteEmailTemplate = async (templateName: string, userId?: string) => {
  const url = `${servicesUrl}/email/templates/${templateName}`;
  const res = await fetch(url, {
    method: "DELETE",
  });
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  return null;
};
