import { useContext, useState } from "react";
import { CustomDataTable } from "../../../components/common/DataTable";
import { FlowOrderInfoModel, FlowStepModel } from "../models";
import AppContext from "../../../contexts/AppContext";
import { OrderTagDialog } from "./OrderTag";
import { ClaimCell } from "./ClaimCell";

export type FlowOrderAction = "add" | "select" | "details" | "update" | "claim" | "unclaim" | "tag";

const INFO_TEXT_MAX_LENGTH = 50;

function ExpandedOrderInfo({ data }: { data: any }) {
  const order = data as FlowOrderInfoModel;

  return (
    <div className="flex flex-col gap-2 border-double border-b-8 px-16 py-4">
      {order.meta?.info?.map((item) => (
        <p>
          {item.title}: {item.value}
        </p>
      ))}
    </div>
  );
}

export function FlowOrderList({ steps, orders, onOrderAction }: { steps: FlowStepModel[]; orders: FlowOrderInfoModel[]; onOrderAction: (action: FlowOrderAction, order?: FlowOrderInfoModel) => void }) {
  const appContext = useContext(AppContext);

  const columns = [
    {
      name: " ",
      selector: (row: FlowOrderInfoModel) => row.meta?.tags?.color || "",
      cell: (row: FlowOrderInfoModel) => {
        const colorTag = row.meta?.tags?.color;
        const textTag = row.meta?.tags?.text;
        let color = colorTag ? `bg-${colorTag}-400` : "igray";
        return (
          <div className="w-12 mx-auto" title={textTag}>
            <button
              className={`flex w-8 h-8 mx-auto rounded-full ${color}`}
              onClick={(e) => {
                setSelectedRow(row);
                handleContextMenu(e);
              }}
            ></button>
          </div>
        );
      },
      sortable: true,
      width: "4rem",
    },
    {
      name: "ID",
      selector: (row: FlowOrderInfoModel) => parseInt(row.id),
      sortable: true,
      width: "8rem",
    },
    {
      name: "State",
      selector: (row: FlowOrderInfoModel) => row.meta?.state?.value?.toUpperCase(),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: FlowOrderInfoModel) => steps.find((item) => item.status === row.meta?.status?.value)?.name?.toUpperCase() || "",
      sortable: true,
    },
    {
      name: "Claimed By",
      // selector: (row: FlowOrderInfoModel) => row.meta?.claimed?.initials || "",
      cell: (row: FlowOrderInfoModel) =>
        row.meta?.claimed ? (
          row.meta.claimed.id === appContext.login?.id ? (
            <ClaimCell initials={row.meta.claimed.initials} name={row.meta.claimed.name} action="unclaim" onClick={() => onOrderAction("unclaim", row)} />
          ) : (
            <ClaimCell initials={row.meta.claimed.initials} name={row.meta.claimed.name} />
          )
        ) : (
          <ClaimCell action="claim" onClick={() => onOrderAction("claim", row)} />
        ),
      sortable: true,
      width: "16rem",
    },
    {
      name: "Info",
      selector: (row: FlowOrderInfoModel) => {
        const infoText = row.meta?.info?.map((item) => `${item.title}: ${item.value}`).join(", ");
        return infoText?.length > INFO_TEXT_MAX_LENGTH ? infoText.slice(0, INFO_TEXT_MAX_LENGTH - 3) + "..." : infoText;
      },
      sortable: true,
    },
  ];

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedRow, setSelectedRow] = useState<FlowOrderInfoModel | null>(null);

  const handleContextMenu = (e: React.MouseEvent) => {
    console.log(e.clientX, e.clientY);
    e.preventDefault();
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
  };

  const handleClose = () => {
    setContextMenuPosition(null);
  };

  return (
    <div>
      <div className="flex flex-col flex-1">
        <CustomDataTable columns={columns} data={orders} expandedRow={ExpandedOrderInfo} onAddClicked={() => onOrderAction("add")} onMoreClicked={(order) => onOrderAction("details", order)} onRowDoubleClicked={(order) => onOrderAction("details", order)} />
        {selectedRow ? (
          <OrderTagDialog
            position={contextMenuPosition}
            tags={selectedRow.meta.tags}
            onSetTags={(tags) => setSelectedRow({ ...selectedRow, meta: { ...selectedRow.meta, tags: tags } })}
            onSave={() => onOrderAction("tag", selectedRow)}
            onClose={() => setContextMenuPosition(null)}
          />
        ) : null}
      </div>
    </div>
  );
}
