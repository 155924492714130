import { TELENOR_API_URL, TELENOR_PORTAL_SERVICES_URL } from "./env";
import { CustomerModel, CustomerModelMapper } from "./models/CustomerModel";
import { LoginModel, LoginModelMapper } from "./models/login/LoginModel";
import OrderModel from "./models/OrderModel";
import ProductModel from "./models/ProductModel";
import { ProductSupplierModel, ProductSupplierModelMapper } from "./models/product/ProductSupplierModel";

const apiUrl = TELENOR_API_URL;
const servicesUrl = TELENOR_PORTAL_SERVICES_URL;

export const fetchCustomers: (searchField: string, searchValue: string, userId?: string) => Promise<CustomerModel[]> = async (searchField, searchValue, userId) => {
  const url = `${apiUrl}/customers/${searchField ? `${searchField}/` : ""}${searchValue}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const customers = await res.json();
  return customers.map((item: any) => CustomerModelMapper.fromBackendObject(item));
};

export const fetchProducts: (searchField: string, searchValue: string, userId?: string) => Promise<ProductModel[]> = async (searchField: string, searchValue: string, userId?: string) => {
  const url = `${apiUrl}/products/${searchField ? `${searchField}/` : ""}${searchValue}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const products = await res.json();
  return products.map((item: any) => ProductModel.fromBackendObject(item));
};

export const fetchSuppliers: (userId?: string) => Promise<ProductSupplierModel[]> = async (userId) => {
  const url = `${apiUrl}/suppliers`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const suppliers = await res.json();
  return suppliers.map((item: any) => ProductSupplierModelMapper.fromBackendObject(item));
};

export const fetchOrders: (userId?: string) => Promise<OrderModel[]> = async (userId?: string) => {
  const url = `${apiUrl}/orders`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const orders = await res.json();
  return orders.map((item: any) => OrderModel.fromBackendObject(item));
};

export const fetchOrder: (orderId: string, userId?: string) => Promise<OrderModel> = async (orderId: string, userId?: string) => {
  const url = `${apiUrl}/orders/${orderId}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const order = await res.json();
  return OrderModel.fromBackendObject(order);
};

export const createOrder = async (order: OrderModel, userId?: string) => {
  const url = `${apiUrl}/orders`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  // console.log("CREATE ORDER", userId ? { user_id: userId, ...OrderModel.toBackendObject(order) } : OrderModel.toBackendObject(order));
  const res = await fetch(url, {
    method: "POST",
    headers: headers,
    body: userId ? JSON.stringify({ user_id: userId, ...OrderModel.toBackendObject(order) }) : JSON.stringify(OrderModel.toBackendObject(order)),
    // body: JSON.stringify({
    //   ...order,
    //   products: order.products.map((product) => {
    //     return {
    //       article_id: product.id,
    //       outprice: product.outPrice,
    //       quantity: product.quantity,
    //       levnr: product.levnr,
    //       bid: product.bid,
    //     };
    //   }),
    // }),
    // redirect: "follow",
  });
  if (res.status === 200) {
    const body = await res.json();
    return body.orderId;
  }
  return false;
};

export const updateOrder = async (order: OrderModel, userId?: string) => {
  const url = `${apiUrl}/orders/${order.id}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  // console.log("UPDATE ORDER", userId ? { user_id: userId, ...OrderModel.toBackendObject(order) } : OrderModel.toBackendObject(order));
  // const body = {
  //   ...order,
  //   products: order.products?.map((product) => {
  //     return {
  //       article_id: product.id,
  //       outprice: product.outPrice,
  //       quantity: product.quantity,
  //       levnr: product.levnr,
  //       bid: product.bid,
  //     };
  //   }),
  // };

  const res = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: userId ? JSON.stringify({ user_id: userId, ...OrderModel.toBackendObject(order) }) : JSON.stringify(OrderModel.toBackendObject(order)),
    // redirect: "follow",
  });
  // if (res.status === 200) {
  //   alert("Order updated");
  // }
  return res.status === 200;
};

export const deleteOrder = async (orderId: string, userId?: string) => {
  const url = `${apiUrl}/orders/${orderId}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: headers,
      // redirect: "follow",
    });
    if (res.status === 200) {
      return true;
    }
    return false;
  } catch (ex) {
    return false;
  }
};

export const changeOrderStatus = async (orderId: string, newStatus: string, userId?: string) => {
  try {
    const url = `${apiUrl}/status/${orderId}/${newStatus}`;
    const headers = new Headers();
    if (userId) {
      headers.append("Auth0-User", userId);
    }
    const res = await fetch(url, {
      method: "PUT",
      headers: headers,
    });

    return res.status === 200;
  } catch (err) {
    console.log(err)
  }
};

export const fetchCountries = async (userId?: string) => {
  if (!userId) {
    return [];
  }

  const url = `${apiUrl}/countries`;
  const headers = new Headers();
  headers.append("Auth0-User", userId);
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  return [];
};

export const fetchDeliveryAlternatives = async (userId?: string) => {
  if (!userId) {
    return [];
  }

  const url = `${apiUrl}/levalts`;
  const headers = new Headers();
  headers.append("Auth0-User", userId);
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  return [];
};

export const fetchInformationReferences = async (userId: string) => {
  try {
    const url = `${apiUrl}/projects/references`;
    const headers = new Headers();
    headers.append("Auth0-User", userId);
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (res.status === 200) {
      const data = await res.json();
      return data.information.references;
    }
  } catch (err: any) {
    console.log("Cannot fetch information references.");
  }
  return [];
};

export const lookupCity = async (countryCode: string, zipCode: string) => {
  const url = `${servicesUrl}/city?country=${countryCode}&zip=${zipCode}`;
  const res = await fetch(url, {
    method: "GET",
  });
  if (res.status === 200) {
    const body = await res.json();
    return body.city;
  }
  return "";
};

export const lookupUser: (userId: string, userEmail: string) => Promise<LoginModel | null> = async (userId, userEmail) => {
  if (userId) {
    const url = `${apiUrl}/users/${userId}`;
    const headers = new Headers();
    headers.append("Auth0-User", userId);
    headers.append("Auth0-Email", userEmail);
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (res.status === 200) {
      const body = await res.json();
      return LoginModelMapper.fromBackendObject(body);
    }
  }
  return null;
};
