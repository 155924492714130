export function Notification({ type, text, onClose }: { type: "information" | "warning" | "error"; text: string; onClose: () => void }) {
  return (
    <div className="fixed flex mt-4 mx-auto w-full">
      <div className="flex mx-auto px-4 py-1 bg-black text-white rounded-md">
        <img className="mr-4 h-8 w-8" src={`/assets/images/notifications/${type}.svg`} alt="" />
        <p className="my-auto">{text}</p>
        <button onClick={onClose}>
          <img className="ml-4 h-4 w-4" src={`/assets/images/close.svg`} alt="close" />
        </button>
      </div>
    </div>
  );
}
