export interface ExDeliveryModel {
  name: string;
  name2: string;
  contact: string;
  address: string;
  address2: string;
  zipcode: string;
  city: string;
  country: string;
  mobile: string;
  email: string;
}

export const ExDeliveryMapper = {
  empty: () => {
    return {
      name: "",
      name2: "",
      contact: "",
      address: "",
      address2: "",
      zipcode: "",
      city: "",
      country: "NO",
      mobile: "",
      email: "",
    };
  },

  fromBackendObject: (bo: any): ExDeliveryModel => {
    return { ...ExDeliveryMapper.empty(), ...bo };
  },

  toBackendObject: (delivery: ExDeliveryModel): object => {
    return { ...delivery };
  },
};
