import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";

export interface FormFieldModel {
  label?: string;
  description?: string;
  value: any;
  setValue?: (fieldValue: any) => void;
  options?: { name: string; value: any }[];
}

export function FormTextField({ field, rows, frontLabel, onBlur }: { field: FormFieldModel; rows?: number; frontLabel?: boolean; onBlur?: () => void }) {
  return frontLabel ? (
    <div className="flex">
      <FormLabel className="mt-2 mr-8" sx={{ color: "inherit" }}>
        {field.label}
      </FormLabel>
      <TextField
        className="flex-1 bg-white"
        value={field.value}
        onChange={(e) => field.setValue?.(e.target.value)}
        onBlur={onBlur}
        multiline={rows ? rows > 1 : false}
        rows={rows}
      />
    </div>
  ) : (
    <TextField
      className="flex-1 bg-white"
      label={field.label}
      value={field.value}
      onChange={(e) => field.setValue?.(e.target.value)}
      onBlur={onBlur}
      multiline={rows ? rows > 1 : false}
      rows={rows}
    />
  );
}

export function FormNumberField({ field }: { field: FormFieldModel }) {
  return (
    <TextField
      className="flex-1 bg-white"
      inputProps={{ type: "number" }}
      label={field.label}
      value={field.value}
      onChange={(e) => field.setValue?.(e.target.value)}
    />
  );
}

export function FormDateField({ field, frontLabel }: { field: FormFieldModel; frontLabel?: boolean }) {
  return frontLabel ? (
    <div className="flex">
      <FormLabel className="my-auto mr-8" sx={{ color: "inherit" }}>
        {field.label}
      </FormLabel>
      <TextField type="date" size="small" className="flex-1 bg-white" value={field.value} onChange={(e) => field.setValue?.(e.target.value)} />
    </div>
  ) : (
    <TextField type="date" className="flex-1 bg-white" label={field.label} value={field.value} onChange={(e) => field.setValue?.(e.target.value)} />
  );
}

export function FormSwitchField({ field, frontLabel }: { field: FormFieldModel; frontLabel?: boolean }) {
  return frontLabel ? (
    <FormControlLabel className="flex" control={<Switch checked={field.value} onChange={(e) => field.setValue?.(e.target.checked)} />} label={field.label} />
  ) : (
    <FormControlLabel
      className="flex"
      control={<Switch checked={field.value} onChange={(e) => field.setValue?.(e.target.checked)} />}
      label={<p className="">{field.label}</p>}
      labelPlacement="end"
    />
  );
}

export function FormSelectField({ field, frontLabel }: { field: FormFieldModel; frontLabel?: boolean }) {
  return frontLabel ? (
    <div className="flex">
      <FormLabel className="my-auto mr-16" sx={{ color: "inherit" }}>
        {field.label}
      </FormLabel>
      <Select className="flex-1 bg-white" value={field.value} onChange={(e) => field.setValue?.(e.target.value)}>
        {field.options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : (
    <FormControl className="flex-1 flex-col">
      <InputLabel className="flex-1">{field.label}</InputLabel>
      <Select className="flex-1 bg-white" label={field.label} value={field.value} onChange={(e) => field.setValue?.(e.target.value)}>
        {field.options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
