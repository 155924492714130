import { useContext, useEffect } from "react";
import AppContext from "../../../contexts/AppContext";
import { UserManagementContext } from "../../../contexts/UserManagementContext";
import OrderModel from "../../../models/OrderModel";
import { OrderFormFields } from "../../../ui/OrderFields";
import { SelectBox } from "../../elements/common/SelectBox";
import { Field } from "../../fields/order/Field";
import { PoNrField } from "../../fields/order/PoNrField";

export const OrderPanel = ({ order, changeFieldValueHandler, editable }: { order: OrderModel; changeFieldValueHandler: (fieldValue: any, fieldName: string, subfieldName?: string) => void; editable: boolean }) => {
  const appContext = useContext(AppContext);
  const userManagement = useContext(UserManagementContext);
  const sapNrField = OrderFormFields.order.fields[4];
  const trackingField = OrderFormFields.order.fields[5];
  const poNrField = OrderFormFields.order.fields[6];

  useEffect(() => {
    (async () => {
      if (!userManagement.state.status) {
        await userManagement.refreshUsers();
      }
      if (!order.seller || order.seller === "0") {
        changeFieldValueHandler(appContext.login?.id, "seller");
      }
    })();
  }, []);

  return (
    <div className="grid grid-cols-12 gap-8 w-full">
      <div className="flex flex-col col-span-4">
        {OrderFormFields.order.fields.slice(0, 4).map((field) => (
          <Field
            key={field.text}
            field={field}
            labelWidth="64"
            value={field.dataSubfield ? (order as any)[field.dataField][field.dataSubfield] : (order as any)[field.dataField]}
            onChanged={(text: string) => changeFieldValueHandler(text, field.dataField, field.dataSubfield)}
            editable={editable}
          />
        ))}
        {/* <DateField
            field={OrderFields.order.labels[0]}
            labelWidth="64"
            value={order.deliveryDate}
            onTextChanged={(text: string) => {
              changeFieldValueHandler(text, "deliveryDate");
            }}
            editable={editable}
          />
          <EditboxField
            label={OrderFields.order.labels[1]}
            labelWidth="64"
            value={order.reference}
            onTextChanged={(text: string) => {
              changeFieldValueHandler(text, "reference");
            }}
            editable={editable}
          />
          <EditboxField
            label={OrderFields.order.labels[2]}
            labelWidth="64"
            value={order.requisition}
            onTextChanged={(text: string) => {
              changeFieldValueHandler(text, "requisition");
            }}
            editable={editable}
          />
          <EditboxField
            label={OrderFields.order.labels[3]}
            labelWidth="64"
            value={order.caseNumber}
            onTextChanged={(text: string) => changeFieldValueHandler(text, "caseNumber")}
            editable={editable}
          /> */}
      </div>
      <div className="flex flex-1 flex-col col-span-3">
        <Field key={sapNrField.text} field={sapNrField} labelWidth="64" value={(order as any)[sapNrField.dataField]} onChanged={(text: string) => changeFieldValueHandler(text, sapNrField.dataField)} editable={editable} />
        <Field key={trackingField.text} field={trackingField} labelWidth="64" value={(order as any)[trackingField.dataField]} onChanged={(text: string) => changeFieldValueHandler(text, trackingField.dataField)} editable={editable} />
        <PoNrField
          key={poNrField.text}
          field={poNrField}
          labelWidth="64"
          value={(order as any)[poNrField.dataField]}
          onChanged={(supplierId: string, ponrValue: string) => {
            const poNumbers: { supplier: { id: string; name: string }; ponr: string }[] = (order as any)[poNrField.dataField];
            changeFieldValueHandler(
              poNumbers.map((item) => {
                if (item.supplier.id === supplierId) {
                  item.ponr = ponrValue;
                }
                return item;
              }),
              poNrField.dataField
            );
          }}
          editable={editable}
        />
        {/* {OrderFormFields.order.fields.slice(4, 6).map((field) => (
          <Field
            key={field.text}
            field={field}
            labelWidth="64"
            value={(order as any)[field.dataField]}
            onChanged={(text: string) => changeFieldValueHandler(text, field.dataField)}
            editable={editable}
          />
        ))} */}
        {/* <EditboxField
            label={OrderFields.order.labels[4]}
            labelWidth="64"
            value={order.sapNumber}
            onTextChanged={(text: string) => changeFieldValueHandler(text, "sapNumber")}
            editable={editable}
          />
          <EditboxField
            label={OrderFields.order.labels[5]}
            labelWidth="64"
            value={order.poNumber}
            onTextChanged={(text: string) => changeFieldValueHandler(text, "poNumber")}
            editable={editable}
          /> */}
      </div>
      <div className="flex flex-1 flex-col col-span-2">
        {OrderFormFields.order.fields.slice(7, 13).map((field) => (
          <Field
            key={field.text}
            field={field}
            labelWidth="64"
            value={field.dataSubfield ? (order as any)[field.dataField][field.dataSubfield] : (order as any)[field.dataField]}
            onChanged={(text: string) => changeFieldValueHandler(text, field.dataField, field.dataSubfield)}
            editable={editable}
          />
        ))}
        {/* <CheckboxField
            label="Samlefaktura"
            checkable={editable}
            checked={order.invoice}
            onCheckedChanged={(checked: boolean) => changeFieldValueHandler(checked, "invoice")}
          />
          <CheckboxField
            label="Ordrebekreftelse"
            checkable={editable}
            checked={order["orderConfirm"]}
            onCheckedChanged={(checked: boolean) => changeFieldValueHandler(checked, "orderConfirm")}
          />
          <CheckboxField
            label="Hold"
            checkable={editable}
            checked={order.orderOnHold}
            onCheckedChanged={(checked: boolean) => changeFieldValueHandler(checked, "orderOnHold")}
          />
          <CheckboxField
            label="Oppdatering til kunde"
            checkable={editable}
            checked={order.customerUpdate}
            onCheckedChanged={(checked: boolean) => changeFieldValueHandler(checked, "customerUpdate")}
          /> */}
      </div>
      <div className="flex flex-1 flex-col col-span-3">
        {OrderFormFields.order.fields.slice(13, 15).map((field) => (
          <Field
            key={field.text}
            field={field}
            labelWidth="64"
            value={field.dataSubfield ? (order as any)[field.dataField][field.dataSubfield] : (order as any)[field.dataField]}
            onChanged={(text: string) => changeFieldValueHandler(text, field.dataField, field.dataSubfield)}
            editable={editable}
          />
        ))}
        <SelectBox
          items={[
            { id: "0", name: "SAP Default" },
            ...userManagement.allUsers
              .filter((item) => item.sellernr && item.sellernr !== "0")
              .map((item) => ({ id: item.id, name: item.name || item.email }))
              .sort((item1, item2) => (item1.name >= item2.name ? 1 : -1)),
          ]}
          label="Selger"
          value={order.seller}
          onValueChanged={(userId) => {
            changeFieldValueHandler(userId, "seller");
          }}
          readonly={!editable}
        />
        {/* <TextboxField
            label="Intern kommentar"
            value={order.commentInternal}
            onTextChanged={(text: string) => changeFieldValueHandler(text, "commentInternal")}
            editable={editable}
          />
          <TextboxField
            label="Leverandør kommentar"
            value={order.commentSupplier}
            onTextChanged={(text: string) => changeFieldValueHandler(text, "commentSupplier")}
            editable={editable}
          /> */}
      </div>
    </div>
  );
};
