import { FieldModel } from "../../../models/ui/FieldModel";

export const DateField = ({
    field,
    labelWidth,
    value,
    onTextChanged,
    editable,
  }: {
    field: FieldModel;
    labelWidth?: string;
    value: string;
    onTextChanged: (text: string) => void;
    editable?: boolean;
  }) => {
    const lw = labelWidth ? "w-" + labelWidth : "w-1/4";
  
    return (
      <div className="flex m-1 h-7">
        <label title={field.description} className={`my-auto ${lw}`}>{field.text}</label>
        <input
          type="date"
          className={`w-full px-2 rounded-md border-2 ${editable ? "text-black" : "bg-transparent text-white"}`}
          readOnly={!editable}
          alt="Hint"
          value={value}
          onInput={(e) => {
            onTextChanged(e.currentTarget.value);
          }}
        />
      </div>
    );
  };
  