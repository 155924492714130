import { TELENOR_API_URL } from "../env";
import ProductModel from "../models/ProductModel";

const apiUrl = TELENOR_API_URL;

export const fetchProducts: (searchField: string, searchValue: string, userId?: string) => Promise<ProductModel[]> = async (
  searchField: string,
  searchValue: string,
  userId?: string
) => {
  const url = `${apiUrl}/products/${searchField ? `${searchField}/` : ""}${searchValue}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const products = await res.json();
  return products.map((item: any) => ProductModel.fromBackendObject(item));
};

export const fetchProduct: (productId: string, userId?: string) => Promise<ProductModel> = async (productId, userId?) => {
  const url = `${apiUrl}/products/${productId}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const fetchedData = await res.json();
  const products = fetchedData.map((item: any) => ProductModel.fromBackendObject(item))
  return products?.length > 0 ? products[0] : new ProductModel();
};
