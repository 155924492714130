import { useContext, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import { TELENOR_PORTAL_SERVICES_URL } from "../../../env";

const chatServiceUrl = `${TELENOR_PORTAL_SERVICES_URL}/chat`;

export const ChatDialog = () => {
  const appContext = useContext(AppContext);
  const [chatActive, setChatActive] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [sendingStatus, setSendingStatus] = useState<"" | "sending" | "sent" | "error">("");

  const sendMessage = async () => {
    try {
      setSendingStatus("sending");
      setChatActive(false);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      await fetch(chatServiceUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          sender: {
            name: appContext.login?.name || "?",
            email: appContext.login?.email || "?",
          },
          message: message,
        }),
      });
      setMessage("");
      setSendingStatus("");
    } catch (err) {
      setSendingStatus("error");
    }
  };

  return (
    <div className="fixed flex flex-col bottom-8 right-8 flex gap-2">
      {chatActive && (
        <div className="rounded-xl border-1 border-white bg-black p-4">
          {appContext.login?.email}
          <div className="flex flex-col mt-2 ">
            <label>Melding:</label>
            <textarea className="w-72 p-2 rounded-md text-black" rows={5} value={message} onChange={(e) => setMessage(e.target.value)} />
          </div>
          <button className="flex mt-2 ml-auto p-1" onClick={sendMessage}>
            <img src="/assets/images/chat/send-message.svg" alt="send message" />
          </button>
        </div>
      )}
      <button className="ml-auto flex bg-black rounded-full p-4" onClick={() => setChatActive(!chatActive)}>
        <img className="w-6 h-6" src={chatActive ? (sendingStatus === "sending" ? "/assets/images/chat/sending.gif" : "/assets/images/chat/end-chat.svg") : "/assets/images/chat/start-chat.svg"} alt="chat" />
      </button>
    </div>
  );
};
