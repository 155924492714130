import { TELENOR_API_URL } from "../env";
import { CustomerModel, CustomerModelMapper } from "../models/CustomerModel";

const apiUrl = TELENOR_API_URL;

export const fetchCustomers: (
  searchField: string,
  searchValue: string,
  userId?: string
) => Promise<CustomerModel[]> = async (searchField, searchValue, userId) => {
  const url = `${apiUrl}/customers/${searchField ? `${searchField}/` : ""}${searchValue}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const customers = await res.json();
  return customers.map((item: any) => CustomerModelMapper.fromBackendObject(item));
};

export const fetchCustomer: (
  customerId: string,
  userId?: string
) => Promise<CustomerModel> = async (customerId, userId) => {
  const url = `${apiUrl}/customers/id/${customerId}?exact=1`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const customer = await res.json();
  return CustomerModelMapper.fromBackendObject(customer);
};
