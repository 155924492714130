import "./App.css";
import { AppContextComponent } from "./contexts/AppContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { AppRouter } from "./AppRouter";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/nb';

dayjs.extend(utc);

const auth0config = {
  domain: "slog.auth0.com",
  clientId: "dOjZCcLpx6D8J2EscIwIFDsHkfOEOhq9",
};

function App() {
  return (
    <>
      <Auth0Provider domain={auth0config.domain} clientId={auth0config.clientId} authorizationParams={{ redirect_uri: window.location.origin }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb" >
          <AppContextComponent>
            <AppRouter />
          </AppContextComponent>
        </LocalizationProvider>
      </Auth0Provider>
    </>
  );
}

export default App;
