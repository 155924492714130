import { ExOrderProductLineMapper, ExOrderProductLineModel } from ".";

export interface ExProductBundleModel {
  id: string;
  name: string;
  description: string;
  products: ExOrderProductLineModel[];
}

export const ExProductBundleMapper = {
  empty: () => {
    return { id: "", name: "", description: "", products: [] };
  },

  clone: (bundle: ExProductBundleModel) => {
    return {
      ...bundle,
      products: bundle.products.map((item) => ExOrderProductLineMapper.clone(item)),
    };
  },

  fromBackendObject: (backendObject: any) => {
    return {
      id: backendObject.id,
      name: backendObject.name,
      description: backendObject.description || "",
      products: backendObject.products.map((item: any) => ExOrderProductLineMapper.fromBackendObject(item)),
    };
  },

  toBackendObject: (bundle: ExProductBundleModel) => {
    return { ...bundle, products: bundle.products.map((item) => ExOrderProductLineMapper.toBackendObject(item)) };
  },
};
