import { ModelMapper } from "../ModelMapper";

export interface ProjectInfoFieldModel {
  id: number;
  name: string;
  description: string;
  defaultValue: string;
  reference: string;
}

export const ProjectInfoFieldsModelMapper: ModelMapper<ProjectInfoFieldModel> = {
  fromBackendObject: (backendObject: any) => {
    return {
      id: backendObject.id,
      name: backendObject.name,
      description: backendObject.description || "",
      defaultValue: backendObject.defaultValue,
      reference: backendObject.reference,
    };
  },

  toBackendObject: (infoField: ProjectInfoFieldModel) => {
    return infoField;
  },

  createEmptyObject: () => {
    return { id: 0, name: "", description: "", defaultValue: "", reference: "" };
  },
};
