import { FlowOrderExecutionStepModel } from "../../models";

export type FlowStepAction = "select" | "add" | "remove" | "switch";

function OrderStep({ step, isActive, isLast, onAction }: { step: FlowOrderExecutionStepModel; isActive: boolean; isLast: boolean; onAction: (action: FlowStepAction) => void }) {
  let stateClass = "";
  let imagePath = "";
  switch (step.state) {
    case "processing":
      stateClass = "border-green-600";
      imagePath = "/assets/images/flows/states/processing.gif";
      break;
    case "awaiting":
      stateClass = "border-green-600";
      imagePath = "/assets/images/flows/states/awaiting.svg";
      break;
    case "operator":
      stateClass = "border-green-600";
      imagePath = "/assets/images/flows/states/operator.svg";
      break;
    case "completed":
      stateClass = "border-green-600";
      imagePath = "/assets/images/flows/states/completed.svg";
      break;
    default:
      stateClass = "";
      imagePath = "";
      break;
  }

  return (
    <>
      <button className={`flex relative p-1 rounded-md border-2 ${isActive ? "bg-gray-400" : ""} ${stateClass}`} onClick={() => onAction("select")}>
        {imagePath ? (
          <div className="flex flex-1 absolute z-10 right-2 bottom-2">
            <img className="h-8 w-8" src={imagePath} alt="state" />
          </div>
        ) : null}
        <div className="text-left">
          <h3 className="text-xl font-bold">{step.model?.name}</h3>
          <p className="text-xs">{step.status}</p>
          <p className="text-xs">type: {step.model?.node_type}</p>
        </div>
      </button>
      {!isLast ? (
        <div className="flex mx-auto h-4">
          <img className="h-4 w-4" src="/assets/images/flows/down-arrow.svg" alt="next" />
        </div>
      ) : null}
    </>
  );
}

export function OrderSteps({ steps, activeStatus, onSetActiveStep }: { steps: FlowOrderExecutionStepModel[]; activeStatus: string | null; onSetActiveStep: (step: FlowOrderExecutionStepModel | undefined) => void }) {
  function stepActionHandler(step: FlowOrderExecutionStepModel, action: FlowStepAction) {
    if (action === "select") {
      onSetActiveStep(step);
    }
  }

  return (
    <div className="flex flex-1 flex-col gap-1">
      {steps.map((step, i) => (
        <OrderStep key={step.status} step={step} isActive={step.status === activeStatus} isLast={i === steps.length - 1} onAction={(action) => stepActionHandler(step, action)} />
      ))}
    </div>
  );
}
