import { useContext } from "react";
import AppContext from "./contexts/AppContext";
import { Header } from "./components/Header";
import { Sidebar, SidebarItem } from "./components/Sidebar";
import { Outlet } from "react-router-dom";
import { Loading } from "./components/common/Loading";
import { OrderContextComponent } from "./contexts/OrderContext";
import { OrdersManagementContextComponent } from "./contexts/OrdersManagementContext";
import { UserManagementContextComponent } from "./contexts/UserManagementContext";
import { ProcessingContextComponent } from "./contexts/ProcessingContext";
import { AppConstantsContextComponent } from "./contexts/AppConstantsContext";
import { ErrorBoundary, Notification } from "./modules/common/components";

const MainContent = () => {
  return (
    <AppConstantsContextComponent>
      <OrderContextComponent>
        <OrdersManagementContextComponent>
          <UserManagementContextComponent>
            <ProcessingContextComponent>
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </ProcessingContextComponent>
          </UserManagementContextComponent>
        </OrdersManagementContextComponent>
      </OrderContextComponent>
    </AppConstantsContextComponent>
  );
};

export const AppLayout = ({ sidebarItems }: { sidebarItems: SidebarItem[] }) => {
  const appContext = useContext(AppContext);

  return (
    <div className="flex flex-col m-0 min-h-screen w-full">
      {appContext.notification && <Notification type={appContext.notification.type} text={appContext.notification.text} onClose={() => appContext.setNotification(null)} />}
      {!appContext.wizardMode && (
        <div className="flex w-full iheader">
          <Header />
        </div>
      )}
      <div className="flex flex-1">
        {appContext.state.status === "loading" && <Loading />}
        {appContext.state.status === "message" && <div className="m-auto text-black font-bold">{appContext.state.message}</div>}
        {appContext.state.status === "ready" && (
          <div className="flex p-1 gap-1 w-full">
            {!appContext.wizardMode && (
              <div className="flex w-12">
                <Sidebar items={sidebarItems} />
              </div>
            )}
            <div className="flex w-full">
              <div className="flex w-full rounded-xl igray">
                <MainContent />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
