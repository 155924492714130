import { useContext, useEffect, useState } from "react";
import { BarChart, Bar, XAxis } from "recharts";
import { StatsContext } from "../../../contexts/StatsContext";

export const KeyInfoPanel = () => {
  const stats = useContext(StatsContext);

  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 30);

  const [fromDate, setFromDate] = useState<string>(sevenDaysAgo.toISOString().split("T")[0]);
  const [toDate, setToDate] = useState<string>(today.toISOString().split("T")[0]);

  useEffect(() => {
    (async () => {
      if (!stats.state.status) {
        await stats.refreshStats();
      }
    })();
  }, []);

  return (
    <div className="flex flex-col w-full font-bold relative">
      <h1 className="mx-auto my-4 text-3xl">NØKKELTALL</h1>
      <div className="flex ml-8 mr-8 mt-8 gap-8">
        <div>
          <label>Fra</label>
          <input
            className="px-2 ml-4 text-black h-8 rounded-md w-48"
            type="date"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.currentTarget.value);
            }}
          />
        </div>
        <div>
          <label>TIl</label>
          <input
            className="px-2 ml-4 text-black h-8 rounded-md w-48"
            type="date"
            value={toDate}
            onChange={(e) => {
              setToDate(e.currentTarget.value);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-full text-black gap-4 my-4">
        {[
          { title: "Ordertall", fetchFunction: stats.getOrdersCount, dataKey: "orders" },
          { title: "Produkttall", fetchFunction: stats.getProductsCount, dataKey: "products" },
        ].map((item) => (
          <div className="flex flex-1 " key={item.dataKey}>
            <div className="flex mx-8 p-8 rounded-md iheader w-full">
              <p className="">{item.title}</p>
              <p className="mx-12 text-5xl my-auto w-32 text-center p-2 rounded-md">{stats.allOrderStats.length > 0 ? item.fetchFunction(fromDate, toDate) : "-"}</p>
              <div className="flex flex-col ml-auto">
                <BarChart width={512} height={192} data={stats.getSummary()}>
                  <XAxis dataKey="month" />
                  {/* <YAxis /> */}
                  <Bar dataKey={item.dataKey} stroke="var(--inselo-blue)" strokeWidth={4} fill="var(--inselo-light-blue)" label={{ position: "top", fontSize: "12px" }} />
                  {/* <Bar dataKey="orders" fill="#8884d8" label={{ position: 'top' }} /> */}
                </BarChart>
                <p className="mx-auto mt-2 text-sm">siste 6 måneder</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
