import { ModelMapper } from "./ModelMapper";

export interface SklOrderStatusModel {
  numbersLoaded: boolean;
  readyForTest: boolean;
  readyForUse: boolean;
  inUse: boolean;
  delivered: boolean;
  testStartDate: string;
  prodStartDate: string;
  invoiceStartDate: string;
  comment: string;
  orderDate: number;
  deliveryDate: number;
  state: "" | "step1" | "step2";
}

export const SklOrderStatusModelMapper: ModelMapper<SklOrderStatusModel> = {
  newModel() {
    const today = new Date();
    const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

    return {
      numbersLoaded: false,
      readyForTest: false,
      readyForUse: false,
      inUse: false,
      delivered: false,
      testStartDate: todayString,
      prodStartDate: todayString,
      invoiceStartDate: todayString,
      comment: "",
      orderDate: 0,
      deliveryDate: 0,
      state: "",
    };
  },

  mapToModel(backendObject) {
    return { ...SklOrderStatusModelMapper.newModel(), ...backendObject };
  },
};
