import { UserAccessMapper, UserAccessModel } from "./UserAccessModel";

export interface UserModel {
  id: string;
  uid: string;
  email: string;
  name: string;
  active: boolean;
  sellernr: string;
  access: UserAccessModel;
  options: any;
}

export const UserMapper = {
  createEmptyObject: () => {
    return {
      id: "",
      uid: "",
      email: "",
      name: "",
      active: false,
      sellernr: "",
      access: UserAccessMapper.empty(),
      options: null,
    };
  },

  fromBackendObject: (backendObject: any) => {
    return { ...backendObject, access: UserAccessMapper.fromBackendObject(backendObject?.access), active: backendObject.active === true || backendObject.active === "1" };
  },

  toBackendObject: (model: UserModel) => {
    return { ...model, access: UserAccessMapper.toBackendObject(model.access) };
  },
};
