import { useContext } from "react";
import { AppConstantsContext } from "../../../../contexts/AppConstantsContext";
import { ExOrderProductLineModel } from "../../models";
import { PriceCalculator } from "../../utils";
import { ReadonlyField } from "./product-field";

export function useProductLineViewer(productLine: ExOrderProductLineModel, Layout: React.ComponentType<{ children: JSX.Element[] }>) {
  const appConstants = useContext(AppConstantsContext);

  
    return (
      <Layout>
        <ReadonlyField value={productLine.number} align="left" />
        <ReadonlyField value={productLine.ean} align="left" />
        <ReadonlyField value={productLine.description} align="left" />
        <ReadonlyField value={productLine.levname} align="left" />
        <ReadonlyField value={appConstants.deliveryAlternatives.find((item) => item.lev_alt === productLine.lev_alt)?.description || ""} align="left" />
        <ReadonlyField value={productLine.bid} align="left" />
        <div>{productLine.moms ? "True" : "False"}</div>
        <ReadonlyField value={productLine.quantity} />
        <ReadonlyField value={productLine.calculation} />
        <ReadonlyField value={productLine.price} />
        <ReadonlyField value={productLine.outPrice} />
        <ReadonlyField value={productLine ? PriceCalculator.GetTotalPricesFormated(productLine?.price, productLine?.outPrice, productLine?.quantity) : ""} />
        <ReadonlyField value={productLine.information} align="left" />
      </Layout>
    );
  
  // return [
  //   productLine.number,
  //   productLine.ean,
  //   productLine.description,
  //   productLine.levname,
  //   appConstants.deliveryAlternatives.find((item) => item.lev_alt === productLine.levalt)?.description || "",
  //   productLine.bid,
  //   productLine.vat,
  //   productLine.quantity,
  //   productLine.margin,
  //   productLine.price,
  //   productLine.outPrice,
  //   productLine ? PriceCalculator.GetTotalPricesFormated(productLine?.price, productLine?.outPrice, productLine?.quantity) : "",
  //   productLine.information,
  // ];
}
