import { ReactNode, useState } from "react";

export function CollapsibleCard({ title, toolbar, children }: { title: string; toolbar?: {image: string, onClick: () => void}[]; children: ReactNode }) {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="flex flex-col">
      <div className="flex iheader p-1 rounded-t-md items-center">
        <button>
          <img
            className="w-8"
            src={expanded ? "/assets/images/ex/less.svg" : "/assets/images/ex/more.svg"}
            alt="expand"
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          />
        </button>
        <span className="ml-2 text-lg font-bold text-black">{title}</span>
      </div>
      <div className={`${expanded ? "flex" : "hidden"} p-2 border-2 rounded-b-md`}>{children}</div>
    </div>
  );
}
