import { UserAccessMapper, UserAccessModel } from "../../modules/users/models";
import { ModelMapper } from "../ModelMapper";

export interface LoginModel {
  id: string;
  uid: string;
  email: string;
  name: string;
  active: boolean;
  sellernr: string;
  access: UserAccessModel;
  options: any;
}

export const LoginModelMapper: ModelMapper<LoginModel> = {
  createEmptyObject: () => {
    return {
      id: "",
      uid: "",
      email: "",
      name: "",
      active: false,
      sellernr: "",
      access: UserAccessMapper.empty(),
      options: null,
    };
  },

  fromBackendObject: (backendObject: any) => {
    return { ...backendObject, access: UserAccessMapper.fromBackendObject(backendObject?.access) };
  },

  toBackendObject: (model: LoginModel) => {
    return { ...model, access: UserAccessMapper.toBackendObject(model.access) };
  },
};
