import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import OrderModel from "../models/OrderModel";
import ProductModel from "../models/ProductModel";
import { fetchCountries, fetchDeliveryAlternatives } from "../backend";
import AppContext from "./AppContext";

// const getEmptyOrder = () => {
//   return {
//     customer: {
//       id: "",
//       orgnr: "",
//       name1: "",
//       name2: "",
//       city: "",
//       address1: "",
//       zipcode: "",
//     },
//     delivery: {
//       name: "",
//       address: "",
//       zipcode: "",
//       city: "",
//       mobile: "",
//       email: "",
//     },
//     products: [],
//     reference: "",
//     comment: "",
//     invoice: false,
//   };
// };

// const getPoNumbers = (products: ProductModel[]) => {
//   const poNumbers: string[] = [];
//   for (const product of products) {
//     if (!poNumbers.includes(product.levname)) {
//       poNumbers.push(product.levname);
//     }
//   }
//   if (poNumbers.length > 0) {
//     return poNumbers.join(", ");
//   }
//   return "";
// };

const OrderContext = createContext<{
  order: OrderModel;
  resetOrder: () => void;
  setOrderProperty: (value: any, name: string, child?: string) => void;
  copyCustomerInfoToDeliveryInfo: () => void;
  addProduct: (product: ProductModel) => void;
  removeProduct: (product: ProductModel) => void;
  updateProduct: (originalProduct: ProductModel, updatedProduct: ProductModel) => void;

  countries: { name: string; code: string }[];
  deliveryAlternatives: { lev_alt: string; description: string }[];
}>({
  order: new OrderModel(),
  resetOrder: () => {},
  setOrderProperty: (value, name, child) => {},
  copyCustomerInfoToDeliveryInfo: () => {},
  addProduct: (product) => {},
  removeProduct: (product) => {},
  updateProduct: (originalProduct, updatedProduct) => {},

  countries: [],
  deliveryAlternatives: [],
});

export const OrderContextComponent = ({ children }: { children: ReactNode }) => {
  const appContext = useContext(AppContext);

  const [order, setOrder] = useState<OrderModel>(new OrderModel());
  const [countries, setCountries] = useState<{ name: string; code: string }[]>([]);
  const [deliveryAlternatives, setDeliveryAlternatives] = useState<{ lev_alt: string; description: string }[]>([]);

  useEffect(() => {
    if (countries.length === 0) {
      fetchCountries(appContext.login?.uid).then((fetchedCountries) => setCountries(fetchedCountries));
    }
    if (deliveryAlternatives.length === 0) {
      fetchDeliveryAlternatives(appContext.login?.uid).then((fetchedDeliveryAlternatives) => setDeliveryAlternatives(fetchedDeliveryAlternatives));
    }
  }, []);

  const initValue = {
    order: order,
    resetOrder: () => setOrder(new OrderModel()),
    setOrderProperty: (value: any, name: string, child?: string) => {
      if (child) {
        setOrder({ ...order, [name]: { ...(order as any)[name], [child]: value } });
      } else {
        setOrder({ ...order, [name]: value });
      }
    },
    copyCustomerInfoToDeliveryInfo: () => {
      setOrder({
        ...order,
        delivery: {
          name: order.customer.name1,
          name2: order.customer.name2,
          address: order.customer.address2,
          address2: order.customer.address1,
          zipcode: order.customer.zipcode,
          city: order.customer.city,
          country: "NO",
          contact: "",
          email: order.customer.email,
          mobile: order.customer.phone,
        },
      });
    },
    addProduct: (product: ProductModel) => {
      const newProducts = [...order.products, product];
      const newPoNumbers = order.poNumbers.find((item) => item.supplier.id === product.levnr)
        ? order.poNumbers
        : [...order.poNumbers, { supplier: { id: product.levnr, name: product.levname }, ponr: "" }];
      setOrder({ ...order, products: newProducts, poNumbers: newPoNumbers });
    },
    removeProduct: (product: ProductModel) => {
      const newProducts = order.products.filter((item) => item !== product);
      const newPoNumbers = newProducts.find((item) => item.levnr === product.levnr)
        ? order.poNumbers
        : order.poNumbers.filter((item) => item.supplier.id !== product.levnr);
      setOrder({ ...order, products: newProducts, poNumbers: newPoNumbers });
    },
    updateProduct: (originalProduct: ProductModel, updatedProduct: ProductModel) => {
      const newProducts = order.products.map((item) => item === originalProduct ? updatedProduct : item);
      const newPoNumbers = newProducts.find((item) => item.levnr === originalProduct.levnr)
        ? order.poNumbers
        : order.poNumbers.filter((item) => item.supplier.id !== originalProduct.levnr);
      setOrder({ ...order, products: newProducts, poNumbers: newPoNumbers });
    },
    countries,
    deliveryAlternatives,
  };

  return <OrderContext.Provider value={initValue}>{children}</OrderContext.Provider>;
};

export default OrderContext;
