import DataTable, { TableColumn, createTheme } from "react-data-table-component";

createTheme(
  "solarized",
  {
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
    },
    context: {
      background: "#3C474B",
      text: "#FFFFFF",
    },
    divider: {
      default: "var(--inselo-gray)",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--inselo-light-green)",
      color: "black",
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  rows: {
    style: {
      fontSize: "16px",
    },
  },
};

const MyCheckbox = () => {
  return (
    <div className="flex m-2 text-md p-4 border-double border-b-4 border-[#162521]">
      <div className="flex flex-col gap-2 ml-8">here</div>
    </div>
  );
};

export function CustomDataTable<T>({
  columns,
  data,
  selectableRows,
  expandedRow,
  onAddClicked,
  onMoreClicked,
  onRowDoubleClicked,
}: {
  columns: TableColumn<T>[];
  data: T[];
  selectableRows?: boolean;
  expandedRow?: (data: any) => JSX.Element;
  onAddClicked?: () => void;
  onMoreClicked?: (row: T) => void;
  onRowDoubleClicked?: (row: T) => void;
}) {
  return (
    <DataTable
      columns={
        onMoreClicked
          ? [
              ...columns,
              {
                name: onAddClicked ? (
                  <button onClick={onAddClicked}>
                    <img className="h-6 w-6" src="/assets/images/add-black.svg" alt="" />
                  </button>
                ) : (
                  ""
                ),
                cell: (row: T) => (
                  <button
                    className="h-6 w-6"
                    onClick={() => {
                      onMoreClicked(row);
                    }}
                  >
                    <img src="/assets/images/more.svg" alt="" />
                  </button>
                ),
                right: true,
                width: "4rem",
              },
            ]
          : columns
      }
      data={data}
      theme="solarized"
      customStyles={customStyles}
      expandableRows={expandedRow ? true : false}
      expandableRowsComponent={expandedRow}
      persistTableHead
      pagination
      paginationPerPage={20}
      paginationRowsPerPageOptions={[10, 20, 50, 100]}
      selectableRows={selectableRows}
      onSelectedRowsChange={(e) => {}}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
}
