import { CustomerModel } from "../models/CustomerModel";
import { DeliveryModel } from "../models/DeliveryModel";
import OrderModel from "../models/OrderModel";

const checkCustomerRequiredFields = (c: CustomerModel) => {
  const req = [];
  if (!c.orgnr) req.push("OrgNr");
  if (!c.name1) req.push("Navn 1");
  return req.length > 0 ? `Oppdragsgiver(${req.join(", ")})` : "";
};

const checkDeliveryRequiredFields = (d: DeliveryModel) => {
  const req = [];
  if (!d.name) req.push("Navn");
  if (!d.address2) req.push("Gateadresse");
  if (!d.country) req.push("Land");
  if (!d.zipcode) req.push("Postnr");
  if (!d.city) req.push("Poststed");
  if (!d.contact) req.push("Kontakt");
  if (!d.mobile) req.push("Mobil");
  if (!d.email) req.push("E-post");
  return req.length > 0 ? `Varemottaker (${req.join(", ")})` : "";
};

// const checkOrderInfoRequiredFields = (o: OrderModel) => {
//   const req = [];
//   if (!o.reference) req.push("Kunde referanse");
//   return req.length > 0 ? `Ordrer (${req.join(", ")})` : "";
// };

const checkProducts = (o: OrderModel) => {
  return !o.products?.length ? "Ingen produkter" : "";
};

const checkDeliveryEmail = (email: string) => {
  return email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? "" : `Ugyldig varemottaker e-post: ${email}`;
}

export const checkOrder = (o: OrderModel, ...options: ("customerInfo" | "deliveryInfo" | "orderInfo" | "products" | "deliveryEmail")[]) => {
  const requiredFields: string[] = [];
  let requirements: string[] = [];

  const crf = options.includes("customerInfo") ? checkCustomerRequiredFields(o.customer) : "";
  if (crf) requiredFields.push(crf);
  const drf = options.includes("deliveryInfo") ? checkDeliveryRequiredFields(o.delivery) : "";
  if (drf) requiredFields.push(drf);
  if (requiredFields.length > 0) requirements.push("Obligatoriske felt: " + requiredFields.join(", "));

  const prf = options.includes("products") ? checkProducts(o) : "";
  if (prf) requirements.push(prf);

  const invalidEmail = options.includes("deliveryEmail") ? checkDeliveryEmail(o.delivery.email) : "";
  if(invalidEmail) requirements.push(invalidEmail);

  return requirements.join("; ");
};
