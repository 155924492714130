import { UIField } from "../../../models/UIField";

export const ComboboxField = ({ model, list, readonly, maxChars }: { model: UIField; list?: string; readonly?: boolean; maxChars?: number }) => {
  return (
      <input
        type="text"
        className={`h-8 w-full px-2 rounded border-2 ${!readonly ? "text-black" : "bg-transparent text-white"}`}
        list={list}
        readOnly={readonly}
        value={model.value}
        onInput={(e) => {
          const text = e.currentTarget.value;
          model.setValue(maxChars ? text.substring(0, maxChars) : text);
        }}
      />
  );
};
