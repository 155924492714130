const ProductLayout = ({ children }: { children: JSX.Element[] }) => {
  return (
    <div className="grid grid-cols-12 gap-2 w-full text-sm">
      <div className="col-span-4 grid grid-cols-12 gap-2">
        <div className="flex items-center col-span-3">{children[0]}</div>
        <div className="flex items-center col-span-4">{children[1]}</div>
        <div className="flex items-center col-span-5">{children[2]}</div>
      </div>
      <div className="col-span-3 grid grid-cols-12 gap-2">
        {/* <div className="col-span-2">{children[3]}</div> */}
        <div className="flex items-center col-span-4">{children[3]}</div>
        <div className="flex items-center col-span-4">{children[4]}</div>
        <div className="flex items-center col-span-3">{children[5]}</div>
        <div className="flex items-center col-span-1">{children[6]}</div>
      </div>
      <div className="col-span-5 grid grid-cols-12 gap-2">
        <div className="flex items-center col-span-2">{children[7]}</div>
        <div className="flex items-center col-span-2">{children[8]}</div>
        <div className="flex items-center col-span-2">{children[9]}</div>
        <div className="flex items-center col-span-2">{children[10]}</div>
        <div className="flex items-center col-span-4">{children[11]}</div>
      </div>
    </div>
  );
};

export default ProductLayout;
