import { ProjectBundleModel, ProjectBundleModelMapper } from "./ProjectBundleModel";
import { ModelMapper } from "../ModelMapper";
import { CustomerModel, CustomerModelMapper } from "../CustomerModel";
import { OrderModel, OrderModelMapper } from "../order/OrderModel";
import { ProjectInfoFieldModel } from "./ProjectInfoFieldModel";
import { EmailAlertModel, EmailAlertModelMapper } from "../email/EmailAlertModel";

const mapProductOrder: (backendObject: any) => OrderModel = (backendObject: any) => {
  return OrderModelMapper.fromBackendObject(backendObject);
};

export interface ProjectModel {
  id: string;
  name: string;
  information: ProjectInfoFieldModel[];
  bundles: ProjectBundleModel[];
  customer: CustomerModel;
  orders: OrderModel[];
  emails: EmailAlertModel[];
}

export const ProjectModelMapper: ModelMapper<ProjectModel> = {
  createEmptyObject: () => {
    return {
      id: "",
      name: "",
      information: [],
      bundles: [],
      customer: CustomerModelMapper.createEmptyObject(),
      orders: [],
      emails: [],
    };
  },

  fromBackendObject: (backendObject: any) => {
    return {
      id: backendObject.id,
      name: backendObject.name,
      description: backendObject.description || `description for project ${backendObject.id}`,
      information: backendObject.information || [],
      bundles: backendObject.bundles?.map((item: any) => ProjectBundleModelMapper.fromBackendObject(item)) || [],
      customer: CustomerModelMapper.fromBackendObject(backendObject.customer),
      orders: backendObject.orders?.map((item: any) => mapProductOrder(item)) || [],
      emails:
        backendObject.emails?.map((item: any, i: number) =>
          EmailAlertModelMapper.fromBackendObject({ ...item, id: `${i}` })
        ) || [],
    };
  },

  toBackendObject: (project: ProjectModel) => {
    return {
      ...project,
      bundles: project.bundles.map((item) => ProjectBundleModelMapper.toBackendObject(item)),
      emails: project.emails.map((item) => EmailAlertModelMapper.toBackendObject(item)),
    };
  },
};
