import { TELENOR_API_URL } from "../../../env";
import { ExCustomerMapper, ExCustomerModel, ExOrderMapper, ExOrderModel, ExProductBundleMapper, ExProductBundleModel, ExProductDeliveryOption, ExProductMapper, ExProductModel, ExProductSupplierMapper, ExProductSupplierModel } from "../models";

const apiUrl = TELENOR_API_URL;

export const fetchCustomers: (searchField: string, searchValue: string, userId?: string) => Promise<ExCustomerModel[]> = async (searchField, searchValue, userId) => {
  const url = `${apiUrl}/customers/${searchField ? `${searchField}/` : ""}${searchValue}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const customers = await res.json();
  return customers.map((item: any) => ExCustomerMapper.fromBackendObject(item));
};

export async function fetchCountries(userId?: string): Promise<{ name: string; value: string }[]> {
  const url = `${apiUrl}/countries`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  if (res.status === 200) {
    const fetchedCountries = await res.json();
    const countries = fetchedCountries.map((item: any) => ({ name: item.name, value: item.code }));
    return countries;
  }
  return [];
}

export async function fetchProducts(searchField: string, searchValue: string, userId?: string): Promise<ExProductModel[]> {
  const url = `${apiUrl}/products/${searchField ? `${searchField}/` : ""}${searchValue}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const fetchedProducts = await res.json();
  const products = fetchedProducts.map((item: any) => ExProductMapper.fromBackendObject(item));
  return products;
}

export async function fetchSuppliers(userId?: string): Promise<ExProductSupplierModel[]> {
  const url = `${apiUrl}/suppliers`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const fetchedSuppliers = await res.json();
  const suppliers = fetchedSuppliers.map((item: any) => ExProductSupplierMapper.fromBackendObject(item));
  return suppliers;
}

export async function fetchDeliveryOptions(userId?: string): Promise<ExProductDeliveryOption[]> {
  const url = `${apiUrl}/levalts`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  if (res.status === 200) {
    const fetchedDeliveryOptions = await res.json();
    const deliveryOptions = fetchedDeliveryOptions.map((item: any) => ExProductSupplierMapper.fromBackendObject(item));
    return deliveryOptions;
  }
  return [];
}

export async function fetchExBundles(userId?: string): Promise<ExProductBundleModel[]> {
  const url = `${apiUrl}/ex/bundles`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  if (res.status === 200) {
    const fetchedBundles = await res.json();
    const bundles = fetchedBundles.map((item: any) => ExProductBundleMapper.fromBackendObject(item));
    return bundles;
  }
  return [];
}

export async function fetchExBundle(bundleId: string, userId?: string): Promise<ExProductBundleModel> {
  const url = `${apiUrl}/ex/bundles/${bundleId}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  if (res.status === 200) {
    const fetchedBundle = await res.json();
    const bundle = ExProductBundleMapper.fromBackendObject(fetchedBundle);
    return bundle;
  }
  return ExProductBundleMapper.empty();
}

export const createExBundle = async (bundle: ExProductBundleModel, userId?: string) => {
  const url = `${apiUrl}/ex/bundles`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  console.log("CREATE BUNDLE", bundle);
  const res = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ ...ExProductBundleMapper.toBackendObject(bundle), id: undefined }),
  });
  if (res.status === 200) {
    const body = await res.json();
    console.log("BUNDLE CREATED", body);
    return body;
  }
};

function fixBundleUpdate(bundle: any) {
  return {
    ...bundle,
    products: bundle.products.map((item: any) => {
      if (!item.article_id) {
        return { ...item, id: undefined };
      }
      return item;
    }),
  };
}

export const updateExBundle = async (bundle: ExProductBundleModel, userId?: string) => {
  const url = `${apiUrl}/ex/bundles/${bundle.id}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  console.log("UPDATE BUNDLE", fixBundleUpdate({ ...ExProductBundleMapper.toBackendObject(bundle) }));
  const res = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(fixBundleUpdate({ ...ExProductBundleMapper.toBackendObject(bundle) })),
  });
  if (res.status === 200) {
    const body = await res.json();
    console.log("BUNDLE UPDATED", body);
    return body;
  }
};

export const deleteExBundle = async (bundle: ExProductBundleModel, userId?: string) => {
  const url = `${apiUrl}/ex/bundles/${bundle.id}`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  console.log("DELETE BUNDLE", bundle.id, bundle.name);
  const res = await fetch(url, {
    method: "DELETE",
    headers: headers,
  });
  if (res.status === 200) {
    const body = await res.json();
    console.log("BUNDLE DELETED", body);
    return body;
  }
};

function prepareForSending(order: any) {
  order.order.type = "ex";
  order.flow_id = "5";
  order.status = {
    value: "sap_po",
  };
  return order;
}

export const createExOrder = async (order: ExOrderModel, userId?: string) => {
  const url = `${apiUrl}/orders`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const preparedOrder = prepareForSending(ExOrderMapper.toBackendObject(order));
  console.log("CREATE ORDER", preparedOrder);
  const res = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(preparedOrder),
  });
  if (res.status === 200) {
    const body = await res.json();
    console.log("ORDER CREATED", body);
    return body;
  }
};
