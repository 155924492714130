import { useState } from "react";

const Button = ({
  text,
  onClick,
  onEdit,
  onDelete,
}: {
  text: string;
  onClick: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}) => {
  const [deleteState, setDeleteState] = useState(false);

  return (
    <>
      {deleteState ? (
        <div className="flex flex-col w-72 h-24 border-2 rounded-md text-md">
          <p className="m-auto">Fjern {text}?</p>
          <div className="flex m-auto gap-4">
            <button
              className="tp-button-confirm"
              onClick={() => {
                if (onDelete) {
                  onDelete();
                }
                setDeleteState(false);
              }}
            >
              Ja
            </button>
            <button className="tp-button-cancel" onClick={() => setDeleteState(false)}>
              Nei
            </button>
          </div>
        </div>
      ) : (
        <div className="flex w-72 h-24 border-2 rounded-md text-xl">
          <div className="flex flex-col p-1 gap-2  bg-white">
            {onEdit && (
              <button
                className="h-6 w-6 rounded-md"
                onClick={(e) => {
                  onEdit();
                  e.stopPropagation();
                }}
              >
                <img src="/assets/images/projects/edit.svg" alt="edit" />
              </button>
            )}
            {onDelete && (
              <button
                className="h-6 w-6 rounded-md mt-auto"
                onClick={(e) => {
                  setDeleteState(true);
                  e.stopPropagation();
                }}
              >
                <img src="/assets/images/projects/delete.svg" alt="delete" />
              </button>
            )}
          </div>
          <div className="flex flex-1 cursor-pointer" onClick={onClick}>
            <p className="m-auto">{text}</p>
          </div>
        </div>
      )}
    </>
  );
};

export const ButtonGrid = ({
  buttons,
  onButtonClicked,
  onEdit,
  onDelete,
}: {
  buttons: { id: string; text: string }[];
  onButtonClicked: (buttonId?: string) => void;
  onEdit?: (buttonId: string) => void;
  onDelete?: (buttonId: string) => void;
}) => {
  return (
    <div className="flex flex-wrap m-24 gap-4">
      {buttons.map((button) => (
        <Button
          key={button.id}
          text={button.text}
          onClick={() => onButtonClicked(button.id)}
          onEdit={
            onEdit
              ? () => {
                  onEdit(button.id);
                }
              : undefined
          }
          onDelete={
            onDelete
              ? () => {
                  onDelete(button.id);
                }
              : undefined
          }
        />
      ))}
      <button className="w-72 h-24 border-2 rounded-md text-black text-2xl" onClick={() => onButtonClicked()}>
        <img className="mx-auto" src="/assets/images/add.svg" alt="add" />
      </button>
    </div>
  );
};
