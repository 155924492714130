import { ModelMapper } from "../ModelMapper";

export interface OrderEventModel {
  id: number;
  description: string;
}

export const OrderEventModelMapper: ModelMapper<OrderEventModel> = {
  createEmptyObject: () => {
    return {
      id: 0,
      description: "",
    };
  },

  fromBackendObject: (backendObject: any) => {
    return backendObject;
  },

  toBackendObject: (event: OrderEventModel) => {
    return event;
  },
};
