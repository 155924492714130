import { useContext, useEffect, useState } from "react";
import { CustomDataTable } from "../common/DataTable";
import { Loading } from "../common/Loading";
import { EmailTemplateModel } from "../../models/email/EmailTemplateModel";
import { EmailManagementContext, EmailManagementContextComponent } from "../../contexts/EmailManagementContext";
import { CreateEmailTemplateDialog, EditEmailTemplateDialog } from "../dialogs";

const templateTableColumns = [
  {
    name: "Malnavn",
    selector: (row: EmailTemplateModel) => row.name,
    sortable: true,
  },
  {
    name: "Beskrivelse",
    selector: (row: EmailTemplateModel) => row.description,
    sortable: true,
  },
  {
    name: "Brukt av",
    selector: (row: EmailTemplateModel) => (row.usedBy ? row.usedBy.join(", ") : "-"),
    sortable: true,
  },
];

const EmailManagementPanel = () => {
  const emailManagement = useContext(EmailManagementContext);

  const [status, setStatus] = useState<string>("list"); //loading, list, add, edit
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplateModel | null>(null);

  useEffect(() => {
    (async () => {
      if (!emailManagement.state.status) {
        await emailManagement.refreshTemplates();
      }
    })();
  }, []);

  return (
    <div className="flex h-full w-full relative">
      {emailManagement.state.status === "processing" && (
        <div className="absolute flex z-40 w-full h-full rounded-xl">
          <Loading />
        </div>
      )}
      {emailManagement.state.status === "error" && <div className="m-auto">{emailManagement.state.message}</div>}
      {emailManagement.state.status !== "error" && (
        <div className="m-4 w-full">
          {status === "add" && (
            <CreateEmailTemplateDialog
              onClose={() => {
                setStatus("list");
                setSelectedTemplate(null);
              }}
            />
          )}
          {status === "edit" && selectedTemplate && (
            <EditEmailTemplateDialog
              title={status === "edit" ? "Edit template" : "Add template"}
              template={selectedTemplate}
              onEdit={(template: EmailTemplateModel) => {
                emailManagement.editTemplate(template);
              }}
              onDelete={(templateName: string) => {
                emailManagement.deleteTemplate(templateName);
              }}
              onClose={() => {
                setStatus("list");
                setSelectedTemplate(null);
              }}
            />
          )}

          <div className="w-full">
            <div className="flex mb-2">
              <div className="flex ml-auto">
                <button
                  onClick={() => {
                    setStatus("add");
                  }}
                >
                  <img className="h-10 w-10" src="/assets/images/add-item.svg" alt="add" />
                </button>
              </div>
            </div>
            <CustomDataTable
              columns={templateTableColumns}
              data={emailManagement.allTemplates}
              onMoreClicked={async (row: EmailTemplateModel) => {
                setStatus("edit");
                const template = await emailManagement.getTemplate(row.name);
                setSelectedTemplate(template);
              }}
              // expandedRow={ExpandedRow}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const EmailListForm = () => {
  return (
    <EmailManagementContextComponent>
      <EmailManagementPanel />
    </EmailManagementContextComponent>
  );
};
