type SupportedButtonColors = "red" | "green";

function getButtonColor(buttonColor?: string) {
  const colorMapping = {
    red: "",
    green: "",
  };

  return buttonColor ? (colorMapping as any)[buttonColor] || "" : "";
}

export type ButtonBarModel = { text: string; color?: SupportedButtonColors; action?: string; default?: boolean }[];

export const ButtonBar = ({ buttons, onButtonClicked }: { buttons: ButtonBarModel; onButtonClicked: (action: string) => void }) => {
  return (
    <div className="flex">
      <div className="flex gap-2 ml-auto">
        {buttons.map((button) => (
          <button
            autoFocus={button.default}
            key={button.text}
            className={`w-24 border-2 rounded-md ${getButtonColor(button.color)}`}
            onClick={() => {
              if (button.action) {
                onButtonClicked(button.action);
              }
            }}
          >
            {button.text}
          </button>
        ))}
      </div>
    </div>
  );
};
