export const NumberField = ({
    disabled,
    readonly,
    float,
    value,
    onValueChanged,
  }: {
    disabled?: boolean;
    readonly?: boolean;
    float?: boolean;
    value: string | number;
    onValueChanged?: (value: number) => void;
  }) => {
    const s = readonly ? "addProductReadonlyField" : "addProductField";

    return (
      <input
        type="number"
        className={`${s} text-right`}
        disabled={disabled}
        readOnly={readonly}
        step={float ? 0.01 : 1}
        min={float? 0.00 : 1}
        value={value}
        onChange={(e) => {
          if(onValueChanged) {
            onValueChanged(parseFloat(e.target.value));
          }
        }}
      />
    );
  };