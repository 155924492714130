import { useContext, useState } from "react";
import { Api } from "../backends/Api";
import AppContext from "../../../contexts/AppContext";

type ApiState = "" | "running" | "completed" | "error";
type ApiMethods = {
  fetchAll: () => Promise<any>;
  fetch: (itemId: string) => Promise<any>;
  create: (item: any) => Promise<any>;
  update: (itemId: string, item: any) => Promise<any>;
  delete: (itemId: string) => Promise<any>;
};
type ErrorMessages = {
  fetchAll: string;
  fetch: string;
  create: string;
  update: string;
  delete: string;
};

export function useApiRunner(
  api: Api,
  errors?: ErrorMessages
): {
  methods: ApiMethods;
  status: { state: ApiState; method: string; message: string };
  reset: () => void;
} {
  const appContext = useContext(AppContext);
  const [status, setStatus] = useState<{ state: ApiState; method: string; message: string }>({ state: "", method: "", message: "" });

  const reset = () => setStatus({ state: "", method: "", message: "" });

  const run = async (backendCall: (...args: any[]) => Promise<any>, ...args: any[]) => {
    const methodName = backendCall.name;
    try {
      setStatus({ state: "running", method: methodName, message: "" });
      const result = await backendCall(...args);
      setStatus({ ...status, state: "completed" });
      return result;
    } catch (error: any) {
      const errorMessage = (errors && (errors as any)[methodName]) || error.message;
      setStatus({ ...status, state: "error", message: errorMessage });
      appContext.setNotification({ type: "error", text: errorMessage });
    }
  };

  const userId = appContext.login?.uid;
  const methods: ApiMethods = {
    fetchAll: async () => {
      const result = await run(api.fetchAll, userId);
      return result;
    },
    fetch: async (itemId) => {
      const result = await run(api.fetch, userId, itemId);
      return result;
    },
    create: async (item) => {
      const result = await run(api.create, userId, item);
      return result;
    },
    update: async (itemId, item) => {
      const result = await run(api.update, userId, itemId, item);
      return result;
    },
    delete: async (itemId) => {
      const result = await run(api.delete, userId, itemId);
      return result;
    },
  };

  return { methods, status, reset };
}
