import { useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import backends from "../../../backends";
import AppContext from "../../../contexts/AppContext";
import { OrderModel } from "../../../models/order/OrderModel";
import { ProjectModel, ProjectModelMapper } from "../../../models/project/ProjectModel";
import { DataListColumn } from "../../common/DataList";
import { CustomDataTable } from "../../common/DataTable";
import { ProcessingContext } from "../../../contexts/ProcessingContext";
import { ExportButton } from "../../../export/components/ExportButton";
import { OrderSchema } from "../../../export/schemas/OrderSchema";

const columns: DataListColumn<OrderModel>[] = [
  {
    name: "Navn",
    selector: (row: OrderModel) => row.id,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row: OrderModel) => row.status.value,
    sortable: true,
  },
  {
    name: "Oppdragsgiver Navn",
    selector: (row: OrderModel) => row.customer?.name1,
    sortable: true,
  },
  {
    name: "Varemottaker Navn",
    selector: (row: OrderModel) => row.delivery.name,
    sortable: true,
  },
  {
    name: "Produkter",
    selector: (row: OrderModel) => `${row.products.length}`,
    sortable: true,
  },
  {
    name: "Sporingsnummer",
    selector: (row: OrderModel) => `${row.tracking}`,
    sortable: false,
  },
];

export const OrderList = () => {
  const appContext = useContext(AppContext);
  const processing = useContext(ProcessingContext);
  const match = useMatch("/prosjekter/:id/ordrer");
  const navigate = useNavigate();
  const [project, setProject] = useState<ProjectModel>();
  // const [error, setError] = useState<string>("");

  useEffect(() => {
    const projectId = match?.params.id;
    if (projectId) {
      if (projectId === "ny") {
        setProject(ProjectModelMapper.createEmptyObject());
      } else {
        processing.run(async () => {
          const fetchedProject = await backends.projects.fetch(projectId, appContext.login?.uid);
          setProject(fetchedProject);
        });
      }
    }
  }, [match?.params.id]);
  return project ? (
    <div className="flex flex-1 flex-col p-4">
      <div className="flex gap-4 mb-4">
        <button
          className=""
          onClick={() => {
            navigate("/prosjekter");
          }}
        >
          <img className="h-6 w-6" alt="back to projects" src="/assets/images/projects/back.svg" />
        </button>
        <div className="flex my-auto">
          <ExportButton<OrderModel>
            defaultSchema={OrderSchema}
            onGetExportedItems={() => {
              return project.orders;
            }}
          />
        </div>
      </div>
      <div className="">
        <CustomDataTable
          columns={columns}
          data={project.orders}
          onAddClicked={() => {
            navigate(`/prosjekter/${project.id}/ordrer/ny`);
          }}
          onMoreClicked={async (row: OrderModel) => {
            navigate(`/prosjekter/${project.id}/ordrer/${row.id}`);
          }}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
