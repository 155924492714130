export const ButtonBar = ({
  buttons,
  onButtonClicked,
}: {
  buttons: { text: string; color?: string; action?: string }[];
  onButtonClicked: (action: string) => void;
}) => {
  return (
    <div className="flex">
      <div className="flex gap-2 ml-auto mt-8">
        {buttons.map((button) => (
          <button
            key={button.text}
            className={`w-24 border-2 rounded-md ${button.color}`}
            onClick={() => {
              if (button.action) {
                onButtonClicked(button.action);
              }
            }}
          >
            {button.text}
          </button>
        ))}
      </div>
    </div>
  );
};
