import { FlowModel } from "./FlowModel";

export interface FlowOrderInfoModel {
  id: string;
  flowId: string;
  currentFlowId: string;
  olderFlowId: boolean;
  dates: {
    created?: string;
    updated?: string;
    due?: string;
    completed?: string;
  };
  meta: {
    status: {
      value: string;
      group: string;
    };
    state: {
      value: string;
    };
    claimed?: {
      id: string;
      name: string;
      initials: string;
    };
    info: {
      title: string;
      value: string;
    }[];
    tags: { color: string; text: string; comment: string };
  };
}

export function FlowOrderInfoCreator() {
  function empty(flow?: FlowModel): FlowOrderInfoModel {
    return {
      id: "",
      flowId: flow?.flow_id || "",
      currentFlowId: flow?.flow_id || "",
      olderFlowId: false,
      dates: {},
      meta: {
        status: {
          value: "",
          group: "",
        },
        state: {
          value: "",
        },
        info: [],
        tags: { color: "", text: "", comment: "" },
      },
    };
  }

  function fromBackendObject(bo: any): FlowOrderInfoModel {
    const model: FlowOrderInfoModel = { ...bo };

    const dates: {
      created?: string;
      updated?: string;
      due?: string;
      completed?: string;
    } = {};
    if(bo.dates?.created) dates.created = bo.dates.created.split(" ")[0] || "";
    if(bo.dates?.updated) dates.updated = bo.dates.updated.split(" ")[0] || "";
    if(bo.dates?.due) dates.due = bo.dates.due.split(" ")[0] || "";
    if(bo.dates?.completed) dates.completed = bo.dates.completed.split(" ")[0] || "";
    model.dates = dates;

    const tags = { color: "", text: "", comment: "" };
    if (bo.meta?.tags) {
      bo.meta.tags.forEach((item: any) => {
        const propName = item.title.toLowerCase();
        if (propName === "color") {
          tags.color = item.value;
        } else if (propName === "text") {
          tags.text = item.value;
        } else if (propName === "comment") {
          tags.comment = item.value;
        }
      });
      model.meta.tags = tags;
    }
    return model;
  }

  function toBackendObject(orderInfo: FlowOrderInfoModel): any {
    return orderInfo;
  }

  return {
    empty,
    fromBackendObject,
    toBackendObject,
  };
}
