import { FlowStepFieldsModel } from "./FlowStepFieldsModel";

export interface FlowStepNextStatusModel {
  name: string;
  description: string;
  status: string;
  mapping: {
    from: string;
    to: string;
  }[];
}

export function FlowStepNextStatusCreator(): { empty: () => FlowStepNextStatusModel } {
  function empty() {
    return { name: "", description: "", status: "", mapping: [] };
  }
  return { empty };
}

export interface FlowStepModel {
  id: string;
  name: string;
  status: string;
  status_group: string;
  node_type: string;
  automated: boolean;
  first_step: boolean;
  routine: string;
  step_data: FlowStepFieldsModel;
  next_statuses: FlowStepNextStatusModel[];
  settings: { id: string; value: string }[];

  previous: FlowStepModel[];
  next: FlowStepModel[];
}

export function FlowStepCreator(): { empty: (index?: number) => FlowStepModel; fromBackendObject: (bo: any) => FlowStepModel } {
  function empty(index?: number) {
    return {
      id: "",
      name: `Status ${index}`,
      node_type: "manual",
      status: `status${index}`,
      status_group: "",
      automated: false,
      first_step: false,
      step_data: {
        locked: false,
        in: [],
        out: [],
      },
      routine: "",
      next_statuses: [],
      settings: [],

      previous: [],
      next: [],
    };
  }

  function fromBackendObject(bo: any) {
    return { ...bo, first_step: bo.first_step === "1" };
  }
  return { empty, fromBackendObject };
}
