import { useState } from "react";
import { ModalDialog } from "../../common/components/dialogs";
import { CrudDialog } from "../../common/components/dialogs/CrudDialog";
import { UserModel } from "../models";
import { EditUser } from "./EditUser";

export const EditUserDialog = ({ user, mode, onUpdate, onDelete, onClose }: { user: UserModel; mode: "readonly" | "view" | "edit"; onUpdate: (user: UserModel) => void; onDelete?: () => void; onClose: () => void }) => {
  const [editingUser, setEditingUser] = useState<UserModel>(user);
  const [readonly, setReadonly] = useState<boolean>(mode === "view");

  return mode === "readonly" ? (
    <ModalDialog flex visible onClose={onClose}>
      <EditUser user={editingUser} onSetUser={setEditingUser} readonly />
    </ModalDialog>
  ) : (
    <CrudDialog
      mode={mode}
      onAction={(action) => {
        console.log("ACTION", action)
        if (action === "close") {
          onClose();
        } else if (action === "create" || action === "update") {
          onUpdate(editingUser);
        } else if (action === "delete" && onDelete) {
          onDelete();
        } else if (action === "editOn") {
          setReadonly(false);
        } else if (action === "editOff") {
          setEditingUser(user);
          setReadonly(true);
        }
      }}
    >
      <EditUser user={editingUser} onSetUser={setEditingUser} readonly={readonly} />
    </CrudDialog>
  );
};
