import { FieldModel } from "../../../models/ui/FieldModel";

export const SelectboxField = ({
  field,
  labelWidth,
  editable,
  value,
  items,
  onValueChanged,
}: {
  field: FieldModel;
  labelWidth?: string;
  editable?: boolean;
  value?: string;
  items: { name: string; code: string }[];
  onValueChanged: (value: string) => void;
}) => {
  const s = editable ? "selectBoxField" : "selectBoxReadonlyField";
  const lw = labelWidth ? "w-" + labelWidth : "w-1/3";

  return (
    // editable ? 
    <div className="flex m-1 h-7">
      <label title={field.description} className={`my-auto ${lw}`}>
        {field.text}
      </label>
      <select
        disabled={!editable}
        className={s}
        value={value}
        onChange={(e) => {
          onValueChanged(e.target.value);
        }}
      >
        {items.map((item) => {
          return (
            <option key={item.code} value={item.code}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div> 
    // : <EditboxField field={field} labelWidth={labelWidth} editable={false} value={items.find(item => item.code === value)?.name || ""} onTextChanged={() => {}}/>
  );
};
