import { ButtonBar } from "../common/ButtonBar";
import ModalDialog from "../common/ModalDialog";

export const SimpleFormDialog = ({
  title,
  children,
  buttons,
  onButtonClicked,
  onCloseClicked,
}: {
  title?: string;
  children: JSX.Element;
  buttons: { text: string; action: string, color?: string; default?: boolean }[];
  onButtonClicked: (text: string) => void;
  onCloseClicked: () => void,
}) => {
  return (
    <ModalDialog
      flex
      visible
      title={title}
      onClose={onCloseClicked}
    >
      <div onKeyUp={(e) => {
        if(e.code === "Enter") {
          onButtonClicked(buttons.find((button) => button?.default || false)?.action || "");
        }
      }}>
        <div>{children}</div>
        <ButtonBar buttons={buttons} onButtonClicked={onButtonClicked} />
      </div>
    </ModalDialog>
  );
};
