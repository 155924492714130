import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface SidebarItem {
  label: string;
  icon: string;
  route: string;
}

const SidebarButton = ({ label, icon, selected, onClick }: { label: string; icon: string; selected?: boolean; onClick: () => void }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <button className="z-50 flex text-black h-10 w-10" onClick={onClick} onMouseEnter={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)}>
      {expanded && (
        <div className="absolute flex ml-10 pl-4 w-48 h-10 text-left iwgray rounded-r-full">
          <h3 className="my-auto">{label}</h3>
        </div>
      )}
      <img className={`mx-auto my-auto ${selected ? "border-2 border-black rounded-md h-10 w-10" : "h-8 w-8"}`} src={icon} alt={label} />
    </button>
  );
};

export const Sidebar = ({ items }: { items: SidebarItem[] }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col h-full p-1 font-bold text-md z-50 w-full gap-1 bg-inselo-light-blue rounded-md">
      {items.map((item) => {
        return (
          <SidebarButton
            key={item.label}
            label={item.label}
            icon={item.icon}
            selected={item.route === "/" ? location.pathname === "/" : location.pathname.startsWith(item.route)}
            onClick={() => {
              navigate(item.route);
            }}
          />
        );
      })}
    </div>
  );
};
