import React, { useContext, useEffect, useState } from "react";
import { ExProductModel } from "../../models/ExProductModel";
import { PriceCalculator } from "../../utils/PriceCalculator";
import { CheckboxField, EditboxField, NumberField, OutPriceField, ReadonlyField, SelectboxField } from "./product-field";
import { ExOrderProductLineMapper, ExOrderProductLineModel } from "../../models";
import { isProductEditable } from "../../utils/ProductHelper";
import { ModuleContext } from "../../../common/components";
import { ExBackend } from "../../backends";
import { Datalist } from "../common";
import { ComboboxField } from "./product-field";
import { AppConstantsContext } from "../../../../contexts/AppConstantsContext";

const PRODUCT_NUMBER_DATALIST_NAME = "productNumberList";
const PRODUCT_EAN_DATALIST_NAME = "productEanList";
const PRODUCT_DESCRIPTION_DATALIST_NAME = "productDescriptionList";

export function useProductLineEditor(initialValue: ExOrderProductLineModel, Layout: React.ComponentType<{ children: JSX.Element[] }>) {
  const processor = useContext(ModuleContext);
  const appConstants = useContext(AppConstantsContext);

  const [productLine, setProductLine] = useState<ExOrderProductLineModel | undefined>(initialValue);
  const [searchFields, setSearchFields] = useState({ number: "", ean: "", description: "" });
  const [filteredProducts, setFilteredProducts] = useState<ExProductModel[]>([]);

  useEffect(() => {
    if (initialValue) {
      setSearchFields({ number: initialValue.number, ean: initialValue.ean, description: initialValue.description });
    }
  }, []);

  const productFields = {
    number: { value: searchFields.number, setValue: (value: string) => filterProducts("number", value) },
    ean: { value: searchFields.ean, setValue: (value: string) => filterProducts("ean", value) },
    description: { value: searchFields.description, setValue: (value: string) => filterProducts("description", value) },
    levnr: { disabled: !productLine, value: productLine?.levnr || "", setValue: (value: string) => setProductSupplier(value, productLine?.custom) },
    lev_alt: { disabled: !productLine, value: productLine?.lev_alt || "", setValue: (value: string) => productLine && setProductLine({ ...productLine, lev_alt: value }) },
    bid: { disabled: !productLine, value: productLine?.bid || "", setValue: (value: string) => productLine && setProductLine({ ...productLine, bid: value }) },
    moms: { disabled: !productLine, value: productLine?.moms || false, setValue: (value: boolean) => productLine && setProductLine({ ...productLine, moms: value }) },
    quantity: { disabled: !productLine, value: productLine?.quantity || "", setValue: (value: number) => productLine && setProductLine({ ...productLine, quantity: value }) },
    calculation: { disabled: !productLine || isProductEditable(productLine), value: productLine?.calculation || "", setValue: (value: string) => setProductPrices(productLine?.price || 0, value) },
    price: { disabled: !productLine, value: productLine?.price.toFixed(2) || "", setValue: (value: number) => setProductPrices(value, productLine?.calculation || "0%") },
    information: { disabled: !productLine, value: productLine?.information || "", setValue: (value: string) => productLine && setProductLine({ ...productLine, information: value }) },
  };

  async function filterProducts(fieldName: string, searchValue: string) {
    setSearchFields({ ...searchFields, [fieldName]: searchValue });
    if (searchValue.length >= 3) {
      for (const prod of filteredProducts) {
        if ((prod as any)[fieldName] === searchValue) {
          setFilteredProducts([]);
          setSearchFields({ number: prod.number, ean: prod.ean, description: prod.description });
          setProductLine(ExOrderProductLineMapper.fromProduct(prod));
          return;
        }
      }

      try {
        const products = await processor?.runSilently(ExBackend.fetchProducts, fieldName === "number" ? "" : searchValue, searchValue);
        setFilteredProducts(products);
      } catch (err) {
        setFilteredProducts([]);
        console.log("ERROR", err);
      }
      setProductLine(undefined);
    } else {
      setFilteredProducts([]);
      setSearchFields({ number: "", ean: "", description: "", [fieldName]: searchValue });
      setProductLine(undefined);
    }
  }

  function getProductSuppliers() {
    if (productLine?.custom) {
      return appConstants.allSuppliers.map((item) => ({ label: item.levname, value: item.levnr })) || [];
    } else {
      return productLine?.suppliers?.map((item) => ({ label: item.levname, value: item.levnr })) || [];
    }
  }

  const setProductSupplier = (levnr: string, customize?: boolean) => {
    if (productLine) {
      const supplier = customize ? appConstants.allSuppliers.find((s) => s.levnr === levnr) : productLine.suppliers.find((s) => s.levnr === levnr);
      if (supplier) {
        setProductLine({ ...productLine, levnr: supplier.levnr, levname: supplier.levname });
      }
    }
  };

  const setProductPrices = (price: number, calculation: string) => {
    let marginValue;
    let outPrice;
    if (productLine) {
      if (calculation[calculation.length - 1] === "%") {
        marginValue = parseFloat(calculation.slice(0, calculation.length - 1));
        outPrice = price * (1 + marginValue / 100);
      } else {
        marginValue = parseFloat(calculation);
        outPrice = price + marginValue;
      }

      setProductLine({ ...productLine, price, calculation, outPrice });
    }
  };

  return {
    value: productLine,
    layout: (
      <>
        <Layout>
          <ComboboxField model={productFields.number} list={PRODUCT_NUMBER_DATALIST_NAME} />
          <ComboboxField model={productFields.ean} list={PRODUCT_EAN_DATALIST_NAME} />
          <ComboboxField model={productFields.description} list={PRODUCT_DESCRIPTION_DATALIST_NAME} />
          <SelectboxField model={productFields.levnr} items={getProductSuppliers()} />
          <SelectboxField model={productFields.lev_alt} items={appConstants.deliveryAlternatives.map((item) => ({ label: item.description, value: item.lev_alt }))} />
          <EditboxField model={productFields.bid} />
          <CheckboxField model={productFields.moms} />
          <NumberField model={productFields.quantity} />
          <EditboxField model={productFields.calculation} style="text-right" />
          {productLine?.custom || isProductEditable(productLine) ? <NumberField model={productFields.price} /> : <ReadonlyField value={productLine?.price.toFixed(2) || ""} />}
          <OutPriceField product={productLine} setProduct={setProductLine} />
          <ReadonlyField value={productLine ? PriceCalculator.GetTotalPricesFormated(productLine?.price, productLine?.outPrice, productLine?.quantity) : ""} />
          <EditboxField model={productFields.information} />
        </Layout>

        <Datalist id={PRODUCT_NUMBER_DATALIST_NAME} items={filteredProducts} getTitle={(prod) => prod.number} getDescription={(prod) => `${prod.description} (levnr: ${prod.levnr}, levartnr: ${prod.levartnr}, gartnr: ${prod.gartnr})`} />
        <Datalist id={PRODUCT_EAN_DATALIST_NAME} items={filteredProducts} getTitle={(prod) => prod.ean} getDescription={(prod) => `${prod.description}`} />
        <Datalist id={PRODUCT_DESCRIPTION_DATALIST_NAME} items={filteredProducts} getTitle={(prod) => prod.description} getDescription={(prod) => `${prod.number}`} />
      </>
    ),
  };
}
