import { useContext, useEffect, useState } from "react";
import { ModalDialog } from "../../../common/components/dialogs";
import { FlowOrderInfoModel, FlowOrderModel } from "../../models";
import { ModuleContainer, ModuleContext } from "../../../common/components";
import { FlowBackend } from "../../backends";
import { OrderDetails } from ".";

function OrderDetailsContainer({ orderId }: { orderId: string }) {
  const processor = useContext(ModuleContext);
  const [order, setOrder] = useState<FlowOrderModel>();

  useEffect(() => {
    (async () => {
      setOrder(undefined);
      const fetchedOrder = await processor?.run(FlowBackend.getOrder, orderId);
      setOrder(fetchedOrder);
    })();
  }, [orderId]);

  return order ? (
    <OrderDetails
      order={order}
      onUpdateOrder={async (updatedOrder) => {
        const result = await processor?.run(FlowBackend.updateOrder, updatedOrder);
      }}
    />
  ) : null;
}

export function MultipleOrdersDetailsDialog({ orders, onClose }: { orders: FlowOrderInfoModel[]; onClose: () => void }) {
  const [orderIndex, setOrderIndex] = useState<number>(0);

  return (
    <ModalDialog title={`Order #${orders[orderIndex].id}`} visible onClose={onClose}>
      <div className="flex flex-1 flex-col">
        <section className="flex">
          {orderIndex > 0 ? (
            <button className="px-1 rounded-md bg-black border-2" onClick={() => setOrderIndex(orderIndex - 1)}>
              <img className="h-8 w-8" src={`/assets/images/flows/backward.svg`} alt="" />
            </button>
          ) : null}
          {orderIndex < orders.length - 1 ? (
            <button className="ml-auto px-1 rounded-md bg-black border-2" onClick={() => setOrderIndex(orderIndex + 1)}>
              <img className="h-8 w-8" src={`/assets/images/flows/forward.svg`} alt="" />
            </button>
          ) : null}
        </section>
        <ModuleContainer name="edit-all-orders">
          <OrderDetailsContainer orderId={orders[orderIndex].id} />
        </ModuleContainer>
      </div>
    </ModalDialog>
  );
}
