import { OrderBundleModel } from "../../../../models/project/OrderBundleModel";
import { ProjectBundleModel } from "../../../../models/project/ProjectBundleModel";
import { ProductsPanel } from "../../../panels/order/ProductsPanel";
import OrderModel from "../../../../models/OrderModel";
import { useState } from "react";
import { TotalPricePanel } from "../../../panels/order/TotalPricePanel";

const BundleButton = ({
  bundle,
  click,
  plusClick,
  minusClick,
}: {
  bundle: OrderBundleModel;
  click: (state: "down" | "up") => void;
  plusClick: () => void;
  minusClick: () => void;
}) => {
  return (
    <div className="flex rounded-md border-2" onMouseLeave={() => click("up")}>
      <div className="flex flex-col gep-2 w-48 my-auto p-2">
        <button onMouseDown={() => click("down")} onMouseUp={() => click("up")}>
          <p className="text-2xl text-center">{bundle.name}</p>
          <p className="text-sm text-center">{bundle.description}</p>
        </button>
      </div>

      <div className="flex flex-col my-auto border-l-2 w-8">
        <button
          className="font-bold text-xl"
          onMouseDown={() => {
            click("down");
          }}
          onMouseUp={() => {
            click("up");
            plusClick();
          }}
        >
          +
        </button>
        <p className="text-center">{bundle.quantity}</p>
        <button
          className="font-bold text-xl"
          onMouseDown={() => {
            click("down");
          }}
          onMouseUp={() => {
            click("up");
            minusClick();
          }}
        >
          -
        </button>
      </div>
    </div>
  );
};
const BundleList = ({
  bundles,
  onAction,
}: {
  bundles: OrderBundleModel[];
  onAction: (action: "add" | "remove" | "highlightOn" | "highlightOff", bundle: OrderBundleModel) => void;
}) => {
  return (
    <div className="flex m-6 gap-4">
      {bundles?.map((bundle) => (
        <BundleButton
          bundle={bundle}
          click={(state) => {
            if (state === "down") {
              onAction("highlightOn", bundle);
            } else {
              onAction("highlightOff", bundle);
            }
          }}
          plusClick={() => {
            onAction("add", bundle);
          }}
          minusClick={() => {
            onAction("remove", bundle);
          }}
        />
      ))}
    </div>
  );
};

const columns = [
  {
    name: "Navn",
    selector: (row: ProjectBundleModel) => row.name,
    sortable: true,
    width: "25%",
  },
  {
    name: "Beskrivelse",
    selector: (row: ProjectBundleModel) => row.description,
    sortable: true,
    width: "45%",
  },
  {
    name: "Mengde",
    selector: (row: ProjectBundleModel) => row.id,
    sortable: true,
    width: "15%",
  },
];

export const BundlesPanel = ({ bundles, order, setOrder }: { bundles: ProjectBundleModel[]; order: OrderModel; setOrder: (order: OrderModel) => void }) => {
  const [highlightedBundleId, setHighlightedBundelId] = useState<string | null>(null);

  const handleAddAction = (bundle: OrderBundleModel) => {
    const bundles = order.bundles.map((item) => (item === bundle ? { ...item, quantity: item.quantity + 1 } : item));
    const found = order.products.find((item) => item.bundle_id === bundle.id);

    if (found) {
      setOrder({
        ...order,
        bundles,
        products: order.products.map((item) => (item.bundle_id === bundle.id ? { ...item, quantity: item.quantity + (bundle.products.find(p => p.id === item.id)?.quantity || 1) } : item)),
      });
    } else {
      setOrder({
        ...order,
        bundles,
        products: [...order.products, ...bundle.products.map((item) => ({ ...item, quantity: (bundle.products.find(p => p.id === item.id)?.quantity || 1) }))],
      });
    }
  };

  const handleRemoveAction = (bundle: OrderBundleModel) => {
    const bundles = order.bundles.map((item) => (item === bundle ? { ...item, quantity: item.quantity - (bundle.products.find(p => p.id === item.id)?.quantity || 1) } : item));
    setOrder({
      ...order,
      bundles,
      products: order.products
        .map((item) => (item.bundle_id === bundle.id ? { ...item, quantity: item.quantity - (bundle.products.find(p => p.id === item.id)?.quantity || 1) } : item))
        .filter((item) => item.quantity > 0),
    });
  };

  const mapBundles: (bundles: ProjectBundleModel[]) => OrderBundleModel[] = (bundles) => {
    return bundles.map((b) => {
      const orderProduct = order.products.find((p) => p.bundle_id === b.id);
      const bundleProduct = b.products.find((p) => p.id === orderProduct?.id);
      const quantity = (orderProduct?.quantity || 0) / (bundleProduct?.quantity || 1);
      return { ...b, quantity: quantity };
    });
  };

  return (
    <div className="flex flex-col gap-8 flex-1">
      <BundleList
        bundles={mapBundles(bundles)}
        onAction={(action, bundle) => {
          if (action === "add") {
            handleAddAction(bundle);
          } else if (action === "remove" && bundle.quantity > 0) {
            handleRemoveAction(bundle);
          } else if (action === "highlightOn") {
            setHighlightedBundelId(`${bundle.id}`);
          } else if (action === "highlightOff") {
            setHighlightedBundelId(null);
          }
        }}
      />
      <ProductsPanel
        products={order.products}
        addProduct={(product) => setOrder({ ...order, products: [...order.products, product] })}
        removeProduct={(product) => setOrder({ ...order, products: order.products.filter((item) => item !== product) })}
        updateProduct={(product, updatedProduct) =>
          setOrder({
            ...order,
            products: [...order.products.map((item) => (item === product ? updatedProduct : item))],
          })
        }
        edit
        highlighted={order.products.filter((item) => item.bundle_id === highlightedBundleId)}
      />
      <div className="flex mr-8">
        <TotalPricePanel products={order.products} />
      </div>
      {/* <CustomDataTable
        columns={columns}
        data={bundles}
        selectableRows

        // onMoreClicked={async (row: ProjectBundleModel) => {
        //   setStatus("edit");
        //   const template = await emailManagement.getTemplate(row.name);
        //   setSelectedTemplate(template);
        // }}
        // expandedRow={ExpandedRow}
      /> */}
    </div>
  );
};
