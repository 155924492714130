import { ModulePermissionLevels } from "../utils/ModulePermissionLevels";

const CURRENT_VERSION = 1;

export interface ModuleAccessLevel {
  value: number;
  label: string;
  description: string;
}

export interface UserAccessModel {
  role: string;
  modules: { [moduleName: string]: ModuleAccessLevel };
  version: number;
}

export const UserAccessMapper = {
  empty: function (): UserAccessModel {
    return {
      role: "user",
      modules: {},
      version: CURRENT_VERSION,
    };
  },

  fromBackendObject: function (bo: any): UserAccessModel {
    const access: UserAccessModel = { ...UserAccessMapper.empty() };
    if (bo) {
      if (bo.version && bo.version >= CURRENT_VERSION) {
        access.role = bo.role;
        access.version = bo.version;
      }

      for (const item of ModulePermissionLevels) {
        if (bo.modules && bo.modules[item.moduleName] !== undefined) {
          const foundLevel = item.levels.find((levelItem) => levelItem.value === bo.modules[item.moduleName]);
          access.modules[item.moduleName] = foundLevel || item.levels[item.levels.length - 1];
        } else {
          const modulePermissions = item.levels.find((levelItem) => levelItem.value === item.default);
          if (modulePermissions) {
            access.modules[item.moduleName] = modulePermissions;
          }
        }
      }
    }

    return access;
  },

  toBackendObject: function (access: UserAccessModel): any {
    return { ...access, modules: Object.keys(access.modules).reduce((prev, curr) => ({ ...prev, [curr]: access.modules[curr].value }), {}) };
  },
};
