import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ProjectModel } from "../../models/project/ProjectModel";
import { ButtonGrid } from "../common/ButtonGrid";
import AppContext from "../../contexts/AppContext";
import backends from "../../backends";
import { ProcessingContext } from "../../contexts/ProcessingContext";

export const ProjectListForm = () => {
  const processing = useContext(ProcessingContext);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [allProjects, setAllProjects] = useState<ProjectModel[]>();

  useEffect(() => {
    processing.run(async () => {
      const fetchedProjects = await backends.projects.fetchAll(appContext.login?.uid);
      setAllProjects(fetchedProjects);
    });
    // processing.setState({status: "processing"})
    //     backends.projects
    //       .fetchAll(appContext.login?.uid)
    //       .then((fetchedProjects) => {
    //         console.log("READY")
    //         processing.setState({status: "ready"});
    //         setAllProjects(fetchedProjects);
    //       })
    //       .catch((error) => setError(error));
  }, []);

  // if (allProjects) {
  return allProjects ? (
    <div className="">
      <ButtonGrid
        buttons={allProjects.map((item) => ({ id: item.id, text: item.name }))}
        onButtonClicked={(projectId) => {
          if (projectId) {
            navigate(`/prosjekter/${projectId}/ordrer`);
          } else {
            navigate("/prosjekter/ny");
          }
        }}
        onEdit={(projectId) => {
          navigate(`/prosjekter/${projectId}`);
        }}
        onDelete={(projectId) => {
          processing.run(async () => {
            await backends.projects.delete(projectId, appContext.login?.uid);
            const fetchedProjects = await backends.projects.fetchAll(appContext.login?.uid);
            setAllProjects(fetchedProjects);
          });
          // setAllProjects(allProjects.filter((item) => item.id !== projectId));
        }}
      />
    </div>
  ) : (
    <></>
  );
  // );
  // } else if (error) {
  //   return <div className="m-auto text-xl font-bold">Error {error}</div>;
  // } else {
  //   return <Loading />;
  // }
};
