import { FlowStepCreator, FlowStepModel } from "../../models";

export type FlowStepAction = "select" | "add" | "remove" | "switch";

function FlowStep({ step, isActive, isLast, viewonly, onAction }: { step: FlowStepModel; isActive: boolean; isLast: boolean; viewonly?: boolean; onAction: (action: FlowStepAction) => void }) {
  return (
    <>
      <button className={`flex p-1 rounded-md border-2 ${isActive ? "bg-gray-400" : ""}`} onClick={() => onAction("select")}>
        <div className="text-left">
          <h3 className="text-xl font-bold">{step.name}</h3>
          <p className="text-xs">ID: {step.status}</p>
          <p className="text-xs">type: {step.node_type}</p>
        </div>
        {!viewonly ? (
          <button
            className="ml-auto my-auto h-6 w-6"
            onClick={(e) => {
              onAction("remove");
              e.stopPropagation();
            }}
          >
            <img src="/assets/images/flows/remove.svg" alt="remove" />
          </button>
        ) : null}
      </button>
      {!isLast ? (
        !viewonly ? (
          <button className="mx-auto h-6 w-6">
            <img src="/assets/images/flows/switch.svg" onClick={() => onAction("switch")} alt="add" />
          </button>
        ) : (
          <div className="flex mx-auto h-4">
            {step.next_statuses?.map(() => (
              <img className="h-4 w-4" src="/assets/images/flows/down-arrow.svg" alt="next" />
            ))}
          </div>
        )
      ) : null}
    </>
  );
}

export function FlowSteps({
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  viewonly,
}: {
  steps: FlowStepModel[];
  setSteps: (steps: FlowStepModel[]) => void;
  activeStep?: FlowStepModel | null;
  setActiveStep: (step: FlowStepModel | null) => void;
  viewonly?: boolean;
}) {
  function stepActionHandler(step: FlowStepModel | null, action: FlowStepAction) {
    if (action === "add") {
      const newStep: FlowStepModel = FlowStepCreator().empty(steps?.length + 1 || 0);
      setSteps(steps?.length ? [...steps, newStep] : [newStep]);
      setActiveStep(newStep);
    } else if (action === "select") {
      setActiveStep(step);
    } else if (action === "remove") {
      if (step === activeStep) {
        setActiveStep(null);
      }
      setSteps(steps?.filter((item) => item !== step));
    } else if (action === "switch") {
      if (step) {
        const i = steps.indexOf(step);
        const updatedSteps = [...steps];
        updatedSteps[i] = updatedSteps[i + 1];
        updatedSteps[i + 1] = step;
        setSteps(updatedSteps);
      }
    }
  }

  return (
    <div className="flex flex-1 flex-col gap-1">
      {steps.map((step, i) => (
        <FlowStep step={step} isActive={step === activeStep} isLast={i === steps.length - 1} viewonly={viewonly} onAction={(action) => stepActionHandler(step, action)} />
      ))}
      {!viewonly ? (
        <button className="mx-auto h-6 w-6" onClick={() => stepActionHandler(null, "add")}>
          <img src="/assets/images/flows/add.svg" alt="add" />
        </button>
      ) : null}
    </div>
  );
}
