export type Language = "nb-NO" | "en-US";
export type Gender = "FEMALE" | "MALE";
export type Format = "WAV" | "MP3";

export const supportedLanguage = [
  { name: "Engelsk", id: "en-US" },
  { name: "Norsk", id: "nb-NO" },
];

export const supportedGender = [
  { name: "Kvinne", id: "FEMALE" },
  { name: "Mann", id: "MALE" },
];

export const supportedFormat = [
  { name: "MP3", id: "MP3" },
  { name: "WAV", id: "WAV" },
];

export const supportedSpeed = [0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1.0, 1.25, 1.5, 1.75, 2.0, 2.5, 3.0, 3.5, 4.0];
export const supportedDelay = [0, 0.25, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 4, 5];

interface TextSectionModel {
  text: string;
  language: Language;
  gender: Gender;
  delay: number;
}

export function TextSectionModelManager() {
  function create(): TextSectionModel {
    return {
      text: "",
      language: "nb-NO",
      gender: "MALE",
      delay: 0,
    };
  }

  return {
    create,
  };
}

export interface Text2SpeechModel {
  defaultLanguage: Language;
  defaultGender: Gender;
  textSections: TextSectionModel[];
  format: Format;
  speed: number;
}

export function Text2SpeechModelManager() {
  function create(): Text2SpeechModel {
    return {
      defaultLanguage: "nb-NO",
      defaultGender: "MALE",
      textSections: [TextSectionModelManager().create()],
      format: "WAV",
      speed: 1.0,
    };
  }

  function fromJson(json: string): Text2SpeechModel {
    return JSON.parse(json) as Text2SpeechModel;
  }

  function toJson(model: Text2SpeechModel): string {
    return JSON.stringify(model);
  }

  function fromSsml(): Text2SpeechModel {
    return {
      defaultLanguage: "nb-NO",
      defaultGender: "MALE",
      textSections: [],
      format: "WAV",
      speed: 1.0,
    };
  }

  function toSsml(model: Text2SpeechModel) {
    const ssml = model.textSections.map((section) => {
      let sectionSsml = "";
      if (section.delay > 0) {
        sectionSsml += `<break time="${section.delay * 1000}ms"/>`;
      }
      if (section.language === model.defaultLanguage && section.gender === model.defaultGender) {
        sectionSsml += section.text;
      } else {
        sectionSsml += `<voice${section.language !== model.defaultLanguage ? ' language="' + section.language + '"' : ""}${section.gender !== model.defaultGender ? ' gender="' + section.gender + '"' : ""}>${section.text}</voice>`;
      }
      return sectionSsml;
    });
    return `<speak>${ssml.join("")}</speak>`;
  }

  return {
    create,
    fromJson,
    toJson,
    fromSsml,
    toSsml,
  };
}
