import { UIField } from "../../../models/UIField";

export const SelectboxField = ({ model, disabled, items }: { model: UIField; disabled?: boolean; items: { name: string; value: string }[] }) => {
  return (
    <div className="flex h-7 items-center">
      <label title={model.description}>{model.label}</label>
      <select
        className={`ml-auto h-full w-3/5 rounded border-2 ${!disabled ? "text-black" : "bg-transparent text-white"}`}
        disabled={disabled}
        value={model.value}
        onChange={(e) => {
          model.setValue(e.target.value);
        }}
      >
        {items.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
