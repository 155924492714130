import { ReactNode, createContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import OrderModel from "../models/OrderModel";
import { fetchOrders } from "../backend";

export const OrdersManagementContext = createContext<{
  state: {
    status: string;
    message: string;
  };
  allOrders: OrderModel[];
  refreshOrders: () => void;
}>({
  state: {
    status: "",
    message: "",
  },
  allOrders: [],
  refreshOrders: () => {},
});

export const OrdersManagementContextComponent = ({ children }: { children: ReactNode }) => {
  const auth0 = useAuth0();

  const [state, setState] = useState<{
    status: string;
    message: string;
  }>({
    status: "",
    message: "",
  });
  const [allOrders, setAllOrders] = useState<OrderModel[]>([]);

  const initValue = {
    state,
    allOrders,
    refreshOrders: async () => {
      try {
        setState({ status: "processing", message: "" });
        const orders = await fetchOrders(auth0.user?.sub);
        setAllOrders(orders.reverse());
        setState({ status: "ready", message: "" });
      } catch (err: any) {
        setState({ status: "error", message: err?.message || "Kunne ikke hente ordrer" });
      }
    },
  };

  return <OrdersManagementContext.Provider value={initValue}>{children}</OrdersManagementContext.Provider>;
};
