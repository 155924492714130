import { useEffect, useState } from "react";
import { ExOrderProductLineModel } from "../../../models";

export const OutPriceField = ({ product, setProduct }: { product?: ExOrderProductLineModel; setProduct: (product: ExOrderProductLineModel) => void }) => {
  const [text, setText] = useState(product?.outPrice?.toString() || "");

  useEffect(() => {
    setText(product?.outPrice.toString() || "");
  }, [product?.calculation]);

  return (
    <input
      type="text"
      className={`addProductField text-right`}
      disabled={!product}
      value={text}
      onChange={(e) => {
        const newValue = e.currentTarget.value;
        setText(newValue);

        const outPrice = parseFloat(newValue);
        if (product && !isNaN(outPrice)) {
          const calculation = ((outPrice - product.price) / product.price) * 100;
          let calculationString = "";
          if (isFinite(calculation)) {
            calculationString = calculation.toFixed(2) + "%";
          }
          setProduct({ ...product, calculation: calculationString, outPrice });
        }
      }}
    />
  );
};
