import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { fetchCountries, fetchDeliveryAlternatives, fetchInformationReferences } from "../backend";
import backends from "../backends";
import { EmailTemplateModel } from "../models/email/EmailTemplateModel";
import { OrderEventModel } from "../models/email/OrderEventModel";
import { fetchSuppliers } from "../modules/ex/backends/ExBackend";
import AppContext from "./AppContext";
import { FlowStateModel } from "../modules/flows/models";
import { FlowBackend } from "../modules/flows/backends";

export const AppConstantsContext = createContext<{
  countries: { name: string; code: string }[];
  allSuppliers: { levnr: string; levname: string }[];
  deliveryAlternatives: { lev_alt: string; description: string }[];
  orderEvents: OrderEventModel[];
  emailTemplates: EmailTemplateModel[];
  informationReferences: { [key: string]: string };
  flowStates: FlowStateModel[];
  refresh: (constant: "countries" | "deliveryAlternatives" | "orderEvents" | "emailTemplates" | "informationReferences") => void;
}>({
  countries: [],
  allSuppliers: [],
  deliveryAlternatives: [],
  orderEvents: [],
  emailTemplates: [],
  informationReferences: {},
  flowStates: [],
  refresh: async (constant: "countries" | "deliveryAlternatives" | "orderEvents" | "emailTemplates" | "informationReferences") => {},
});

export const AppConstantsContextComponent = ({ children }: { children: ReactNode }) => {
  const appContext = useContext(AppContext);

  const [countries, setCountries] = useState<{ name: string; code: string }[]>([]);
  const [allSuppliers, setAllSuppliers] = useState<{ levnr: string; levname: string }[]>([]);
  const [deliveryAlternatives, setDeliveryAlternatives] = useState<{ lev_alt: string; description: string }[]>([]);
  const [orderEvents, setOrderEvents] = useState<OrderEventModel[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplateModel[]>([]);
  const [informationReferences, setInformationReferences] = useState<{ [key: string]: string }>({});
  const [flowStates, setFlowStates] = useState<FlowStateModel[]>([]);

  const refresh = async (constant: string) => {
    if (constant === "countries") {
      const fetchedCountries = await fetchCountries(appContext.login?.uid);
      setCountries(fetchedCountries);
    } else if (constant === "suppliers") {
      const fetchedSuppliers = await fetchSuppliers(appContext.login?.uid);
      setAllSuppliers(fetchedSuppliers);
    } else if (constant === "deliveryAlternatives") {
      const fetchedDeliveryAlternatives = await fetchDeliveryAlternatives(appContext.login?.uid);
      setDeliveryAlternatives(fetchedDeliveryAlternatives);
    } else if (constant === "orderEvents") {
      const fetchedOrderEvents = await backends.emails.events.fetchAll(appContext.login?.uid);
      setOrderEvents(fetchedOrderEvents);
    } else if (constant === "emailTemplates") {
      const fetchedEmailTemplates = await backends.emails.templates.fetchAll(appContext.login?.uid);
      setEmailTemplates(fetchedEmailTemplates);
    } else if (constant === "informationReferences") {
      const fetchedInformationReferences = await fetchInformationReferences(appContext.login?.uid || "");
      setInformationReferences(fetchedInformationReferences);
    } else if (constant === "flowStates") {
      const fetchedFlowStates = await FlowBackend.getAllFlowStates(appContext.login?.uid || "");
      setFlowStates(fetchedFlowStates);
    }
  };

  useEffect(() => {
    fetchCountries(appContext.login?.uid).then((fetchedCountries) => setCountries(fetchedCountries));
    fetchSuppliers(appContext.login?.uid).then((fetchedSuppliers) => setAllSuppliers(fetchedSuppliers));
    fetchDeliveryAlternatives(appContext.login?.uid).then((fetchedDeliveryAlternatives) => setDeliveryAlternatives(fetchedDeliveryAlternatives));
    backends.emails.events.fetchAll(appContext.login?.uid).then((fetchedOrderEvents) => setOrderEvents(fetchedOrderEvents));
    backends.emails.templates.fetchAll(appContext.login?.uid).then((fetchedEmailTemplates) => setEmailTemplates(fetchedEmailTemplates));
    fetchInformationReferences(appContext.login?.uid || "").then((fetchedData) => setInformationReferences(fetchedData));
    FlowBackend.getAllFlowStates(appContext.login?.uid || "").then((fetchedFlowStates) => setFlowStates(fetchedFlowStates));
  }, []);

  const initValue = {
    countries,
    allSuppliers,
    deliveryAlternatives,
    orderEvents,
    emailTemplates,
    informationReferences,
    flowStates,
    refresh,
  };
  return <AppConstantsContext.Provider value={initValue}>{children}</AppConstantsContext.Provider>;
};
