import { ModelMapper } from "../ModelMapper";

export interface EmailAlertModel {
  id: string;
  emails: string[];
  event_id: number[];
  template_id: string;
}

export const EmailAlertModelMapper: ModelMapper<EmailAlertModel> = {
  createEmptyObject: () => {
    return {
      id: "",
      emails: [],
      event_id: [],
      template_id: "",
    };
  },

  fromBackendObject: (backendObject: any) => {
    return {
      ...backendObject,
      emails: backendObject.emails?.split?.(",") || [],
    };
  },

  toBackendObject: (model: EmailAlertModel) => {
    return { emails: model.emails.join(","), event_id: model.event_id, template_id: model.template_id };
  },
};
