import { TELENOR_API_URL } from "../env";
import { StatsModel, StatsModelMapper } from "../models/stats/StatsModel";

const apiUrl = TELENOR_API_URL;

export const fetchStats: (userId?: string) => Promise<StatsModel[]> = async (userId?: string) => {
  const url = `${apiUrl}/stats`;
  const headers = new Headers();
  if (userId) {
    headers.append("Auth0-User", userId);
  }
  const res = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const orderStats = await res.json();
  return orderStats.map((item: any) => StatsModelMapper.fromBackendObject(item));
};