import { ModuleAccessLevel } from "../models";

export interface ModuleAccessModel { moduleName: string; levels: ModuleAccessLevel[]; default: number };

export const ModulePermissionLevels: ModuleAccessModel[] = [
  {
    moduleName: "dashboard",
    levels: [
      { value: 0, label: "disabled", description: "This is disabled" },
      { value: 1, label: "view", description: "" },
      { value: 2, label: "add information", description: "" },
      { value: 3, label: "edit/delete information", description: "" },
      { value: 4, label: "full access", description: "" },
    ],
    default: 1,
  },
  {
    moduleName: "hw",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "full access", description: "" },
    ],
    default: 1,
  },
  {
    moduleName: "ex",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "full access", description: "" },
    ],
    default: 1,
  },
  {
    moduleName: "orders",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "full access", description: "" },
    ],
    default: 1,
  },
  {
    moduleName: "projects",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "full access", description: "" },
    ],
    default: 1,
  },
  {
    moduleName: "users",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "view users", description: "" },
      { value: 2, label: "add users", description: "" },
      { value: 3, label: "edit/delete users", description: "" },
      { value: 4, label: "full access", description: "" },
    ],
    default: 0,
  },
  {
    moduleName: "emails",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "full access", description: "" },
    ],
    default: 0,
  },
  {
    moduleName: "skl",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "full access", description: "" },
    ],
    default: 1,
  },
  {
    moduleName: "flows",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "viewer", description: "" },
      { value: 2, label: "operator", description: "" },
      { value: 3, label: "administrator", description: "" },
    ],
    default: 0,
  },
  {
    moduleName: "tools",
    levels: [
      { value: 0, label: "disabled", description: "" },
      { value: 1, label: "full access", description: "" },
    ],
    default: 0,
  },
];
