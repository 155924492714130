import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import DataTable, { TableColumn, createTheme } from "react-data-table-component";

createTheme(
  "solarized",
  {
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
    },
    context: {
      background: "",
      text: "#FFFFFF",
    },
    divider: {
      default: "var(--inselo-light-gray)",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--inselo-light-green)",
      color: "black",
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  rows: {
    style: {
      fontSize: "16px",
    },
  },
};

export function CustomDataTable<T>({
  columns,
  data,
  expandedRow,
  rowActions,
  onAddClicked,
  onRowActionClicked,
}: {
  columns: TableColumn<T>[];
  data: T[];
  expandedRow?: (data: any) => JSX.Element;
  rowActions: string[];
  onAddClicked?: () => void;
  onRowActionClicked?: (row: T, action: string) => void;
}) {
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number } | null>(null);
  const [selectedRow, setSelectedRow] = useState<T | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <div className="flex flex-col flex-1">
      <DataTable
        columns={
          onRowActionClicked
            ? [
                ...columns,
                {
                  name: onAddClicked ? (
                    <button onClick={(e) => console.log(e)}>
                      <img className="h-6 w-6" src="/assets/images/add-black.svg" alt="" />
                    </button>
                  ) : (
                    ""
                  ),
                  cell: (row: T) => (
                    <button
                      className="h-6 w-6"
                      onClick={(e) => {
                        setSelectedRow(row);
                        handleContextMenu(e);
                      }}
                    >
                      <img src="/assets/images/more.svg" alt="" />
                    </button>
                  ),
                  right: true,
                },
              ]
            : columns
        }
        data={data}
        theme="solarized"
        customStyles={customStyles}
        expandableRows={expandedRow ? true : false}
        expandableRowsComponent={expandedRow}
        persistTableHead
        pagination
        paginationPerPage={20}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        onSelectedRowsChange={(e) => {}}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        {rowActions.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              handleClose();
              if (selectedRow) {
                onRowActionClicked?.(selectedRow, item);
              }
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
