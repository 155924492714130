import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { fetchCustomers, lookupCity } from "../../../backend";
import OrderModel from "../../../models/OrderModel";
import { EditboxField } from "../../fields/order/EditboxField";
import { OrderFormFields } from "../../../ui/OrderFields";
import { SelectboxField } from "../../fields/order/SelectboxField";
import { CustomerModel } from "../../../models/CustomerModel";
import OrderContext from "../../../contexts/OrderContext";

const DeliveryFields = ({
  deliveryInfo,
  changeFieldValueHandler,
  onCopyCustomerInfo,
  editable,
}: {
  deliveryInfo: any;
  changeFieldValueHandler: (fieldValue: any, fieldName: string, subfieldName?: string) => void;
  onCopyCustomerInfo: () => void;
  editable: boolean;
}) => {
  const orderManagement = useContext(OrderContext);
  const zipcodeField = OrderFormFields.customer.delivery.fields[4];
  const cityField = OrderFormFields.customer.delivery.fields[5];
  const countryField = OrderFormFields.customer.delivery.fields[6];

  useEffect(() => {
    (async () => {
      if (!deliveryInfo.country) {
        changeFieldValueHandler("NO", "delivery", countryField.dataField);
      }
    })();
  }, []);

  return (
    <div>
      <div className="flex border-b-2 mb-2 font-bold">
        {OrderFormFields.customer.delivery.header.text}
        <button hidden={!editable} title="Kopiere fra oppdragsgiver" className="ml-2" onClick={onCopyCustomerInfo}>
          <img className="h-6" src="/assets/images/copy-paste.svg" alt="copy" />
        </button>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-1">
          {OrderFormFields.customer.delivery.fields.slice(0, 4).map((label) => (
            <EditboxField
              key={label.text}
              field={label}
              maxChars={40}
              value={label.dataField ? deliveryInfo[label.dataField] : "not bound"}
              onTextChanged={(text) => {
                changeFieldValueHandler(text, "delivery", label.dataField);
              }}
              editable={editable}
            />
          ))}
          <SelectboxField
            field={countryField}
            key={countryField.text}
            value={deliveryInfo[countryField.dataField]}
            onValueChanged={(text) => {
              changeFieldValueHandler(text, "delivery", countryField.dataField);
            }}
            items={orderManagement.countries}
            editable={editable}
          />
          <EditboxField
            key={zipcodeField.text}
            field={zipcodeField}
            maxChars={40}
            value={zipcodeField.dataField ? deliveryInfo[zipcodeField.dataField] : "not bound"}
            onTextChanged={(text) => {
              changeFieldValueHandler(text, "delivery", zipcodeField.dataField);
            }}
            onEditingCompleted={async () => {
              const city = await lookupCity(deliveryInfo[countryField.dataField], deliveryInfo[zipcodeField.dataField]);
              changeFieldValueHandler(city, "delivery", cityField.dataField);
            }}
            editable={editable}
          />
          <EditboxField
            key={cityField.text}
            field={cityField}
            maxChars={40}
            value={cityField.dataField ? deliveryInfo[cityField.dataField] : "not bound"}
            onTextChanged={(text) => {
              changeFieldValueHandler(text, "delivery", cityField.dataField);
            }}
            editable={editable}
          />
        </div>
        <div className="col-span-1">
          {OrderFormFields.customer.delivery.fields.slice(7, 10).map((label) => (
            <EditboxField
              key={label.text}
              maxChars={label.dataField !== "email" ? 40 : undefined}
              field={label}
              value={label.dataField ? deliveryInfo[label.dataField] : "not bound"}
              onTextChanged={(text) => {
                changeFieldValueHandler(text, "delivery", label.dataField);
              }}
              editable={editable}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const CustomerPanel = ({
  orderInfo,
  changeFieldValueHandler,
  onCopyCustomerInfo,
  edit,
}: {
  orderInfo: OrderModel;
  changeFieldValueHandler: (fieldValue: any, fieldName: string, subfieldName?: string) => void;
  onCopyCustomerInfo: () => void;
  edit: boolean;
}) => {
  const { user } = useAuth0();

  const [customers, setCustomers] = useState<CustomerModel[]>([]);
  const [something, setSomething] = useState({
    orgnr: orderInfo.customer?.orgnr || "",
    name1: orderInfo.customer?.name1 || "",
  });

  const loadCustomers = async (searchField: string, serachValue: string) => {
    const customers = await fetchCustomers(searchField, serachValue, user?.sub);
    setCustomers(customers);
  };

  const purchaseFields: {
    text: string;
    description?: string;
    dataField: string;
    list?: string;
    searchable?: boolean;
  }[] = [...OrderFormFields.customer.purchaser.fields];
  purchaseFields[0] = { ...purchaseFields[0], list: "organizationNumbers", searchable: true };
  purchaseFields[1] = { ...purchaseFields[1], list: "customerNames", searchable: true };
  // const fields = {
  //   customer: [
  //     { label: "Orgnr", name: "orgnr", required: true, list: "organizationNumbers", searchable: true },
  //     { label: "Navn 1", name: "name1", required: true, list: "customerNames", searchable: true },
  //     { label: "Navn 2 ", name: "name2", required: true },
  //     { label: "Adresse ", name: "address1", required: true },
  //     { label: "Postnr ", name: "zipcode", required: true },
  //     { label: "Poststed ", name: "city", required: true },
  //   ],
  //   delivery: [
  //     { label: "Navn", name: "name", required: true },
  //     { label: "Adresse", name: "address", required: true },
  //     { label: "Postnr", name: "zipcode", required: true },
  //     { label: "Poststed", name: "city", required: true },
  //     { label: "Mobil", name: "mobile", required: true },
  //     { label: "E-post", name: "email", required: true },
  //   ],
  // };

  const editBoxClass = edit ? "editBoxField" : "editBoxReadonlyField";

  return (
    <div className="grid grid-cols-7 gap-8 w-full">
      <div className="col-span-2">
        <div className="border-b-2 mb-3 font-bold">Oppdragsgiver</div>
        {purchaseFields.map((field) => (
          <div className="flex m-1 h-7" key={field.dataField}>
            <label title={field.description} className="w-48 my-auto">
              {field.text}
            </label>
            {field.searchable ? (
              <input
                list={field.list}
                type="text"
                className={editBoxClass}
                readOnly={!edit}
                // className=" w-full px-2 rounded-md text-black "
                // value={orderInfo.customer && (orderInfo.customer as any)[field.name]}
                value={(something as any)[field.dataField]}
                onInput={(e) => {
                  const value = e.currentTarget.value;
                  if (field.searchable) {
                    let found = false;
                    if (value.length >= 3) {
                      for (const c of customers) {
                        if ((c as any)[field.dataField] === value) {
                          found = true;
                          changeFieldValueHandler(c, "customer");
                          setSomething({ orgnr: c.orgnr || "", name1: c.name1 || "" });
                          break;
                        }
                      }
                      if (!found) {
                        loadCustomers(field.dataField, value);
                        changeFieldValueHandler(null, "customer");
                        setSomething({ orgnr: "", name1: "", [field.dataField]: value });
                      }
                    } else {
                      setCustomers([]);
                      changeFieldValueHandler(null, "customer");
                      setSomething({ orgnr: "", name1: "", [field.dataField]: value });
                    }
                  }
                }}
              />
            ) : (
              <input className="editBoxReadonlyField" value={orderInfo.customer ? (orderInfo.customer as any)[field.dataField] : ""} />
            )}
            {field.list === "organizationNumbers" && (
              <datalist id="organizationNumbers">
                {customers.map((customer) => (
                  <option value={customer.orgnr} label={`${customer.name1}, ${customer.zipcode ? customer.zipcode + " " : ""}${customer.city || ""}`} key={customer.id} />
                ))}
              </datalist>
            )}
            {field.list === "customerNames" && (
              <datalist id="customerNames">
                {customers.map((customer) => (
                  <option value={customer.name1} label={`${customer.zipcode ? customer.zipcode + " " : ""}${customer.city ? customer.city + " " : ""} (${customer.orgnr})`} key={customer.id} />
                ))}
              </datalist>
            )}
          </div>
        ))}
      </div>
      <div className="col-span-4">
        <DeliveryFields deliveryInfo={orderInfo.delivery} changeFieldValueHandler={changeFieldValueHandler} onCopyCustomerInfo={onCopyCustomerInfo} editable={edit} />
      </div>
      <div className="col-span-1">
        <div className="border-b-2 mb-2 font-bold">Annen</div>
        <div className="flex justify-between mx-1">
          <label>Debitor</label>
          <span>{orderInfo?.customer?.debitor}</span>
        </div>
        <div className="flex justify-between mx-1">
          <label>Reg.by</label>
          <span>{orderInfo?.user?.email}</span>
        </div>
      </div>
    </div>
  );
};
