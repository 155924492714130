import { ModelMapper } from "../ModelMapper";

export interface StatsModel {
  order_id: string;
  date_registered: string;
  products_count: number;
}

export const StatsModelMapper: ModelMapper<StatsModel> = {
    createEmptyObject: () => {
      return {
        order_id: "",
        date_registered: "",
        products_count: 0
      };
    },
  
    fromBackendObject: (backendObject: any) => {
      return { 
        order_id: backendObject.id,
        date_registered: backendObject.date_registered,
        products_count: parseInt(backendObject.linjer)
       };
    },
  
    toBackendObject: (model: StatsModel) => {
      return {};
    },
  };