import { useState } from "react";
import { ToolButton } from "../../../common/elements";
import { FlowModel, FlowNodeTypeModel, FlowOrderCreator, FlowOrderExecutionStepCreator, FlowOrderModel, FlowStepModel } from "../../models";
import { FlowStepEditor } from "./FlowStepEditor";
import { OrderStepEditor } from "../OrderDetails/OrderStepEditor/OrderStepEditor";
import { FlowStepJsonViewer } from "./FlowStepJsonViewer";

type ViewType = "edit" | "view" | "json";

export function FlowStepDetails({
  flow,
  nodeTypes,
  step,
  onSetStep,
  onAction,
}: {
  flow: FlowModel;
  nodeTypes: FlowNodeTypeModel[];
  step: FlowStepModel;
  onSetStep: (step: FlowStepModel) => void;
  onAction: (action: "back" | "select-step", data?: any) => void;
}) {
  const [view, setView] = useState<ViewType>("edit");
  const [order, setOrder] = useState<FlowOrderModel>(FlowOrderCreator().empty(flow));

  const views: { name: string; viewType: ViewType }[] = [
    { name: "Edit", viewType: "edit" },
    { name: "View", viewType: "view" },
    { name: "Json", viewType: "json" },
  ];

  return (
    <div className="flex flex-1 flex-col bg-gray-400 rounded-md p-4 border-2 gap-2 ">
      <div className="flex">
        <ToolButton image="/assets/images/projects/back.svg" onClick={() => onAction("back")} />
        <ul className="flex ml-auto gap-4">
          {views.map((item) => (
            <li>
              <button className={view === item.viewType ? "text-black" : ""} onClick={() => setView(item.viewType)}>
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-1 flex-col text-black bg-white p-4 rounded-md">
        {view === "edit" ? <FlowStepEditor flow={flow} nodeTypes={nodeTypes} step={step} onSetStep={onSetStep} /> : null}
        {view === "view" ? <OrderStepEditor stepData={FlowOrderExecutionStepCreator().empty(step)} /> : null}
        {view === "json" ? <FlowStepJsonViewer step={step} data={order} /> : null}
      </div>
    </div>
  );
}
