import { useState } from "react";
import { CustomDataTable } from "../common/DataTable";
import { Loading } from "../common/Loading";
import { ManagerModel } from "../../models/managers/ManagerModel";
import { CrudDialog } from "../dialogs/CrudDialog";
import { TableColumn } from "react-data-table-component";

export type DataListColumn<T> = TableColumn<T>;

export function DataList<T extends { id: any }>({
  dataManager,
  columns,
  payload,
  EditItemComponent,
  onAddClicked,
  onMoreClicked,
}: {
  dataManager: ManagerModel<T>;
  columns: DataListColumn<T>[];
  payload?: any;
  EditItemComponent?: ({
    item,
    setItem,
    readonly,
    payload,
  }: {
    item: T;
    setItem: (item: T) => void;
    readonly: boolean;
    payload?: any;
  }) => JSX.Element;
  onAddClicked?: () => void;
  onMoreClicked?: (item: T) => void;
}) {
  const [editing, setEditing] = useState<boolean>(false);
  const [status, setStatus] = useState<"list" | "processing" | "add dialog" | "more dialog">("list");
  const [selectedItem, setSelectedItem] = useState<T>();

  const actionHandler = async (item: T, action: string) => {
    if (action === "editOn") {
      setEditing(true);
    } else if (action === "editOff") {
      setEditing(false);
    } else if (action === "create") {
      await dataManager.create(item);
      // await refreshData();
    } else if (action === "update") {
      await dataManager.update(item.id, item);
      // await refreshData();
    } else if (action === "delete") {
      await dataManager.delete(item.id);
      // await refreshData();
    } else if (action === "close") {
      setStatus("list");
    }
  };

  const addClickHandler = () => {
    if (onAddClicked) {
      onAddClicked();
    } else {
      setEditing(true);
      setSelectedItem(dataManager.new());
      setStatus("add dialog");
    }
  };

  const moreClickHandler = (row: T) => {
    if (onMoreClicked) {
      onMoreClicked(row);
    } else {
      setEditing(false);
      setSelectedItem(row);
      setStatus("more dialog");
    }
  };

  return (
    <div className="relative flex flex-1">
      {/* {dataManager.state.status === "processing" && (
        <div className="absolute z-50 bg-red-200">
          <Loading />
        </div>
      )} */}
      {dataManager.state.status === "error" && <div className="m-auto">{dataManager.state.message}</div>}
      {dataManager.state.status !== "error" && (
        <>
          {EditItemComponent && selectedItem && (status === "add dialog" || status === "more dialog") && (
            <CrudDialog
              mode={status === "add dialog" ? "edit" : "view"}
              title=""
              onAction={async (action) => await actionHandler(selectedItem, action)}
            >
              <EditItemComponent item={selectedItem} setItem={setSelectedItem} readonly={!editing} payload={payload} />
            </CrudDialog>
          )}
          <div className="flex-1">
            <div className="flex w-full h-8">{dataManager.state.status === "processing" && <Loading size={6} />}</div>
            <div className="flex-1">
              <CustomDataTable
                columns={columns}
                data={dataManager.data}
                onAddClicked={() => addClickHandler()}
                onMoreClicked={(row: T) => moreClickHandler(row)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
