export const CheckboxList = ({ items, checkedItems, setCheckedItems, readonly }: { items: { label: string; value: any }[]; checkedItems: any[]; setCheckedItems: (checkedItems: any[]) => void; readonly?: boolean }) => {
  return (
    <div className="flex flex-col">
      {items.map((item, i) => (
        <div key={i} className="flex">
          <input
            id={`cb${i}`}
            type="checkbox"
            className={``}
            disabled={readonly}
            value={item.value}
            checked={checkedItems.includes(item.value)}
            onChange={(e) => {
              const checked = e.currentTarget.checked;
              if (checked) {
                setCheckedItems([...checkedItems, item.value]);
              } else {
                setCheckedItems(checkedItems.filter((ci) => ci !== item.value));
              }
            }}
          />
          <label className="ml-2" htmlFor={`cb${i}`}>
            {item.label}
          </label>
        </div>
      ))}
    </div>
  );
};
