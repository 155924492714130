import { FieldModel } from "../../../models/ui/FieldModel";

export const PoNrField = ({
  field,
  labelWidth,
  value,
  onChanged,
  editable,
}: {
  field: FieldModel;
  labelWidth?: string;
  value: { supplier: { id: string; name: string }; ponr: string }[];
  onChanged: (supplierId: string, ponrValue: any) => void;
  editable?: boolean;
}) => {
  const lw = labelWidth ? "w-" + labelWidth : "w-1/4";
  return (
    <div className="flex flex-col m-1">
      {value.length > 0 && (
        <label title={field.description} className={`my-auto w-full border-b-2 ${lw}`}>
          {field.text}
        </label>
      )}
      <div className="flex flex-col w-full mt-2 gap-0.5">
        {value &&
          value.map((item) => (
            <div className="flex">
              <label className="w-1/2 my-auto">{item.supplier?.name?.substring(0, 16)}</label>
              <input
                type="text"
                className={`w-1/2 px-2 rounded-md border-2 ${editable ? "text-black" : "bg-transparent text-white"}`}
                readOnly={!editable}
                // placeholder={item.supplier.name}
                value={item.ponr}
                onInput={(e) => {
                  onChanged(item.supplier.id, e.currentTarget.value);
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
