import { useState } from "react";
import { DialogButtonBar } from "../common/DialogButtonBar";
import ModalDialog from "../common/ModalDialog";
import { EmailTemplateModel } from "../../models/email/EmailTemplateModel";
import { HtmlEditor } from "../common/HtmlEditor";

export const EditEmailTemplateDialog = ({
  template,
  title,
  onEdit,
  onDelete,
  onClose,
}: {
  template: EmailTemplateModel;
  title: string;
  onEdit?: (user: EmailTemplateModel) => void;
  onDelete?: (templateName: string) => void;
  onClose: () => void;
}) => {
  const [templateData, setTemplateData] = useState<EmailTemplateModel>(template);
  const [edit, setEdit] = useState<boolean>(false);

  return (
    <ModalDialog flex title={title} visible onClose={onClose}>
      <div className="flex flex-col">
        <div className="mt-4 mb-8 flex">
          {/* {edit ? (
            <HtmlEditor
              html={templateData.html}
              setHtml={(newHtml: string) => {
                setTemplateData({ ...templateData, html: newHtml });
              }}
              readonly={!edit}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: templateData.html }}></div>
          )} */}
          <HtmlEditor
              html={templateData.html}
              setHtml={(newHtml: string) => {
                setTemplateData({ ...templateData, html: newHtml });
              }}
              readonly={!edit}
            />
        </div>
        <DialogButtonBar
          buttons="editdelete"
          onClick={(action: string, state: string) => {
            if (action === "edit") {
              if (state === "started") {
                setEdit(true);
              } else if (state === "confirmed") {
                if (onEdit) {
                  onEdit(templateData);
                }
                onClose();
              } else if (state === "canceled") {
                setEdit(false);
              }
            } else if (action === "delete") {
              if (state === "started") {
              } else if (state === "confirmed") {
                if (onDelete) {
                  onDelete(templateData.name);
                }
                onClose();
              } else if (state === "canceled") {
              }
            }
          }}
        />
      </div>
    </ModalDialog>
  );
};
