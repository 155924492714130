export const CheckboxField = ({
  disabled,
  readonly,
  value,
  onValueChanged,
}: {
  disabled?: boolean;
  readonly?: boolean;
  value: boolean;
  onValueChanged?: (value: boolean) => void;
}) => {
  return (
    <div className="flex w-full h-full">
      <input
        disabled={disabled}
        className="w-4 h-4 my-auto ml-auto"
        type="checkbox"
        checked={value}
        onChange={(e) => {
          if (onValueChanged) {
            onValueChanged(e.target.checked);
          }
        }}
      />
    </div>
  );
};
