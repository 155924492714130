export const EditBox = ({
  label,
  value,
  placeholder,
  onTextChanged,
  onEditingCompleted,
  readonly,
  maxChars,
}: {
  label: string;
  value: string;
  placeholder?: string;
  onTextChanged: (text: string) => void;
  onEditingCompleted?: () => void;
  readonly?: boolean;
  maxChars?: number;
}) => {
  return (
    <div className="flex flex-1 h-7">
      <label className="my-auto w-1/3">{label}</label>
      <input
        type="text"
        placeholder={placeholder}
        className={`w-full px-2 rounded-md border-2 ${readonly ? "bg-transparent text-white" : "text-black"}`}
        readOnly={readonly}
        value={value}
        onInput={(e) => {
          const text = e.currentTarget.value;
          onTextChanged(maxChars ? text.substring(0, maxChars) : text);
        }}
        onBlur={(e) => {
          if (onEditingCompleted) {
            onEditingCompleted();
          }
        }}
      />
    </div>
  );
};
