import { useContext, useEffect, useState } from "react";
import { ModuleContext } from "../../common/components";
import { ExBackend } from "../backends";
import { fetchCustomers } from "../backends/ExBackend";
import { ExCustomerModel, ExOrderModel } from "../models";
import { Datalist } from "./common";
import { ComboboxField, EditboxField, SelectboxField } from "./common/field";

const CUSTOMER_NUMBER_DATALIST_NAME = "customerNumberList";
const CUSTOMER_NAME_DATALIST_NAME = "customerNameList";

export function CustomerPanel({ order, onSetOrder }: { order: ExOrderModel; onSetOrder: (newOrder: ExOrderModel) => void }) {
  const processor = useContext(ModuleContext);

  const [filteredCustomers, setFilteredCustomers] = useState<ExCustomerModel[]>([]);
  const [searchFields, setSearchFields] = useState<{ orgnr: string; name1: string }>({ orgnr: "", name1: "" });
  const [countries, setCountries] = useState<{ name: string; value: string }[]>([]);

  const customerFields = {
    orgnr: { label: "Orgnr*", description: "", value: searchFields.orgnr, setValue: (value: string) => filterCustomers("orgnr", value) },
    name1: { label: "Navn 1*", description: "", value: searchFields.name1, setValue: (value: string) => filterCustomers("name1", value) },
    name2: { label: "Navn 2", description: "", value: order.customer?.name2 || "", setValue: (value: string) => onSetOrder({ ...order, customer: order.customer && { ...order.customer, name2: value } }) },
    address: { label: "Adresse*", description: "", value: order.customer?.address1 || "", setValue: (value: string) => onSetOrder({ ...order, customer: order.customer && { ...order.customer, address1: value } }) },
    zipcode: { label: "Postnr*", description: "", value: order.customer?.zipcode || "", setValue: (value: string) => onSetOrder({ ...order, customer: order.customer && { ...order.customer, zipcode: value } }) },
    city: { label: "Poststed*", description: "", value: order.customer?.city || "", setValue: (value: string) => onSetOrder({ ...order, customer: order.customer && { ...order.customer, city: value } }) },
  };

  const deliveryFields = {
    name: { label: "Navn 1*", description: "", value: order.delivery.name, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, name: value } }) },
    name2: { label: "Navn 2", description: "", value: order.delivery.name2, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, name2: value } }) },
    address: { label: "CO adresse", description: "", value: order.delivery.address, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, address: value } }) },
    address2: { label: "Gateadresse*", description: "", value: order.delivery.address2, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, address2: value } }) },
    zipcode: { label: "Postnr*", description: "", value: order.delivery.zipcode, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, zipcode: value } }) },
    city: { label: "Poststed*", description: "", value: order.delivery.city, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, city: value } }) },
    country: { label: "Land*", description: "", value: order.delivery.country, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, country: value } }) },
    contact: { label: "Kontakt*", description: "", value: order.delivery.contact, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, contact: value } }) },
    mobile: { label: "Mobil*", description: "", value: order.delivery.mobile, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, mobile: value } }) },
    email: { label: "E-post*", description: "", value: order.delivery.email, setValue: (value: string) => onSetOrder({ ...order, delivery: { ...order.delivery, email: value } }) },
  };

  async function filterCustomers(fieldName: string, searchValue: string) {
    setSearchFields({ ...searchFields, [fieldName]: searchValue });
    if (searchValue.length >= 3) {
      for (const cust of filteredCustomers) {
        if ((cust as any)[fieldName] === searchValue) {
          setFilteredCustomers([]);
          setSearchFields({ orgnr: cust.orgnr, name1: cust.name1 });
          onSetOrder({ ...order, customer: cust });
          return;
        }
      }

      const customers = await processor?.runSilently(fetchCustomers, fieldName, searchValue);
      setFilteredCustomers(customers);
      onSetOrder({ ...order, customer: undefined });
    } else {
      setFilteredCustomers([]);
      setSearchFields({ orgnr: "", name1: "", [fieldName]: searchValue });
      onSetOrder({ ...order, customer: undefined });
    }
  }

  function copyCustomerToDelivery() {
    if (order.customer) {
      onSetOrder({
        ...order,
        delivery: {
          name: order.customer.name1,
          name2: order.customer.name2,
          address: "",
          address2: order.customer.address1,
          zipcode: order.customer.zipcode,
          city: order.customer.city,
          country: "NO",
          contact: "",
          email: order.customer.email,
          mobile: order.customer.phone,
        },
      });
    }
  }

  useEffect(() => {
    processor?.runSilently(ExBackend.fetchCountries).then((result) => setCountries(result));
  }, []);

  return (
    <div className="grid grid-cols-7 gap-8 w-full">
      <div className="flex flex-col gap-1 col-span-2">
        <h3 className="border-b-2 font-bold">Oppdragsgiver</h3>
        <ComboboxField model={customerFields.orgnr} list={CUSTOMER_NUMBER_DATALIST_NAME} />
        <ComboboxField model={customerFields.name1} list={CUSTOMER_NAME_DATALIST_NAME} />
        <EditboxField model={customerFields.name2} readonly />
        <EditboxField model={customerFields.address} readonly />
        <EditboxField model={customerFields.zipcode} readonly />
        <EditboxField model={customerFields.city} readonly />
        <button className="ml-auto" onClick={copyCustomerToDelivery}>
          <img className="h-7" src="/assets/images/ex/customer-to-delivery.svg" alt="copy" />
        </button>
      </div>
      <div className="flex flex-col gap-1 col-span-4">
        <h3 className="border-b-2 font-bold">Varemottaker</h3>
        <div className="flex gap-8">
          <div className="flex flex-col flex-1 gap-1">
            <EditboxField model={deliveryFields.name} />
            <EditboxField model={deliveryFields.name2} />
            <EditboxField model={deliveryFields.address} />
            <EditboxField model={deliveryFields.address2} />
            <EditboxField model={deliveryFields.zipcode} />
            <EditboxField model={deliveryFields.city} />
            <SelectboxField model={deliveryFields.country} items={countries} />
          </div>
          <div className="flex flex-col flex-1 gap-1">
            <EditboxField model={deliveryFields.contact} />
            <EditboxField model={deliveryFields.mobile} />
            <EditboxField model={deliveryFields.email} />
          </div>
        </div>
      </div>
      <Datalist id={CUSTOMER_NUMBER_DATALIST_NAME} items={filteredCustomers} getTitle={(cust) => cust.orgnr} getDescription={(cust) => `${cust.name1}, ${cust.zipcode ? cust.zipcode + " " : ""}${cust.city || ""}`} />
      <Datalist id={CUSTOMER_NAME_DATALIST_NAME} items={filteredCustomers} getTitle={(cust) => cust.name1} getDescription={(cust) => `${cust.zipcode ? cust.zipcode + " " : ""}${cust.city ? cust.city + " " : ""} (${cust.orgnr})`} />
    </div>
  );
}
