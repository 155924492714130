import { UIField } from "../../../models/UIField";

export const CheckboxField = ({ model, disabled }: { model: UIField; disabled?: boolean }) => {
  return (
    <div className="flex h-7 items-center">
      <input
        className="w-4 h-4 mr-2"
        id={model.label}
        type="checkbox"
        checked={model.value}
        disabled={disabled}
        onChange={(e) => {
          model.setValue(e.target.checked);
        }}
      />
      <label htmlFor={model.label}>{model.label}</label>
    </div>
  );
};
