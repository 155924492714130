export const TextField = ({
  label,
  text,
  readonly,
  multiline,
  onTextChanged,
}: {
  label: string;
  text: string;
  readonly?: boolean
  multiline?: boolean;
  onTextChanged: (text: string) => void;
}) => {
  return (
    <div className="flex m-1">
      <label title={label} className={`w-1/3 ${multiline ? "": "my-auto"}`}>
        {label}
      </label>
      {!multiline && (
        <input
          type="text"
          readOnly={readonly}
          className={`w-2/3 px-2 py-1 rounded-md border-2 text-black ${readonly ? "bg-transparent text-white" : ""}`}
          value={text}
          onInput={(e) => {
            onTextChanged(e.currentTarget.value);
          }}
        />
      )}
      {multiline && (
        <textarea
          readOnly={readonly}
          rows={3}
          className={`w-2/3 px-2 py-1 rounded-md border-2 text-black ${readonly ? "bg-transparent text-white" : ""}`}
          value={text}
          onInput={(e) => {
            onTextChanged(e.currentTarget.value);
          }}
        />
      )}
    </div>
  );
};
