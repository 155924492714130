import { ReactNode, createContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { StatsModel } from "../models/stats/StatsModel";
import { fetchStats } from "../backends/Stats";

export const StatsContext = createContext<{
  state: {
    status: string;
    message: string;
  };
  allOrderStats: StatsModel[];
  refreshStats: () => void;
  getOrdersCount: (fromDate: string, toDate: string) => number;
  getProductsCount: (fromDate: string, toDate: string) => number;
  getSummary: () => { date: Date; month: string; orders: number; products: number }[];
}>({
  state: {
    status: "",
    message: "",
  },
  allOrderStats: [],
  refreshStats: () => {},
  getOrdersCount: (fromDate: string, toDate: string) => {
    return 0;
  },
  getProductsCount: (fromDate: string, toDate: string) => {
    return 0;
  },
  getSummary: () => {
    return [];
  },
});

export const StatsContextComponent = ({ children }: { children: ReactNode }) => {
  const auth0 = useAuth0();

  const [state, setState] = useState<{
    status: string;
    message: string;
  }>({
    status: "",
    message: "",
  });
  const [allOrderStats, setAllOrderStats] = useState<StatsModel[]>([]);

  const refreshStats = async () => {
    try {
      setState({ status: "processing", message: "" });
      const stats = await fetchStats(auth0.user?.sub);
      setAllOrderStats(stats);
      setState({ status: "ready", message: "" });
    } catch (err: any) {
      setState({ status: "error", message: err?.message || "Kunne ikke hente prosjekter" });
    }
  };

  const getOrdersCount = (fromDate: string, toDate: string) => {
    let ordersCount = 0;
    allOrderStats.forEach((item) => {
      if (item.date_registered >= fromDate && item.date_registered <= toDate) {
        ordersCount++;
      }
    });
    return ordersCount;
  };

  const getProductsCount = (fromDate: string, toDate: string) => {
    let ordersCount = 0;
    allOrderStats.forEach((item) => {
      if (item.date_registered >= fromDate && item.date_registered <= toDate) {
        ordersCount += item.products_count;
      }
    });
    return ordersCount;
  };

  const getSummary = () => {
    const firstDayOfTheMonth = new Date();
    firstDayOfTheMonth.setDate(1);
    
    const summary = [-5, -4, -3, -2, -1, 0].map((item) => {
      const date = new Date(firstDayOfTheMonth);
      date.setMonth(date.getMonth() + item);

      return { date: date, month: "", orders: 0, products: 0 };
    });

    allOrderStats.forEach((orderStats) => {
      const orderDate = new Date(orderStats.date_registered);
      summary.forEach((item) => {
        if (item.date.getMonth() === orderDate.getMonth()) {
          item.orders += 1;
          item.products += orderStats.products_count;
        }
      });
    });

    summary.forEach((item) => {
      item.month = ["jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"][
        item.date.getMonth()
      ];
    });

    return summary;
  };

  const initValue = {
    state,
    allOrderStats,
    refreshStats,
    getOrdersCount,
    getProductsCount,
    getSummary
  };

  return <StatsContext.Provider value={initValue}>{children}</StatsContext.Provider>;
};
