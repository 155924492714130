import { useContext } from "react";
import { EmailAlertModel } from "../../../../models/email/EmailAlertModel";
import { OrderEventModel } from "../../../../models/email/OrderEventModel";
import { DataList, DataListColumn } from "../../../common/DataList";
import { SelectBox } from "../../../elements/common/SelectBox";
import { AppConstantsContext } from "../../../../contexts/AppConstantsContext";
import AppContext from "../../../../contexts/AppContext";
import backends from "../../../../backends";
import { ComboBox } from "../../../elements/common/ComboBox";
import { CheckboxList } from "../../../elements/common/CheckboxList";
import { EmailAlertsManager } from "../../../../managers/EmailAlertsManager";

const bundlesTableColumns: (events: OrderEventModel[]) => DataListColumn<EmailAlertModel>[] = (events) => {
  return [
    {
      name: "Mal",
      selector: (row: EmailAlertModel) => row.template_id,
      sortable: true,
      width: "15%",
    },
    {
      name: "Hendelse",
      selector: (row: EmailAlertModel) => row.event_id.map((item) => events.find((e) => e.id === item)?.description || "").join(", "),
      sortable: true,
      width: "35%",
    },
    {
      name: "E-post adresse",
      selector: (row: EmailAlertModel) => row.emails.join(", "),
      sortable: true,
      width: "35%%",
    },
  ];
};

const EditEmailAlert = ({ item, setItem, readonly }: { item: EmailAlertModel; setItem: (email: EmailAlertModel) => void; readonly: boolean }) => {
  const appContext = useContext(AppContext);
  const appConstants = useContext(AppConstantsContext);

  return (
    <div>
      <SelectBox
        label="Mal"
        items={appConstants.emailTemplates.map((item) => ({ id: item.name, name: item.name }))}
        onValueChanged={(value) => {
          setItem({ ...item, template_id: value });
        }}
        value={item.template_id}
        readonly={readonly}
      />
      <div className="flex gap-8 my-8">
        <div className="flex-1">
          <p>Hendelse</p>
          <div className="flex flex-col gap-1 rounded-md border-2 p-2">
            <CheckboxList
              items={appConstants.orderEvents.map((item) => ({ label: `${item.description}`, value: item.id }))}
              checkedItems={item.event_id}
              setCheckedItems={(checkedItems) => setItem({ ...item, event_id: checkedItems })}
              readonly={readonly}
            />
          </div>
        </div>
        <div className="flex-1">
          <p>Mottakere</p>
          <div className="flex flex-col gap-1 rounded-md border-2 p-2">
            {item.emails.length > 0 ? (
              item.emails.map((email, i) => (
                <div key={i} className="flex px-1 rounded-md border-2 bg-black">
                  <p className="my-auto">{email}</p>
                  {!readonly && (
                    <button
                      className="ml-auto"
                      onClick={() => {
                        setItem({ ...item, emails: item.emails.filter((e, j) => i !== j) });
                      }}
                    >
                      <img className="h-4 w-4" src="/assets/images/close.svg" alt="close" />
                    </button>
                  )}
                </div>
              ))
            ) : (
              <p className="mx-auto">?</p>
            )}
          </div>
          {!readonly && (
            <div className="mt-2 mx-2">
              <ComboBox
                onFetchValues={async () => {
                  const allEmails = await backends.emails.addresses.fetchAll(appContext.login?.uid);
                  return allEmails;
                }}
                onValidityCheck={(email) => email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? true : false}
                onEditingCompleted={(email) => {
                  setItem({ ...item, emails: [...item.emails, email] });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const EmailAlertsPanel = ({
  emailAlerts,
  setEmailAlerts,
}: {
  emailAlerts: EmailAlertModel[];
  setEmailAlerts: (emailAlerts: EmailAlertModel[]) => void;
}) => {
  const appConstants = useContext(AppConstantsContext);
  const dataManager = EmailAlertsManager(emailAlerts, setEmailAlerts);

  return (
    <div className="flex flex-1">
      <DataList<EmailAlertModel>
        dataManager={dataManager}
        columns={bundlesTableColumns(appConstants.orderEvents)}
        EditItemComponent={EditEmailAlert}
        payload={{ events: appConstants.orderEvents, templates: appConstants.emailTemplates }}
      />
    </div>
  );
};
