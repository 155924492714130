export const EditboxField = ({ label, description, value, readonly, maxChars, onSetValue }: { label?: string; description?: string; value: string; readonly?: boolean; maxChars?: number; onSetValue: (value: string) => void }) => {
  return (
    <div className="flex h-7 items-center">
      <label title={description}>{label}</label>
      <input
        type="text"
        className={`ml-auto w-3/5 px-2 rounded border-2 ${!readonly ? "text-black" : "bg-transparent text-white"}`}
        readOnly={readonly}
        value={value}
        onInput={(e) => {
          const text = e.currentTarget.value;
          onSetValue(maxChars ? text.substring(0, maxChars) : text);
        }}
      />
    </div>
  );
};
