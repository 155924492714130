import { useState } from "react";
import { EditUserDialog } from "./components";
import { SearchField, ToolButton } from "../common/elements";
import { UserMapper, UserModel } from "./models";
import { UserAction } from "./models/Actions";
import { CustomDataTable } from "../../components/common/DataTable";
import { Restricted } from "../common/components/Restricted";
import { usePermissionChecker } from "../common/hooks/usePermissionChecker";

const userListColumns = [
  { name: "Navn", selector: (row: UserModel) => row.name, sortable: true },
  { name: "E-post", selector: (row: UserModel) => row.email, sortable: true },
  { name: "Role", selector: (row: UserModel) => row.access?.role || "", sortable: true },
  { name: "Active", selector: (row: UserModel) => (row.active ? "✔" : ""), sortable: true },
];

export function UsersLayout({ users, selectedUser, onAction }: { users: UserModel[]; selectedUser?: UserModel; onAction: (action: UserAction, user?: UserModel) => void }) {
  const permissionChecker = usePermissionChecker();
  
  const [dialog, setDialog] = useState<"add" | "edit">();
  const [searchString, setSearchString] = useState<string>("");

  return (
    <div className="flex w-full h-full relative">
      {selectedUser && dialog ? (
        dialog === "add" ? (
          <EditUserDialog
            mode="edit"
            user={UserMapper.createEmptyObject()}
            onUpdate={(user) => onAction("add", user)}
            onClose={() => {
              onAction("select", undefined);
              setDialog(undefined);
            }}
          />
        ) : (
          <EditUserDialog
            mode={permissionChecker.isAccessAllowed(3) ? "view" : "readonly"}
            user={selectedUser}
            onUpdate={(user) => onAction("update", user)}
            onDelete={() => onAction("delete")}
            onClose={() => {
              onAction("select", undefined);
              setDialog(undefined);
            }}
          />
        )
      ) : null}
      <div className="w-full m-4">
        <div className="flex mb-2">
          <SearchField placeholder="Filtrer brukere" onSearch={(searchText) => setSearchString(searchText)} />
          <Restricted level={2}><ToolButton image="/assets/images/add-user.svg" className="ml-auto" onClick={() => setDialog("add")} /></Restricted>
        </div>
        <CustomDataTable<UserModel>
          columns={userListColumns}
          data={searchString.length > 0 ? users.filter((item) => item.name?.toLowerCase().includes(searchString.toLowerCase()) || item.email?.toLocaleLowerCase().includes(searchString.toLowerCase())) : users}
          onMoreClicked={async (user: UserModel) => {
            onAction("select", user);
            setDialog("edit");
          }}
        />
      </div>
    </div>
  );
}
