import { ProductSupplierModel, ProductSupplierModelMapper } from "../product/ProductSupplierModel";
import { PriceCalculator } from "../../utils/PriceCalculator";
import { ModelMapper } from "../ModelMapper";

const LEVALT_DEFAULT = "2";

export interface ProductModel {
  id: string;
  line_id: string | null;
  bundle_id?: string;
  number: string;
  ean: string;
  description: string;
  levnr: string;
  levname: string;
  levalt: string;
  levartnr: string;
  margin: string;
  price: number;
  outPrice: number;
  quantity: number;
  suppliers: ProductSupplierModel[];
  bid: string;
  custom: boolean;
  vat: boolean;
  article_type: string;
  gartnr: string;
  information: string;
}

export const ProductModelMapper: ModelMapper<ProductModel> = {
  createEmptyObject: () => {
    return {
      id: "",
      line_id: null,
      number: "",
      ean: "",
      description: "",
      levnr: "",
      levname: "",
      levalt: LEVALT_DEFAULT,
      levartnr: "",
      margin: "10%",
      price: 0,
      outPrice: 0,
      quantity: 1,
      suppliers: [],
      bid: "",
      custom: false,
      vat: true,
      article_type: "",
      gartnr: "",
      information: "",
    };
  },

  fromBackendObject: (backendObject: any) => {
    const product = ProductModelMapper.createEmptyObject();

    product.id = backendObject.id || "";
    product.line_id = backendObject.id || "";
    product.bundle_id = backendObject.bundle_id;
    product.number = backendObject.number || "";
    product.ean = backendObject.ean || "";
    product.description = backendObject.description || "";
    product.levnr = backendObject.levnr || "";
    product.levname = backendObject.levname || "";
    product.levalt = backendObject.lev_alt || LEVALT_DEFAULT;
    product.levartnr = backendObject.levartnr || "";
    product.margin = backendObject.calculation;
    product.price = backendObject.price ? parseFloat(backendObject.price) : 0;
    product.outPrice = backendObject.outprice ? parseFloat(backendObject.outprice) : PriceCalculator.GetOutPrice(product.price, product.margin);
    product.quantity = backendObject.quantity ? parseFloat(backendObject.quantity) : 1;
    product.suppliers = [
      { levnr: product.levnr, levname: product.levname },
      ...(backendObject.suppliers?.map((item: any) => ProductSupplierModelMapper.fromBackendObject(item)) || []),
    ];
    product.bid = backendObject.bid || "";
    product.vat = backendObject.moms === "1";
    product.article_type = backendObject.article_type || "";
    product.gartnr = backendObject.gartnr || "";
    product.information = backendObject.information || "";

    return product;
  },

  toBackendObject: (product: ProductModel) => {
    return {
      article_id: product.id,
      id: product.line_id,
      bundle_id: product.bundle_id,
      number: product.number,
      ean: product.ean,
      description: product.description,
      levnr: product.levnr,
      levname: product.levname,
      lev_alt: product.levalt.toString(),
      levartnr: product.levartnr,
      calculation: product.margin,
      price: product.price,
      outprice: product.outPrice,
      quantity: product.quantity,
      suppliers: product.suppliers?.map((item) => ProductSupplierModelMapper.toBackendObject(item)),
      bid: product.bid,
      moms: product.vat ? "1" : "0",
      article_type: product.article_type,
      gartnr: product.gartnr,
      information: product.information
    };
  },
};
