import { ModelMapper } from "../ModelMapper";

export interface EmailTemplateModel {
  name: string;
  description: string;
  version: string;
  html: string;
  usedBy: [];
}

export const EmailTemplateModelMapper: ModelMapper<EmailTemplateModel> = {
  createEmptyObject: () => {
    return {
      name: "",
      description: "",
      version: "",
      html: "",
      usedBy: []
    };
  },

  fromBackendObject: (backendObject: any) => {
    return { ...EmailTemplateModelMapper.createEmptyObject(),  ...backendObject };
  },

  toBackendObject: (model: EmailTemplateModel) => {
    return model;
  },
};
