import { FieldModel } from "../../../models/ui/FieldModel";
import { CheckboxField } from "./CheckboxField";
import { DateField } from "./DateField";
import { EditboxField } from "./EditboxField";
import { TextboxField } from "./TextboxField";

export const Field = ({
  field,
  labelWidth,
  value,
  onChanged,
  editable,
}: {
  field: FieldModel;
  labelWidth?: string;
  value: any;
  onChanged: (value: any) => void;
  editable?: boolean;
}) => {
  switch (field.type) {
    case "checkbox":
      return <CheckboxField label={field.text} checked={value} onCheckedChanged={onChanged} checkable={editable} />;
    case "datebox":
      return (
        <DateField field={field} labelWidth={labelWidth} value={value} onTextChanged={onChanged} editable={editable} />
      );
    case "editbox":
      return (
        <EditboxField
          field={field}
          labelWidth={labelWidth}
          value={value}
          onTextChanged={onChanged}
          editable={editable}
        />
      );
    case "textbox":
      return <TextboxField field={field} value={value} onTextChanged={onChanged} editable={editable} />;
    default:
      return <div>Unknown field type</div>;
  }
};
