import { useEffect, useState } from "react";

export const ComboBox = ({
  label,
  placeholder,
  readonly,
  onFetchValues,
  onValidityCheck,
  onEditingCompleted,
}: {
  label?: string;
  placeholder?: string;
  readonly?: boolean;
  onFetchValues: () => Promise<string[]>;
  onValidityCheck?: (value: string) => boolean;
  onEditingCompleted?: (value: string) => void;
}) => {
  const [value, setValue] = useState<string>("");
  const [allItems, setAllItems] = useState<{ label: string; value: string }[]>([]);
  const [filteredItems, setFilteredItems] = useState<{ label: string; value: string }[]>([]);
  const [isValid, setIsValid] = useState(true);

  const filterItems = (filter: string) => {
    return filter ? allItems.filter((item) => item.label.startsWith(filter)) : allItems;
  };

  useEffect(() => {
    (async () => {
      const items = (await onFetchValues()).map((item) => ({ label: item, value: item }));
      setAllItems(items);
      setFilteredItems(items);
    })();
  }, []);

  const addItem = () => {
    let isValidValue = allItems.map((item) => item.value).includes(value);
    if(!isValidValue && onValidityCheck) {
      isValidValue = onValidityCheck(value);
    }
    setIsValid(isValidValue);
    if (isValidValue) {
      onEditingCompleted?.(value);
      setValue("");
      setFilteredItems(allItems);
    }
  };

  return (
    <div className="flex flex-1 h-7">
      <datalist id="values">
        {filteredItems.map((item) => {
          return <option value={item.value} label={item.label} key={item.value} />;
        })}
      </datalist>
      {label && <label className="my-auto w-1/3">{label}</label>}
      <input
        type="text"
        list="values"
        placeholder={placeholder}
        className={`w-full px-2 rounded-md border-2 ${readonly ? "bg-transparent text-white" : isValid ? "text-black" : "text-red-400"}`}
        readOnly={readonly}
        value={value}
        onInput={(e) => {
          const newValue = e.currentTarget.value;
          setValue(newValue);
          setFilteredItems(filterItems(newValue));
        }}
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            addItem();
          }
        }}
      />
      <button className="w-8 ml-1" onClick={addItem}>
        &#10003;
      </button>
    </div>
  );
};
