export const SupportedFieldTypes = ["string", "text", "number", "boolean", "date", "select", "raw data", "object"];

export type FlowStepDataItemType = "string" | "text" | "number" | "boolean" | "date" | "select" | "raw data";

export interface FlowStepFieldModel {
  id: string;
  name: string;
  description?: string;
  type: FlowStepDataItemType;
  values?: { id: number; name: string; value: string }[];
  options?: string[];
  source?: string;
}

export interface FlowStepFieldsModel {
  in: FlowStepFieldModel[];
  out: FlowStepFieldModel[];
}
