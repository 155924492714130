export interface SchemaField<T> {
  set?: number;
  label: string;
  visible: boolean;
  export: boolean;
  getValue: (item: T) => any;
}

export interface Schema<T> {
  fields: SchemaField<T>[];
  sets?: string[];
  layout?: {
    sets: number[];
  }[];
}

export interface Mapper<T> {
  toCsv: (item?: T) => string;
}

export function SchemaMapper<T>(schema: Schema<T>): Mapper<T> {
  return {
    toCsv: (item?: T) => {
      if (item) {
        const mappedFields: string[] = [];
        schema.fields.forEach((field) => {
          if (field.export) {
            let fieldValue: string = `${field.getValue(item)}`;
            if (fieldValue.indexOf(",") > 0 || fieldValue.indexOf('"') > 0) {
              fieldValue = `"${fieldValue}"`;
            }
            mappedFields.push(fieldValue);
          }
        });
        return mappedFields.join(",");
      } else {
        return schema.fields.map((field) => field.label).join(",");
      }
    },
  };
}
