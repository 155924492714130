import * as customers from "./Customers";
import * as emails from "./Emails";
import * as messages from "./Messages";
import * as orders from "./Orders";
import * as products from "./Products";
import * as projects from "./Projects";
import * as users from "./Users";
import * as stats from "./Stats";

export default {
    customers: {
        fetchAll: customers.fetchCustomers,
        fetch: customers.fetchCustomer
    },
    emails: {
        addresses: {
            fetchAll: emails.fetchAllEmailAddresses
        },
        alerts: {
            fetchAll: emails.fetchAllEmails
        },
        events: {
            fetchAll: emails.fetchAllEvents
        },
        templates: {
            fetchAll: emails.fetchAllEmailTemplates
        },
    },
    messages: {
        fetchAll: messages.fetchAllMessages,
        create: messages.createMessage,
        delete: messages.deleteMessage
    },
    orders: {
        fetchAll: orders.fetchAllOrders,
        fetch: orders.fetchOrder,
        create: orders.createOrder,
        update: orders.updateOrder,
        delete: orders.deleteOrder
    },
    products: {
        fetchAll: products.fetchProducts,        
        fetch: products.fetchProduct
    },
    projects: {
        fetchAll: projects.fetchAllProjects,
        fetch: projects.fetchProject,
        create: projects.createProject,
        update: projects.updateProject,
        delete: projects.deleteProject
    },
    statistics: {
        fetchAll: stats.fetchStats
    },
    users: {
        fetchAll: users.fetchAllUsers,
        fetch: users.fetchUser,
        create: users.createUser,
        update: users.updateUser,
        delete: users.deleteUser
    }
}