import { ModelMapper } from "./ModelMapper";

export interface DeliveryModel {
  name: string;
  name2: string;
  contact: string;
  address: string;
  address2: string;
  zipcode: string;
  city: string;
  country: string;
  mobile: string;
  email: string;
}

export const DeliveryModelMapper: ModelMapper<DeliveryModel> = {
  createEmptyObject: () => {
    return {
      name: "",
      name2: "",
      contact: "",
      address: "",
      address2: "",
      zipcode: "",
      city: "",
      country: "NO",
      mobile: "",
      email: "",
    };
  },

  fromBackendObject: (backendObject: any) => {
    return { ...backendObject };
  },

  toBackendObject: (model: DeliveryModel) => {
    return model;
  },
};

// export default class DeliveryModelOld {
//   name: string = "";
//   contact: string = "";
//   address: string = "";
//   address2: string = "";
//   zipcode: string = "";
//   city: string = "";
//   country: string = "NO";
//   mobile: string = "";
//   email: string = "";

//   public static fromOrderBackendObject(backendObject: BackendOrderDeliveryModel): DeliveryModelOld {
//     const delivery = new DeliveryModelOld();

//     delivery.name = backendObject.name || "";
//     delivery.address = backendObject.address || "";
//     delivery.address2 = backendObject.address2 || "";
//     delivery.zipcode = backendObject.zipcode || "";
//     delivery.city = backendObject.city || "";
//     delivery.country = backendObject.country || "NO";
//     delivery.contact = backendObject.contact || "";
//     delivery.mobile = backendObject.mobile || "";
//     delivery.email = backendObject.email || "";

//     return delivery;
//   }

//   public static toOrderBackendObject(delivery: DeliveryModelOld): object {
//     return {
//       name: delivery.name,
//       address: delivery.address,
//       address2: delivery.address2,
//       zipcode: delivery.zipcode,
//       city: delivery.city,
//       country: delivery.country,
//       contact: delivery.contact,
//       mobile: delivery.mobile,
//       email: delivery.email,
//     };
//   }
// }
