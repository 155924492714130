export interface ExProductSupplierModel {
  levnr: string;
  levname: string;
}

export const ExProductSupplierMapper = {
  empty: () => {
    return {
      levnr: "",
      levname: "",
    };
  },

  fromBackendObject: (bo: any) => {
    return bo as ExProductSupplierModel;
  },

  toBackendObject: (productSupplier: ExProductSupplierModel) => {
    return productSupplier;
  },
};

export interface ExProductDeliveryOption {
  lev_alt: string;
  description: string;
}

export interface ExProductModel {
  id: string;
  number: string;
  description: string;
  levartnr: string;
  gartnr: string;
  levnr: string;
  levname: string;
  ean: string;
  price: number;
  stock: number;
  sys: string;
  article_type: string;

  suppliers: {
    levnr: string;
    levname: string;
  }[];
}

export const ExProductMapper = {
  empty: () => {
    return {
      id: "",
      number: "",
      description: "",
      levartnr: "",
      gartnr: "",
      levnr: "",
      levname: "",
      ean: "",
      price: 0,
      stock: 0,
      sys: "",
      article_type: "",

      suppliers: [],
    };
  },

  fromBackendObject: (bo: any) => {
    return {
      ...bo,
      price: parseFloat(bo.price),
      stock: parseInt(bo.stock),

      suppliers: bo.suppliers ? [{ levnr: bo.levnr, levname: bo.levname }, ...bo.suppliers] : [{ levnr: bo.levnr, levname: bo.levname }],
    };
  },

  toBackendObject: (product: ExProductModel) => {
    return {
      ...product,
      price: `${product.price}`,
      stock: `${product.stock}`,
    };
  },
};
